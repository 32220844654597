import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import _ from "underscore";
import { RootState } from "../redux/store";
import { components } from "../generated-types/api";
import { Diagram, PropertyStore } from "../redux/reducer/property";

const baseUrl = process.env.REACT_APP_FH_API_URL;
if (!baseUrl) {
  throw new Error("Cannot read baseURL from environment");
}

export const fetchDiagramDataArgKeys = [
  "alignment",
  "defaultHeight",
  "direction",
  "edgeSpan",
  "expandIDs",
  "expandedRowHeight",
  "iconBufferSpace",
  "layerSpan",
  "maxColumnRender",
  "maxTextSizeRender",
  "nodeSpan",
  "operatorRadius",
  "sqlData",
  "symbolWidth",
  "titlePaddingWidth",
] as const;

export type FetchDiagramDataArg = Pick<
  Diagram,
  (typeof fetchDiagramDataArgKeys)[number]
>;

export const isFetchDiagramArg = (
  diagram: object
): diagram is FetchDiagramDataArg => {
  return fetchDiagramDataArgKeys
    .map((key) => _.has(diagram, key))
    .reduce((acc, val) => acc && val, true);
};

export const fetchDiagramData = async (
  data: FetchDiagramDataArg,
  accessToken: string
): Promise<AxiosResponse<components["schemas"]["ParSeQLOutput"]>> => {
  if (data.sqlData === "") {
    throw new Error("empty sql");
  }

  const url = new URL("layout/ui", baseUrl);
  const body: Pick<typeof data, (typeof fetchDiagramDataArgKeys)[number]> & {
    collapse: boolean;
    expanded: string[];
    json: boolean;
    sql: string;
  } = Object.assign(_.pick(data, ...fetchDiagramDataArgKeys), {
    collapse: true,
    expanded: data.expandIDs,
    json: true,
    sql: data.sqlData,
  });

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
  };

  return await axios.post(url.href, body, config);
};

export type SampleDataType = "sampleParse" | "sampleFormat" | "sampleLayout";

// For now, just mirror the diagram request
export const fetchSampleDataArgKeys = fetchDiagramDataArgKeys;

export const isFetchSampleArg = (
  diagram: object
): diagram is FetchSampleDataArg => {
  return fetchSampleDataArgKeys
    .map((key) => _.has(diagram, key))
    .reduce((acc, val) => acc && val, true);
};

export type FetchSampleDataArg = Diagram;

/**
 *
 * @param rawInputEnabled - used only when type is "sampleParse"
 * @returns
 */
export async function fetchSampleData(
  dataType: {
    type: SampleDataType;
    sample: PropertyStore["userLayout"][string]["sampleTab"];
  },
  data?: FetchSampleDataArg,
  rawInputEnabled?: boolean
): Promise<AxiosResponse<components["schemas"]["ParSeQLOutput"]>> {
  let id: number;
  switch (dataType.sample) {
    case "Simple":
      id = 1;
      break;
    case "Regular":
      id = 2;
      break;
    case "Complex":
      id = 3;
      break;
  }

  const url = new URL(`${dataType.type}/${id}`, baseUrl);

  let body = {};
  if (dataType.type === "sampleLayout" && data) {
    body = Object.assign(_.pick(data, ...fetchSampleDataArgKeys), {
      collapse: true,
      expanded: data?.sampleTabExpandIDS?.[dataType?.sample] ?? [],
      json: true,
    });
  } else if (dataType.type === "sampleLayout") {
    throw new Error("Need data to fetchSampleLayout");
  }

  let params: AxiosRequestConfig["params"] = {};
  if (dataType.type === "sampleParse" && rawInputEnabled !== undefined) {
    params = {
      rawInputEnabled,
    };
  }

  const config: AxiosRequestConfig = {
    headers: {
      "Content-Type": "application/json",
    },
    params,
  };

  return await axios.post(url.href, body, config);
}

export const fetchReportsData = async (
  accessToken: string
): Promise<AxiosResponse<components["schemas"]["Report"]>> => {
  const url = new URL("reports", baseUrl);
  const body = {};
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: accessToken,
    },
  };
  return await axios.post(url.href, body, config);
};

export const fetchParseData = async (
  data: string,
  rawInputEnabled: boolean,
  accessToken: string
): Promise<AxiosResponse<components["schemas"]["ParSeQLOutput"]>> => {
  const url = new URL("parse/ui", baseUrl);
  const body = {
    rawInputEnabled,
    sql: data,
    json: true,
  };
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: accessToken,
    },
  };

  return await axios.post(url.href, body, config);
};

export const fetchOptimizeData = async (
  data: string,
  accessToken: string
): Promise<AxiosResponse<components["schemas"]["ParSeQLOutput"]>> => {
  const url = new URL("parse", baseUrl);
  const body = {
    sql: data,
    json: true,
    rawInputEnabled: true,
    xml: true,
  };
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: accessToken,
    },
  };

  return await axios.post(url.href, body, config);
};

export const fetchAnalyzeData = async (
  data: string,
  accessToken: string
): Promise<AxiosResponse<components["schemas"]["ParSeQLOutput"]>> => {
  const url = new URL("formatter/ui", baseUrl);
  const body = {
    sql: data,
  };
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: accessToken,
    },
  };

  return axios.post(url.href, body, config);
};

export interface LogsObject {
  error: {
    message: string;
    stack: string;
  };
  logs: string[];
  reduxState: RootState;
  timestamp: string;
}

export const exportLogsObject = async (data: LogsObject) => {
  const url = new URL("debug", baseUrl);
  const body = data;
  return axios.post(url.href, body);
};
