/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setDynamicNotifyText } from "../../../redux/actions/property";
import "./dynamicNotify.css";
import { setClickSearch } from "../../../redux/actions/global";

const DynamicNotify = ({ text, query }) => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.propertyReducer.user);
  const currentDiagram =
    useSelector(
      (state) => state.propertyReducer.userLayout[user].currentDiagram
    ) || "global";
  const activeDynamicNotifyText = useSelector(
    (state) =>
      state.propertyReducer.userLayout[user].dynamicNotifyText[currentDiagram]
  );

  // let parseAndVisualzeSnackbar = false;
  //  if (query!== null && query!== undefined) {
  //      parseAndVisualzeSnackbar = true;
  // }

  const handleNewTab = (e) => {
    if (e === "search") {
      dispatch(setClickSearch(true));
    }
  };
  useEffect(() => {
    //Added this check for disapearing the snackbar when snackbar is coming from parse and visualze screen.
    setTimeout(
      () => {
        dispatch(setDynamicNotifyText(null));
      },
      query === "edit" ? 3000 : 5000
    );
  }, [text]);
  return (
    <div className="dynamic-notify-wrapper">
      <div className="dynamic-notify-text">
        {text}{" "}
        {activeDynamicNotifyText === "edit" ? (
          <span
            style={{ color: "#01C1D5", cursor: "pointer" }}
            onClick={() => handleNewTab("edit")}
          >
            {" "}
            &nbsp; CREATE A NEW QUERY
          </span>
        ) : activeDynamicNotifyText === "search" ? (
          <span
            style={{ color: "#01C1D5", cursor: "pointer" }}
            onClick={() => handleNewTab("search")}
          >
            {" "}
            &nbsp; SEARCH IN EDITOR
          </span>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default DynamicNotify;
