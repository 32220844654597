import { GlobalStore } from "../reducer/global";
import { RootState } from "../store";

export const selectGrandTotalFromReportsData = (state: RootState): number => {
  return state.globalReducer.reportsData.grandTotal;
};

export const selectAntipatternData = (
  state: RootState
): GlobalStore["antipatternData"] => {
  return state.globalReducer.antipatternData;
};

export const selectGlobalModalID = (
  state: RootState
): GlobalStore["modalID"] => {
  return state.globalReducer.modalID;
};
