import { Ace as AceTypes } from "ace-builds";
import Ace from "ace-builds/src-noconflict/ace";
import log from "loglevel";
import { Diagram } from "../redux/reducer/property";
import _ from "underscore";
import { EmptyObject, SetRequired, Simplify } from "type-fest";
import { components } from "../generated-types/api";

/**
 * Create an Ace.Range from a position string as returned by the API
 * @param {string} editorContents - The current contents of the editor as a string
 * @param {string} position - a position string in the form "start-offset"
 */
export const getRangeFromPositionString = (
  editorContents: string,
  position: string
): AceTypes.Range | undefined => {
  let [startPositionString, endPositionString] = position.split("-");
  if (
    startPositionString === undefined ||
    startPositionString === "" ||
    endPositionString === undefined ||
    endPositionString === ""
  ) {
    throw new Error("Recieved bad position string: " + position);
  }
  const startPosition = Number(startPositionString);
  const endPosition = Number(endPositionString) + startPosition;

  const lines = editorContents.split("\n");

  let currentPosition = 0;
  let isSelectStartPosition = false;
  let startRow: number | undefined = undefined;
  let startColumn: number | undefined = undefined;
  let endRow: number | undefined = undefined;
  let endColumn: number | undefined = undefined;
  for (let i = 0; i < lines.length; i++) {
    const currentLine = lines[i];
    if (
      startPosition <= currentPosition + currentLine.length &&
      !isSelectStartPosition
    ) {
      isSelectStartPosition = true;
      startRow = i;
      startColumn = startPosition - currentPosition;
    }
    if (endPosition <= currentPosition + currentLine.length) {
      endRow = i;
      endColumn = endPosition - currentPosition;
      break;
    }
    // Add one to account for newline character
    currentPosition += currentLine.length + 1;
  }
  if (
    startRow === undefined ||
    startColumn === undefined ||
    endRow === undefined ||
    endColumn === undefined
  ) {
    log
      .getLogger("antipattern")
      .warn(
        "getRangeFromPositionString received bad position string for editor contents",
        editorContents,
        position
      );
    return;
  } else {
    return new Ace.Range(startRow, startColumn, endRow, endColumn);
  }
};

export const isParseDataValid = (
  parseData: components["schemas"]["ParSeQLOutput"] | {} | undefined
): parseData is SetRequired<
  Exclude<components["schemas"]["ParSeQLOutput"], EmptyObject>,
  "statement"
> => {
  return (
    parseData !== undefined &&
    !_.isEmpty(parseData) &&
    (parseData as Exclude<Diagram["optimiseData"], EmptyObject>).statement !==
      undefined &&
    !_.isEmpty(
      (parseData as Exclude<Diagram["optimiseData"], EmptyObject>).statement
    )
  );
};

export interface AEL {
  antipatterns: Simplify<
    Omit<components["schemas"]["AntiPattern"], "pos"> & {
      pos: string;
      range: AceTypes.Range;
      row: number;
    }
  >[];
  antipatternsCount: number;
  errors: (components["schemas"]["Error"] & {
    range: AceTypes.Range;
    pos: string;
    row: number;
  })[];
  errorsCount: number;
  lineIndex: number;
  setmark: number;
}

/**
 * Construct an AEL array, containing information about antipatterns and errors to render by line
 * @param optimiseData  - Response from the server containing information on antipatterns and errors
 * @param sqlData - The contents of the editor
 * @param distinctResults
 * @returns An array of AEL objects
 */
export const getAntipatternAndErrorLines = (
  optimiseData: Diagram["optimiseData"],
  sqlData?: string,
  distinctResults = true
): AEL[] => {
  if (!isParseDataValid(optimiseData) || sqlData === undefined) {
    return [];
  }

  const result: AEL[] = [];

  // If there are neither antipatterns nor errors return early
  if (!optimiseData.statement[0].antiPatterns && !optimiseData.error) {
    return [];
  }

  const logger = log.getLogger("antipattern");

  optimiseData.statement?.forEach((value) => {
    let antiPatterns = value.antiPatterns;
    if (antiPatterns) {
      antiPatterns.forEach((antiPattern) => {
        antiPattern.pos.forEach((pos) => {
          let antipatternRange: AceTypes.Range | undefined;
          try {
            antipatternRange = getRangeFromPositionString(sqlData, pos);
          } catch (error: unknown) {
            logger.warn(
              `passed bad input to getRangeFromPositionString, ${error}`
            );
            return;
          }

          if (antipatternRange === undefined) {
            return;
          }

          const startRow = antipatternRange.start.row;

          const antipatternForAEL = {
            ...antiPattern,
            pos,
            range: antipatternRange,
            row: startRow,
          };

          // Initialize the result
          if (!result[startRow]) {
            result[startRow] = {
              lineIndex: startRow,
              errorsCount: 0,
              antipatternsCount: 1,
              antipatterns: [antipatternForAEL],
              errors: [],
              setmark: startRow,
            };
          } else {
            // Or it's already initialized
            result[startRow].antipatternsCount++;
            result[startRow].antipatterns.push(antipatternForAEL);
          }
        });
      });
    }
  });

  optimiseData.error?.forEach((value) => {
    if (value.pos === undefined) {
      return;
    }
    let errorRange: AceTypes.Range | undefined;
    try {
      errorRange = getRangeFromPositionString(sqlData, value.pos);
    } catch (error: unknown) {
      logger.warn(`passed bad input to getRangeFromPositionString, ${error}`);
      return;
    }

    if (errorRange === undefined) {
      return;
    }

    const startRow = errorRange.start.row;

    const errorForAEL = {
      ...(value as SetRequired<typeof value, "pos">), // At this point we've already returned if pos is undefined
      range: errorRange,
      row: startRow,
    };

    // Initialize the result
    if (!result[startRow]) {
      result[startRow] = {
        lineIndex: startRow,
        errorsCount: 1,
        antipatternsCount: 0,
        antipatterns: [],
        errors: [errorForAEL],
        setmark: startRow,
      };
    } else {
      // Or it's already initialized
      result[startRow].errorsCount++;
      result[startRow].errors.push(errorForAEL);
    }
  });

  if (distinctResults) {
    result.forEach((item) => {
      // Get distinct antipatterns
      item.antipatterns = Array.from(
        new Map(item.antipatterns.map((x) => [x["type"], x])).values()
      );

      // Get distinct errors
      item.errors = Array.from(
        new Map(item.errors.map((x) => [x["message"], x])).values()
      );

      item.antipatternsCount = item.antipatterns.length;
      item.errorsCount = item.errors.length;
    });
  }
  return Object.values(result).sort((a, b) => a.lineIndex - b.lineIndex);
};
/**
 *
 * @param parseData
 * @param eltype
 * @param pos - A position string in the format index-offset, possibly with multiple strings seperated by ;
 * @returns
 */
export const getAntipatternsOrErrorsByPos = (
  parseData: SetRequired<
    Exclude<components["schemas"]["ParSeQLOutput"], EmptyObject>,
    "statement"
  >,
  eltype: "antipattern" | "error",
  pos: string
):
  | components["schemas"]["Error"][]
  | components["schemas"]["AntiPattern"][] => {
  if (eltype === "antipattern") {
    return (
      parseData.statement
        .flatMap((value) =>
          value.antiPatterns?.filter((pattern) => pattern.pos.includes(pos))
        )
        .filter(
          (value): value is components["schemas"]["AntiPattern"] =>
            value !== undefined
        ) ?? []
    );
  } else {
    return (
      parseData.error
        ?.filter((value) => value.eltype === "error" && value.pos === pos)
        .filter((value) => value !== undefined) ?? []
    );
  }
};

export const validateEnvVar = (
  varName: string,
  varValue: string | undefined
) => {
  if (!varValue) {
    console.error(`Cannot get ${varName} from ENV`);
    throw new Error(`Cannot get ${varName} from ENV`);
  }
  return varValue;
};
