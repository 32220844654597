import { RootState } from "../store";

export const selectAuthState = (state: RootState) => {
  const expiresAt = state.authReducer.expiresAt;
  const isAuthenticated = new Date().getTime() < expiresAt;

  return {
    ...state.authReducer,
    isAuthenticated,
  };
};
