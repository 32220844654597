/* eslint-disable react-hooks/exhaustive-deps */
import React, { forwardRef, useEffect } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import Content from "../content";
import {
  setDiagramBoxState,
  setActiveToolbarItem,
} from "../../redux/actions/layout";
import LoaderComponent from "../commonComponent/loaderComponent";
import { useParams } from "react-router-dom";

const MainBoard = forwardRef((props, parseEditorRef) => {
  const param = useParams();
  const option = param["*"].split("-sql")[0].toLowerCase();
  const dispatch = useDispatch();
  const showLoadingMask = useSelector(
    (state) => state.globalReducer.showLoadingMask
  );
  useEffect(() => {
    if (option) {
      batch(() => {
        dispatch(setDiagramBoxState(option));

        dispatch(setActiveToolbarItem(option));
      });

      // set window title
      switch (option) {
        case "visualise":
          document.title = "FlowHigh - SQL Visualiser and SQL Formatter";
          break;
        case "parse":
          document.title =
            "FlowHigh - SQL parser. Online SQL parsing as a Service";
          break;
        case "analyse":
          document.title = "FlowHigh - SQL Analyse.";
          break;
        case "parser-sdk":
          document.title = "FlowHigh - SDK";
          break;
        case "tune":
          document.title = "FlowHigh - SQL tuning";
          break;
        case "Survey":
          document.title = "Survey -SQL";
          break;
        default:
          break;
      }
    }
  }, [option]);

  return (
    <>
      {showLoadingMask && (
        <>
          <div className="mask overlay"></div>
          <LoaderComponent />
        </>
      )}
      <div className="flex-column" style={{ width: "100%" }}>
        <Content
          ref={parseEditorRef}
          setFindPreviousInEditor={props.setFindPreviousInEditor}
          setFindNextInEditor={props.setFindNextInEditor}
        />
      </div>
    </>
  );
});

export default MainBoard;
