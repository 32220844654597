import React from "react";
import { batch, useDispatch } from "react-redux";
import { applyZoomAction, zoomButtonClicked } from "../../redux/actions/layout";
import {
  fullscreenButtonClicked,
  setSvgResized,
} from "../../redux/actions/layout";
import { ReactComponent as ZoomFitSvg } from "../../assets/icons/zoom-fit.svg";
import { ReactComponent as ZoomOriginSvg } from "../../assets/icons/zoom-100.svg";
import { ReactComponent as FullscreenSvg } from "../../assets/icons/fullscreen.svg";

import "./zoomToolNav.css";

const ZoomToolNav = () => {
  const dispatch = useDispatch();

  const zoomOriginal = () => {
    batch(() => {
      dispatch(zoomButtonClicked());
      dispatch(applyZoomAction("original"));
    });
  };

  const zoomFit = () => {
    batch(() => {
      dispatch(zoomButtonClicked());
      dispatch(applyZoomAction("fit"));
    });
  };

  // set full screen / restore.
  const handleClickedFullScreen = () => {
    batch(() => {
      dispatch(setSvgResized());
      dispatch(fullscreenButtonClicked());
    });
  };

  return (
    <div className="zoomtool-wrapper">
      <span
        title="Zoom to Fit"
        className="float-item"
        data-testid="zoom-to-fit"
        onClick={() => {
          zoomFit();
        }}
      >
        <ZoomFitSvg />
      </span>
      <span
        title="Reset Zoom"
        className="float-item"
        data-testid="reset-zoom"
        onClick={() => {
          zoomOriginal();
        }}
      >
        <ZoomOriginSvg />
      </span>
      <span
        title="Fullscreen"
        className="float-item"
        data-testid="full-screen"
        onClick={() => {
          handleClickedFullScreen();
        }}
      >
        <FullscreenSvg />
      </span>
    </div>
  );
};

export default ZoomToolNav;
