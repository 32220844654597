import React from "react";
import { useAppSelector } from "../../redux/hooks";
import AntiPatternModal from "../modals/antiPatternModal";
import FeedbackModal from "../modals/feedbackModal";
import DataLineageModal from "../modals/dataLineageModal";
import ResetPasswordModal from "../modals/resetPasswordModal";
import TryModal from "../modals/tryModal";
import TuningModal from "../modals/tuningModal";
import { GlobalStore } from "../../redux/reducer/global";
import PostRegistrationModal from "../modals/post-registration";
import UnsupportedBrowserModal from "../modals/unsupportedBrowser";
import LoginModal from "../modals/loginModal";
import SignupModal from "../modals/signupModal";
import UnauthorizedDomain from "../modals/unauthorized-domain";

export const modalRegistry: {
  [Name in GlobalStore["modalID"]]: () => JSX.Element | null;
} = {
  antipattern: () => <AntiPatternModal />,
  feedback: () => <FeedbackModal />,
  "data-lineage": () => <DataLineageModal />,
  "post-registration": () => <PostRegistrationModal />,
  "reset-password": () => <ResetPasswordModal />,
  try: () => <TryModal />,
  tuning: () => <TuningModal />,
  "unsupported-browser": () => (
    <UnsupportedBrowserModal type="unsupported-browser" />
  ),
  "unsupported-resolution": () => (
    <UnsupportedBrowserModal type={"unsupported-resolution"} />
  ),
  login: () => <LoginModal />,
  signup: () => <SignupModal />,
  "unauthorized-domain": () => <UnauthorizedDomain />,
  "": () => null,
};

const ModalWrapper: React.FC = () => {
  const globalModalID = useAppSelector((state) => state.globalReducer.modalID);

  return (
    <div data-testid="modal-wrapper">{modalRegistry[globalModalID]()}</div>
  );
};

export default ModalWrapper;
