import AceEditor from "react-ace";
import "./Message.css";
import { useRef } from "react";
export let downEditor = "";
const Message = ({ msgData, classes, mode, highlightText }) => {
  const editorRef = useRef();
  downEditor = editorRef;

  return (
    <div className={`parse-box pane ${classes} sdk`} style={{ height: `100%` }}>
      <div style={{ height: `100%` }}>
        <AceEditor
          className="parse-editor-container"
          mode={mode}
          theme={mode}
          name="parseDataEditor"
          editorProps={{ $blockScrolling: true }}
          style={{ height: "100%", width: "100%", borderTop: "none" }}
          setOptions={{
            fontFamily: "Roboto Mono, Consolas, monospace",
            fontSize: "12px",
            showLineNumbers: true,
            showPrintMargin: false,
            wrap: false,
            hScrollBarAlwaysVisible: false,
            vScrollBarAlwaysVisible: true,
            tabSize: 4,
            cursorStyle: "smooth",
            useWorker: false,
            readOnly: true,
          }}
          highlightActiveLine={false}
          ref={editorRef}
          value={msgData}
          onScroll={highlightText}
        />
      </div>
    </div>
  );
};

export default Message;
