import { ParseRemoteConfig } from "papaparse";
import React, { useState, useEffect } from "react";
import SelectSearch, {
  SelectedOptionValue,
  fuzzySearch,
} from "react-select-search";
import { usePapaParse } from "react-papaparse";
import "./countrySelector.css";
import countryListCSV from "../../../assets/data/countryList.csv";

interface OptionValue {
  name: string;
  value: string;
}

export interface CountrySelectorProps {
  onChange: (e: OptionValue) => void;
  value: string;
}

const CountrySelector = (props: CountrySelectorProps) => {
  const [countryList, setCountryList] = useState<OptionValue[]>([]);
  const { readRemoteFile } = usePapaParse();

  useEffect(() => {
    const papaConfig: ParseRemoteConfig = {
      complete: (results) => {
        if (results.errors.length !== 0) {
          console.error("Error while parsing:", results.errors);
          return;
        }
        let list: OptionValue[] = [];
        results.data.forEach((element) => {
          list.push({
            value: element[0],
            name: element[1],
          });
        });

        setCountryList(list);
      },
      delimiter: ",",
      download: true,
    };

    readRemoteFile(countryListCSV, papaConfig);
  }, [readRemoteFile]);

  return (
    <SelectSearch
      options={countryList}
      value={props.value}
      onChange={
        props.onChange as (
          value: SelectedOptionValue | SelectedOptionValue[]
        ) => void
      }
      search={true}
      filterOptions={fuzzySearch}
      placeholder="Select your country *"
    />
  );
};

export default CountrySelector;
