/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./optimiseDataBox.css";
import PatternMark from "../commonComponent/patternMark";
import { useSelector } from "react-redux";
import { ReactComponent as Antipattern } from "../../assets/icons/antipattern.svg";
import { ReactComponent as Analyse } from "../../assets/icons/analyse-default.svg";
import SuggestModal from "./suggestModal";
import AntiPatternContent from "./AntiPatternContent";
import { useAuth } from "../../auth/AuthContext";

const OptimiseDataBox = ({ width }) => {
  const user = useSelector((state) => state.propertyReducer.user);
  const currentDiagram = useSelector(
    (state) => state.propertyReducer.userLayout[user].currentDiagram
  );
  const activeDisplayedAntiPattern = useSelector(
    (state) =>
      state.propertyReducer.userLayout[user].diagramList[currentDiagram]
        .displayedAntiPattern
  );
  const diagramData = useSelector(
    (state) =>
      state.propertyReducer.userLayout[user].diagramList[currentDiagram]
  );

  const { isAuthenticated, login } = useAuth();

  const [show, setShow] = useState(false);
  const [Count, setCount] = useState(0);
  const [lineIndex, setLineIndex] = useState(0);

  const Close = () => {
    setShow(false);
  };
  const handleClick = async () => {
    if (isAuthenticated) {
      setShow(true);
    } else {
      setShow(false);
      await login();
    }
  };

  useEffect(() => {
    if (activeDisplayedAntiPattern?.eltype === "antipattern") {
      setCount(activeDisplayedAntiPattern.count);
      setLineIndex(activeDisplayedAntiPattern.lineIndex + 1);
    } else {
      setCount(0);
      setLineIndex(0);
    }
  }, [width, activeDisplayedAntiPattern]);
  return (
    <>
      <div className="tuning-pane pane">
        {diagramData?.isShowAntiPattern && diagramData?.sqlData ? (
          <div className="optimise-wrapper wrapper">
            <div className="sub-header">
              <PatternMark
                width={20}
                height={18}
                count={Count}
                color="#F9A825"
              />
              <div className="pattern-line">
                Anti patterns, Line {lineIndex}
              </div>
            </div>
            <div className="optimise-box">
              <div
                className="anti-flex"
                style={
                  !activeDisplayedAntiPattern
                    ? { marginTop: "6%" }
                    : { marginTop: "2.5%" }
                }
              >
                {!activeDisplayedAntiPattern ? (
                  <div className="anti-flex" style={{ marginTop: "6%" }}>
                    <h1 className="anti-heading">
                      No anti patterns
                      <br /> found
                    </h1>
                    <div className="hr"> </div>
                    <Antipattern className="anti-img" />
                    <div className="hr" style={{ width: "312px" }}></div>
                  </div>
                ) : (
                  <AntiPatternContent />
                )}
                <div className="suggest-btn">
                  <button onClick={handleClick}>Suggest an anti pattern</button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="optimise-wrapper wrapper">
            <div className="optimise-box">
              <div className="sub-header-default"></div>
              <div className="anti-flex" style={{ marginTop: "2.5%" }}>
                <div className="anti-flex" style={{ marginTop: "6%" }}>
                  <h1 className="anti-heading">
                    Detect and highlight
                    <br />
                    anti patterns.
                  </h1>
                  <div className="hr"> </div>
                  <p className="anti-para">
                    FlowHigh enables you to write a more efficient
                    <br /> SQL queries, by detecting and highlighting
                    <br /> anti patterns.
                  </p>

                  <div className="hr"> </div>
                  <Analyse className="anti-img" />
                  <div className="hr" style={{ width: "312px" }}></div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <SuggestModal show={show} onClose={Close} />
    </>
  );
};

export default OptimiseDataBox;
