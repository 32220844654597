import React from "react";
import { useSelector } from "react-redux";

const ListItem = (props) => {
  const { itemArray, handleClickedItem } = props;

  const store = useSelector((state) => state);
  const { propertyReducer } = store;
  const { userLayout, user } = propertyReducer;
  const { currentDiagram, queryModified } = userLayout[user];
  const activeQueryModified = queryModified[currentDiagram];

  return itemArray.map((item) => (
    <li key={item.id}>
      <label
        className={
          (item.highlighted ? " checked" : "") +
          (activeQueryModified ? " disabled" : "")
        }
      >
        <input
          className="table-item-checkbox"
          node_id={item.id}
          onChange={!activeQueryModified ? handleClickedItem : null}
          disabled={activeQueryModified}
          type="checkbox"
          checked={item.highlighted}
        />
        <span className="item-name">
          {" "}
          {item.count > 1 ? item.name + " : (" + item.count + ")" : item.name}
        </span>
      </label>
    </li>
  ));
};

export default ListItem;
