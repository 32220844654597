import { ErrorBoundaryPropsWithComponent } from "react-error-boundary";
import { DEFAULT_LOGS_STORAGE_KEY } from "./writeLogsToSessionStorage";
import { store } from "../redux/store";
import * as flowhighClient from "../client/flowhighClient";

export interface SendLogsToAPIOptions {
  storeKey: string;
}

const defaultOptions: SendLogsToAPIOptions = {
  storeKey: DEFAULT_LOGS_STORAGE_KEY,
};

export const createLogExporter = (
  options: SendLogsToAPIOptions = defaultOptions
) => {
  const handler: ErrorBoundaryPropsWithComponent["onError"] = (error, info) => {
    console.log("error handler called");
    const rawLogStack = sessionStorage.getItem(options.storeKey);
    let logs: string[];
    if (!rawLogStack) {
      logs = [];
    } else {
      logs = JSON.parse(rawLogStack);
    }

    const reduxState = store.getState();

    const requestBody: flowhighClient.LogsObject = {
      error: {
        message: error.message,
        stack: info.componentStack,
      },
      logs,
      reduxState,
      timestamp: new Date().toISOString(),
    };
    if (process.env.NODE_ENV === "development") {
      console.error("Encountered top level error", requestBody);
      sessionStorage.setItem(options.storeKey, "[]");
    } else {
      flowhighClient
        .exportLogsObject(requestBody)
        .then((response) => {
          if (response.status !== 200) {
            console.error(
              "Failed to export logs, received status ",
              response.status
            );
          } else {
            sessionStorage.setItem(options.storeKey, "[]");
          }
        })
        .catch((error) => {
          console.error("Problem while exporting logs ", error);
        });
    }
  };
  return handler;
};
