/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import ToolbarNav from "../toolbarNav";
import { ReactComponent as LogoSvg } from "../../assets/icons/sonra-logo.svg";
import "./leftSideBar.css";

const LeftSideBar = () => {
  return (
    <>
      <ToolbarNav />
      <div className="left-side-bar header" data-testid="left-side-bar">
        <div className="logo-wrapper">
          <a href="#">
            <LogoSvg className="logo-img" />
          </a>
        </div>
      </div>
    </>
  );
};

export default LeftSideBar;
