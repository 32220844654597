export const DEFAULT_LOGS_STORAGE_KEY = "flowhigh-logs";

export interface WriteLogsOptions {
  // The key to use when storing in session storage
  storeKey: string;
}

export const writeLogsToSessionStorage = (
  logs: string[],
  options: WriteLogsOptions = {
    storeKey: DEFAULT_LOGS_STORAGE_KEY,
  }
) => {
  if (logs.length === 0) {
    return;
  }

  const persistedLogs = sessionStorage.getItem(options.storeKey);

  let parsedLogs: string[];
  if (!persistedLogs) {
    parsedLogs = [];
  } else {
    parsedLogs = JSON.parse(persistedLogs) as string[];
  }

  const logsToPersist = parsedLogs.concat(logs);
  sessionStorage.setItem(options.storeKey, JSON.stringify(logsToPersist));
};
