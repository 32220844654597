/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import {
  setGlobalModalID,
  setResetPasswordState,
} from "../../../redux/actions/global";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ReactComponent as ResetPasswordImage } from "../../../assets/icons/reset-password-image.svg";
import { ReactComponent as ResetPasswordSuccessImage } from "../../../assets/icons/reset-password-success-image.svg";
import { ReactComponent as LogoSvg } from "../../../assets/icons/sonra-logo.svg";
import "./resetPasswordModal.css";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { useAuth } from "../../../auth/AuthContext";

const ResetPasswordModal = () => {
  const dispatch = useAppDispatch();
  const resetPasswordState = useAppSelector(
    (state) => state.globalReducer.resetPasswordState
  );
  const { sendChangePasswordEmail } = useAuth();

  const onClose = () => {
    dispatch(setGlobalModalID(""));
  };

  useEffect(() => {
    dispatch(setResetPasswordState("start"));
  }, []);

  const handleClickedResend = () => {
    dispatch(setResetPasswordState("start"));
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Must be a valid email")
      .max(255)
      .required("Email is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    onSubmit: async (values) => {
      await formik.validateField("email");
      if (values.email && !formik.errors.email) {
        sendChangePasswordEmail(values.email);
      }
    },
    validationSchema,
  });

  return (
    <div
      style={{
        visibility: "visible",
        opacity: "1",
      }}
      className="overlay"
    >
      <div className="popup">
        <div className="content" data-testid="global-modal-reset-password">
          <div className="reset-password-modal-body">
            <div className="left-panel">
              {resetPasswordState === "start" ||
              resetPasswordState === "fail" ? (
                <ResetPasswordImage className="reset-password-image" />
              ) : (
                <ResetPasswordSuccessImage className="reset-password-image" />
              )}
            </div>
            <div className="right-panel">
              <LogoSvg className="reset-password-avata" />
              {resetPasswordState === "start" ||
              resetPasswordState === "fail" ? (
                <>
                  <h1 className="reset-password-caption1">FlowHigh</h1>
                  <div className="reset-password-caption2">Reset Password</div>

                  <div className="reset-password-description">
                    <div>
                      We'll email you instruction to reset your password. If you
                      don't have access to your email anymore, you can&nbsp;
                      <a
                        href=" https://sonra.io/contact-sonra/"
                        target="_blank"
                      >
                        contact us
                      </a>
                    </div>
                  </div>

                  <form onSubmit={formik.handleSubmit}>
                    <input
                      className={`email${
                        formik.errors.email ? " required" : ""
                      }`}
                      name="email"
                      placeholder="Email *"
                      type="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      data-testid="forgot-pass-input"
                    />
                    <span className="error">{formik.errors.email}</span>
                    {resetPasswordState === "fail" ? (
                      <span className="error send-failed">
                        The email address you entered isn’t connected to an
                        account.
                      </span>
                    ) : (
                      <></>
                    )}
                    <button className="continue-button" type="submit">
                      Reset Password
                    </button>
                  </form>
                  <div className="reset-password-hyper-text" onClick={onClose}>
                    Back to FlowHigh
                  </div>
                </>
              ) : (
                <>
                  <h1 className="reset-password-caption1">FlowHigh</h1>

                  <div
                    className="reset-password-caption2"
                    data-testid="email-password-reset-sent"
                  >
                    Success!
                  </div>

                  <div className="reset-password-description">
                    We’ve just sent you an email to reset your password! If the
                    email doesn't show up soon, please check your spam folder.
                  </div>

                  <button className="back-to-main-button" onClick={onClose}>
                    Back to FlowHigh
                  </button>

                  <div
                    className="reset-password-hyper-text"
                    onClick={handleClickedResend}
                  >
                    Resend again
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordModal;
