/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch, batch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import {
  activateDiagram,
  renameDiagram,
  newDiagram,
  removeDiagram,
  setUpdateFlag,
  setDiagramOrder,
  setCurrentDiagram,
  setVisualizeState,
  setCurrentState,
} from "../../redux/actions/property";
import { setActiveToolbarItem } from "../../redux/actions/layout";
import { isSpecialTab, getPath } from "../../helper/util";
import { ReactComponent as NewTabSvg } from "../../assets/icons/new-tab.svg";
import { ReactComponent as Rarrow } from "../../assets/icons/right-arrow.svg";
import { ReactComponent as Larrow } from "../../assets/icons/left-arrow.svg";
import useNavigateHook from "./navigator";
import TabItem from "./tabItem";
import "./tabBar.css";
import { setAnimation } from "../../redux/actions/global";
import { useUpdateEffect } from "react-use";
import { useAuth } from "../../auth/AuthContext";

const TabBar = ({
  onClickNewTab,
  onClickCloseTab,
  checkCurrentTabOverlapText,
  checkCurrentTabOverlapButton,
  changeVisibitilyOfFreeText,
  tabBarRef,
  tabNewRef,
  barItemWidth,
}) => {
  const tabitemRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { navigateHook } = useNavigateHook();
  const diagramBoxState = useSelector(
    (state) => state.layoutReducer.diagramBoxState
  );

  const currentUser = useSelector((state) => state.propertyReducer.user);
  const currentDiagram = useSelector(
    (state) => state.propertyReducer.userLayout[currentUser].currentDiagram
  );
  const diagramList = useSelector(
    (state) => state.propertyReducer.userLayout[currentUser].diagramList
  );
  const currentEndPoint = useSelector(
    (state) =>
      state.propertyReducer.userLayout[currentUser].diagramList[currentDiagram]
        ?.currentEndPoint
  );
  const lastActiveTab = useSelector(
    (state) => state.propertyReducer.userLayout[currentUser].lastActiveTab
  );

  const { isAuthenticated, login } = useAuth();

  const [renameDiagramId, setRenameDiagramId] = useState("");
  const [tabs, setTabs] = useState(9);
  const screenWidth = document?.getElementById("root")?.getBoundingClientRect();

  useEffect(() => {
    if (screenWidth.width >= 1800) {
      setTabs(9);
    } else if (screenWidth.width >= 1600 && screenWidth.width < 1800) {
      setTabs(8);
    } else if (screenWidth.width >= 1450 && screenWidth.width < 1600) {
      setTabs(7);
    } else if (screenWidth.width >= 1200 && screenWidth.width < 1450) {
      setTabs(6);
    } else if (screenWidth.width < 1200) {
      setTabs(5);
    }
  }, [screenWidth.width, diagramList]);

  // close tab, show/hide free text when resizing
  useEffect(() => {
    if (!isSpecialTab(currentDiagram)) {
      if (currentEndPoint !== diagramBoxState && currentEndPoint !== "") {
        navigate("/" + currentEndPoint + "-sql");
      }
    }
    const resizeListener = () => {
      checkCurrentTabOverlapButton();
      if (checkCurrentTabOverlapText()) {
        // hide free text
        changeVisibitilyOfFreeText(1, 0);
      } else if (!checkCurrentTabOverlapText()) {
        changeVisibitilyOfFreeText(0, 1);
      }
    };
    // set resize listener
    window.addEventListener("resize", resizeListener);

    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener("resize", resizeListener);
    };
  }, [currentDiagram]);

  useEffect(() => {
    dispatch(setCurrentState(diagramBoxState));
  }, [diagramBoxState]);

  useEffect(() => {
    if (
      Object.keys(diagramList).findIndex((a) => !diagramList[a].order) !== -1
    ) {
      dispatch(setDiagramOrder());
    }
  }, [diagramList]);

  // navigate tab
  const navigateTab = (tabName) => {
    let path = getPath(tabName, diagramBoxState, isAuthenticated);
    if (path === "/about") {
      dispatch(setActiveToolbarItem("about"));
    }

    if (path !== location.pathname) {
      navigate(path);
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (isAuthenticated) {
      if (lastActiveTab !== "") {
        batch(() => {
          dispatch(setCurrentDiagram(lastActiveTab));
          dispatch(activateDiagram(lastActiveTab));
        });
      }
    } else {
      dispatch(setCurrentDiagram(Object.keys(diagramList)[0]));
    }
  }, [isAuthenticated]);

  useUpdateEffect(() => {
    // navigate
    if (!navigateTab(currentDiagram)) {
      dispatch(setUpdateFlag(false));
    }
  }, [currentDiagram]);

  useEffect(() => {
    navigateHook({ location: location.pathname.toLowerCase() });
  }, []);

  // activate the tab
  const handleClickTab = (tabName, isSampleTab) => {
    batch(() => {
      dispatch(setAnimation(false));
      dispatch(setVisualizeState("break"));
    });
    // setPosition();

    if (!isSpecialTab(tabName) && tabName === currentDiagram && !isSampleTab) {
      setRenameDiagramId(tabName);
      return;
    }
    navigateTab(tabName);
    dispatch(activateDiagram(tabName));
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") handleChangeName(e);
  };

  // change the diagram name
  const handleChangeName = (e) => {
    let newName = e.target.value;
    let oldName = e.target.parentNode.getAttribute("data-diagram_id");
    if (
      newName === "" ||
      isSpecialTab(newName) ||
      newName === oldName ||
      newName in diagramList
    ) {
      setRenameDiagramId("");
      return;
    }

    dispatch(
      renameDiagram({
        name: oldName,
        newName: newName,
      })
    );

    setRenameDiagramId("");
  };

  const scroll = (scrollOffset) => {
    tabitemRef.current.scrollLeft += scrollOffset;
  };
  // append the diagram
  const handleClickNewTab = async () => {
    if (!isAuthenticated) {
      await login();
      return;
    }
    // if tab pos over lap to the try free button
    onClickNewTab();
    let id = 1;
    while ("Diagram" + id in diagramList) {
      id++;
      let list = 10;
      let list2 = [
        "My Profile",
        "Account Settings",
        "About",
        "SDK Downloads",
        "Survey",
      ];
      const commonElements = Object.keys(diagramList).filter((element) =>
        list2.includes(element)
      );
      if (commonElements.length !== 0) {
        list += commonElements.length;
      }
      if (Object.keys(diagramList).length > list) {
        return;
      }
    }

    let newName = "Diagram" + id;
    if (Object.keys(diagramList).length >= tabs - 1) {
      setTimeout(() => {
        scroll(138);
      }, 200);
    }

    // create new tab and make it active
    batch(() => {
      dispatch(newDiagram({ newDiagram: newName, endPoint: diagramBoxState }));
      dispatch(setCurrentDiagram(newName));
    });
    // setPosition();
  };

  // remove the diagram
  const handleCloseTab = (e) => {
    e.stopPropagation();
    // if tab pos over lap to the try free button
    onClickCloseTab();
    // get name of the tab to close
    let tabName = e.target.parentNode.getAttribute("data-diagram_id");
    // should keep one tab at least.
    if (!isSpecialTab(tabName)) {
      let diagramCount = 0;
      Object.keys(diagramList).forEach((name) => {
        if (!isSpecialTab(name)) {
          diagramCount++;
        }
      });
      if (diagramCount <= 1) return;
    }

    // remove diagram
    dispatch(removeDiagram(tabName));
    // for (let iStack = recentStack.length - 1; iStack >= 0; iStack--) {
    //   if (
    //     !isSpecialTab(recentStack[iStack]) &&
    //     Object.prototype.hasOwnProperty.call(diagramList, recentStack[iStack])
    //   ) {
    //     dispatch(activateDiagram(recentStack[iStack]));

    //     break;
    //   }
    // }
  };

  const makeTabs = () => {
    let names = [];
    let keys = Object.keys(diagramList).sort(
      (a, b) => diagramList[a]?.order - diagramList[b]?.order
    );
    if (isAuthenticated) {
      names = keys;
    } else {
      names = [keys[0]];
      if ("About" in diagramList) {
        names.push("About");
      }
      if ("SDK Downloads" in diagramList) {
        names.push("SDK Downloads");
      }
    }

    return names.map((name, idx) => {
      const isSampleTab = diagramList[name].isSampleTab;
      return (
        <TabItem
          isSampleTab={isSampleTab}
          key={`tab_` + idx}
          label={name}
          active={name === currentDiagram}
          renaming={name === renameDiagramId}
          onClick={() => handleClickTab(name, isSampleTab)}
          onKeyDown={isSampleTab ? undefined : handleKeyDown}
          onChangeName={isSampleTab ? undefined : handleChangeName}
          onCloseTab={isSampleTab ? undefined : handleCloseTab}
          width={barItemWidth}
        />
      );
    });
  };

  return (
    <div className="tabbar-wrapper">
      <div className="tag-line">
        <b>FlowHigh.</b> Lift your data processing
      </div>
      <div className="tabbar-list" id="tab-wrapper" ref={tabBarRef}>
        <div className="tablist-scroll" ref={tabitemRef}>
          {makeTabs()}
        </div>
        {Object.keys(diagramList).length >= tabs ? (
          <div className="tab-arrow">
            <Larrow onClick={() => scroll(-138)} />
            <Rarrow onClick={() => scroll(138)} />
          </div>
        ) : (
          ""
        )}
        <div
          className="tab-new"
          onClick={handleClickNewTab}
          title="new diagram"
          ref={tabNewRef}
        >
          <NewTabSvg />
        </div>
      </div>
    </div>
  );
};

export default TabBar;
