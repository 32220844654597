import React from "react";
import "./timeoutApi.css";

import { ReactComponent as TimeoutSVG } from "../../../assets/icons/timeout.svg";

const TimeOutAPI = ({ data = "API is not available" }) => {
  return (
    <div className="pane api_wrap">
      <div className="API-pane pane">
        <TimeoutSVG />
        <h1>{data}</h1>
      </div>
    </div>
  );
};

export default TimeOutAPI;
