import React, { useEffect } from "react";
import { ReactComponent as CloseTabSvg } from "../../assets/icons/close-tab.svg";
import classNames from "classnames";

interface TabItemProps {
  active: boolean; // Is this the currently selected tab
  isSampleTab: boolean; // Is this the Sample SQL tab
  label: string; // The name of the tab to display
  onChangeName?: React.FocusEventHandler;
  onClick?: React.MouseEventHandler;
  onCloseTab?: React.MouseEventHandler;
  onKeyDown?: React.KeyboardEventHandler;
  renaming: boolean; // Is this tab currently being renamed
}
const TabItem = (props: TabItemProps) => {
  const {
    isSampleTab,
    label,
    active,
    renaming,
    onClick,
    onKeyDown,
    onChangeName,
    onCloseTab,
  } = props;

  const input = React.createRef<HTMLInputElement>();
  useEffect(() => {
    if (renaming && input.current) {
      input.current.focus();
    }
  }, [input, renaming]);

  const tabItemClassnames = classNames({
    active,
    renaming,
    sample: isSampleTab,
    "tab-item": true,
  });

  const closeMarkClassnames = classNames({
    "close-mark": true,
    hidden: renaming || isSampleTab,
  });

  return (
    <div
      className={tabItemClassnames}
      data-testid={`tab-${label}`}
      title={label}
      data-diagram_id={label}
      onClick={onClick}
    >
      {renaming ? (
        <input
          data-testid={`tab-${label}-input`}
          defaultValue={label}
          onKeyDown={onKeyDown}
          onBlur={onChangeName}
          ref={input}
        />
      ) : (
        <div className="tab-label">{label}</div>
      )}
      <div
        data-testid={`tab-${label}-close`}
        title="close diagram"
        className={closeMarkClassnames}
        onClick={onCloseTab}
      >
        <CloseTabSvg />
      </div>
    </div>
  );
};

export default TabItem;
