/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
// import AceEditor from "react-ace";
// import "ace-builds/src-noconflict/mode-json";
// import "ace-builds/src-noconflict/mode-xml";
// import { useSelector } from 'react-redux';
import "./tuningDataBox.css";

const TuningDataBox = ({ width }) => {
  // const store = useSelector(state=>state)
  // const {globalReducer} = store;
  // const {parsedData} = globalReducer;

  useEffect(() => {}, []);

  useEffect(() => {}, [width]);

  return (
    <div className="tuning-pane pane">
      <div className="tuning-wrapper wrapper"></div>
    </div>
  );
};

export default TuningDataBox;
