/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  setGlobalModalID,
  setSignupState,
} from "../../../redux/actions/global";
import { useFormik } from "formik";
import * as Yup from "yup";
import CountrySelector from "../../commonComponent/countrySelector";
import AgreeCheck from "../../commonComponent/agreeCheck";
import { ReactComponent as SignupImage } from "../../../assets/icons/signup-image.svg";
import { ReactComponent as SignupSuccessImage } from "../../../assets/icons/signup-success-image.svg";
import { ReactComponent as LogoSvg } from "../../../assets/icons/sonra-logo.svg";
import { ReactComponent as EyeSvg } from "../../../assets/icons/eye.svg";
import "./signupModal.css";
import SelectSearch from "react-select-search";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { useAuth } from "../../../auth/AuthContext";
import { useUpdateEffect } from "react-use";
import { USECASE_OPTIONS } from "../../../helper/constants";

const SignupModal = () => {
  const dispatch = useAppDispatch();
  const signupState = useAppSelector(
    (state) => state.globalReducer.signupState
  );

  const { signup, signupError } = useAuth();

  useEffect(() => {
    dispatch(setSignupState("start"));
  }, []);

  const onClose = () => {
    dispatch(setGlobalModalID(""));
  };

  const [showPassword, setShowPassword] = useState(false);
  const [hideCountryDropdown, setHideCountryDropdown] = useState(false);
  const [hideSqlLevelDropdown, setHideSqlLevelDropdown] = useState(false);
  const [hideUseCaseDropdown, setHideUseCaseDropdown] = useState(false);

  const validationSchema = Yup.object().shape({
    firstname: Yup.string().max(255).required("First name is required"),
    lastname: Yup.string().max(255).required("Last name is required"),
    country: Yup.string().required("Country is required"),
    useCase: Yup.string().required("Use case is required"),
    useCaseDescription: Yup.string().required(
      "Use case description is required"
    ),
    email: Yup.string().email("Invalid Email").required("Email is required"),
    password: Yup.string()
      .required("Password is required")
      .matches(/^(?=.*[a-z])/, "Must contain one Lowercase(a-z)")
      .matches(/^(?=.*[A-Z])/, "Must contain one Uppercase(A-Z)")
      .matches(/^(?=.*[0-9])/, "Must contain one Number(0-9)")
      .matches(
        /^(?=.*[!@#\$%\^&\*])/,
        "Must contain one special case Character(!@#$%^&*)"
      )
      .matches(/^(?=.{8,})/, "Must Contain 8 Characters"),
    agree: Yup.boolean().oneOf([true], "*").required("*"),
  });

  const formik = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      country: "",
      email: "",
      password: "",
      agree: false,
      useCase: "",
      useCaseDescription: "",
    },
    onSubmit: (values) => {
      const props = {
        email: values.email,
        password: values.password,
        username: values.firstname + " " + values.lastname,
        metadata: {
          firstName: values.firstname,
          lastName: values.lastname,
          country: values.country,
          userType: "business",
          useCase: values?.useCase,
          useCaseDescription: values?.useCaseDescription,
        },
      };

      signup(props);
    },
    validationSchema,
    validateOnChange: true,
  });

  const preventClick: React.MouseEventHandler = (event) => {
    event.stopPropagation();
    return false;
  };

  const hidesignupPopup = () => {
    dispatch(setGlobalModalID(""));
  };

  const popupClick = (e: string) => {
    let node;
    if (e === "country") {
      if (hideSqlLevelDropdown) {
        node = document.getElementById("country");
        if (!node) {
          return;
        }
        const elem = node.getElementsByClassName("select-search__select")[0];
        if (elem.classList.contains("show")) {
          elem.classList.remove("show");
        } else {
          elem.classList.add("show");
        }
        setHideCountryDropdown(false);
      }
      setHideSqlLevelDropdown(true);
    } else if (e === "useCase") {
      if (hideUseCaseDropdown) {
        node = document.getElementById("useCase");
        if (!node) {
          return;
        }
        const elem = node.getElementsByClassName("select-search__select")[0];
        if (elem.classList.contains("show")) {
          elem.classList.remove("show");
        } else {
          elem.classList.add("show");
        }
        setHideUseCaseDropdown(false);
      }
      setHideUseCaseDropdown(true);
    } else {
      if (hideCountryDropdown) {
        node = document.getElementById("level");
        if (!node) {
          return;
        }
        const elem = node.getElementsByClassName("select-search__select")[0];
        if (elem.classList.contains("show")) {
          elem.classList.remove("show");
        } else {
          elem.classList.add("show");
        }
        setHideSqlLevelDropdown(false);
      }
      setHideCountryDropdown(true);
    }
  };

  /**
   * Render the errors for a form field
   * @param fieldName - The form field for these errors
   * @param errors - The error messages themseleves
   * @returns A div containing the error messages
   */
  const makeErrorDisplay = (
    fieldName: string,
    errors?: string
  ): JSX.Element => {
    if (!errors) {
      return <></>;
    }
    return (
      <div className={`${fieldName}-errors validation-errors`}>
        {errors
          .split("\n")
          .filter((error) => error !== "")
          .map((error) => {
            return <div key={error}>{error}</div>;
          })}
      </div>
    );
  };

  const isInvalid = (
    name:
      | "firstname"
      | "lastname"
      | "country"
      | "email"
      | "password"
      | "agree"
      | "useCase"
      | "useCaseDescription"
  ) => {
    return formik?.errors?.[name] && formik?.touched?.[name];
  };

  useUpdateEffect(() => {
    if (signupState === "fail") {
      formik.setFieldError("email", signupError);
    }
  }, [signupState]);

  return (
    <div
      style={{
        visibility: "visible",
        opacity: "1",
      }}
      className="signupPopup"
      onClick={hidesignupPopup}
    >
      <div className="popup" onClick={preventClick}>
        <span className="close" onClick={onClose}>
          &times;
        </span>
        <div className="content" data-testid="global-modal-signup">
          <div className="signup-modal-body">
            <div className="left-panel">
              {signupState === "start" || signupState === "fail" ? (
                <>
                  <LogoSvg className="signup-avata" />
                  <div className="signup-caption">FlowHigh Private Preview</div>

                  <form onSubmit={formik.handleSubmit}>
                    <input
                      className={`firstname${
                        isInvalid("firstname") ? " required" : ""
                      }`}
                      data-testid="register-first-name"
                      name="firstname"
                      placeholder="First name *"
                      type="text"
                      value={formik.values.firstname}
                      onChange={formik.handleChange}
                    />
                    <span className="error">
                      {isInvalid("firstname") &&
                        makeErrorDisplay("firstname", formik.errors.firstname)}
                    </span>
                    <input
                      className={`lastname${
                        isInvalid("lastname") ? " required" : ""
                      }`}
                      data-testid="register-last-name"
                      name="lastname"
                      placeholder="Last name *"
                      type="text"
                      value={formik.values.lastname}
                      onChange={formik.handleChange}
                    />
                    <span className="error">
                      {isInvalid("lastname") &&
                        makeErrorDisplay("lastname", formik.errors.lastname)}
                    </span>
                    <input
                      className={`email${
                        isInvalid("email") ? " required" : ""
                      }`}
                      data-testid="register-email-input"
                      name="email"
                      placeholder="Email *"
                      type="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                    />
                    <span className="error">
                      {isInvalid("email") &&
                        makeErrorDisplay("email", formik.errors.email)}{" "}
                    </span>
                    <div
                      className={`${isInvalid("country") ? " required" : ""}`}
                      data-testid="register-select-country"
                      id="country"
                      onClick={() => popupClick("country")}
                      onBlur={() => setHideCountryDropdown(false)}
                    >
                      <CountrySelector
                        value={formik.values.country}
                        onChange={(e) => formik.setFieldValue("country", e)}
                      />
                    </div>
                    <span className="error">
                      {isInvalid("country") &&
                        makeErrorDisplay("country", formik.errors.country)}
                    </span>
                    <div
                      className={` ${isInvalid("useCase") ? " required" : ""} `}
                      data-testid="register-select-useCase"
                      id="useCase"
                      onClick={() => popupClick("useCase")}
                      onBlur={() => setHideUseCaseDropdown(false)}
                    >
                      <SelectSearch
                        options={USECASE_OPTIONS}
                        value={formik.values.useCase}
                        placeholder="Select your Use case *"
                        onChange={(e) => formik.setFieldValue("useCase", e)}
                      />
                    </div>
                    <span className="error">
                      {isInvalid("useCase") &&
                        makeErrorDisplay("useCase", formik.errors.useCase)}
                    </span>
                    <textarea
                      className={`${
                        isInvalid("useCaseDescription") ? " required" : ""
                      }`}
                      data-testid="register-useCaseDescription"
                      name="useCaseDescription"
                      placeholder="Description of use case *"
                      value={formik.values.useCaseDescription}
                      onChange={formik.handleChange}
                      rows={4}
                    />
                    <span className="error">
                      {isInvalid("useCaseDescription") &&
                        makeErrorDisplay(
                          "useCaseDescription",
                          formik.errors.useCaseDescription
                        )}
                    </span>
                    <div className="password-instructions">
                      A valid password must contain at least one uppercase
                      letter, one lowercase letter, one number, one special
                      character (!@#$%^&*), and be at least 8 characters long.
                    </div>
                    <div className="password-container">
                      <input
                        className={`password${
                          isInvalid("password") ? " required" : ""
                        }`}
                        data-testid="register-password-input"
                        name="password"
                        placeholder="Password *"
                        type={showPassword ? "text" : "password"}
                        value={formik.values.password}
                        autoComplete="off"
                        onChange={formik.handleChange}
                      />
                      <EyeSvg
                        className="show-password-icon"
                        onClick={() => {
                          setShowPassword(!showPassword);
                        }}
                      />
                    </div>
                    <span className="error">
                      {isInvalid("password") &&
                        makeErrorDisplay("password", formik.errors.password)}
                    </span>

                    <div className="signup-agree-container">
                      <AgreeCheck
                        isChecked={!isInvalid("agree")}
                        handleClicked={formik.handleChange}
                        newText={"By submitting this form you agree to our "}
                      />
                    </div>

                    <button
                      className="register-button"
                      data-testid="register-button"
                      type="submit"
                    >
                      Register
                    </button>
                  </form>
                </>
              ) : (
                <>
                  <LogoSvg
                    className="signup-avata"
                    style={{ marginTop: "95px" }}
                  />

                  <div className="signup-caption" data-testid="signup-success">
                    Success!
                  </div>

                  <div className="signup-success-description">
                    A verification email has been sent to your email address.
                    Check your inbox and spam folder.
                    <br /> Please verify your account.
                  </div>

                  <button
                    className="return-button"
                    data-testid="return-register-button"
                    onClick={onClose}
                  >
                    Go to FlowHigh
                  </button>
                </>
              )}
            </div>
            <div className="right-panel">
              {signupState === "start" || signupState === "fail" ? (
                <SignupImage className="signup-image" />
              ) : (
                <SignupSuccessImage className="signup-image" />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignupModal;
