import classnames from "classnames";
import React, { useState, useEffect } from "react";
import {
  setDisplayedAntiPattern,
  setTabsModal,
} from "../../../redux/actions/property";
import * as tsutil from "../../../helper/tsutil";
import AceEditor from "react-ace";
import "../../sqlBox/ace/mode-parseql";
import "../../sqlBox/ace/theme-parseql";
import { ReactComponent as AntiPatterLabel } from "../../../assets/icons/anti-pattern-label.svg";
import { ReactComponent as ErrorLabel } from "../../../assets/icons/error-label.svg";
import "./antiPattern.css";
import PatternMark from "../../commonComponent/patternMark";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { setGlobalModalID } from "../../../redux/actions/global";
import { Diagram } from "../../../redux/reducer/property";
import { EmptyObject, SetRequired } from "type-fest";
import { batch } from "react-redux";

interface AntipatternShape {
  type: string;
  number: number;
  correctUse: string;
  description: string;
  eltype: "error" | "antipattern";
}

const AntiPatternModal = () => {
  const dispatch = useAppDispatch();
  const [leftOffset, setLeftOffset] = useState(0);
  const editorSize = useAppSelector((state) => state.layoutReducer.editorSize);
  const activeDiagram = useAppSelector((state) => {
    const user = state.propertyReducer.user;
    const currentDiagram =
      state.propertyReducer.userLayout[user].currentDiagram;
    return state.propertyReducer.userLayout[user].diagramList[currentDiagram];
  });
  const activeDisplayedAntiPattern = useAppSelector((state) => {
    const user = state.propertyReducer.user;
    const currentDiagram =
      state.propertyReducer.userLayout[user].currentDiagram;
    return state.propertyReducer.userLayout[user].diagramList[currentDiagram]
      .displayedAntiPattern;
  });
  let parseData = useAppSelector((state) => {
    const user = state.propertyReducer.user;
    const currentDiagram =
      state.propertyReducer.userLayout[user].currentDiagram;
    return state.propertyReducer.userLayout[user].diagramList[currentDiagram]
      .optimiseData;
  });
  parseData = activeDiagram ? parseData : {};

  // states to display data
  const [count, setCount] = useState(0);
  const [eltype, setEltype] = useState("error");
  const [patterns, setPatterns] = useState<AntipatternShape[]>([]);
  const [selectedPatternIndex, setSelectedPatternIndex] = useState(0);

  const [showDropdown, setShowDropdown] = useState(false);
  const handleToggleDropdown = () => {
    if (count > 1) {
      setShowDropdown(!showDropdown);
    }
  };
  useEffect(() => {
    setLeftOffset(
      50 + document.getElementsByClassName("sql-pane pane")[0]?.clientWidth || 0
    );
  }, [editorSize]);

  useEffect(() => {
    if (
      activeDisplayedAntiPattern?.count &&
      activeDisplayedAntiPattern?.eltype === "error"
    ) {
      // set count
      setCount(activeDisplayedAntiPattern.count);
      setEltype(activeDisplayedAntiPattern.eltype);
      let tmpPatterns: AntipatternShape[] = [];
      if (
        activeDisplayedAntiPattern.pos &&
        tsutil.isParseDataValid(parseData)
      ) {
        // @ts-ignore stop spreading the Set
        tmpPatterns = Array.from(
          new Set(activeDisplayedAntiPattern.pos.split(";"))
        )
          .flatMap<
            ReturnType<typeof tsutil.getAntipatternsOrErrorsByPos>[number]
          >((pos) => {
            return tsutil.getAntipatternsOrErrorsByPos(
              parseData as SetRequired<
                Exclude<Diagram["optimiseData"], EmptyObject>,
                "statement"
              >,
              activeDisplayedAntiPattern.eltype,
              pos
            );
          })
          .map((item, i) => {
            return {
              type: "message" in item ? item.message : item.type,
              number: i + 1,
              correctUse: "suggestions" in item ? item.suggestions : "-",
              description: "-",
              eltype: activeDisplayedAntiPattern.eltype,
            };
          });
      }
      setShowDropdown(false);
      setPatterns(tmpPatterns);
      setTimeout(() => {
        if (activeDisplayedAntiPattern.selectedPatternIndex) {
          setSelectedPatternIndex(
            activeDisplayedAntiPattern.selectedPatternIndex
          );
        } else {
          setSelectedPatternIndex(0);
        }
      }, 100);
    }
  }, [activeDisplayedAntiPattern, dispatch, parseData]);

  const overlayClassnames = classnames({
    "antipattern-modal": true,
    error: eltype === "error",
    overlay: true,
  });

  const closeOnClick = () => {
    if (activeDisplayedAntiPattern?.count) {
      dispatch(setDisplayedAntiPattern(undefined));
    }
    batch(() => {
      dispatch(setGlobalModalID(""));
      dispatch(setTabsModal(""));
    });
  };

  return (
    <div
      style={{
        visibility: "visible",
        opacity: "1",
        left: `${leftOffset}px`,
      }}
      className={overlayClassnames}
    >
      <div className="popup antipattern">
        <span
          className="close"
          data-testid="antipattern-modal-close-button"
          onClick={closeOnClick}
        >
          &times;
        </span>
        <div className="content" data-testid="global-modal-antipattern">
          <div className="anti-pattern-body">
            <div className="caption">
              {eltype === "error" ? (
                <div className="pattern-icon error">
                  <ErrorLabel />
                </div>
              ) : (
                <div className="pattern-icon">
                  <AntiPatterLabel />
                </div>
              )}
              {eltype === "error" ? (
                <div className="pattern-label error">Error</div>
              ) : (
                <div className="pattern-label">Anti-Pattern</div>
              )}
              <PatternMark
                width={17}
                color={eltype === "error" ? "#F02849" : "#F9A825"}
                count={count}
              />
            </div>
            <div className={`content ${eltype === "error" ? "error" : ""}`}>
              <div className="sub-block first-sub-block">
                {count > 0 ? (
                  <button
                    type="button"
                    className="dropdown"
                    style={{
                      marginTop: "13px",
                      background: showDropdown
                        ? eltype === "error"
                          ? "#FEECEF"
                          : "#FEF6E9"
                        : "none",
                      justifyContent: count > 1 ? "space-between" : "center",
                    }}
                    onClick={() => {
                      handleToggleDropdown();
                    }}
                  >
                    <span>
                      {(selectedPatternIndex + 1).toString().padStart(2, "0")}
                    </span>

                    {count > 1 ? (
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1 0.999999L4.5 4.5L8 1"
                          stroke={eltype === "error" ? "#F02849" : "#F9A825"}
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    ) : null}
                  </button>
                ) : (
                  ""
                )}
                <div
                  className={`dropdown-content ${showDropdown ? "" : "hide"}`}
                >
                  {patterns.map((pattern, index) => {
                    const classes = classnames({
                      item: true,
                      selected: index === selectedPatternIndex,
                    });
                    return (
                      <div
                        className={classes}
                        onClick={() => {
                          setShowDropdown(false);
                          if (activeDisplayedAntiPattern) {
                            dispatch(
                              setDisplayedAntiPattern({
                                ...activeDisplayedAntiPattern,
                                selectedPatternIndex: index,
                              })
                            );
                          }
                        }}
                        key={pattern.type + index}
                      >
                        {(index + 1).toString().padStart(2, "0")}
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="sub-block second-sub-block">
                <div className="sub-caption">Error</div>
                <div className="pattern-text" data-testid="ap-text">
                  {patterns[selectedPatternIndex]?.type}
                </div>
              </div>

              {eltype === "antipattern" && (
                <div className="sub-block">
                  <div className="sub-caption">Correct Usage</div>
                  <div className="pattern-preview">
                    <AceEditor
                      mode="parseql"
                      theme="parseql"
                      name="antiPatternCorrectUse"
                      editorProps={{ $blockScrolling: true }}
                      style={{
                        height: "100%",
                        width: "100%",
                        borderTop: "none",
                      }}
                      setOptions={{
                        fontFamily: "Roboto Mono, Consolas, monospace",
                        fontSize: "12px",
                        showLineNumbers: true,
                        showPrintMargin: false,
                        wrap: false,
                        hScrollBarAlwaysVisible: false,
                        vScrollBarAlwaysVisible: false,
                        tabSize: 4,
                        cursorStyle: "smooth",
                        readOnly: true,
                        showGutter: false,
                      }}
                      value={patterns[selectedPatternIndex]?.correctUse}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AntiPatternModal;
