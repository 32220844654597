import React, { useEffect, useState } from "react";
import { useSelector, useDispatch, batch } from "react-redux";
import {
  setAnimation,
  setClickSearch,
  setSearchTab,
  setSearchText,
  setSearchBoxOpen,
} from "../../redux/actions/global";
import "./topRightBar.css";
import {
  setRecentSearch,
  setSearchNodes,
  setUser,
} from "../../redux/actions/property";
import { ReactComponent as SearchIcon } from "../../assets/icons/search-icon.svg";
import { ReactComponent as ArrowSvg } from "../../assets/icons/search-arrow.svg";
import { ReactComponent as CloseTabSvg } from "../../assets/icons/close-tab.svg";
import { ReactComponent as ButtonDot } from "../../assets/icons/button-dot.svg";
import UserProfileMenu from "./userProfileMenu";
import { useAuth } from "../../auth/AuthContext";

const TopRightBar = (props) => {
  const [nx, setNx] = useState(-1);
  const [pre, setPre] = useState(-1);
  const [searchItems, setSearchItems] = useState(0);
  let Editor = document;
  const dispatch = useDispatch();
  const searchTab = useSelector((state) => state.globalReducer.searchTab);
  const searchText = useSelector((state) => state.globalReducer.searchText);

  const isSearchBoxOpen = useSelector(
    (state) => state.globalReducer.searchBoxOpen
  );

  const diagramBoxState = useSelector(
    (state) => state.layoutReducer.diagramBoxState
  );

  const currentUser = useSelector((state) => state.propertyReducer.user);
  const recentSearch = useSelector(
    (state) => state.propertyReducer.userLayout[currentUser].recentSearch
  );
  const [pressBtn, setPressBtn] = useState(false);

  const { isAuthenticated, login, logout, user } = useAuth();

  const searchInSVG = (searchValue) => {
    if (searchValue.length === 0 || diagramBoxState !== "visualise") {
      return;
    }
    const arr = [];
    // Get a reference to the SVG element
    const svg = document.getElementsByClassName("diagram-svg")[0];

    // Find all text elements in the SVG
    const textElements = svg.getElementsByTagName("text");
    setNx(-1);
    setPre(-1);
    // setSearchItems(0);

    // Loop through the text elements and search for the desired text
    for (let i = 0; i < textElements.length; i++) {
      const textElement = textElements[i];
      if (!textElements[i].parentNode.classList.contains("dataset")) {
        continue;
      }

      const parent = textElement.parentElement;
      const textContent = textElement.textContent;

      // remove all the recent highlights
      const recentHighlights = parent.getElementsByClassName("search-svg");
      while (recentHighlights.length > 0) {
        recentHighlights[0].parentNode.removeChild(recentHighlights[0]);
      }

      const regex = new RegExp(searchValue, "gi");
      let match;
      while ((match = regex.exec(textContent)) !== null) {
        const highlight = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "g"
        );
        highlight.setAttribute("class", "search-svg");
        const rect = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "rect"
        );
        rect.setAttribute("class", "fill-yellow");
        rect.setAttribute(
          "x",
          textElement.getStartPositionOfChar(match.index).x
        );
        rect.setAttribute("y", 11);
        rect.setAttribute(
          "width",
          textElement.getSubStringLength(match.index, match[0].length) + 4
        );
        rect.setAttribute("height", 17);
        highlight.appendChild(rect);
        textElement.parentNode.insertBefore(highlight, textElement);
        arr.push(textElement.parentNode.parentNode.__data__);
      }
    }
    setSearchItems(arr.length);
    searchNext(!!arr.length);
  };

  const searchNext = (searchInDiagram) => {
    let searchPosition = nx;
    if (searchItems === 0 && !searchInDiagram) return;

    // Get a reference to the SVG element
    const svg = document.getElementsByClassName("diagram-svg")[0];
    if (nx === searchItems - 1 || searchInDiagram) {
      searchPosition = 0;
    } else {
      searchPosition = searchPosition + 1;

      setPre(searchPosition);
    }

    dispatch(setSearchNodes([]));

    // Find all text elements in the SVG
    const textElements =
      svg.getElementsByClassName("search-svg")[searchPosition];
    let preElements =
      svg.getElementsByClassName("search-svg")[searchPosition - 1];
    if (searchPosition - 1 === -1) {
      preElements = svg.getElementsByClassName("search-svg")[searchItems - 1];
    }
    const prechild = preElements?.firstChild;
    const child = textElements?.firstChild;
    if (prechild !== undefined) {
      if (prechild && prechild.classList.contains("fill-pink")) {
        prechild.classList.add("fill-yellow");
        prechild.classList.remove("fill-pink");
      }
    }

    if (child && child.classList.contains("fill-yellow")) {
      child.classList.remove("fill-yellow");
      child.classList.add("fill-pink");
      dispatch(setSearchNodes([textElements.parentNode.parentNode.__data__]));
    }
    setNx(searchPosition);
  };

  const searchPrevious = () => {
    let prevPosition = pre;
    if (searchItems === 0) {
      return;
    }

    // Get a reference to the SVG element
    const svg = document.getElementsByClassName("diagram-svg")[0];
    if (prevPosition === -1 || prevPosition === 0) {
      prevPosition = searchItems - 1;
    } else {
      prevPosition = prevPosition - 1;
      setNx(prevPosition);
    }
    dispatch(setSearchNodes([]));

    // Find all text elements in the SVG
    const textElements = svg.getElementsByClassName("search-svg")[prevPosition];
    let preElements =
      svg.getElementsByClassName("search-svg")[prevPosition + 1];
    if (prevPosition === searchItems - 1) {
      preElements = svg.getElementsByClassName("search-svg")[0];
    }
    const prechild = preElements?.firstChild;

    const child = textElements.firstChild;
    if (prechild && prechild.classList.contains("fill-pink")) {
      prechild.classList.add("fill-yellow");
      prechild.classList.remove("fill-pink");
    }

    if (child && child.classList.contains("fill-yellow")) {
      child.classList.remove("fill-yellow");
      child.classList.add("fill-pink");
      dispatch(setSearchNodes([textElements.parentNode.parentNode.__data__]));
    }
    setPre(prevPosition);
  };

  const removeSVGHighlight = () => {
    const svg = document.getElementsByClassName("diagram-svg")[0];
    if (svg === undefined) {
      return;
    }

    // Find all text elements in the SVG
    const textElements = svg.getElementsByTagName("text");
    if (textElements.length === 0) return;

    // Loop through the text elements and search for the desired text
    for (let i = 0; i < textElements.length; i++) {
      const textElement = textElements[i];
      if (!textElements[i].parentNode.classList.contains("dataset")) {
        continue;
      }
      const parent = textElement.parentElement;
      const recentHighlights = parent.getElementsByClassName("search-svg");
      while (recentHighlights.length > 0) {
        recentHighlights[0].parentNode.removeChild(recentHighlights[0]);
      }
    }
  };

  const openSearchBar = () => {
    dispatch(setClickSearch(true));
    dispatch(setSearchBoxOpen(true));
    document.getElementsByClassName("search-box")[0].focus();
    document.getElementsByClassName("search-input")[0].focus();
  };

  const createHandle = (e) => {
    if (e.keyCode === 114 || (e.ctrlKey && e.keyCode === 70)) {
      e.preventDefault();
      setPressBtn(true);
    }
  };
  useEffect(() => {
    document.addEventListener("keydown", createHandle);
    return () => document.removeEventListener("keydown", createHandle);
  });

  useEffect(() => {
    if (pressBtn) {
      openSearchBar();
    }
    setPressBtn(false);
    // eslint-disable-next-line
  }, [pressBtn]);
  const closeSearchBar = () => {
    dispatch(setSearchText(""));
    removeHighlightColors(window.document);
    removeSVGHighlight();
  };

  const editorMarker = () => {
    if (isSearchBoxOpen) {
      if (searchTab === "both") {
        if (diagramBoxState !== "visualise") {
          Editor = document;
          setHighlightColors(Editor);
        } else {
          Editor = document.getElementById("sqlEditor");
          setHighlightColors(Editor);
        }
      } else if (searchTab === "left") {
        Editor = document.getElementById("sqlEditor");
        setHighlightColors(Editor);
        removeHighlightColors(document.getElementById("parseDataEditor"));
      } else if (searchTab === "right") {
        if (diagramBoxState !== "visualise") {
          Editor = document.getElementById("parseDataEditor");
          setHighlightColors(Editor);
        }
        removeHighlightColors(document.getElementById("sqlEditor"));
      }
    }
  };
  const setHighlightColors = (Editor = document) => {
    setTimeout(() => {
      const ace_editor = Editor?.querySelectorAll(".ace_selected-word");
      const ace_selection = Editor?.querySelectorAll(".ace_selection");
      ace_selection?.forEach((item) => {
        item.classList.add("ace_selection-custom");
      });
      ace_editor?.forEach((item) => {
        item.classList.add("ace_selected-word-custom");
      });
    }, 50);
  };

  const removeHighlightColors = (Editor) => {
    if (Editor === null) return;
    setTimeout(() => {
      const ace_editor = Editor?.querySelectorAll(".ace_selected-word");
      const ace_selection = Editor?.querySelectorAll(".ace_selection");
      ace_selection?.forEach((item) => {
        item.classList.remove("ace_selection-custom");
      });
      ace_editor?.forEach((item) => {
        item.classList.remove("ace_selected-word-custom");
      });
    }, 50);
  };

  const handleCounter = (sign) => {
    if (searchText) {
      editorMarker();
      if (searchText.length > 1) {
        if (searchTab === "both") {
          if (sign === "+") {
            props.findNextInEditor();
            if (diagramBoxState === "parse") {
              props.parseEditor.current.editor.findNext();
            } else if (diagramBoxState === "visualise") {
              searchNext();
            }
          } else if (sign === "-") {
            props.findPreviousInEditor();
            if (diagramBoxState === "visualise") {
              searchPrevious();
            } else {
              props.findPreviousInEditor();
              props.parseEditor.current.editor.findPrevious();
            }
          }
        } else if (searchTab === "left") {
          if (sign === "+") {
            props.findNextInEditor();
          } else if (sign === "-") {
            props.findPreviousInEditor();
          }
        } else if (searchTab === "right") {
          if (diagramBoxState === "parse") {
            if (sign === "+") {
              props.parseEditor.current.editor.findNext();
            } else if (sign === "-") {
              props.parseEditor.current.editor.findPrevious();
            }
          } else {
            if (sign === "+") {
              searchNext();
            } else if (sign === "-") {
              searchPrevious();
            }
          }
        }
      }
      if (!recentSearch.includes(searchText)) {
        dispatch(setRecentSearch(searchText));
      }
    }
  };
  // setSearchText
  const handleSearchtext = (text) => {
    dispatch(setSearchText(text));
  };

  const setValue = (value) => {
    dispatch(setSearchText(value));
  };

  useEffect(() => {
    if (diagramBoxState === "visualise") {
      dispatch(setSearchTab("left"));
    }
    // eslint-disable-next-line
  }, [diagramBoxState]);

  useEffect(() => {
    const editorParse = props.parseEditor?.current?.editor;
    if (searchText?.length > 1) {
      if (searchTab === "both") {
        searchInSVG(searchText);
        editorParse?.find("needle", {
          backwards: true,
          wrap: true,
          caseSensitive: false,
          wholeWord: false,
        });
      } else if (searchTab === "right") {
        if (diagramBoxState === "visualise") {
          searchInSVG(searchText);
        } else if (
          diagramBoxState === "parse" &&
          (searchTab === "both" || searchTab === "right")
        ) {
          editorParse?.find("needle", {
            backwards: true,
            wrap: true,
            caseSensitive: false,
            wholeWord: false,
          });
        }
      }
    }
    editorMarker();
    // eslint-disable-next-line
  }, [searchText, searchTab]);

  const removeSVGSearchItems = () => {
    const searchSvg = document.querySelectorAll(".search-svg");
    for (let i = 0; i < searchSvg.length; i++) {
      searchSvg[i].remove();
    }
  };

  useEffect(() => {
    editorMarker();
    if (
      diagramBoxState === "visualise" &&
      (searchTab === "right" || searchTab === "both")
    ) {
      searchInSVG(searchText);
    } else {
      removeSVGSearchItems();
      removeSVGHighlight();
    }

    // eslint-disable-next-line
  }, [searchTab]);

  const events = [
    "load",
    "mousemove",
    "mousedown",
    "click",
    "scroll",
    "keypress",
  ];

  let timer;

  useEffect(() => {
    if (isAuthenticated) {
      Object.values(events).forEach((item) => {
        window.addEventListener(item, () => {
          resetTimer();
          handleTimer();
        });
      });
    }
    // eslint-disable-next-line
  }, [isAuthenticated]);

  const resetTimer = () => {
    if (timer) clearTimeout(timer);
  };

  const handleTimer = () => {
    // 20 minutes
    const INACTIVE_TIMEOUT = 1200000;

    timer = setTimeout(() => {
      resetTimer();
      Object.values(events).forEach((item) => {
        window.removeEventListener(item, resetTimer);
      });
      logoutAction();
    }, INACTIVE_TIMEOUT);
  };

  const logoutAction = () => {
    handleLogoutClicked();
  };

  const handleLogoutClicked = () => {
    localStorage.removeItem("sample");
    localStorage.removeItem("response");
    localStorage.removeItem("UserInfo");
    setAnimation(false);
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    }).catch(console.error);
    dispatch(setUser("sample"));
  };

  const handleScreen = (e) => {
    // if (diagramBoxState!=="parse") {
    //     dispatch(setSearchTab("left"))
    // }
    // else{

    // }
    dispatch(setSearchTab(e));
  };

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(setUser(user?.sub));
    }
  }, [dispatch, isAuthenticated, user?.sub]);

  const preventClick = (event) => {
    if (event.target === "div.userinfo-bar") {
      batch(() => {
        dispatch(setClickSearch(false));
        dispatch(setSearchText(""));
      });
      dispatch(setSearchBoxOpen(false));
      event.stopPropagation();
      return false;
    }
  };
  const focus = () => {
    dispatch(setClickSearch(true));
    dispatch(setSearchBoxOpen(true));
  };

  return (
    <div className="userinfo-bar" onClick={preventClick}>
      <div
        className="search-box"
        onClick={openSearchBar}
        tabIndex={0}
        onFocus={() => focus()}
        onBlur={() => {
          dispatch(setClickSearch(false));
        }}
      >
        <SearchIcon />
        <input
          type="text"
          className="search-input"
          data-testid="search-input"
          placeholder="Search in screens"
          value={searchText}
          onClick={openSearchBar}
          onChange={(e) => handleSearchtext(e.target.value)}
        />
        {searchText !== "" ? (
          <div className="flex">
            <button className="arrow-down" onClick={() => handleCounter("-")}>
              <ArrowSvg />
            </button>
            <button className="arrow-up" onClick={() => handleCounter("+")}>
              <ArrowSvg />
            </button>
            <button className="close-search" onClick={closeSearchBar}>
              <CloseTabSvg />
            </button>
          </div>
        ) : (
          ""
        )}

        {isSearchBoxOpen ? (
          <div className="search-suggest">
            <div className="search-hr">
              <div className="flex mb">
                <div
                  className={`flex ${
                    searchTab === "both" ? "search-type-acticated" : ""
                  }`}
                  onClick={() => handleScreen("both")}
                >
                  <ButtonDot />
                  <span>Both Screens</span>
                </div>
                <div
                  className={`flex ${
                    searchTab === "left" ? "search-type-acticated" : ""
                  }`}
                  onClick={() => handleScreen("left")}
                >
                  <ButtonDot />
                  <span>Left</span>
                </div>
                <div
                  className={`flex ${
                    searchTab === "right" ? "search-type-acticated" : ""
                  }`}
                  onClick={() => handleScreen("right")}
                >
                  <ButtonDot />
                  <span>Right</span>
                </div>
              </div>
            </div>
            <div className="flex-col">
              {recentSearch
                ? recentSearch.map((value, key) => (
                    <p
                      key={key}
                      onClick={() => {
                        setValue(value);
                      }}
                    >
                      {value}
                    </p>
                  ))
                : ""}
            </div>
          </div>
        ) : (
          ""
        )}
      </div>

      {isAuthenticated ? (
        <UserProfileMenu handleLogoutClicked={handleLogoutClicked} />
      ) : (
        <div
          className="login-button div-button"
          data-testid="loginModalButton"
          onClick={async () => {
            await login();
          }}
        >
          Login
        </div>
      )}
    </div>
  );
};

export default TopRightBar;
