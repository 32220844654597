import {
  Auth0Callback,
  Auth0Result,
  Auth0UserProfile,
  Management,
  WebAuth,
} from "auth0-js";
import React, { createContext, useCallback, useContext, useMemo } from "react";
import {
  setGlobalModalID,
  setLoginState,
  setResetPasswordState,
  setSignupState,
} from "../redux/actions/global";
import { useDispatch } from "react-redux";
import AuthActionTypes from "../redux/actionTypes/auth";
import { setErrorDescription } from "../redux/actions/auth";
import { useAppSelector } from "../redux/hooks";
import { User } from "../redux/reducer/auth";
import { debounce } from "underscore";
import { validateEnvVar } from "../helper/tsutil";
import { selectAuthState } from "../redux/selectors/auth";

const audience = validateEnvVar(
  "REACT_APP_AUTH0_AUDIENCE",
  process.env.REACT_APP_AUTH0_AUDIENCE
);

const connection = validateEnvVar(
  "REACT_APP_AUTH0_CONNECTION",
  process.env.REACT_APP_AUTH0_CONNECTION
);
const domain = validateEnvVar(
  "REACT_APP_AUTH0_DOMAIN",
  process.env.REACT_APP_AUTH0_DOMAIN
);

const clientId = validateEnvVar(
  "REACT_APP_AUTH0_CLIENT_ID",
  process.env.REACT_APP_AUTH0_CLIENT_ID
);

const management_domain = validateEnvVar(
  "REACT_APP_AUTH0_MANAGEMENT_DOMAIN",
  process.env.REACT_APP_AUTH0_MANAGEMENT_DOMAIN
);

const management_audience = validateEnvVar(
  "REACT_APP_AUTH0_MANAGEMENT_AUDIENCE",
  process.env.REACT_APP_AUTH0_MANAGEMENT_AUDIENCE
);

const webAuth = new WebAuth({
  domain: domain,
  audience: audience,
  clientID: clientId,
  responseType: "code",
  scope:
    "read:current_user update:current_user_metadata openid profile email offline_access",
  redirectUri: window.location.origin + "/",
});

const webManageAuth = new WebAuth({
  clientID: clientId,
  domain: management_domain,
  redirectUri: window.location.origin + "/",
  audience: management_audience,
  scope: "read:current_user update:current_user_metadata offline_access",
  responseType: "code token id_token",
});

// Define the type for the context value
type AuthContextType = {
  login: () => Promise<void>;
  user: User | undefined;
  logout: () => Promise<void>;
  accessToken: string;
  isAuthenticated: boolean;
  sendChangePasswordEmail: (email: string) => void;
  setUserMetadata: (
    userProfile: {
      firstName: string;
      lastName: string;
      country: string;
      userType: string;
      skillLevel: string;
      picture: any;
    },
    cb: Auth0Callback<Auth0UserProfile>
  ) => void;
  authLogin: (email: string, password: string) => void;
  signup: (option: {
    email: string;
    metadata: {
      firstName: string;
      lastName: string;
      country: string;
      userType: string;
      useCase: string;
      useCaseDescription: string;
    };
    password: string;
    username: string;
  }) => void;
  signupError: string;
};

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export const AuthProvider: React.FC = ({ children }) => {
  const dispatch = useDispatch();
  const [signupError, setSignupError] = React.useState("");

  const { accessToken, user, isAuthenticated } =
    useAppSelector(selectAuthState);

  const login = async () => {
    dispatch(setGlobalModalID("login"));
  };

  const authLogin = (email: string, password: string) => {
    try {
      webAuth.login(
        {
          realm: connection,
          username: email,
          password: password,
        },
        function (err) {
          if (err) {
            dispatch(setLoginState("fail"));
          } else {
            dispatch(setLoginState("success"));
          }
        }
      );
    } catch (er) {
      dispatch(setLoginState("fail"));
    }
  };

  const logout = async () => {
    try {
      dispatch({
        type: AuthActionTypes.AUTH_CLEAR_INFO,
      });
      localStorage.removeItem("tmp");
      webAuth.logout({
        returnTo: window.location.origin + "/",
        clientID: clientId,
      });
    } catch (error) {}
  };

  const sendChangePasswordEmail = (email: string) => {
    webAuth.changePassword(
      {
        connection: connection,
        email: email,
      },
      function (err) {
        if (err) {
          dispatch(setResetPasswordState("fail"));
        } else {
          dispatch(setResetPasswordState("success"));
        }
      }
    );
  };

  const setUserMetadata = (
    userProfile: {
      firstName: string;
      lastName: string;
      country: string;
      userType: string;
      skillLevel: string;
      picture: string | undefined;
    },
    cb: Auth0Callback<Auth0UserProfile>
  ) => {
    webManageAuth.checkSession(
      {
        scope: "update:current_user_metadata",
        prompt: "consent",
      },
      function (err, authResult) {
        if (err) {
          console.log(err);
          return;
        }

        const auth0Manage = new Management({
          domain: management_domain,
          token: authResult.accessToken,
        });

        auth0Manage.patchUserMetadata(
          authResult.idTokenPayload.sub,
          userProfile,
          cb
        );
      }
    );
  };

  const signup = (userProfile: {
    email: string;
    metadata: {
      firstName: string;
      lastName: string;
      country: string;
      userType: string;
      useCase: string;
      useCaseDescription: string;
    };
    password: string;
    username: string;
  }) => {
    dispatch(setSignupState("start"));
    webAuth.signup(
      {
        connection: connection,
        email: userProfile.email,
        username: userProfile.username,
        password: userProfile.password,
        userMetadata: userProfile.metadata,
      },
      function (err) {
        if (err) {
          setSignupError(err?.description ?? "");
          if (err?.description === "unauthorized_domain") {
            setSignupError("Unauthorized Domain");
          } else if (err.description === "Invalid sign up") {
            setSignupError("Email already registered");
          }
          dispatch(setSignupState("fail"));
        } else {
          dispatch(setSignupState("success"));
        }
      }
    );
  };

  const verifyModal = useCallback(() => {
    dispatch(setGlobalModalID("post-registration"));
  }, [dispatch]);
  const displayVerifyModal = useMemo(() => {
    return debounce(verifyModal, 100, false);
  }, [verifyModal]);

  const getUser = (authResult: Auth0Result) => {
    if (authResult && authResult.accessToken) {
      webAuth.client.userInfo(authResult.accessToken, function (err, user) {
        if (err) {
          return;
        }
        if (!authResult || !authResult.expiresIn) {
          return;
        }
        const userInfo = {
          userId: user.sub,
          accessToken: authResult.accessToken,
          refreshToken: authResult.refreshToken,
          expiresAt: authResult.expiresIn * 1000 + new Date().getTime(),
          user,
        };
        dispatch({
          type: AuthActionTypes.AUTH_SET_INFO,
          payload: userInfo,
        });
      });
    }
  };

  webAuth.parseHash(() => {
    const error = new URLSearchParams(window.location.search).get("error");
    const errorDescription = new URLSearchParams(window.location.search).get(
      "error_description"
    );
    if (error !== null) {
      if (errorDescription !== null) {
        dispatch(setErrorDescription(errorDescription));
      }
      if (errorDescription === "Unauthorized Domain") {
        dispatch(setGlobalModalID("unauthorized-domain"));
      }
      if (errorDescription === "Please verify your email before logging in.") {
        displayVerifyModal();
      }
      dispatch(setLoginState("fail"));
      return;
    }
    const authCode = new URLSearchParams(window.location.search).get("code");
    if (authCode) {
      webAuth.client.oauthToken(
        {
          code: authCode,
          grantType: "authorization_code",
          redirectUri: window.location.origin,
        },
        function (err, result) {
          if (err) {
            dispatch(setLoginState("fail"));
          } else {
            getUser(result);
          }
        }
      );
    }
  });

  const contextValue: AuthContextType = {
    login,
    user,
    logout,
    isAuthenticated,
    sendChangePasswordEmail,
    setUserMetadata,
    authLogin,
    signup,
    accessToken,
    signupError,
  };

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};
