import log from "loglevel";
import {
  DEFAULT_LOGS_STORAGE_KEY,
  writeLogsToSessionStorage,
} from "./writeLogsToSessionStorage";
import _ from "underscore";

interface LogLevelSessionStorageOptions {
  debounceRate?: number; // The maximum frequency with which data can be persisted to local storage in ms, see https://lodash.com/docs/#debounce
  storeKey?: string; // The key to use when storing in session storage
}

export const defaultOptions: Required<LogLevelSessionStorageOptions> = {
  debounceRate: 1000,
  storeKey: DEFAULT_LOGS_STORAGE_KEY,
};

const logLevelSessionStorage = (
  logger: log.Logger,
  options: LogLevelSessionStorageOptions = defaultOptions
) => {
  const copiedOptions = Object.assign({}, defaultOptions, options);

  let buffer: string[] = [];
  const originalFactory = logger.methodFactory;

  const debouncedWrite = _.debounce(
    writeLogsToSessionStorage,
    copiedOptions.debounceRate,
    true
  );

  logger.methodFactory = (methodName, logLevel, loggerName) => {
    const rawMethod = originalFactory(methodName, logLevel, loggerName);
    return (...message: any[]) => {
      const joinedMessage = message
        .map((item) => JSON.stringify(item))
        .join(" ");
      const messageToLog = `[${String(
        loggerName
      )}] ${methodName}: ${joinedMessage}`;

      rawMethod(messageToLog);
      buffer.push(messageToLog);
      debouncedWrite(buffer, {
        storeKey: copiedOptions.storeKey,
      });
      buffer = [];
    };
  };
  logger.setLevel(logger.getLevel());
};

export default logLevelSessionStorage;
