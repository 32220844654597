import React from "react";
import "./postRegistrationModal.css";
import { ReactComponent as CloseIconSvg } from "../../../assets/icons/close-tab.svg";
import { ReactComponent as LogoSvg } from "../../../assets/icons/sonra-logo.svg";
import { ReactComponent as SignupSuccessSvg } from "../../../assets/icons/signup-success-image.svg";
import { useAppDispatch } from "../../../redux/hooks";
import { setGlobalModalID } from "../../../redux/actions/global";
import { useAuth } from "../../../auth/AuthContext";

const PostRegistrationModal: React.FC = () => {
  const dispatch = useAppDispatch();

  const { logout } = useAuth();

  const closeOverlay = React.useCallback(async () => {
    dispatch(setGlobalModalID(""));
    // Logout to clear auth cache
    await logout();
  }, [dispatch, logout]);

  return (
    <div
      className="post-registration-modal modal-overlay"
      onClick={closeOverlay}
    >
      <div
        className="modal-content"
        data-testid="global-modal-post-registration"
      >
        <div className="left-content">
          <LogoSvg className="logo" />
          <div className="caption">Verify Email</div>
          <div className="verification-text">
            A verification email has been sent to your email address. Please
            verify your account.
          </div>{" "}
          <button className="return-button" onClick={closeOverlay}>
            Go to FlowHigh
          </button>
        </div>
        <SignupSuccessSvg className="success" />
        <CloseIconSvg className="close-button" onClick={closeOverlay} />
      </div>
    </div>
  );
};

export default PostRegistrationModal;
