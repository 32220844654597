/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch, batch } from "react-redux";
import SDKEditor from "./Editor";
import Split from "react-split";
import "./sdkBox.css";
import TableList from "./TableList";
import {
  setSDKActiveTab,
  setSDKAntipattern,
  setSDKDDL,
  setSDKFormat,
  setSDKMessAge,
  setSDKTable,
  setSDKFilter,
  setSDKJoin,
  setSDKSortGroup,
} from "../../redux/actions/property";
import Message from "./SDKComponents/Message";
import usePyodideHook from "./Utils";
import { downEditor } from "./SDKComponents/Message";
import { UpEditor } from "./Editor";
import { ReactComponent as ScreenSvg } from "../.././assets/icons/togglescreen.svg";
import { useMount } from "react-use";
import { pyodideLoading } from "../../helper/util";

const SDKBox = () => {
  const dispatch = useDispatch();
  const diagramBoxState = useSelector(
    (state) => state.layoutReducer.diagramBoxState
  );

  const [physicalTables, setPhysicalTables] = useState([]);
  const [inlineTables, setInlineTables] = useState([]);
  const [pseudoTables, setPseudoTables] = useState([]);
  const [recursiveClause, setRecursiveClause] = useState([]);
  const [functionTables, setFunctionTables] = useState([]);
  const [commonTables, setCommonTables] = useState([]);
  const [sizes, setSizes] = useState([30, 70]);

  const user = useSelector((state) => state.propertyReducer.user);
  const currentDiagram = useSelector(
    (state) => state.propertyReducer.userLayout[user].currentDiagram
  );
  const activeDiagram = useSelector(
    (state) =>
      state.propertyReducer.userLayout[user].diagramList[currentDiagram]
  );
  let SDKData = useSelector(
    (state) =>
      state.propertyReducer.userLayout[user].diagramList[currentDiagram].SDKData
  );
  let SDKActiveTab = useSelector(
    (state) =>
      state.propertyReducer.userLayout[user].diagramList[currentDiagram]
        .SDKActiveTab
  );
  let sqlData = useSelector(
    (state) =>
      state.propertyReducer.userLayout[user].diagramList[currentDiagram].sqlData
  );
  SDKData = activeDiagram ? SDKData : {};
  SDKActiveTab = activeDiagram ? SDKActiveTab : {};
  sqlData = activeDiagram ? sqlData : "";
  const [msgData, setMsgData] = useState();
  const [ForData, setForData] = useState();
  const [DDLData, setDDLData] = useState();
  const [AntiData, setAntiData] = useState();
  const [tableData, setTableData] = useState();
  const [filterData, setFilterData] = useState();
  const [joinData, setJoinData] = useState();
  const [sortGroupData, setSortGroupData] = useState();
  const [data, setData] = useState();
  const [classes, setClasses] = useState("msg");
  const [mode, setMode] = useState("parseql");

  const { handlePyScript } = usePyodideHook();

  useMount(() => {
    const loadPyodide = async () => {
      try {
        await pyodideLoading();
      } catch (error) {
        throw error;
      }
    };

    loadPyodide().catch((e) => console.error(e));
  });

  useEffect(async () => {
    if (diagramBoxState !== "parser-sdk" || window.Pyodide === undefined)
      return;

    if (
      diagramBoxState === "parser-sdk" &&
      Object.keys(SDKData).length !== 0 &&
      window.Pyodide !== undefined
    ) {
      const pyData = await handlePyScript();
      setMsgData(pyData.Message);
      setForData(pyData.Format);
      setAntiData(pyData.Antipattern);
      setDDLData(pyData.DDL);
      setTableData(pyData.Tablelist);
      setFilterData(pyData.Filter);
      setJoinData(pyData.Join);
      setSortGroupData(pyData.SortGroup);

      batch(() => {
        dispatch(setSDKMessAge(pyData.Message));
        dispatch(setSDKFormat(pyData.Format));
        dispatch(setSDKAntipattern(pyData.Antipattern));
        dispatch(setSDKDDL(pyData.DDL));
        dispatch(setSDKTable(pyData.Tablelist));
        dispatch(setSDKFilter(pyData.Filter));
        dispatch(setSDKJoin(pyData.Join));
        dispatch(setSDKSortGroup(pyData.SortGroup));
      });
    } else if (Object.keys(SDKData).length === 0) {
      setTableData("");
      setDDLData("");
      setAntiData("");
      setForData("");
      setMsgData("");
      setFilterData("");
      setJoinData("");
      setSortGroupData("");
    }
  }, [SDKData, window.Pyodide]);

  useEffect(() => {
    setTableData("");
    setDDLData("");
    setAntiData("");
    setForData("");
    setMsgData("");
    setFilterData("");
    setJoinData("");
    setSortGroupData("");
  }, [sqlData]);

  const changedTab = (tabName) => {
    dispatch(setSDKActiveTab(tabName));
  };

  const tableList = () => {
    if (!tableData) {
      setPhysicalTables([]);
      setInlineTables([]);
      setRecursiveClause([]);
      setPseudoTables([]);
      setFunctionTables([]);
      setCommonTables([]);
      return;
    }

    let physicalNodes = [],
      inlineNodes = [],
      recursiveNodes = [],
      pseudoNodes = [],
      functionNodes = [],
      commonNodes = [];

    JSON.parse(tableData).forEach((node) => {
      switch (node.subtype ? node.subtype : node.type) {
        case "physical":
          physicalNodes.push(node);
          break;
        case "select":
        case "inline":
          inlineNodes.push(node);
          break;
        case "recursive":
          recursiveNodes.push(node);
          break;
        case "pseudo":
          pseudoNodes.push(node);
          break;
        case "functional":
        case "udtf":
          functionNodes.push(node);
          break;
        case "cte":
          commonNodes.push(node);
          break;
        default:
          physicalNodes.push(node);
          break;
      }
    });
    setPhysicalTables(physicalNodes);
    setInlineTables(inlineNodes);
    setRecursiveClause(recursiveNodes);
    setPseudoTables(pseudoNodes);
    setFunctionTables(functionNodes);
    setCommonTables(commonNodes);
  };
  document.addEventListener("dragstart", { passive: false });

  useEffect(() => {
    tableList();
  }, [tableData]);

  const resizeUpEditors = () => {
    if (!downEditor.current) {
      return;
    }
    downEditor.current.editor.resize();
    highlightText();
  };

  useEffect(() => {
    if (SDKActiveTab === "Message") {
      setData(msgData);
      setClasses("Message");
      setMode("parseql");
    } else if (SDKActiveTab === "DDL") {
      setData(DDLData);
      setClasses("DDL");
      setMode("parseql");
    } else if (SDKActiveTab === "Format") {
      setData(ForData);
      setClasses("format");
      setMode("parseql");
    } else if (SDKActiveTab === "Antipattern") {
      setData(AntiData);
      setClasses("Antipattern");
      setMode("parseql");
    } else if (SDKActiveTab === "Filter") {
      setData(filterData);
      setClasses("filterData");
      setMode("text");
    } else if (SDKActiveTab === "Join") {
      setData(joinData);
      setClasses("joinData");
      setMode("text");
    } else if (SDKActiveTab === "SortGroup") {
      setData(sortGroupData);
      setClasses("sortGroupData");
      setMode("text");
      highlightText();
    }
  }, [
    msgData,
    SDKActiveTab,
    DDLData,
    ForData,
    AntiData,
    filterData,
    joinData,
    sortGroupData,
  ]);

  useEffect(() => {
    if (
      SDKActiveTab === "Join" ||
      SDKActiveTab === "Filter" ||
      SDKActiveTab === "SortGroup"
    ) {
      const a = document.getElementsByClassName("sdk")[0];
      const b = a?.getElementsByClassName("ace_cursor")[0];
      if (b) {
        if (!b.classList.contains("del_cursor")) {
          b.classList.add("del_cursor");
        }
      }
    }
    resizeDownEditors();
    resizeUpEditors();
  }, [SDKActiveTab]);

  const highlightText = () => {
    if (SDKActiveTab === "SortGroup") {
      const parseDataEditor = document.getElementById("parseDataEditor");
      if (!parseDataEditor) {
        return;
      }
      const line_group = parseDataEditor?.getElementsByClassName("ace_line");
      if (line_group) {
        for (let i = 0; i < line_group.length; i++) {
          const element = line_group[i];
          if (
            (element.innerText === "ORDER BY" ||
              element.innerText === "GROUP BY") &&
            !line_group[i + 1].classList.contains("highlightText")
          ) {
            line_group[i + 1].classList.add("highlightText");
            element.classList.add("highlightText");
          }
        }
      }
    }
  };
  const resizeDownEditors = () => {
    if (!UpEditor.current) {
      return;
    }
    UpEditor.current.editor.resize();
  };
  resizeDownEditors();
  resizeUpEditors();
  useEffect(() => {
    highlightText();
    setTimeout(() => {
      highlightText();
    }, 500);
    setTimeout(() => {
      highlightText();
    }, 800);
    setTimeout(() => {
      highlightText();
    }, 1000);
  }, [downEditor?.current?.editor, data]);

  return (
    <div className="SDK-pane pane">
      <div className="SDK-wrapper wrapper">
        <div className="sub-header">
          <div className="tab-wrapper">
            <button
              type="button"
              data-testid="sdk-message-btn"
              className={
                "button left" + (SDKActiveTab === "Message" ? " activated" : "")
              }
              onClick={() => changedTab("Message")}
            >
              Message
            </button>
            <button
              type="button"
              data-testid="sdk-tableList-btn"
              className={
                "button" + (SDKActiveTab === "Tablelist" ? " activated" : "")
              }
              onClick={() => changedTab("Tablelist")}
            >
              Table list
            </button>
            <button
              type="button"
              data-testid="sdk-antipattern-btn"
              className={
                "button" + (SDKActiveTab === "Antipattern" ? " activated" : "")
              }
              onClick={() => changedTab("Antipattern")}
            >
              Anti pattern
            </button>
            <button
              type="button"
              data-testid="sdk-format-btn"
              className={
                "button" + (SDKActiveTab === "Format" ? " activated" : "")
              }
              onClick={() => changedTab("Format")}
            >
              Format
            </button>
            <button
              type="button"
              data-testid="sdk-ddl-btn"
              className={
                "button" + (SDKActiveTab === "DDL" ? " activated" : "")
              }
              onClick={() => changedTab("DDL")}
            >
              DDL
            </button>
            <button
              type="button"
              data-testid="sdk-filter-btn"
              className={
                "button" + (SDKActiveTab === "Filter" ? " activated" : "")
              }
              onClick={() => changedTab("Filter")}
            >
              Filter Columns
            </button>
            <button
              type="button"
              data-testid="sdk-joins-btn"
              className={
                "button" + (SDKActiveTab === "Join" ? " activated" : "")
              }
              onClick={() => changedTab("Join")}
            >
              Joins
            </button>
            <button
              type="button"
              data-testid="sdk-sortGroup-btn"
              className={
                "button" + (SDKActiveTab === "SortGroup" ? " activated" : "")
              }
              onClick={() => changedTab("SortGroup")}
            >
              Order By / Group By
            </button>
          </div>
        </div>
        <Split
          className="split-sdk"
          fontFamily="Open Sans"
          sizes={sizes}
          minSize={[30, 70]}
          // maxSize={[90, 90]}
          expandToMin={true}
          gutterSize={8}
          gutterAlign="center"
          snapOffset={5}
          dragInterval={1}
          direction="vertical"
          cursor={ScreenSvg}
          onDrag={(e) => {
            setSizes(e);
            resizeUpEditors();
            resizeDownEditors();
          }}
        >
          <SDKEditor SDKData={SDKData} sqlData={sqlData} type={SDKActiveTab} />
          <div
            className={
              SDKActiveTab === "Tablelist" ? "overflow tablelist" : "tablelist"
            }
          >
            {SDKActiveTab === "Tablelist" ? (
              <div style={{ height: "100 %" }}>
                <div className="table_wrapper">
                  <TableList
                    styleData={{
                      border: "hr_phy",
                      hover: "hov_phy",
                      fill: "fill_phy",
                    }}
                    type={"physical"}
                    data={physicalTables}
                  />
                  <TableList
                    styleData={{
                      border: "hr_cmn",
                      hover: "hov_cmn",
                      fill: "fill_cmn",
                    }}
                    type={"common"}
                    data={commonTables}
                  />
                  <TableList
                    styleData={{
                      border: "hr_inf",
                      hover: "hov_inf",
                      fill: "fill_inf",
                    }}
                    type={"inline"}
                    data={inlineTables}
                  />
                  <TableList
                    styleData={{
                      border: "hr_rec",
                      hover: "hov_rec",
                      fill: "fill_rec",
                    }}
                    type={"recursive"}
                    data={recursiveClause}
                  />
                  <TableList
                    styleData={{
                      border: "hr_tbl",
                      hover: "hov_tbl",
                      fill: "fill_tbl",
                    }}
                    type={"table"}
                    data={functionTables}
                  />
                  <TableList
                    styleData={{
                      border: "hr_pse",
                      hover: "hov_pse",
                      fill: "fill_pse",
                    }}
                    type={"pseudo"}
                    data={pseudoTables}
                  />
                </div>
              </div>
            ) : (
              <Message
                msgData={data}
                classes={classes}
                mode={mode}
                highlightText={highlightText}
              />
            )}
          </div>
        </Split>
      </div>
    </div>
  );
};

export default SDKBox;
