import React, { useEffect, useRef, useState } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ReactDOM from "react-dom";
import LeftSideBar from "../leftSideBar";
import TabBar from "../tabBar";
import TopRightBar from "../topRightBar";
import MainBoard from "../mainBoard";
import UserProfile from "../userProfile";
import AccountSettings from "../accountSettings";
import About from "../about";
import FormatTest from "../formatTest";
import "./main.css";
import { setAntipatternData } from "../../redux/actions/global";
import axios from "axios";
import { showSDKModal } from "../../redux/actions/global";
import SdkWorkFlow from "../sdkWorkflow";
import SurveyModal from "../modals/Survey";

const Main = () => {
  const fullscreenMode = useSelector(
    (state) => state.layoutReducer.fullscreenMode
  );
  const diagramBoxState = useSelector(
    (state) => state.layoutReducer.diagramBoxState
  );
  const dispatch = useDispatch();
  const [barItemWidth, setBarItemWidth] = useState(null);

  const parseEditorRef = useRef(null);
  const tryFreeTextRef = useRef(null);
  const tryFreeButtonRef = useRef(null);
  const tabBarRef = useRef(null);
  const tabNewRef = useRef(null);
  const [findNextInEditor, setFindNextInEditor] = useState(() => {
    return () => {
      console.trace("findNextInEditor has not been set");
    };
  });
  const [findPreviousInEditor, setFindPreviousInEditor] = useState(() => {
    return () => {
      console.trace("findPreviousInEditor has not been set");
    };
  });

  const RedirectToMain = (_) => {
    return <Navigate to={"/" + diagramBoxState + "-sql"} />;
  };

  //  set antipatterns type json data
  useEffect(() => {
    axios
      .get("https://docs.sonra.io/flowhigh/master/docs/static/index.json")
      .then((response) => {
        dispatch(setAntipatternData(response.data));
      })
      .catch((err) => {
        console.log(err);
      });
  }, [dispatch]);

  // current tab pos over lap free text
  const checkCurrentTabOverlapText = () => {
    if (tryFreeTextRef.current && tabBarRef.current && tabNewRef.current) {
      const userInfoXPos = tryFreeTextRef.current.getBoundingClientRect().left;
      const tabItemXEndPos =
        tabBarRef.current.getBoundingClientRect().left +
        tabBarRef.current.getBoundingClientRect().width;

      if (tabItemXEndPos > userInfoXPos) {
        return true;
      }
    }
    return false;
  };

  // current tab pos over lap free button
  const checkCurrentTabOverlapButton = () => {
    if (tryFreeButtonRef.current && tabBarRef.current && tabNewRef.current) {
      const tryFreeButtonXPos =
        tryFreeButtonRef.current.getBoundingClientRect().left;
      const tabItemXEndPos =
        tabBarRef.current.getBoundingClientRect().left +
        tabBarRef.current.getBoundingClientRect().width;

      // overlap to the free text button
      if (tabItemXEndPos >= tryFreeButtonXPos - 50) {
        const maxWidth =
          tryFreeButtonXPos -
          tabNewRef.current.getBoundingClientRect().width -
          50;
        const tabBarDom = ReactDOM.findDOMNode(tabBarRef.current);
        setBarItemWidth(maxWidth / tabBarDom.childNodes?.length);
        return true;
      }
    }
    return false;
  };

  // show/hide free text
  const changeVisibitilyOfFreeText = (tabPos, textPos) => {
    if (tryFreeTextRef.current) {
      if (tabPos > textPos) {
        tryFreeTextRef.current.style.visibility = "hidden";
      } else {
        tryFreeTextRef.current.style.visibility = "visible";
      }
    }
  };

  const handleClickedNewTab = () => {
    if (
      tryFreeTextRef.current &&
      tryFreeButtonRef.current &&
      tabBarRef.current &&
      tabNewRef.current
    ) {
      const userInfoXPos = tryFreeTextRef.current.getBoundingClientRect().left;
      const tryFreeButtonXPos =
        tryFreeButtonRef.current.getBoundingClientRect().left;
      const tabItemXEndPos =
        tabBarRef.current.getBoundingClientRect().left +
        tabBarRef.current.getBoundingClientRect().width;
      const tabItemCount =
        ReactDOM.findDOMNode(tabBarRef.current)?.childNodes?.length - 1;
      const tabItemWidth =
        (tabBarRef.current.getBoundingClientRect().width -
          tabNewRef.current.getBoundingClientRect().width) /
        tabItemCount;

      // overlap to the free text button
      if (tabItemXEndPos + tabItemWidth > tryFreeButtonXPos - 50) {
        const maxWidth =
          tryFreeButtonXPos -
          tabNewRef.current.getBoundingClientRect().width -
          50;
        const tabBarDom = ReactDOM.findDOMNode(tabBarRef.current);
        setBarItemWidth(maxWidth / tabBarDom.childNodes?.length);
        return false;
      }
      changeVisibitilyOfFreeText(tabItemXEndPos + tabItemWidth, userInfoXPos);
    }
    return true;
  };

  const handleClickCloseTab = () => {
    if (
      tryFreeTextRef.current &&
      tabBarRef.current &&
      tabNewRef.current &&
      tabNewRef.current
    ) {
      const userInfoXPos = tryFreeTextRef.current.getBoundingClientRect().left;
      const tabItemXEndPos =
        tabBarRef.current.getBoundingClientRect().left +
        tabBarRef.current.getBoundingClientRect().width;
      const tabItemCount =
        ReactDOM.findDOMNode(tabBarRef.current)?.childNodes?.length - 1;
      const tabItemWidth =
        (tabBarRef.current.getBoundingClientRect().width -
          tabNewRef.current.getBoundingClientRect().width) /
        tabItemCount;

      changeVisibitilyOfFreeText(tabItemXEndPos - tabItemWidth, userInfoXPos);
    }
  };

  const hideInfo = () => {
    dispatch(showSDKModal(false));
  };

  const getDefaultMainBoard = () => {
    return (
      <MainBoard
        ref={parseEditorRef}
        setFindPreviousInEditor={setFindPreviousInEditor}
        setFindNextInEditor={setFindNextInEditor}
      />
    );
  };

  return (
    <div
      className={`main-wrapper flex-row bright-theme ${
        fullscreenMode ? "fullscreen" : ""
      }`}
      onClick={hideInfo}
    >
      <LeftSideBar />
      <div className="body-wrapper">
        <div className="header-wrapper">
          <TabBar
            tabBarRef={tabBarRef}
            tabNewRef={tabNewRef}
            barItemWidth={barItemWidth}
            onClickNewTab={handleClickedNewTab}
            onClickCloseTab={handleClickCloseTab}
            checkCurrentTabOverlapText={checkCurrentTabOverlapText}
            checkCurrentTabOverlapButton={checkCurrentTabOverlapButton}
            changeVisibitilyOfFreeText={changeVisibitilyOfFreeText}
          />
          <TopRightBar
            findNextInEditor={findNextInEditor}
            findPreviousInEditor={findPreviousInEditor}
            parseEditorRef={parseEditorRef}
          />
        </div>
        <Routes>
          <Route index element={<RedirectToMain />} />
          <Route path="/visualise-sql" element={getDefaultMainBoard()} />
          <Route path="/parse-sql" element={getDefaultMainBoard()} />
          <Route path="/analyse-sql" element={getDefaultMainBoard()} />
          <Route path="/tune-sql" element={getDefaultMainBoard()} />
          <Route path="/parser-sdk-sql" element={getDefaultMainBoard()} />
          <Route path="/userprofile" element={<UserProfile />} />
          <Route path="/accountsettings" element={<AccountSettings />} />
          <Route path="/sdk-workflow" element={<SdkWorkFlow />} />
          <Route path="/about" element={<About />} />
          <Route path="/formattest" element={<FormatTest />} />
          <Route path="/survey-sql" element={<SurveyModal />} />
        </Routes>
      </div>
    </div>
  );
};

export default Main;
