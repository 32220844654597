import React from "react";
import "./patternMark.css";

const PatternMark = ({ width = 17, color = "#F9A825", count }) => {
  return (
    <div
      className="pattern-mark"
      style={{ width: `${width}px`, height: `${width}px` }}
    >
      <svg
        width={width}
        height={width}
        viewBox={`0 0 ${width} ${width}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx={width / 2} cy={width / 2} r={width / 2} fill={color} />
      </svg>
      <div
        className="pattern-count"
        style={{
          width: `${width}px`,
          height: `${width}px`,
          color: `${color === "#F9A825" ? "black" : "white"}`,
        }}
      >
        {count}
      </div>
    </div>
  );
};

export default PatternMark;
