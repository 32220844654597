import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { handlePropertyBox, setActiveTab } from "../../redux/actions/property";
import TableList from "../tableList";
import PropertyBox from "../propertyBox";
import { ReactComponent as SettingsSvg } from "../../assets/icons/settings.svg";
import { ReactComponent as CloseSettingSvg } from "../../assets/icons/close-settings.svg";
import "./containerBox.css";

import { isSpecialTab } from "../../helper/util";

const ContainerBox = () => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.propertyReducer.user);
  const diagramBoxState = useSelector(
    (state) => state.layoutReducer.diagramBoxState
  );
  const currentDiagram = useSelector(
    (state) => state.propertyReducer.userLayout[user].currentDiagram
  );

  const actPropertyBox = useSelector(
    (state) =>
      state.propertyReducer.userLayout[user].diagramList[currentDiagram]
        .propertyBox
  );
  const currentTab = useSelector(
    (state) =>
      state.propertyReducer.userLayout[user].diagramList[currentDiagram]
        .activeTab
  );
  const activeDiagram = useSelector(
    (state) =>
      state.propertyReducer.userLayout[user].diagramList[currentDiagram]
  );
  let diagramData = useSelector(
    (state) =>
      state.propertyReducer.userLayout[user].diagramList[currentDiagram]
        .diagramData
  );
  diagramData = activeDiagram ? diagramData : {};

  // close property box
  const handleClickedCloseSettings = () => {
    dispatch(handlePropertyBox(false));
  };
  return !isSpecialTab(currentDiagram) &&
    actPropertyBox &&
    diagramBoxState === "visualise" ? (
    <div
      className={
        currentTab === "tablelist"
          ? "container-pane1"
          : "container-pane" +
            (!diagramData || Object.keys(diagramData).length === 0
              ? " hidden disabled"
              : "")
      }
    >
      <div className={"container-wrapper"}>
        <div className="container-header">
          <span className="container-header-icon float-item disabled">
            <SettingsSvg />
          </span>
          <span className="container-title">Settings</span>
          <div
            className="container-close-button"
            onClick={() => {
              handleClickedCloseSettings();
            }}
          >
            <CloseSettingSvg />
          </div>
        </div>
        <div className="container-tab-wrapper flex-space">
          <div
            className={
              "container-tab" + (currentTab === "property" ? " activated" : "")
            }
            onClick={() => dispatch(setActiveTab("property"))}
          >
            Properties
          </div>
          <div
            className={
              "container-tab" + (currentTab === "tablelist" ? " activated" : "")
            }
            onClick={() => dispatch(setActiveTab("tablelist"))}
          >
            Table List
          </div>
        </div>
        <div className="container-box pane">
          {
            {
              property: <PropertyBox />,
              tablelist: <TableList />,
            }[currentTab]
          }
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default ContainerBox;
