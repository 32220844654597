/* eslint-disable no-multi-str */
import Ace from "ace-builds/src-noconflict/ace";
Ace.define(
  "ace/theme/parseql",
  ["require", "exports", "module", "ace/lib/dom"],
  function (require, exports) {
    exports.isDark = false;
    exports.cssClass = "ace-parseql";
    exports.cssText =
      '.ace-parseql .ace_gutter {\
background: #ebebeb;\
color: #333;\
overflow: hidden;\
}\
.ace-parseql .ace_print-margin {\
width: 1px;\
background: #e8e8e8;\
}\
.ace-parseql {\
background-color: #FFFFFF;\
color: black;\
}\
.ace-parseql .ace_identifier {\
color: black;\
}\
.ace-parseql .ace_keyword {\
font-weight: 600;\
color: #5200FF;\
}\
.ace-parseql .ace_numeric {\
font-weight: 600;\
color: #FF185E;\
}\
.ace-parseql .ace_storage {\
color: #11B7BE;\
}\
.ace-parseql .ace_keyword.ace_operator,\
.ace-parseql .ace_lparen,\
.ace-parseql .ace_rparen,\
.ace-parseql .ace_punctuation {\
color: #808080;\
}\
.ace-parseql .ace_set.ace_statement {\
color: #5200FF;\
text-decoration: underline;\
}\
.ace-parseql .ace_cursor {\
color: black;\
}\
.ace-parseql .ace_invisible {\
color: rgb(191, 191, 191);\
}\
.ace-parseql .ace_constant.ace_buildin {\
color: rgb(88, 72, 246);\
}\
.ace-parseql .ace_constant.ace_language {\
color: #979797;\
}\
.ace-parseql .ace_constant.ace_library {\
color: rgb(6, 150, 14);\
}\
.ace-parseql .ace_invalid {\
background-color: rgb(153, 0, 0);\
color: white;\
}\
.ace-parseql .ace_support.ace_function {\
color: #006FBF;\
font-weight: 600;\
}\
.ace-parseql .ace_support.ace_constant {\
color: rgb(6, 150, 14);\
}\
.ace-parseql .ace_class {\
color: #008080;\
}\
.ace-parseql .ace_support.ace_other {\
color: #6D79DE;\
}\
.ace-parseql .ace_variable.ace_parameter {\
font-style: italic;\
color: #FD971F;\
}\
.ace-parseql .ace_comment {\
color: #19B0BB;\
}\
.ace-parseql .ace_constant.ace_numeric {\
color: #FF185E;\
}\
.ace-parseql .ace_variable {\
color: rgb(49, 132, 149);\
}\
.ace-parseql .ace_xml-pe {\
color: rgb(104, 104, 91);\
}\
.ace-parseql .ace_support.ace_storedprocedure {\
color: #800000;\
}\
.ace-parseql .ace_heading {\
color: rgb(12, 7, 255);\
}\
.ace-parseql .ace_list {\
color: rgb(185, 6, 144);\
}\
.ace-parseql .ace_marker-layer .ace_selection {\
background: #AEDBFC;\
}\
.ace-parseql .ace_marker-layer .ace_step {\
background: rgb(252, 255, 0);\
}\
.ace-parseql .ace_marker-layer .ace_stack {\
background: rgb(164, 229, 101);\
}\
.ace-parseql .ace_marker-layer .ace_bracket {\
margin: -1px 0 0 -1px;\
border: 1px solid rgb(192, 192, 192);\
}\
.ace-parseql .ace_marker-layer .ace_active-line {\
background: rgba(0, 0, 0, 0.07);\
}\
.ace-parseql .ace_gutter-active-line {\
background-color: #dcdcdc;\
}\
.ace-parseql .ace_marker-layer .ace_selected-word {\
// background: rgb(250, 250, 255);\
// border: 1px solid rgb(200, 200, 250);\
}\
.ace-parseql .ace_meta.ace_tag {\
color: #0000FF;\
}\
.ace-parseql .ace_string.ace_regex {\
color: #FF0000;\
}\
.ace-parseql .ace_string {\
color: #FF0000;\
}\
.ace-parseql .ace_entity.ace_other.ace_attribute-name {\
color: #994409;\
}\
.ace-parseql .ace_indent-guide {\
background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAACCAYAAACZgbYnAAAAE0lEQVQImWP4////f4bLly//BwAmVgd1/w11/gAAAABJRU5ErkJggg==") right repeat-y;\
}\
';

    var dom = require("../lib/dom");
    dom.importCssString(exports.cssText, exports.cssClass, false);
  }
);

(function () {
  Ace.require(["parseql"], function (m) {
    if (typeof module == "object" && typeof exports == "object" && module) {
      module.exports = m;
    }
  });
})();
