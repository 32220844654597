import MenuButton from "../menuButton";
import "./sampleButtonMenu.css";
function SampleButtonMenu() {
  const menuButtons = ["Simple", "Regular", "Complex"];
  return (
    <div className="sub-header" data-testid="sample-menu">
      <div className="sample-button-wrapper">
        {menuButtons.map((item) => (
          <MenuButton displayName={item} key={item} />
        ))}
      </div>
    </div>
  );
}

export default SampleButtonMenu;
