import { createStore, applyMiddleware, compose, AnyAction } from "redux";
import thunk, { ThunkDispatch } from "redux-thunk";
import { Simplify } from "type-fest";
import rootReducer from "./reducer";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import createMigrate from "redux-persist/es/createMigrate";
import migrationManifest from "./migrationManifest";

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["globalReducer", "sampleDataReducer"],
  debug: true,
  version: 8,
  migrate: createMigrate(migrationManifest, { debug: true }),
};

let composeEnhancer = compose;
const persistedReducer = persistReducer(persistConfig, rootReducer);

if (process.env.NODE_ENV && process.env.NODE_ENV === "development") {
  composeEnhancer =
    (
      window as unknown as {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: typeof compose;
      }
    )["__REDUX_DEVTOOLS_EXTENSION_COMPOSE__"] || compose;
}

const store = createStore(
  persistedReducer,
  composeEnhancer(applyMiddleware(thunk /*, logger*/))
);
const persistor = persistStore(store);

export type RootState = Simplify<ReturnType<typeof store.getState>>;
export type Dispatch = ThunkDispatch<RootState, never, AnyAction>;
export { store, persistor };
