/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import axios from "axios";
import * as Yup from "yup";
import { ReactComponent as TuningSuccessImage } from "../../../assets/icons/tuning-success-image.svg";
import { ReactComponent as LogoSvg } from "../../../assets/icons/sonra-logo.svg";
import { ReactComponent as NextArrowSvg } from "../../../assets/icons/sign-arrow.svg";
import { ReactComponent as BackArrowSvg } from "../../../assets/icons/back-arrow.svg";
import { ReactComponent as SurveyNinga } from "../../../assets/icons/Ninga.svg";

import "./survay.css";
import { showSurveyModal } from "../../../redux/actions/global";
import { useAuth } from "../../../auth/AuthContext";

const SurveyModal = () => {
  const dispatch = useDispatch();

  const { user } = useAuth();

  const [surveyState, setSurveyState] = useState("start");

  // state for Survey
  // state for wizard
  const [showPane, setShowPane] = useState(1);
  const [EMAIL, setEMAIL] = useState("");
  const [FIRSTNAME, setFIRSTNAME] = useState("");
  const [LASTNAME, setLASTNAME] = useState("");

  // const checkConditions = ()=> {
  //     if(showPane===1 && formik.values.EMAIL && formik.values.FIRSTNAME && formik.values.LASTNAME && formik.values.DIFFICULTY_OF_USE && formik.values.INTUITIVENESS_OF_USER_INTERFACE && formik.values.PROBLEM_USING_FEATURES)
  //     {
  //         return true
  //     }
  //     else if(showPane===2 &&formik.values.MISSING_FEATURES && formik.values.FH_SIMILAR_PRODUCTS && formik.values.FH_FEATURES && formik.values.IMPROVEMENTS_OF_FEATURES)
  //     {
  //         return true
  //     }
  //     else if(showPane===3 &&formik.values.MOSTLIKABLE_ASPECTS && formik.values.LESSLIKABLE_ASPECTS && formik.values.IMPROVEMENTS_TO_FH && formik.values.NEW_FEATURES_TO_FH && formik.values.RECOMMENDATION_OF_FH)
  //     {
  //         return true
  //     }
  //     else if(showPane===4 &&formik.values.CRASHES_OR_FREEZES && formik.values.TASKS_OR_ACTIONS_THAT_LEAD_TO_ISSUES && formik.values.UNEXPECTED_BEHAVIOR )
  //     {
  //         return true
  //     }else if(showPane===5 &&formik.values.DOCUMENTATION_HELPFUL && formik.values.DOCU_AREAS_IMPROVEMENTS && formik.values.DOCS_NOT_COVERED_TOPICS && formik.values.ADDITIONAL_SUPPORT && formik.values.SATISFIED_WITH_SUPPORT)
  //     {
  //         return true
  //     }else if(showPane===6 &&formik.values.PRICING_MODEL && formik.values.WHY_PRICING_MODEL && formik.values.MONTHLY_PRICE_RANGE && formik.values.HIGHER_PRICE && formik.values.PRODUCT_VALUE)
  //     {
  //         return true
  //     }
  //     else{
  //         return false
  //     }
  // }

  const validationSchema = Yup.object().shape({
    EMAIL: Yup.string().email().required("required"),
    FIRSTNAME: Yup.string().required("required"),
    LASTNAME: Yup.string().required("required"),
    // DIFFICULTY_OF_USE:Yup.string().required('required'),

    // IMPROVEMENTS_OF_FEATURES:Yup.string().required('required'),
    // MISSING_FEATURES:Yup.string().required('required'),
    // FH_SIMILAR_PRODUCTS:Yup.string().required('required'),
    // FH_FEATURES:Yup.string().required('required'),

    // INTUITIVENESS_OF_USER_INTERFACE:Yup.string().required('required'),
    // PROBLEM_USING_FEATURES:Yup.string().required('required'),

    // MOSTLIKABLE_ASPECTS:Yup.string().required('required'),
    // LESSLIKABLE_ASPECTS:Yup.string().required('required'),
    // IMPROVEMENTS_TO_FH:Yup.string().required('required'),
    // NEW_FEATURES_TO_FH:Yup.string().required('required'),
    // RECOMMENDATION_OF_FH:Yup.string().required('required'),

    // CRASHES_OR_FREEZES:Yup.string().required('required'),
    // TASKS_OR_ACTIONS_THAT_LEAD_TO_ISSUES:Yup.string().required('required'),
    // UNEXPECTED_BEHAVIOR:Yup.string().required('required'),
    // DOCUMENTATION_HELPFUL:Yup.string().required('required'),
    // DOCU_AREAS_IMPROVEMENTS:Yup.string().required('required'),
    // DOCS_NOT_COVERED_TOPICS:Yup.string().required('required'),
    // ADDITIONAL_SUPPORT:Yup.string().required('required'),
    // SATISFIED_WITH_SUPPORT:Yup.string().required('required'),
    // PRICING_MODEL:Yup.string().required('required'),
    // WHY_PRICING_MODEL:Yup.string().required('required'),
    // MONTHLY_PRICE_RANGE:Yup.string().required('required'),
    // HIGHER_PRICE:Yup.string().required('required'),
    // PRODUCT_VALUE:Yup.string().required('required'),
  });

  const formik = useFormik({
    initialValues: {
      EMAIL: EMAIL,
      FIRSTNAME: FIRSTNAME,
      LASTNAME: LASTNAME,
      DIFFICULTY_OF_USE: "",
      INTUITIVENESS_OF_USER_INTERFACE: "",
      PROBLEM_USING_FEATURES: "",
      FH_FEATURES: "",
      IMPROVEMENTS_OF_FEATURES: "",
      MISSING_FEATURES: "",
      FH_SIMILAR_PRODUCTS: "",
      MOSTLIKABLE_ASPECTS: "",
      LESSLIKABLE_ASPECTS: "",
      IMPROVEMENTS_TO_FH: "",
      NEW_FEATURES_TO_FH: "",
      RECOMMENDATION_OF_FH: "",
      CRASHES_OR_FREEZES: "",
      TASKS_OR_ACTIONS_THAT_LEAD_TO_ISSUES: "",
      UNEXPECTED_BEHAVIOR: "",
      DOCUMENTATION_HELPFUL: "",
      DOCU_AREAS_IMPROVEMENTS: "",
      DOCS_NOT_COVERED_TOPICS: "",
      ADDITIONAL_SUPPORT: "",
      SATISFIED_WITH_SUPPORT: "",
      PRICING_MODEL: "",
      WHY_PRICING_MODEL: "",
      MONTHLY_PRICE_RANGE: "",
      HIGHER_PRICE: "",
      PRODUCT_VALUE: "",
    },

    onSubmit: (values) => {
      const surveyFormData = new FormData();
      surveyFormData.append("EMAIL", values.EMAIL);
      surveyFormData.append("FIRSTNAME", values.FIRSTNAME);
      surveyFormData.append("LASTNAME", values.LASTNAME);
      surveyFormData.append("DIFFICULTY_OF_USE", values.DIFFICULTY_OF_USE);
      surveyFormData.append(
        "INTUITIVENESS_OF_USER_INTERFACE",
        values.INTUITIVENESS_OF_USER_INTERFACE
      );
      surveyFormData.append(
        "PROBLEM_USING_FEATURES",
        values.PROBLEM_USING_FEATURES
      );
      surveyFormData.append("FH_FEATURES", values.FH_FEATURES);
      surveyFormData.append(
        "IMPROVEMENTS_OF_FEATURES",
        values.IMPROVEMENTS_OF_FEATURES
      );
      surveyFormData.append("MISSING_FEATURES", values.MISSING_FEATURES);
      surveyFormData.append("FH_SIMILAR_PRODUCTS", values.FH_SIMILAR_PRODUCTS);
      surveyFormData.append("MOSTLIKABLE_ASPECTS", values.MOSTLIKABLE_ASPECTS);
      surveyFormData.append("LESSLIKABLE_ASPECTS", values.LESSLIKABLE_ASPECTS);
      surveyFormData.append("IMPROVEMENTS_TO_FH", values.IMPROVEMENTS_TO_FH);
      surveyFormData.append("NEW_FEATURES_TO_FH", values.NEW_FEATURES_TO_FH);
      surveyFormData.append(
        "RECOMMENDATION_OF_FH",
        values.RECOMMENDATION_OF_FH
      );
      surveyFormData.append("CRASHES_OR_FREEZES", values.CRASHES_OR_FREEZES);
      surveyFormData.append(
        "TASKS_OR_ACTIONS_THAT_LEAD_TO_ISSUES",
        values.TASKS_OR_ACTIONS_THAT_LEAD_TO_ISSUES
      );
      surveyFormData.append("UNEXPECTED_BEHAVIOR", values.UNEXPECTED_BEHAVIOR);
      surveyFormData.append(
        "DOCUMENTATION_HELPFUL",
        values.DOCUMENTATION_HELPFUL
      );
      surveyFormData.append(
        "DOCU_AREAS_IMPROVEMENTS",
        values.DOCU_AREAS_IMPROVEMENTS
      );
      surveyFormData.append(
        "DOCS_NOT_COVERED_TOPICS",
        values.DOCS_NOT_COVERED_TOPICS
      );
      surveyFormData.append("ADDITIONAL_SUPPORT", values.ADDITIONAL_SUPPORT);
      surveyFormData.append(
        "SATISFIED_WITH_SUPPORT",
        values.SATISFIED_WITH_SUPPORT
      );
      surveyFormData.append("PRICING_MODEL", values.PRICING_MODEL);
      surveyFormData.append("WHY_PRICING_MODEL", values.WHY_PRICING_MODEL);
      surveyFormData.append("MONTHLY_PRICE_RANGE", values.MONTHLY_PRICE_RANGE);
      surveyFormData.append("HIGHER_PRICE", values.HIGHER_PRICE);
      surveyFormData.append("PRODUCT_VALUE", values.PRODUCT_VALUE);
      surveyFormData.append("locale", "en");

      let request_url =
        "https://f6d808b0.sibforms.com/serve/MUIEAMYR1-ictpqBRUV8FyWhHrhXg6Uugo31gCqPCcEER2XfsVfIu2umdbQBiy90Xd1BdnvrPm_OYSisdhlBSPUC2lMNmVbeJoKQjOkpPLU0UlwTB1miGaGHgTqCf3wYOcPyHucRSwfOklOaG1sBTStqEtKfMO3aLdBcUajdP74PynEyfIqEj6-5bL5NCJ6t67kIdq7sDjQdjAhA?isAjax=1";
      axios
        .post(request_url, surveyFormData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then(() => {
          setSurveyState("success");
        })
        .catch((err) => {
          setSurveyState("fail");
          console.log(err);
        });
    },
    validationSchema,
  });
  useEffect(() => {
    formik.resetForm();

    setEMAIL(user.email);
    formik.values.EMAIL = user.email;
    formik.values.FIRSTNAME =
      user?.["https://flowhigh.io/user_metadata.firstName"] ?? "";
    formik.values.LASTNAME =
      user?.["https://flowhigh.io/user_metadata.lastName"] ?? "";
    setFIRSTNAME(user?.["https://flowhigh.io/user_metadata.firstName"] ?? "");
    setLASTNAME(user?.["https://flowhigh.io/user_metadata.lastName"] ?? "");
  }, []);

  const handleNextClicked = () => {
    setShowPane(showPane + 1);
    // formik.validateForm().then((res) => {
    //     if(checkConditions()){
    //     }else{
    //     }
    // })
  };

  const preventClick = (event) => {
    event.stopPropagation();
    return false;
  };

  const hideSurveyPopup = () => {
    dispatch(showSurveyModal(false));
  };
  return (
    <div
      style={{
        visibility: "visible",
        opacity: "1",
        height: "100%",
      }}
      className=""
      onClick={hideSurveyPopup}
    >
      <div className="popup1" onClick={preventClick}>
        <div className="content1">
          <div className="survey-modal-body">
            {surveyState === "start" || surveyState === "fail" ? (
              <>
                <div className="left-panel1">
                  <h1>Submit your FlowHigh evaluation survey</h1>
                  <SurveyNinga />
                </div>
                <div className="right-panel">
                  <form onSubmit={formik.handleSubmit}>
                    {
                      {
                        1: (
                          <>
                            <h3 className="description-heading">
                              Product Usability:
                            </h3>
                            <div
                              className="description-bold"
                              style={{ marginTop: "20px" }}
                            >
                              How easy was it to set up and start using the
                              product?
                            </div>
                            <select
                              className="database-type"
                              //  className={`database-type ${formik.errors.DIFFICULTY_OF_USE==="required" ? ' required' : ''}`}

                              name="DIFFICULTY_OF_USE"
                              value={formik.values.DIFFICULTY_OF_USE}
                              onChange={formik.handleChange}
                            >
                              <option value="" disabled="" hidden="">
                                Select one
                              </option>
                              <option value="1">
                                Effortless - I had no issues at all.
                              </option>
                              <option value="2">
                                Straightforward - I encountered minor
                                challenges, but overall it was simple.
                              </option>
                              <option value="3">
                                Manageable - I faced some difficulties, but I
                                was able to figure it out.
                              </option>
                              <option value="4">
                                Complicated - I experienced several challenges
                                and needed assistance.
                              </option>
                              <option value="5">
                                Overwhelming - I struggled a lot and found it
                                very difficult to set up and use the product.
                              </option>
                            </select>

                            <div className="description-bold">
                              How intuitive was the user interface?
                            </div>
                            <select
                              className="database-type"
                              // className={`database-type ${formik.errors.INTUITIVENESS_OF_USER_INTERFACE==="required" ? ' required' : ''}`}

                              name="INTUITIVENESS_OF_USER_INTERFACE"
                              value={
                                formik.values.INTUITIVENESS_OF_USER_INTERFACE
                              }
                              onChange={formik.handleChange}
                            >
                              <option value="" disabled="" hidden="">
                                Select one
                              </option>
                              <option value="1">
                                Highly intuitive - The user interface was very
                                easy to understand and navigate.
                              </option>
                              <option value="2">
                                Mostly intuitive - The user interface was
                                generally easy to use, with a few minor
                                exceptions.
                              </option>
                              <option value="3">
                                Somewhat intuitive - The user interface was
                                moderately easy to use, but some aspects were
                                confusing.
                              </option>
                              <option value="4">
                                Not very intuitive - The user interface was
                                difficult to understand and required additional
                                guidance.
                              </option>
                              <option value="5">
                                Not intuitive at all - The user interface was
                                extremely confusing and challenging to navigate.
                              </option>
                            </select>

                            <div className="description-bold">
                              What difficulties or confusion did you encounter
                              when using specific features?
                            </div>

                            <textarea
                              // className={`${formik.errors.PROBLEM_USING_FEATURES==="required" ? ' required' : ''}`}
                              name="PROBLEM_USING_FEATURES"
                              id="PROBLEM_USING_FEATURES"
                              value={formik.values.PROBLEM_USING_FEATURES}
                              onChange={formik.handleChange}
                              maxLength="500"
                              required
                              placeholder="Please note that the maximum character limit for this response is 500 characters."
                            ></textarea>
                          </>
                        ),
                        2: (
                          <>
                            <div
                              className="back-button"
                              onClick={() => {
                                setShowPane(showPane - 1);
                              }}
                            >
                              <BackArrowSvg />
                              <div className="caption">Back</div>
                            </div>

                            <h3 className="description-heading">
                              Product Features:
                            </h3>

                            <div
                              className="description-bold"
                              style={{ marginTop: "20px" }}
                            >
                              Which features did you find most useful?
                            </div>
                            <select
                              className="database-type"
                              //  className={`database-type ${formik.errors.FH_FEATURES==="required" ? ' required' : ''}`}

                              name="FH_FEATURES"
                              value={formik.values.FH_FEATURES}
                              onChange={formik.handleChange}
                            >
                              <option value="" disabled="" hidden="">
                                Select one
                              </option>
                              <option value="1">Visualisation</option>
                              <option value="2">Format</option>
                              <option value="3"> Parse</option>
                              <option value="4">Analyse</option>
                              <option value="5">SDK</option>
                              <option value="5">Tune</option>
                            </select>

                            <div
                              className="description-bold"
                              style={{ marginTop: "20px" }}
                            >
                              Which features do you think require improvements?
                              Why?
                            </div>
                            <textarea
                              name="IMPROVEMENTS_OF_FEATURES"
                              id="IMPROVEMENTS_OF_FEATURES"
                              value={formik.values.IMPROVEMENTS_OF_FEATURES}
                              onChange={formik.handleChange}
                              placeholder="Please note that the maximum character limit for this response is 500 characters."
                              maxLength="500"
                              className="textarea1"
                              // className={`textarea1${formik.errors.IMPROVEMENTS_OF_FEATURES==="required" ? ' required' : ''}`}
                            ></textarea>

                            <div className="description-bold">
                              Were there any features that you expected but were
                              missing?
                            </div>
                            <textarea
                              name="MISSING_FEATURES"
                              id="MISSING_FEATURES"
                              value={formik.values.MISSING_FEATURES}
                              onChange={formik.handleChange}
                              className="textarea1"
                              placeholder="Please note that the maximum character limit for this response is 500 characters."
                              maxLength="500"
                              //  className={`textarea1${formik.errors.MISSING_FEATURES==="required" ? ' required' : ''}`}
                            ></textarea>

                            <div className="description-bold">
                              Have you used similar products? Which products?
                            </div>

                            <textarea
                              name="FH_SIMILAR_PRODUCTS"
                              id="FH_SIMILAR_PRODUCTS"
                              value={formik.values.FH_SIMILAR_PRODUCTS}
                              onChange={formik.handleChange}
                              className="textarea1"
                              placeholder="Please note that the maximum character limit for this response is 500 characters."
                              maxLength="500"
                              // className={`textarea1${formik.errors.FH_SIMILAR_PRODUCTS==="required" ? ' required' : ''}`}
                            ></textarea>
                          </>
                        ),
                        3: (
                          <>
                            <div
                              className="back-button1"
                              onClick={() => {
                                setShowPane(showPane - 1);
                              }}
                            >
                              <BackArrowSvg />
                              <div className="caption">Back</div>
                            </div>
                            <h3 className="description-heading">
                              Feedback and Suggestions:
                            </h3>

                            <div
                              className="description-bold"
                              style={{ marginTop: "20px" }}
                            >
                              What aspects of the product did you like the most?
                            </div>
                            <textarea
                              name="MOSTLIKABLE_ASPECTS"
                              id="MOSTLIKABLE_ASPECTS"
                              value={formik.values.MOSTLIKABLE_ASPECTS}
                              onChange={formik.handleChange}
                              className="textarea1"
                              placeholder="Please note that the maximum character limit for this response is 500 characters."
                              maxLength="500"
                              // className={`textarea1${formik.errors.MOSTLIKABLE_ASPECTS==="required" ? ' required' : ''}`}
                            ></textarea>

                            <div className="description-bold">
                              What aspects of the product did you like the
                              least?
                            </div>
                            <textarea
                              name="LESSLIKABLE_ASPECTS"
                              id="LESSLIKABLE_ASPECTS"
                              value={formik.values.LESSLIKABLE_ASPECTS}
                              onChange={formik.handleChange}
                              placeholder="Please note that the maximum character limit for this response is 500 characters."
                              maxLength="500"
                              className="textarea1"
                              // className={`textarea1${formik.errors.LESSLIKABLE_ASPECTS==="required" ? ' required' : ''}`}
                            ></textarea>

                            <div className="description-bold">
                              What improvements would you suggest for the
                              product?
                            </div>

                            <textarea
                              name="IMPROVEMENTS_TO_FH"
                              id="IMPROVEMENTS_TO_FH"
                              value={formik.values.IMPROVEMENTS_TO_FH}
                              onChange={formik.handleChange}
                              placeholder="Please note that the maximum character limit for this response is 500 characters."
                              maxLength="500"
                              className="textarea1"
                              //  className={`textarea1${formik.errors.IMPROVEMENTS_TO_FH==="required" ? ' required' : ''}`}
                            ></textarea>

                            <div className="description-bold">
                              What additional features would you like to see in
                              the future?
                            </div>

                            <textarea
                              name="NEW_FEATURES_TO_FH"
                              id="NEW_FEATURES_TO_FH"
                              value={formik.values.NEW_FEATURES_TO_FH}
                              onChange={formik.handleChange}
                              placeholder="Please note that the maximum character limit for this response is 500 characters."
                              maxLength="500"
                              className="textarea1"
                              // className={`textarea1${formik.errors.NEW_FEATURES_TO_FH==="required" ? ' required' : ''}`}
                            ></textarea>

                            <div className="description-bold">
                              How likely are you to recommend this product to
                              others?
                            </div>
                            <select
                              className="database-type"
                              //  className={`database-type ${formik.errors.RECOMMENDATION_OF_FH==="required" ? ' required' : ''}`}

                              name="RECOMMENDATION_OF_FH"
                              value={formik.values.RECOMMENDATION_OF_FH}
                              onChange={formik.handleChange}
                            >
                              <option value="" disabled="" hidden="">
                                Select one
                              </option>
                              <option value="1"> 0 - Not likely at all</option>
                              <option value="2">1</option>
                              <option value="3">2</option>
                              <option value="4">3</option>
                              <option value="5">4</option>
                              <option value="6"> 5 - Neutral</option>
                              <option value="7">6</option>
                              <option value="8">7</option>
                              <option value="9">8</option>
                              <option value="10">9</option>
                              <option value="11"> 10 - Extremely Likely</option>
                            </select>
                          </>
                        ),
                        4: (
                          <>
                            <div
                              className="back-button"
                              onClick={() => {
                                setShowPane(showPane - 1);
                              }}
                            >
                              <BackArrowSvg />
                              <div className="caption">Back</div>
                            </div>

                            <h3 className="description-heading">
                              Product Performance:
                            </h3>
                            <div
                              className="description-bold"
                              style={{ marginTop: "20px" }}
                            >
                              Did you experience any crashes or freezes while
                              using the product?
                            </div>
                            <select
                              className="database-type"
                              //  className={`database-type ${formik.errors.CRASHES_OR_FREEZES==="required" ? ' required' : ''}`}

                              name="CRASHES_OR_FREEZES"
                              value={formik.values.CRASHES_OR_FREEZES}
                              onChange={formik.handleChange}
                            >
                              <option value="" disabled="" hidden="">
                                Select one
                              </option>
                              <option value="1">
                                No, I did not experience any crashes or freezes.
                              </option>
                              <option value="2">
                                Yes, I experienced crashes or freezes very
                                rarely.
                              </option>
                              <option value="3">
                                Yes, I experienced crashes or freezes
                                occasionally.
                              </option>
                              <option value="4">
                                Yes, I experienced crashes or freezes
                                frequently.
                              </option>
                              <option value="5">
                                Yes, I experienced crashes or freezes
                                constantly.
                              </option>
                            </select>

                            <div className="description-bold">
                              Were there any specific tasks or actions that
                              resulted in performance issues?
                            </div>

                            <textarea
                              name="TASKS_OR_ACTIONS_THAT_LEAD_TO_ISSUES"
                              id="TASKS_OR_ACTIONS_THAT_LEAD_TO_ISSUES"
                              value={
                                formik.values
                                  .TASKS_OR_ACTIONS_THAT_LEAD_TO_ISSUES
                              }
                              onChange={formik.handleChange}
                              placeholder="Please note that the maximum character limit for this response is 500 characters."
                              maxLength="500"
                              className="textarea1"

                              //  className={`textarea1${formik.errors.TASKS_OR_ACTIONS_THAT_LEAD_TO_ISSUES==="required" ? ' required' : ''}`}
                            ></textarea>

                            <div className="description-bold">
                              Please specify any unexpected behavior or errors
                              you experienced?
                            </div>

                            <textarea
                              name="UNEXPECTED_BEHAVIOR"
                              id="UNEXPECTED_BEHAVIOR"
                              value={formik.values.UNEXPECTED_BEHAVIOR}
                              onChange={formik.handleChange}
                              placeholder="Please note that the maximum character limit for this response is 500 characters."
                              maxLength="500"
                              className="textarea1"
                              // className={`textarea1${formik.errors.UNEXPECTED_BEHAVIOR==="required" ? ' required' : ''}`}
                            ></textarea>
                          </>
                        ),
                        5: (
                          <>
                            <div
                              className="back-button"
                              onClick={() => {
                                setShowPane(showPane - 1);
                              }}
                            >
                              <BackArrowSvg />
                              <div className="caption">Back</div>
                            </div>

                            <h3 className="description-heading">
                              Product Documentation and Support:
                            </h3>
                            <div
                              className="description-bold"
                              style={{ marginTop: "20px" }}
                            >
                              How helpful did you find the product
                              documentation?
                            </div>
                            <select
                              className="database-type"
                              //  className={`database-type ${formik.errors.DOCUMENTATION_HELPFUL==="required" ? ' required' : ''}`}

                              name="DOCUMENTATION_HELPFUL"
                              value={formik.values.DOCUMENTATION_HELPFUL}
                              onChange={formik.handleChange}
                            >
                              <option value="" disabled="" hidden="">
                                Select one
                              </option>
                              <option value="1">
                                Extremely helpful - The documentation provided
                                all the information I needed and was easy to
                                understand.
                              </option>
                              <option value="2">
                                Very helpful - The documentation mostly covered
                                my needs, with just a few minor gaps or unclear
                                areas.
                              </option>
                              <option value="3">
                                Moderately helpful - The documentation was
                                somewhat useful, but I needed additional
                                resources or support.
                              </option>
                              <option value="4">
                                Slightly helpful - The documentation lacked
                                important information and was difficult to
                                understand in some parts.
                              </option>
                              <option value="5">
                                {" "}
                                Not helpful at all - The documentation was
                                confusing, incomplete, or not relevant to my
                                needs.
                              </option>
                            </select>

                            <div className="description-bold">
                              What topics or areas of the documentation require
                              improvements? Why?
                            </div>

                            <textarea
                              name="DOCU_AREAS_IMPROVEMENTS"
                              id="DOCU_AREAS_IMPROVEMENTS"
                              value={formik.values.DOCU_AREAS_IMPROVEMENTS}
                              onChange={formik.handleChange}
                              placeholder="Please note that the maximum character limit for this response is 500 characters."
                              maxLength="500"
                              className='"textarea1'
                              // className={`textarea1${formik.errors.DOCU_AREAS_IMPROVEMENTS==="required" ? ' required' : ''}`}
                            ></textarea>

                            <div className="description-bold">
                              Were there any topics or areas that were not
                              covered in the documentation?
                            </div>

                            <textarea
                              name="DOCS_NOT_COVERED_TOPICS"
                              id="DOCS_NOT_COVERED_TOPICS"
                              value={formik.values.DOCS_NOT_COVERED_TOPICS}
                              onChange={formik.handleChange}
                              className='"textarea1'
                              placeholder="Please note that the maximum character limit for this response is 500 characters."
                              maxLength="500"
                              // className={`textarea1${formik.errors.DOCS_NOT_COVERED_TOPICS==="required" ? ' required' : ''}`}
                            ></textarea>

                            <div className="description-bold">
                              Did you require any additional support during the
                              testing period?
                            </div>

                            <textarea
                              name="ADDITIONAL_SUPPORT"
                              id="ADDITIONAL_SUPPORT"
                              value={formik.values.ADDITIONAL_SUPPORT}
                              onChange={formik.handleChange}
                              placeholder="Please note that the maximum character limit for this response is 500 characters."
                              maxLength="500"
                              className='"textarea1'

                              // className={`textarea1${formik.errors.ADDITIONAL_SUPPORT==="required" ? ' required' : ''}`}
                            ></textarea>

                            <div className="description-bold">
                              How satisfied were you with the support you
                              received?
                            </div>
                            <select
                              className="database-type"
                              //  className={`database-type ${formik.errors.SATISFIED_WITH_SUPPORT==="required" ? ' required' : ''}`}

                              name="SATISFIED_WITH_SUPPORT"
                              value={formik.values.SATISFIED_WITH_SUPPORT}
                              onChange={formik.handleChange}
                            >
                              <option value="" disabled="" hidden="">
                                Select one
                              </option>
                              <option value="1">
                                Extremely satisfied - The support I received
                                exceeded my expectations and resolved my issues
                                promptly.
                              </option>
                              <option value="2">
                                Very satisfied - The support was helpful and
                                addressed most of my concerns, with only minor
                                room for improvement.
                              </option>
                              <option value="3">
                                Moderately satisfied - The support was adequate
                                but could have been more responsive or
                                informative.
                              </option>
                              <option value="4">
                                Slightly satisfied - The support I received was
                                limited and did not fully address my concerns.
                              </option>
                              <option value="5">
                                Not satisfied at all - The support was
                                unhelpful, unresponsive, or did not resolve my
                                issues.
                              </option>
                            </select>
                          </>
                        ),
                        6: (
                          <>
                            <div
                              className="back-button"
                              onClick={() => {
                                setShowPane(showPane - 1);
                              }}
                            >
                              <BackArrowSvg />
                              <div className="caption">Back</div>
                            </div>

                            <h3 className="description-heading">
                              Product Pricing and Value:
                            </h3>
                            <div
                              className="description-bold"
                              style={{ marginTop: "20px" }}
                            >
                              What pricing model do you prefer for a data
                              engineering product?
                            </div>
                            <select
                              className="database-type"
                              //  className={`database-type ${formik.errors.PRICING_MODEL==="required" ? ' required' : ''}`}

                              name="PRICING_MODEL"
                              value={formik.values.PRICING_MODEL}
                              onChange={formik.handleChange}
                            >
                              <option value="" disabled="" hidden="">
                                Select one
                              </option>
                              <option value="1">Per-user pricing</option>
                              <option value="2">Pay-per-use pricing</option>
                              <option value="3">
                                Monthly Flat-rate pricing
                              </option>
                              <option value="4">Feature pricing</option>
                            </select>

                            <div className="description-bold">
                              Why do you prefer this pricing model?
                            </div>

                            <textarea
                              name="WHY_PRICING_MODEL"
                              id="WHY_PRICING_MODEL"
                              value={formik.values.WHY_PRICING_MODEL}
                              onChange={formik.handleChange}
                              placeholder="Please note that the maximum character limit for this response is 500 characters."
                              maxLength="500"
                              className="textarea1"
                              // className={`textarea1${formik.errors.WHY_PRICING_MODEL==="required" ? ' required' : ''}`}
                            ></textarea>

                            <div className="description-bold">
                              What monthly price range do you think is
                              reasonable for the product?
                            </div>

                            <textarea
                              name="MONTHLY_PRICE_RANGE"
                              id="MONTHLY_PRICE_RANGE"
                              value={formik.values.MONTHLY_PRICE_RANGE}
                              onChange={formik.handleChange}
                              placeholder="Please note that the maximum character limit for this response is 500 characters."
                              maxLength="500"
                              className="textarea1"
                              //  className={`textarea1${formik.errors.MONTHLY_PRICE_RANGE==="required" ? ' required' : ''}`}
                            ></textarea>

                            <div className="description-bold">
                              Are there any features or improvements that would
                              make the product worth a higher price?
                            </div>

                            <textarea
                              name="HIGHER_PRICE"
                              id="HIGHER_PRICE"
                              value={formik.values.HIGHER_PRICE}
                              onChange={formik.handleChange}
                              placeholder="Please note that the maximum character limit for this response is 500 characters."
                              maxLength="500"
                              className="textarea1"
                              // className={`textarea1${formik.errors.HIGHER_PRICE==="required" ? ' required' : ''}`}
                            ></textarea>

                            <div className="description-bold">
                              How does this product's value compare to similar
                              products or services you have used?
                            </div>
                            <textarea
                              name="PRODUCT_VALUE"
                              id="PRODUCT_VALUE"
                              value={formik.values.PRODUCT_VALUE}
                              onChange={formik.handleChange}
                              placeholder="Please note that the maximum character limit for this response is 500 characters."
                              maxLength="500"
                              className="textarea1"
                              // className={`textarea1${formik.errors.PRODUCT_VALUE==="required" ? ' required' : ''}`}
                            ></textarea>
                            <button className="submit-button" type="submit">
                              Submit Survey
                            </button>
                          </>
                        ),
                      }[showPane]
                    }
                    {showPane !== 6 ? (
                      <button
                        className="next-button"
                        type="button"
                        onClick={handleNextClicked}
                      >
                        <NextArrowSvg />
                      </button>
                    ) : (
                      <></>
                    )}
                  </form>
                </div>
              </>
            ) : (
              <div className="success-pane">
                <LogoSvg className="tuning-avata" />

                <div className="tuning-success-caption">Success!</div>

                <div className="tuning-success-description">
                  Our SQL ninjas have received your submission
                </div>
                <TuningSuccessImage className="img-survey" />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SurveyModal;
