import React, { MouseEventHandler, useState } from "react";
import OptionSelector from "../../commonComponent/optionSelector";
import { useFormik } from "formik";
import axios from "axios";
import * as Yup from "yup";
import { ReactComponent as TuningImage } from "../../../assets/icons/tuning-image.svg";
import { ReactComponent as TuningSuccessImage } from "../../../assets/icons/tuning-success-image.svg";
import { ReactComponent as LogoSvg } from "../../../assets/icons/sonra-logo.svg";
import { ReactComponent as NextArrowSvg } from "../../../assets/icons/sign-arrow.svg";
import { ReactComponent as BackArrowSvg } from "../../../assets/icons/back-arrow.svg";
import "./tuningModal.css";
import { setGlobalModalID } from "../../../redux/actions/global";
import AgreeCheck from "../../commonComponent/agreeCheck";
import { useAppDispatch } from "../../../redux/hooks";
import { useAuth } from "../../../auth/AuthContext";
import { useUpdateEffect } from "react-use";

const TuningModal = () => {
  const dispatch = useAppDispatch();

  const { isAuthenticated, user } = useAuth();

  const [tuningState, setTuningState] = useState("start");

  // state for tuning component
  const [databaseType, setDatabaseType] = useState(19);
  const [databaseLocation, setDatabaseLocation] = useState(1);
  const [provideEnv, setProvideEnv] = useState(1);
  const [makeDDL, setMakeDDL] = useState(1);
  const [planQuery, setPlanQuery] = useState(1);
  const [businessCase, setBusinessCase] = useState(1);

  // state for wizard
  const [showPane, setShowPane] = useState(1);

  const onClose = () => {
    dispatch(setGlobalModalID(""));
  };

  const initialSchema = {
    email: Yup.string().email().required("required"),
    elapsedTime: Yup.number()
      .required(" required")
      .test("Is positive?", "required", (value) => {
        if (value) {
          return value > 0;
        } else {
          return false;
        }
      }),
    timesPerDay: Yup.number()
      .required(" required")
      .test("Is positive?", "required", (value) => {
        if (value) {
          return value > 0;
        } else {
          return false;
        }
      }),
  };
  // state for validation schema
  const [validationSchema, setValidationSchema] = useState(initialSchema);

  useUpdateEffect(() => {
    if (showPane === 2) {
      const newSchema = {
        ...initialSchema,
        agree: Yup.boolean().oneOf([true], "*").required("*"),
      };

      setValidationSchema(newSchema);
    }
  }, [showPane]);

  const formik = useFormik({
    initialValues: {
      email: user?.email ?? "",
      elapsedTime: 1,
      timesPerDay: 1,
      agree: false,
    },
    onSubmit: (values) => {
      const loginFormData = new FormData();
      loginFormData.append("EMAIL", values.email);
      loginFormData.append("QUERY_RUNNING_TIME", values.elapsedTime.toString());
      loginFormData.append(
        "AMOUNT_OF_TIME_QUERY_RUNS_PERDAY",
        values.timesPerDay.toString()
      );
      loginFormData.append("SQL_DIALECT", databaseType.toString());
      loginFormData.append("DB_LOCATION", databaseLocation.toString());
      loginFormData.append("ACCESS_TO_ENVIRONMENT", provideEnv.toString());
      loginFormData.append("MAKE_DDL", makeDDL.toString());
      loginFormData.append("EXPLAIN_PLAN", planQuery.toString());
      loginFormData.append("SQL_QUERY_REQUIREMENTS", "1");
      loginFormData.append("USECASE", businessCase.toString());
      loginFormData.append("OPT_IN", "1");
      loginFormData.append("email_address_check", "");
      loginFormData.append("locale", "en");

      let request_url =
        "https://f6d808b0.sibforms.com/serve/MUIEAAuPVVMQMRhTNyFi6z5QNWQSk6BhWkzxhe6efaRKYJxu8vSQr3YOERH5KSjeYxe51J6XFU6YxcNHMQofmw-W-hLQX2jJ0_KWbGYeeC0LFbSa_Tk9oDQerGA1bvjIs85ji3fAQeTCJCdeQKUg1mlg6qw-01tbvppDygUC5xCTJTrFp8BBpS8sKzb19uc3uExtfz-iOoEVk9yb?isAjax=1";
      axios
        .post(request_url, loginFormData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then(() => {
          setTuningState("success");
        })
        .catch((err) => {
          setTuningState("fail");
          console.log(err);
        });
    },
    validationSchema: Yup.object().shape(validationSchema),
  });

  const handleNextClicked = () => {
    formik
      .validateForm()
      .then((res) => {
        if (Object.keys(res).length === 0) {
          setShowPane(2);
        }
      })
      .catch((e) => console.error(e));
  };

  const preventClick: MouseEventHandler = (event) => {
    event.stopPropagation();
    return false;
  };

  const hideTunePopup = () => {
    dispatch(setGlobalModalID("tuning"));
  };

  return (
    <div
      style={{
        visibility: "visible",
        opacity: "1",
      }}
      className="overlay tunePopup"
      onClick={hideTunePopup}
    >
      <div className="popup" onClick={preventClick}>
        <span className="close" onClick={onClose} data-testid="close-tune-form">
          &times;
        </span>
        <div className="content" data-testid="global-modal-tuning">
          <div className="tuning-modal-body">
            {tuningState === "start" || tuningState === "fail" ? (
              <>
                <div className="left-panel">
                  <TuningImage className="tuning-image" />
                </div>
                <div className="right-panel">
                  <form onSubmit={formik.handleSubmit}>
                    {showPane === 1 ? (
                      <>
                        <input
                          className={`email${
                            formik.errors.email ? " required" : ""
                          }`}
                          style={{
                            display: `${isAuthenticated ? "none" : "inline"}`,
                          }}
                          name="email"
                          placeholder="Email *"
                          type="email"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          data-testid="tune-form-email-input"
                        />

                        <div
                          className="description-bold"
                          style={{ marginTop: "20px" }}
                        >
                          How long does it take to run this query?
                        </div>
                        <div className="description">
                          (elapsed time in seconds)
                        </div>
                        <input
                          className={`elapsed-time${
                            formik.errors.elapsedTime ? " required" : ""
                          }`}
                          name="elapsedTime"
                          placeholder="*"
                          type="text"
                          value={formik.values.elapsedTime}
                          onChange={formik.handleChange}
                        />

                        <div className="description-bold">
                          How many times do you run this query each day?
                        </div>
                        <input
                          className={`times-per-day${
                            formik.errors.timesPerDay ? " required" : ""
                          }`}
                          name="timesPerDay"
                          placeholder="*"
                          type="text"
                          value={formik.values.timesPerDay}
                          onChange={formik.handleChange}
                        />

                        <div className="description-bold">
                          What database do you use?
                        </div>
                        <select
                          className="database-type"
                          name="databaseType"
                          value={databaseType}
                          onChange={(e) => {
                            setDatabaseType(parseInt(e.target.value));
                          }}
                        >
                          <option value="16">Athena / Presto</option>
                          <option value="11">Azure Synapse</option>
                          <option value="10">BigQuery</option>
                          <option value="12">Databricks / Spark SQL</option>
                          <option value="13">Firebolt</option>
                          <option value="8">MS SQL Server</option>
                          <option value="14">MySQL</option>
                          <option value="7">Oracle</option>
                          <option value="15">PostgreSQL</option>
                          <option value="9">Redshift</option>
                          <option value="19">Snowflake</option>
                          <option value="6">Teradata</option>
                          <option value="20">Other</option>
                        </select>

                        <OptionSelector
                          description="Database location"
                          options={[
                            { value: 1, label: "Cloud" },
                            { value: 2, label: "On premise" },
                          ]}
                          selectedOption={databaseLocation}
                          setSelectedOption={setDatabaseLocation}
                        />

                        <button
                          className="next-button"
                          type="button"
                          onClick={handleNextClicked}
                          data-testid="tune-next-btn"
                        >
                          <NextArrowSvg />
                        </button>
                      </>
                    ) : (
                      <>
                        <div
                          className="back-button"
                          onClick={() => {
                            setShowPane(1);
                          }}
                        >
                          <BackArrowSvg />
                          <div className="caption">Back</div>
                        </div>

                        <div className="second-pane-options-wrapper">
                          <OptionSelector
                            description="Can you provide access to an environment for tuning?"
                            options={[
                              { value: 1, label: "Yes" },
                              { value: 2, label: "No" },
                              { value: 3, label: "Don`t know" },
                            ]}
                            selectedOption={provideEnv}
                            setSelectedOption={setProvideEnv}
                          />

                          <OptionSelector
                            description="Can you make DDL for objects in the query available?"
                            options={[
                              { value: 1, label: "Yes" },
                              { value: 2, label: "No" },
                              { value: 3, label: "Don`t know" },
                            ]}
                            selectedOption={makeDDL}
                            setSelectedOption={setMakeDDL}
                          />
                          <OptionSelector
                            description="Can you make an Explain Plan available for the query?"
                            options={[
                              { value: 1, label: "Yes" },
                              { value: 2, label: "No" },
                            ]}
                            selectedOption={planQuery}
                            setSelectedOption={setPlanQuery}
                          />
                          <OptionSelector
                            description="I have a business case"
                            options={[
                              { value: 1, label: "Yes" },
                              { value: 2, label: "No" },
                              { value: 3, label: "Don`t know" },
                            ]}
                            selectedOption={businessCase}
                            setSelectedOption={setBusinessCase}
                          />
                        </div>
                        <div className="agree-wrapper">
                          <AgreeCheck
                            isChecked={!formik.errors.agree}
                            handleClicked={formik.handleChange}
                          />
                        </div>
                        <button
                          className="submit-button"
                          type="submit"
                          data-testid="submit-tune-form"
                        >
                          Submit Query
                        </button>
                      </>
                    )}
                  </form>
                </div>
              </>
            ) : (
              <div className="success-pane">
                <LogoSvg className="tuning-avata" />

                <div className="tuning-success-caption">Success!</div>

                <div className="tuning-success-description">
                  Our SQL ninjas recieved your tuning query and they are on it.
                </div>

                <button
                  className="perfect-button"
                  type="button"
                  onClick={onClose}
                  data-testid="tune-perfect-btn"
                >
                  Perfect
                </button>

                <TuningSuccessImage />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TuningModal;
