import React, { useState } from "react";
import { ReactComponent as CircledUpArrowSvg } from "../../../assets/icons/circled-up-arrow.svg";
import "./formatOptions.css";
import CheckMarkButton from "../../commonComponent/checkMarkButton";
import {
  setformatState,
  setOptionMargin,
} from "../../../redux/actions/property";
import { Diagram } from "../../../redux/reducer/property";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";

export interface FormatOptionsProps {
  handleClickedFormat: () => Promise<void>;
}

const FormatOptions: React.FunctionComponent<FormatOptionsProps> = (
  props: FormatOptionsProps
) => {
  const { handleClickedFormat } = props;
  const formatState = useAppSelector((state) => {
    const user = state.propertyReducer.user;
    const currentDiagram =
      state.propertyReducer.userLayout[user].currentDiagram;
    return state.propertyReducer.userLayout[user].diagramList[currentDiagram]
      .formatState;
  });
  const optionMargin = useAppSelector((state) => {
    const user = state.propertyReducer.user;
    const currentDiagram =
      state.propertyReducer.userLayout[user].currentDiagram;
    return state.propertyReducer.userLayout[user].diagramList[currentDiagram]
      .optionMargin;
  });

  const [showOptions, setShowOptions] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const dispatch = useAppDispatch();

  const clickedOptionHandle = () => {
    setShowOptions(!showOptions);
  };

  const clickedMarginOption = (option: Diagram["optionMargin"]) => {
    setShowOptions(false);
    if (option !== optionMargin) {
      dispatch(setOptionMargin(option));
    }
  };

  return (
    <div className={`format-sql-wrapper`}>
      <CheckMarkButton
        label="Format"
        checkState={formatState}
        handleClickedButton={handleClickedFormat}
        setCheckState={setformatState}
        handleDisabled={setDisabled}
        data-testid="format-options-button"
      />

      <button
        className={`option-handle ${disabled ? "disabled" : ""}`}
        data-testid="format-options-handle"
        onBlur={() => {
          setShowOptions(false);
        }}
        onClick={() => !disabled && clickedOptionHandle()}
      >
        <CircledUpArrowSvg />
      </button>
      {showOptions && (
        <div className="option-pane" data-testid="format-options-menu">
          <div className="option-margin">
            <ul>
              {/** We use onMouseDown because the container will blur on mouse down when clicking an option */}
              <li
                className={optionMargin === "compact" ? "activated" : ""}
                onMouseDown={() => clickedMarginOption("compact")}
                data-testid="margin-option-compact"
              >
                Compact
              </li>
              <li
                className={optionMargin === "comfortable" ? "activated" : ""}
                onMouseDown={() => clickedMarginOption("comfortable")}
                data-testid="margin-option-comfortable"
              >
                Comfortable
              </li>
              <li
                className={optionMargin === "balanced" ? "activated" : ""}
                data-testid="margin-option-balanced"
                onMouseDown={() => clickedMarginOption("balanced")}
              >
                Balanced
              </li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default FormatOptions;
