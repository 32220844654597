import {
  cutToClipboard,
  handleClickedClipboard,
  handlePressButton,
} from "../../helper/util";
import { store } from "./../../redux/store";
import { setClickSearch, setSearchBoxOpen } from "../../redux/actions/global";

export const getEditorCommands = (enterCmd) => {
  return [
    {
      name: "find",
      exec: () => {
        store.dispatch(setClickSearch(true));
        store.dispatch(setSearchBoxOpen(true));
        document.getElementsByClassName("search-box")[0].focus();
        document.getElementsByClassName("search-input")[0].focus();
      },
      bindKey: { mac: "cmd-f", win: "ctrl-f" },
    },
    {
      name: "undo",
      exec: function (editor) {
        editor.session.getUndoManager().undo();
        handlePressButton("undo");
      },
      bindKey: { mac: "cmd-z", win: "ctrl-z" },
    },
    {
      name: "redo",
      bindKey: { mac: "cmd-y", win: "ctrl-y" },
      exec: function (editor) {
        editor.session.getUndoManager().redo();
        handlePressButton("redo");
      },
    },
    {
      name: "copy",
      exec: handleClickedClipboard,
      bindKey: { mac: "cmd-c", win: "ctrl-c" },
    },
    {
      name: "cut",
      exec: cutToClipboard,
      bindKey: { mac: "cmd-x", win: "ctrl-x" },
    },
    {
      name: "run",
      exec: () => {
        const diagramBoxState = store.getState().layoutReducer.diagramBoxState;
        enterCmd(diagramBoxState);
      },
      bindKey: { mac: "cmd-enter", win: "ctrl-enter" },
    },
  ];
};
