/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-sql";
import Split from "react-split";
import FormatOptions from "../sqlBox/formatOptions";
import "./formatTest.css";
import { processData } from "flowhigh-sdk-js";

const FormatTest = () => {
  const analyzedSqlData = useSelector(
    (state) => state.globalReducer.analyzedSqlData
  );
  const [sizes, setSizes] = useState([600, 600]);

  //////////// state for format
  const [optionMargin, setOptionMargin] = useState("compact");
  const [optionWrap, setOptionWrap] = useState("nowrap");
  const [wrapChars, setWrapChars] = useState("12");
  const [isFormatted, setIsFormatted] = useState("start");
  const [isShowFormatDone, setIsShowFormatDone] = useState(false);
  const leftEditorRef = useRef();
  const rightEditorRef = useRef();

  useEffect(() => {
    setIsFormatted("start");
    setIsShowFormatDone(false);

    formatSql([leftEditorRef.current.value]);
  }, [optionMargin, optionWrap, wrapChars]);

  useEffect(() => {
    leftEditorRef.current.value = analyzedSqlData.join("\n");
    setIsFormatted("start");
    setIsShowFormatDone(false);
  }, []);

  function formatSql(analyzedSqlData) {
    if (analyzedSqlData === undefined) return;
    if (analyzedSqlData.length === 0) {
      rightEditorRef.current.editor.setValue("", -1);
      return;
    }

    let sqlData = "";
    for (let iData = 0; iData < analyzedSqlData.length; iData++) {
      sqlData += processData(
        analyzedSqlData[iData].replaceAll("\\n", "\n"),
        optionMargin
      );

      if (iData < analyzedSqlData.length - 1) {
        sqlData += "\n";
      }
    }

    rightEditorRef.current.editor.setValue(sqlData, -1);
    setIsFormatted("success");
  }

  const handleClickedFormat = () => {
    formatSql([leftEditorRef.current.value]);
  };

  const resizeEditors = () => {
    if (!rightEditorRef.current) {
      return;
    }

    rightEditorRef.current.editor.resize();
  };

  resizeEditors();

  return (
    <Split
      className="content-wrapper"
      fontFamily="Open Sans"
      sizes={sizes}
      minSize={[450, 450]}
      maxSize={[2000, 2000]}
      expandToMin={true}
      gutterSize={3}
      gutterAlign="center"
      snapOffset={5}
      dragInterval={1}
      direction="horizontal"
      cursor="col-resize"
      onDrag={(size) => {
        setSizes(size);
        resizeEditors();
      }}
    >
      <div className="sql-pane pane">
        <div className="sql-wrapper wrapper">
          <div className="sql-box pane" style={{ height: `calc(100% - 43px)` }}>
            <div style={{ height: `100%`, display: "flex" }}>
              <textarea
                style={{ height: "100%", width: "100%", border: "none" }}
                onChange={() => {
                  setIsFormatted("start");
                  setIsShowFormatDone(false);
                }}
                ref={leftEditorRef}
              ></textarea>
            </div>

            <div className="bar">
              <div className="horizontal-toolbar-wrapper">
                <span>
                  <FormatOptions
                    isFormatted={isFormatted}
                    handleClickedFormat={handleClickedFormat}
                    isShowFormatDone={isShowFormatDone}
                    setIsShowFormatDone={setIsShowFormatDone}
                    optionMargin={optionMargin}
                    setOptionMargin={setOptionMargin}
                    optionWrap={optionWrap}
                    setOptionWrap={setOptionWrap}
                    wrapChars={wrapChars}
                    setWrapChars={setWrapChars}
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="sql-pane pane">
        <div className="sql-wrapper wrapper">
          <div className="sql-box pane" style={{ height: `calc(100% + 4px)` }}>
            <div style={{ height: `100%`, display: "flex" }}>
              <AceEditor
                className="sql-editor-container"
                mode="sql"
                name="sqlEditor"
                editorProps={{ $blockScrolling: true }}
                style={{ height: "100%", width: "100%" }}
                setOptions={{
                  fontFamily: "Roboto Mono, Consolas, monospace",
                  showLineNumbers: true,
                  wrap: false,
                  hScrollBarAlwaysVisible: false,
                  vScrollBarAlwaysVisible: false,
                  tabSize: 4,
                  cursorStyle: "smooth",
                }}
                fontSize="14px"
                placeholder="Enter your query here"
                ref={rightEditorRef}
              />
            </div>
          </div>
        </div>
      </div>
    </Split>
  );
};

export default FormatTest;
