/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { showResetPassword } from "../../../helper/util";
import { setGlobalModalID, setLoginState } from "../../../redux/actions/global";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ReactComponent as LoginFailedMark } from "../../../assets/icons/login-failed-mark.svg";
import { ReactComponent as LoginImage } from "../../../assets/icons/login-image.svg";
import "./loginModal.css";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { useAuth } from "../../../auth/AuthContext";

const LoginModal = () => {
  const dispatch = useAppDispatch();
  const loginState = useAppSelector((state) => state.globalReducer.loginState);

  const { authLogin } = useAuth();

  const onClose = () => {
    dispatch(setGlobalModalID(""));
  };

  useEffect(() => {
    dispatch(setLoginState("start"));
  }, []);

  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required("required"),
    password: Yup.string().required("required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: (values) => {
      authLogin(values.email, values.password);
    },
    validationSchema,
  });

  const handleClickedRegister = () => {
    onClose();
    dispatch(setGlobalModalID("signup"));
  };

  const handleClickedForgotPassword = () => {
    onClose();
    showResetPassword();
  };

  const handleChangedInput: React.ChangeEventHandler = (e) => {
    dispatch(setLoginState("start"));
    formik.handleChange(e);
  };

  const preventClick: React.MouseEventHandler = (event) => {
    event.stopPropagation();
    return false;
  };

  const hideLoginPopup = () => {
    dispatch(setGlobalModalID(""));
  };

  return (
    <div
      style={{
        visibility: "visible",
        opacity: "1",
      }}
      className="overlay loginPopUpoverlay"
      onClick={hideLoginPopup}
    >
      <div className="popup" onClick={preventClick}>
        <span className="close" onClick={onClose}>
          &times;
        </span>
        <div className="content" data-testid="global-modal-login">
          <div className="login-modal-body">
            <div className="left-panel">
              <LoginImage className="login-image" />
            </div>
            <div className="right-panel">
              {/* <LogoSvg className="login-avata" /> */}
              <div className="login-caption">Welcome to FlowHigh</div>
              <form onSubmit={formik.handleSubmit}>
                {loginState === "fail" ? (
                  <div className="login-failed-notify">
                    <LoginFailedMark />
                    <span className="error-text">
                      Some of your information is not correct. Try again.
                    </span>
                  </div>
                ) : (
                  <></>
                )}

                <input
                  className={`email${formik.errors.email ? " required" : ""}`}
                  name="email"
                  placeholder="Email *"
                  type="email"
                  value={formik.values.email}
                  onChange={handleChangedInput}
                />

                <input
                  className={`password${
                    formik.errors.password ? " required" : ""
                  }`}
                  name="password"
                  placeholder="Password *"
                  type="password"
                  value={formik.values.password}
                  autoComplete="off"
                  onChange={handleChangedInput}
                />
                <button className="login-button" type="submit">
                  Log in
                </button>
                <div
                  className="login-forgot-password"
                  onClick={handleClickedForgotPassword}
                >
                  Forgot your password?
                </div>

                <div className="login-register-container">
                  <div className="login-havent-account">
                    Don't have an account?
                  </div>
                  <div
                    className="login-register"
                    onClick={handleClickedRegister}
                  >
                    Register
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginModal;
