import React, { useState } from "react";
import { ReactComponent as EyeIcon } from "../../assets/icons/eye-icon.svg";
import { ReactComponent as Diamond } from "../../assets/icons/Diamond.svg";
import { ReactComponent as Sdk1 } from "../../../src/assets/icons/sdk1.svg";
import { ReactComponent as Sdk2 } from "../../../src/assets/icons/sdk2.svg";
import { ReactComponent as Sdk3 } from "../../../src/assets/icons/sdk3.svg";
import { ReactComponent as Sdk4 } from "../../../src/assets/icons/sdk4.svg";
import { ReactComponent as Sdk5 } from "../../../src/assets/icons/sdk5.svg";
import { ReactComponent as Copy } from "../../assets/icons/copy.svg";
import "./sdkWorkflow.css";
import { useNavigate } from "react-router-dom";
import { batch, useDispatch, useSelector } from "react-redux";
import { isSpecialTab } from "../../helper/util";
import { activateDiagram, setCurrentState } from "../../redux/actions/property";
import { setDiagramBoxState } from "../../redux/actions/layout";

const SdkWorkFlow = () => {
  const [selectedItem, setSelectedItem] = useState("python");
  const dispatch = useDispatch();
  const user = useSelector((state) => state.propertyReducer.user);
  const recentStack = useSelector(
    (state) => state.propertyReducer.userLayout[user].recentStack
  );
  const currentDiagram = useSelector(
    (state) => state.propertyReducer.userLayout[user].currentDiagram
  );
  const navigate = useNavigate();
  const navigateToSDK = () => {
    navigate("/parser-sdk-sql");
    if (isSpecialTab(currentDiagram)) {
      for (let iStack = recentStack.length - 1; iStack >= 0; iStack--) {
        if (!isSpecialTab(recentStack[iStack])) {
          batch(() => {
            dispatch(activateDiagram(recentStack[iStack]));
            dispatch(setCurrentState("sdk"));
            dispatch(setDiagramBoxState("sdk"));
          });
          break;
        }
      }
    }
  };
  const copyText = (text) => {
    void navigator.clipboard.writeText(text);
  };

  const a = `
  SELECT
        customer.id,
        customer.date_of_sales,
        SUM(sales.amount) AS amount_of_sales,
        COUNT(customer.orders) AS count_of_orders
  FROM customer
  JOIN sales
  ON customer.id = sales.id
  WHERE customer.date_of_sales > '2020-01-01'
  GROUP BY customer.id, customer.date_of_sales
  HAVING COUNT(customer.orders) > 1
  ORDER BY customer.date_of_sales"""`;

  return (
    <div className="workflow-setting-container">
      <div className="workflow-setting-category">
        <div className="workflow-setting-category-header"></div>
        <div className="workflow-setting-category-content">
          <div
            onClick={() => {
              setSelectedItem("python");
            }}
            className={`workflow-setting-category-item  ${
              selectedItem === "python" ? " selected" : ""
            }`}
          >
            {selectedItem === "python" ? (
              <span className="workflow-setting-category-item-icon">
                <EyeIcon />
              </span>
            ) : (
              <span>
                <Diamond className="workflow-setting-category-item-icon" />
              </span>
            )}
            Install Python
          </div>
          <div
            className={`workflow-setting-category-item ${
              selectedItem === "flowhigh" ? "selected" : ""
            }`}
            onClick={() => {
              setSelectedItem("flowhigh");
            }}
          >
            {selectedItem === "flowhigh" ? (
              <span className="workflow-setting-category-item-icon">
                <EyeIcon />
              </span>
            ) : (
              <span>
                <Diamond className="workflow-setting-category-item-icon" />
              </span>
            )}
            Install FlowHigh SDK
          </div>
          <div
            className={`workflow-setting-category-item ${
              selectedItem === "sdk" ? "selected" : ""
            }`}
            onClick={() => {
              setSelectedItem("sdk");
            }}
          >
            {selectedItem === "sdk" ? (
              <span className="workflow-setting-category-item-icon">
                <EyeIcon />
              </span>
            ) : (
              <span>
                <Diamond className="workflow-setting-category-item-icon" />
              </span>
            )}
            Set up your SDK account
          </div>
          <div
            className={`workflow-setting-category-item ${
              selectedItem === "parsing" ? " selected" : ""
            }`}
            onClick={() => {
              setSelectedItem("parsing");
            }}
          >
            {selectedItem === "parsing" ? (
              <span className="workflow-setting-category-item-icon">
                <EyeIcon />
              </span>
            ) : (
              <span>
                <Diamond className="workflow-setting-category-item-icon" />
              </span>
            )}
            Parsing SQL
          </div>
        </div>
      </div>
      <div className="flex-column">
        <div className="workflow-header"></div>

        <div className="workflow-setting-content">
          <div className="w-80 ">
            <div className="workflow-setting-content-body">
              {
                {
                  python: (
                    <div className="plan-content">
                      <div className="plan-paragrah">
                        <p className="details-bold ">
                          The FlowHigh SDK supports Python 3.6 and higher.
                          {/* <p className="details-bold">
                            <a
                              className="url"
                              href="https://pip.pypa.io/en/stable/cli/pip_install/"
                              target="/blank"
                            >
                             ( https://pip.pypa.io/en/stable/cli/pip_install/ )
                            </a>
                          </p> */}
                        </p>
                        <br />
                        <p className="details-bold ">
                          Python versions can be downloaded from here:
                          <p className="details-bold">
                            <a
                              className="url"
                              href="https://www.python.org/downloads/ "
                              target="/blank"
                            >
                              https://www.python.org/downloads/
                            </a>
                          </p>
                        </p>
                      </div>
                    </div>
                  ),
                  flowhigh: (
                    <div className="plan-content">
                      <div className="main_div">
                        <h4 className="headers">
                          Install the FlowHigh SDK using pip
                        </h4>
                        <div className="sdkquery">
                          <div className="sdkquery-content">
                            <p>pip install flowhigh </p>
                            <p> # name of the pypi hosted package</p>
                          </div>
                          <button
                            className="sdkquery-copy-btn"
                            onClick={() => copyText("pip install flowhigh")}
                          >
                            <Copy className="copy_svg" />
                          </button>
                        </div>
                        <h4 className="headers">
                          Update FlowHigh SDK using pip
                        </h4>
                        <p className="details-bold3">
                          If you have previously installed the FlowHigh SDK run
                          the upgrade command
                        </p>
                        <div className="sdkquery">
                          <div className="sdkquery-content">
                            <p>pip install --upgrade flowhigh </p>
                          </div>
                          <button
                            className="sdkquery-copy-btn"
                            onClick={() =>
                              copyText("pip install --upgrade flowhigh")
                            }
                          >
                            <Copy className="copy_svg" />
                          </button>
                        </div>
                        <p className="note-text">
                          Note: If you have multiple Python versions installed
                          make sure to use the same version for both FlowHigh
                          and pip.
                        </p>
                      </div>
                    </div>
                  ),
                  sdk: (
                    <div className="plan-content">
                      <div className="plan-paragrah">
                        <h4 className="headers">
                          Step 1: Create a FlowHigh account
                        </h4>
                        <p className="details-bold ">
                          If you don't have an acount already , now is the time
                          to create one via the FlowHigh user interface.
                          <br />
                          <p className="details-bold">
                            Please register at &nbsp;
                            <a
                              className="url"
                              href="http://flowhigh.io/"
                              target="/blank"
                            >
                              http://flowhigh.io/
                            </a>
                          </p>
                        </p>
                        <h4 className="headers">
                          Step 2: Import the required components
                        </h4>
                        <div className="sdkquery">
                          <div className="sdkquery-content">
                            <p>
                              <span className="blue"> from</span> flowhigh.
                              <span className="purple">utils.converter</span>
                              <span className="blue"> import</span>&nbsp;
                              FlowHighSubmissionClass
                            </p>
                          </div>
                          <button
                            className="sdkquery-copy-btn"
                            onClick={() =>
                              copyText(
                                "from flowhigh.utils.converter import FlowHighSubmissionClass"
                              )
                            }
                          >
                            <Copy className="copy_svg" />
                          </button>
                        </div>
                        <h4 className="headers">
                          Step 3: Initialise the SDK passing a SQL query
                        </h4>
                        <div className="sdkquery">
                          <div className="sdkquery-content">
                            <p>
                              fh = FlowHighSubmissionClass.
                              <span className="purple"> from_sql </span>("SELECT
                              c1 from t1 ")
                            </p>
                          </div>
                          <button
                            className="sdkquery-copy-btn"
                            onClick={() =>
                              copyText(
                                `fh = FlowHighSubmissionClass. from_sql ("SELECT c1 from t1 ")`
                              )
                            }
                          >
                            <Copy className="copy_svg" />
                          </button>
                        </div>

                        <h4 className="headers">Step 4 : Authenticate</h4>
                        <p className="details-bold ">
                          When you call the SDK in your Python program for the
                          ﬁrst time, e.g. parsing a SQL string as in step 2 or
                          reading from a script (
                          FlowHighSubmissionClass.from_file("/home/user/...") ),
                          you will be asked to authenticate using your browser
                          and your FlowHigh account.
                        </p>
                        <p className="details-bold ">
                          Open the link that is displayed in your terminal in
                          your browser. Authenticating automatically is the
                          preferred option.
                        </p>
                        <br />
                        {/* <p className="details-bold">
                          Visit  &nbsp;
                          <a
                            className="url"
                            href=" http://auth.sonra.io/activate"
                            target="/blank"
                          >
                            http://auth.sonra.io/activate
                          </a>
                          &nbsp;  and enter RMGZ - RBWP
                        </p>
                        <p className="details-bold ">
                            OR
                        </p>

                        <p className="details-bold">
                          Use &nbsp;
                          <a
                            className="url"
                            href=" http://auth.sonra.io/activate?user_code=RMGZ-RBWP"
                            target="/blank"
                          >
                            http://auth.sonra.io/activate?user_code=RMGZ-RBWP
                          </a>
                          &nbsp;  to authenticate automatically
                        </p> */}
                        <div>
                          <Sdk4 />
                        </div>
                        <br />
                        <p className="details-bold ">
                          Clicking on the link for authentication opens the
                          following screen
                        </p>
                        <div className="img11">
                          <Sdk1 />
                          {/* <img src={img1} alt="img1" style={{width:"unset"}}/> */}
                        </div>
                        <p className="details-bold ">
                          Click Confirm and enter your signup details
                          <br />
                        </p>
                        <div className="img12">
                          <Sdk2 />
                          {/* <img src={img2} alt="img2" /> */}
                        </div>
                        <p className="details-bold ">
                          If the login is successful, the success message screen
                          shows in your browser.
                        </p>
                        <div className="img13">
                          <Sdk3 />
                          {/* <img src={img3} alt="img3" /> */}
                        </div>
                        <p className="details-bold ">
                          You will also get an Authenticated message in the
                          terminal where Python is running and the request will
                          then be handled.
                          <br />
                          <br />
                          <div>
                            <Sdk5 />
                          </div>
                          {/* <img className="img5" src={img5} alt="img5" /> */}
                          {/* <div className="sdkquery">
                          <div className="sdkquery-content">
                            <p>AUTHENTICATED! </p>
                          </div>
                        </div> */}
                          <br />
                          <p className="details-bold ">
                            The access token will be cached in a local
                            parseql.json file, created in a new folder inside
                            the current user directory (.parseql).
                          </p>
                          <p className="details-bold ">
                            ● &nbsp;for Windows: %userproﬁle% (C:\Users\
                            &lt;username&gt;
                          </p>
                          <p className="details-bold ">
                            ● &nbsp;for Linux: $HOME (/home/&lt;username&gt;)
                          </p>
                        </p>

                        <p className="details-bold padding">
                          <br />
                          Subsequent calls to the SDK will then use this access
                          token and usage will be recorded against it.
                          <br />
                        </p>
                      </div>
                    </div>
                  ),
                  parsing: (
                    <div className="plan-content ">
                      <div className="plan-paragrah">
                        <p className="details-bold ">
                          Parsing SQL is easy with the SDK
                        </p>
                        <p className="details-bold ">
                          Use the json_message method to parse an SQL statement
                          to a JSON format.
                        </p>
                        <p className="details-bold ">
                          Use the xml_message method to parse an SQL statement
                          to an XML format.
                        </p>
                        <h4 className="headers1">Example 1:</h4>
                        <p className="details-bold mb-21">
                          In this example we parse a query that includes a JOIN,
                          GROUP BY, WHERE, HAVING and ORDER BY clause.
                        </p>
                      </div>
                      <div className="sdkquery">
                        <div className="sdkquery-content1">
                          <p className="querycolor">
                            <span>
                              <span className="blue">from</span>{" "}
                              <span className="black">flowhigh.</span>
                              <span className="gray">utils.converter</span>
                              <span className="blue"> import </span>
                              <span className="black">
                                FlowHighSubmissionClass
                              </span>
                            </span>
                            <br />
                            <br />
                            <span className="black">
                              {" "}
                              fh = FlowHighSubmissionClass.
                            </span>
                            <span className="gray">from_sql</span>
                            <span>
                              <span className="black">(</span>
                              <span className="querycolor">"""</span>
                            </span>
                            <pre>{a}</pre>
                            <span className="black">)</span>
                            <br />
                            <br />
                            <span className="blue">print</span>
                            <span className="black">(fh.</span>
                            <span color="purple">json_message</span>
                            <span className="black">)</span>
                          </p>
                        </div>
                        <button
                          className="sdkquery-copy-btn"
                          onClick={() =>
                            copyText(
                              `from flowhigh.utils.converter import FlowHighSubmissionClass

                              fh = FlowHighSubmissionClass.from_sql("""
                                SELECT
                                      customer.id,
                                      customer.date_of_sales,
                                      SUM(sales.amount) AS amount_of_sales,
                                      COUNT(customer.orders) AS count_of_orders
                                FROM customer
                                JOIN sales
                                ON customer.id = sales.id
                                WHERE customer.date_of_sales > '2020-01-01'
                                GROUP BY customer.id, customer.date_of_sales
                                HAVING COUNT(customer.orders) > 1
                                ORDER BY customer.date_of_sales""")
                              
                              print(fh.json_message)`
                            )
                          }
                        >
                          <Copy className="copy_svg" />
                        </button>
                      </div>
                      <p
                        className="details-bold1"
                        style={{ paddingBottom: "0rem" }}
                      >
                        You can view more examples on how to use the SDK on the
                        &nbsp;
                        <button className="url" onClick={navigateToSDK}>
                          FlowHigh SDK page
                        </button>
                        .
                      </p>
                      <p className="details-bold2">
                        You can also read up on the SDK using&nbsp;
                        <a
                          className="url"
                          href="https://docs.sonra.io/flowhigh/master/docs/flowhigh-sdk/getting-started/"
                          target={"_blank"}
                          rel="noreferrer"
                        >
                          the docs
                        </a>
                        .
                      </p>
                    </div>
                  ),
                }[selectedItem]
              }
            </div>
          </div>
          <div className="w-40"></div>
        </div>
      </div>
    </div>
  );
};

export default SdkWorkFlow;
