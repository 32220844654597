import React from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { setSampleTab, setUpdateFlag } from "../../../redux/actions/property";

function MenuButton({ displayName }) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.propertyReducer.user);
  const sampleTab = useSelector(
    (state) => state.propertyReducer.userLayout[user].sampleTab
  );

  const onClickBtn = () => {
    dispatch(setSampleTab(displayName));
    dispatch(setUpdateFlag(false));
  };

  return (
    <button
      type="button"
      className={"button" + (sampleTab === displayName ? " activated" : "")}
      onClick={onClickBtn}
      data-testid={`sample-button-${displayName}`}
    >
      {displayName}
    </button>
  );
}

MenuButton.propTypes = {
  displayName: PropTypes.string.isRequired,
};

export default MenuButton;
