import React from "react";
import OptionItem from "./optionItem";
import "./optionSelector.css";

const OptionSelector = ({
  description,
  options,
  selectedOption,
  setSelectedOption,
}) => {
  let items = [];
  for (let i = 0; i < options.length; i++) {
    items.push(
      <OptionItem
        option={options[i]}
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
        key={i}
      />
    );
  }

  return (
    <div className="option-selector">
      <div className="option-selector-description">{description}</div>
      <div className="option-selector-wrapper">{items}</div>
    </div>
  );
};

export default OptionSelector;
