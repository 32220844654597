enum LayoutActionTypes {
  ZOOM_ACTION = "ZOOM_ACTION",
  ZOOM_BUTTON_CLICKED = "ZOOM_BUTTON_CLICKED",
  ZOOM_MODE = "ZOOM_MODE",
  FULLSCREEN_BUTTON_CLICKED = "FULLSCREEN_BUTTON_CLICKED",
  DOWNLOAD_SVG_BUTTON_CLICKED = "DOWNLOAD_SVG_BUTTON_CLICKED",
  SVG_RESIZED = "SVG_RESIZED",
  DIAGRAM_BOX_STATE = "DIAGRAM_BOX_STATE", // parse | diagram
  ACTIVE_TOOLBAR_ITEM = "ACTIVE_TOOLBAR_ITEM", // currrent toolbar Item
  SET_FIRSTTIME_FLAG = "SET_FIRSTTIME_FLAG", // first time flag
  SET_IS_QUERY_VISUALIZED = "SET_IS_QUERY_VISUALIZED",
  SET_EDITOR_SIZE = "SET_EDITOR_SIZE",
}

export default LayoutActionTypes;
