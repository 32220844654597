import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/mode-xml";
import { ReactComponent as Copy } from "../../assets/icons/copy.svg";
import ddl from "../../assets/data/snippets/ddl.py";
import format from "../../assets/data/snippets/format.py";
import sql from "../../assets/data/snippets/message.py";
import ap from "../../assets/data/snippets/ap.py";
import filter from "../../assets/data/snippets/filter.py";
import tables from "../../assets/data/snippets/tables.py";
import join from "../../assets/data/snippets/join.py";
import sortAndGroup from "../../assets/data/snippets/sort_and_group.py";
export let UpEditor = "";

const SDKEditor = ({ type }) => {
  const user = useSelector((state) => state.propertyReducer.user);
  const currentDiagram = useSelector(
    (state) => state.propertyReducer.userLayout[user].currentDiagram
  );
  const activeDiagram = useSelector(
    (state) =>
      state.propertyReducer.userLayout[user].diagramList[currentDiagram]
  );
  let SDKState = useSelector(
    (state) =>
      state.propertyReducer.userLayout[user].diagramList[currentDiagram]
        .SDKState
  );
  let sqlData = useSelector(
    (state) =>
      state.propertyReducer.userLayout[user].diagramList[currentDiagram].sqlData
  );
  sqlData = activeDiagram ? sqlData : {};
  const sdkEditorRef = useRef();
  UpEditor = sdkEditorRef;
  const isFirefox = typeof InstallTrigger !== "undefined";
  // const { pyData } = usePyodideHook();
  const [snippet, setSnippet] = useState("");

  const settingSnippets = async () => {
    if (type === "Message") {
      const res = await (await fetch(sql)).text();
      setSnippet(res);
    } else if (type === "Format") {
      const res = await (await fetch(format)).text();
      setSnippet(res);
    } else if (type === "Antipattern") {
      const res = await (await fetch(ap)).text();
      setSnippet(res);
    } else if (type === "DDL") {
      const res = await (await fetch(ddl)).text();
      setSnippet(res);
    } else if (type === "Tablelist") {
      const res = await (await fetch(tables)).text();
      setSnippet(res);
    } else if (type === "Filter") {
      const res = await (await fetch(filter)).text();
      setSnippet(res);
    } else if (type === "Join") {
      const res = await (await fetch(join)).text();
      setSnippet(res);
    } else if (type === "SortGroup") {
      const res = await (await fetch(sortAndGroup)).text();
      setSnippet(res);
    }
  };
  useEffect(() => {
    const setSnippets = async () => {
      try {
        await settingSnippets();
      } catch (e) {
        throw e;
      }
    };

    setSnippets().catch((e) => console.error(e));

    // eslint-disable-next-line
  }, [type, sqlData]);

  const copyText = (editorRef) => {
    let editor = editorRef.current.editor;
    var sel = editor.selection.toJSON();
    editor.selectAll();
    editor.focus();
    document.execCommand("copy");
    editor.selection.fromJSON(sel);
  };

  return (
    <div className="sdk-pane pane" data-testid="sdk-pane">
      {SDKState !== "in-progress" && (
        <div
          className="copy_btn"
          data-testid="copy-btn-sdk"
          onClick={() => copyText(sdkEditorRef, "total")}
        >
          <Copy className="copy_svg" />
        </div>
      )}
      <div className="sdk-wrapper wrapper">
        <div className="sql-box pane" style={{ height: `100%` }}>
          <div
            className="sdk_editor"
            style={{ height: `100%`, display: "flex" }}
          >
            <AceEditor
              className="sdk-editor-container"
              data-testid="sdk-editor"
              mode="python"
              theme="parseql"
              name="sqlEditor"
              editorProps={{ $blockScrolling: true }}
              style={{ width: "100%", borderTop: "none", height: "100%" }}
              setOptions={{
                fontFamily: `${
                  isFirefox
                    ? "Consolas, monospace"
                    : "Roboto Mono,Consolas, monospace"
                }`,
                fontSize: "12px",
                showLineNumbers: true,
                showPrintMargin: false,
                wrap: false,
                hScrollBarAlwaysVisible: true,
                vScrollBarAlwaysVisible: false,
                tabSize: 4,
                cursorStyle: "smooth",
              }}
              value={snippet}
              ref={sdkEditorRef}
              readOnly={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SDKEditor;
