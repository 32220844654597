import { AnyAction } from "redux";

import { Diagram } from "./property";
import sampleDataActionTypes from "../actionTypes/sampleData";

export interface SampleDataStore {
  simple: Diagram["diagramData"];
  regular: Diagram["diagramData"];
  complex: Diagram["diagramData"];
}

const initialState: SampleDataStore = {
  simple: {},
  regular: {},
  complex: {},
};

export default function sampleDataReducer(
  state = initialState,
  action: AnyAction
): SampleDataStore {
  switch (action.type) {
    case sampleDataActionTypes.SET_SIMPLE_SAMPLE:
      return {
        ...state,
        simple: action.payload,
      };
    case sampleDataActionTypes.SET_REGULAR_SAMPLE:
      return {
        ...state,
        regular: action.payload,
      };
    case sampleDataActionTypes.SET_COMPLEX_SAMPLE:
      return {
        ...state,
        complex: action.payload,
      };
    default:
      return state;
  }
}
