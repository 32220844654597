import React, { useCallback, useEffect } from "react";
import { useSelector, useDispatch, batch } from "react-redux";
import {
  setUpdateFlag,
  setDirection,
  setExpandNodes,
} from "../../redux/actions/property";
import {
  showCloumnButtonClicked,
  applyShowCloumnAction,
} from "../../redux/actions/property";
import { setProduceDiagramFlag } from "../../redux/actions/global";
import { setZoomMode } from "../../redux/actions/layout";
import "./propertyBox.css";
import { ReactComponent as OpenEyeSvg } from "../../../src/assets/icons/close-eye.svg";
import { ReactComponent as CloseEyeSvg } from "../../../src/assets/icons/open-eye.svg";
import { ReactComponent as LRArrow } from "../../../src/assets/icons/rightarrow.svg";
import { ReactComponent as TBArrow } from "../../../src/assets/icons/bottomarrow.svg";
import { useAuth } from "../../auth/AuthContext";

const PropertyBox = () => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.propertyReducer.user);
  const currentDiagram = useSelector(
    (state) => state.propertyReducer.userLayout[user].currentDiagram
  );
  const activeDiagram = useSelector(
    (state) =>
      state.propertyReducer.userLayout[user].diagramList[currentDiagram]
  );
  let diagramData = useSelector(
    (state) =>
      state.propertyReducer.userLayout[user].diagramList[currentDiagram]
        .diagramData
  );
  diagramData = activeDiagram ? diagramData : {};
  const direction = useSelector(
    (state) =>
      state.propertyReducer.userLayout[user].diagramList[currentDiagram]
        .direction
  );
  const expandNode = useSelector(
    (state) =>
      state.propertyReducer.userLayout[user].diagramList[currentDiagram]
        .expandNode
  );

  const { isAuthenticated, login } = useAuth();

  const updateDiagramData = useCallback(() => {
    if (!diagramData || Object.keys(diagramData).length === 0) {
      return;
    }

    batch(() => {
      dispatch(setZoomMode("auto"));
      dispatch(setUpdateFlag(false));
      dispatch(setProduceDiagramFlag());
    });

    // eslint-disable-next-line
  }, [dispatch]);

  // const resetAllProperties = () => {
  //     dispatch(resetProperties());
  //     updateDiagramData();
  // }

  const hideAll = () => {
    batch(() => {
      dispatch(setExpandNodes(false));
      dispatch(setZoomMode("auto"));
      dispatch(showCloumnButtonClicked());
      dispatch(applyShowCloumnAction("hideAll"));
    });
  };

  const showAll = () => {
    batch(() => {
      dispatch(setExpandNodes(true));
      dispatch(setZoomMode("auto"));
      dispatch(showCloumnButtonClicked());
      dispatch(applyShowCloumnAction("showAll"));
      dispatch(setUpdateFlag(false));
    });
  };

  const handleDirection = async (event) => {
    if (!isAuthenticated) {
      await login();
    } else {
      dispatch(setDirection(event));
      updateDiagramData();
    }
  };

  useEffect(() => {}, [activeDiagram]);

  return !activeDiagram ? (
    <></>
  ) : (
    <div className="main1-wrapper">
      <div className="propertybox">
        <div className="wrapper2">
          Columns:
          <>
            {expandNode === false ? (
              <div
                className={
                  expandNode === true ? "buttonwrapper bg" : "buttonwrapper"
                }
                onClick={() => {
                  showAll();
                }}
              >
                <OpenEyeSvg />
              </div>
            ) : (
              <div
                className={
                  expandNode === false ? "buttonwrapper " : "buttonwrapper bg"
                }
                onClick={() => {
                  hideAll();
                }}
              >
                <CloseEyeSvg />
              </div>
            )}
          </>
        </div>
      </div>

      <div className="property-box1">
        <div className="wrapper2">
          Direction:
          <div className="space">
            <div
              className={
                direction === "TB" ? "button-wrapper1 bg" : "button-wrapper1"
              }
              onClick={async () => {
                await handleDirection("TB");
              }}
            >
              <TBArrow />
            </div>
            <div
              className={
                direction === "LR" ? "button-wrapper1 bg" : "button-wrapper2"
              }
              onClick={async () => {
                await handleDirection("LR");
              }}
            >
              <LRArrow />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropertyBox;
