import React, { useState, useEffect, forwardRef } from "react";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/mode-xml";
import { setFirstTimeFlag } from "../../redux/actions/layout";
import "./parseDataBox.css";
import { isParseDataValid } from "../../helper/util";
import { setDynamicNotifyText } from "../../redux/actions/property";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { components } from "../../generated-types/api";

export interface ParseDataBoxProps {
  parseEditorRefToUse: React.MutableRefObject<AceEditor | null>;
}

const ParseDataBox = forwardRef<AceEditor, ParseDataBoxProps>(
  (props, parseEditorRef) => {
    const dispatch = useAppDispatch();

    const user = useAppSelector((state) => state.propertyReducer.user);
    const currentDiagram = useAppSelector(
      (state) => state.propertyReducer.userLayout[user].currentDiagram
    );
    const searchTab = useAppSelector((state) => state.globalReducer.searchTab);
    let parseData = useAppSelector(
      (state) =>
        state.propertyReducer.userLayout[user].diagramList[currentDiagram]
          .parseData
    );
    const searchText = useAppSelector(
      (state) => state.globalReducer.searchText
    );
    const firstTimeFlag = useAppSelector(
      (state) => state.layoutReducer.firstTimeFlag
    );

    // states
    const [activeTab, setActiveTab] = useState("json");
    const [tableList, setTableList] = useState({});

    useEffect(() => {
      if (searchTab === "right" || searchTab === "both") {
        props.parseEditorRefToUse?.current?.editor.findAll(searchText);
        props.parseEditorRefToUse?.current?.editor.find(searchText);
      }
      if (searchTab === "left") {
        props.parseEditorRefToUse.current?.editor.clearSelection();
      }
    }, [searchText, searchTab, props.parseEditorRefToUse]);

    // initialize editor
    useEffect(() => {
      if (!props.parseEditorRefToUse.current) {
        return;
      }

      // set line height
      let editor = props.parseEditorRefToUse.current.editor;
      editor.container.style.lineHeight = "1.4";
      editor.renderer.updateFontSize();
    }, [props.parseEditorRefToUse]);

    props.parseEditorRefToUse?.current?.editor?.commands.addCommand({
      name: "find",
      exec: function () {
        dispatch(setDynamicNotifyText("search"));
      },
      bindKey: { mac: "cmd-f", win: "ctrl-f" },
    });

    useEffect(() => {
      const updateParseData = (tabName: string | null = null): void => {
        if (tabName === "tablelist") {
          setTableList({});
        } else {
          if (!props.parseEditorRefToUse.current) return;
          let editor = props.parseEditorRefToUse.current.editor;

          if (isParseDataValid(parseData)) {
            const validParseData =
              parseData as components["schemas"]["ParSeQLOutput"];
            switch (tabName) {
              case "json":
                const jsonData = JSON.parse(
                  JSON.stringify(validParseData.statement)
                );
                let json_data = {
                  ...jsonData,
                };

                editor.setValue(JSON.stringify(json_data, null, "\t"), -1);
                break;
              case "xml":
                if (!validParseData.xml) {
                  return;
                }
                const parser = new DOMParser();
                const xmlDoc = parser.parseFromString(
                  validParseData.xml,
                  "text/xml"
                );

                const serializer = new XMLSerializer();
                editor.setValue(serializer.serializeToString(xmlDoc), -1);
                break;
              default:
                editor.setValue("");
                break;
            }
          } else {
            editor.setValue("");
          }
        }
      };

      updateParseData(activeTab);
    }, [activeTab, parseData, props.parseEditorRefToUse]);

    useEffect(() => {
      if (!props.parseEditorRefToUse.current) {
        return;
      }

      props.parseEditorRefToUse.current.editor.resize();
    }, [props.parseEditorRefToUse]);

    const changedTab = (tabName: string) => {
      setActiveTab(tabName);
    };

    const handleClickedNotifyClose = () => {
      dispatch(setFirstTimeFlag(false));
    };

    return (
      <div className="parse-pane pane">
        <div className="parse-wrapper wrapper">
          <div className="sub-header">
            <div className="tab-wrapper">
              <button
                type="button"
                className={
                  "button left" + (activeTab === "json" ? " activated" : "")
                }
                onClick={() => changedTab("json")}
              >
                JSON
              </button>
              <button
                type="button"
                className={"button" + (activeTab === "xml" ? " activated" : "")}
                onClick={() => changedTab("xml")}
              >
                XML
              </button>
            </div>
          </div>
          <div className="parse-box pane" style={{ height: `100%` }}>
            <div style={{ height: `calc(100% - 50px)` }}>
              {activeTab === "json" || activeTab === "xml" ? (
                <AceEditor
                  className="parse-editor-container"
                  mode={activeTab}
                  name="parseDataEditor"
                  editorProps={{ $blockScrolling: true }}
                  style={{ height: "100%", width: "100%", borderTop: "none" }}
                  setOptions={{
                    fontFamily: "Roboto Mono, Consolas, monospace",
                    fontSize: "12px",
                    showLineNumbers: true,
                    showPrintMargin: false,
                    wrap: true,
                    readOnly: true,
                    hScrollBarAlwaysVisible: false,
                    vScrollBarAlwaysVisible: false,
                    tabSize: 4,
                    cursorStyle: "smooth",
                    useWorker: false,
                  }}
                  ref={parseEditorRef}
                />
              ) : (
                <div className="tablelist-container">
                  {Object.keys(tableList).length === 0 ? <></> : <></>}
                </div>
              )}
            </div>
          </div>
        </div>
        {firstTimeFlag ? (
          <div className={`parse-notify-box ` + activeTab}>
            <div className="notify-indicator"></div>
            <div className="parse-notify-wrapper">
              <div className="parse-notify-caption">The Parsing Output</div>
              <div className="parse-notify-body">
                When FlowHigh parses your SQL statement it returns the output as
                a JSON or XML message
              </div>
              <div
                className="parse-notify-button"
                onClick={handleClickedNotifyClose}
              >
                Got it
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  }
);

export default ParseDataBox;
