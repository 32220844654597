import { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { ReactComponent as CheckmarkSVG } from "../../../assets/icons/checkmark.svg";
import "./checkMarkButton.css";

const CheckMarkButton = (props) => {
  const {
    label,
    checkState,
    setCheckState,
    handleClickedButton,
    handleDisabled,
  } = props;

  const dispatch = useDispatch();

  const [showAnimation, setShowAnimation] = useState(false);
  const [buttonState, setButtonState] = useState(0);
  const [animationStep, setAnimationStep] = useState(0);
  const [disabled, setDisabled] = useState(false);

  const useUnmount = (callback) => {
    const callbackRef = useRef(callback);
    callbackRef.current = callback;

    useEffect(() => {
      return () => {
        callbackRef.current();
      };
    }, []);
  };

  useUnmount(() => {
    setButtonState(2);
  });

  useEffect(() => {
    switch (checkState) {
      case "break":
        setButtonState(0);
        setAnimationStep(0);
        setShowAnimation(false);
        setDisabled(false);
        break;
      case "start":
        setButtonState(0);
        setAnimationStep(0);
        setShowAnimation(false);
        setDisabled(false);
        break;
      case "in-progress":
        //        setDisabled(true);
        if (handleDisabled) {
          //          handleDisabled(true);
        }
        break;
      case "fail":
        dispatch(setCheckState("start"));
        setDisabled(false);
        if (handleDisabled) {
          handleDisabled(false);
        }

        break;
      case "success":
        if (!showAnimation) {
          setButtonState(0);
          setAnimationStep(0);
          setShowAnimation(true);
        }
        setTimeout(() => {
          dispatch(setCheckState("start"));
          // Call here again just to ensure
          setDisabled(false);
          if (handleDisabled) {
            handleDisabled(false);
          }
        }, 1500);
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkState]);

  return (
    <button
      data-testid={props["data-testid"]}
      type="button"
      className="checkmark-btn"
      id={label}
      disabled={disabled}
      onClick={handleClickedButton}
    >
      {!showAnimation ? (
        <span>{label}</span>
      ) : (
        {
          0: (
            <span
              className="checkmark-btn-text"
              onAnimationEnd={() => {
                setButtonState(1);
              }}
            >
              {label}
            </span>
          ),
          1: (
            <div
              className={`ready ${animationStep === 2 ? "done" : ""}`}
              onAnimationEnd={(e) => {
                if (e.target.classList.contains("ready")) {
                  setButtonState(2);
                }
              }}
            >
              <div className="ready-icon-container">
                {animationStep > 0 && (
                  <CheckmarkSVG
                    onAnimationEnd={(e) => {
                      if (e.target.classList.contains("checkmark-check")) {
                        setTimeout(() => {
                          setAnimationStep(2);
                          setTimeout(() => {
                            setDisabled(false);
                            if (handleDisabled) {
                              handleDisabled(false);
                            }
                          }, 200);
                        }, 100);
                      }
                    }}
                  />
                )}
              </div>
              <span
                className="done-text"
                onAnimationEnd={() => {
                  setAnimationStep(1);
                }}
              >
                Done
              </span>
            </div>
          ),
          2: (
            <span
              onAnimationEnd={() => {
                setDisabled(false);
              }}
            >
              {label}
            </span>
          ),
        }[buttonState]
      )}
    </button>
  );
};

export default CheckMarkButton;
