export const USECASE_OPTIONS = [
  { name: "Personal", value: "Personal" },
  { name: "Business Use Case", value: "Business Use Case" },
];

export const SKILL_SQL_OPTIONS = [
  { name: "Wizard", value: "Wizard" },
  { name: "Expert", value: "Expert" },
  { name: "Good", value: "Good" },
  { name: "Moderate", value: "Moderate" },
  { name: "Beginner", value: "Beginner" },
];
