import React, { useState, useRef } from "react";
import {
  fetchReportsData,
  setResetPasswordState,
} from "../../redux/actions/global";
import { useFormik } from "formik";
import * as Yup from "yup";
import CountrySelector from "../commonComponent/countrySelector";
import { setDynamicNotifyText } from "../../redux/actions/property";
import DynamicNotify from "../commonComponent/dynamicNotify";
import { ReactComponent as EditProfileSvg } from "../../assets/icons/profile-edit-profile.svg";
import { ReactComponent as ChangePasswordSvg } from "../../assets/icons/profile-change-password.svg";
import { ReactComponent as ProfileImageSvg } from "../../assets/icons/profile-image.svg";
import { ReactComponent as PasswordSuccessSvg } from "../../assets/icons/profile-password-success.svg";
import "./userProfile.css";
import SelectSearch from "react-select-search";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { useMount } from "react-use";
import { selectGrandTotalFromReportsData } from "../../redux/selectors/global";
import { useAuth } from "../../auth/AuthContext";
import { setUser } from "../../redux/actions/auth";
import { SKILL_SQL_OPTIONS } from "../../helper/constants";

const UserProfile = () => {
  const dispatch = useAppDispatch();

  const currentUser = useAppSelector((state) => state.propertyReducer.user);
  const currentDiagram = useAppSelector(
    (state) => state.propertyReducer.userLayout[currentUser].currentDiagram
  );
  const activeDynamicNotifyText = useAppSelector(
    (state) =>
      state.propertyReducer.userLayout[currentUser].dynamicNotifyText[
        currentDiagram || "global"
      ]
  );
  const resetPasswordState = useAppSelector(
    (state) => state.globalReducer.resetPasswordState
  );
  const grandTotalFromReportsData = useAppSelector(
    selectGrandTotalFromReportsData
  );

  const { accessToken, user, sendChangePasswordEmail, setUserMetadata } =
    useAuth();
  const pictureRef = useRef<HTMLImageElement>(null);
  // state for profile component
  const [firstName, setFirstName] = useState(
    user?.["https://flowhigh.io/user_metadata.firstName"] ?? ""
  );
  const [bool, setBool] = useState(false);
  const [bool1, setBool1] = useState(false);
  const [profileName, setprofileName] = useState("");
  // state for selected category
  const [selectedItem, setSelectedItem] = useState("profile");

  // drag state
  const [dragActive, setDragActive] = React.useState(false);
  // ref
  const inputRef = React.useRef(null);

  // Fetch reports data on mount, used to show grand total API calls
  useMount(async () => {
    if (accessToken) {
      await dispatch(fetchReportsData(`Bearer ${accessToken}`));
    }
  });

  // handle drag events
  const handleDrag: React.DragEventHandler<HTMLDivElement> = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop: React.DragEventHandler<HTMLDivElement> = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (dragActive) {
      setDragActive(false);
    }
  };

  // triggers when file is selected with click
  const handleChange: React.ChangeEventHandler<HTMLInputElement> = function (
    e
  ) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      var reader = new FileReader();
      reader.readAsDataURL(file);
    }
  };

  const profilePicture = (firstName: string, lastName: string) => {
    let i = 0;
    let j = 0;
    while (i < firstName.length) {
      if (firstName[i] !== " ") {
        break;
      } else {
        i++;
      }
    }

    while (j < lastName.length) {
      if (lastName[j] !== " ") {
        break;
      } else {
        j++;
      }
    }

    const fName = firstName?.[i]?.toUpperCase();
    const lName = lastName?.[j]?.toUpperCase();
    const cName = fName + lName;
    setprofileName(cName);
    return cName ?? "";
  };

  // get user profile and initialize ui
  useMount(() => {
    profilePicture(
      user?.["https://flowhigh.io/user_metadata.firstName"] ?? "",
      user?.["https://flowhigh.io/user_metadata.lastName"] ?? ""
    );

    dispatch(setResetPasswordState("start"));
  });

  // formik for profile pane
  const formik = useFormik({
    initialValues: {
      firstName: user?.["https://flowhigh.io/user_metadata.firstName"] ?? "",
      lastName: user?.["https://flowhigh.io/user_metadata.lastName"] ?? "",
      agree: false,
      skillLevel: user?.["https://flowhigh.io/user_metadata.skillLevel"],
      country: user?.["https://flowhigh.io/user_metadata.country"],
      userType:
        user?.["https://flowhigh.io/user_metadata.userType"] ?? "Business",
    },
    onSubmit: (values) => {
      let userProfile = {
        firstName: values.firstName,
        lastName: values.lastName,
        country: values?.country,
        userType: values?.userType,
        skillLevel: values?.skillLevel,
        picture: user?.picture ?? "",
      };
      const updatedProfile = {
        "https://flowhigh.io/user_metadata.firstName": values.firstName,
        "https://flowhigh.io/user_metadata.country": values.country,
        "https://flowhigh.io/user_metadata.userType": values.userType,
        "https://flowhigh.io/user_metadata.skillLevel": values.skillLevel,
      };
      dispatch(setUser(updatedProfile));
      profilePicture(values.firstName, values.lastName);

      setUserMetadata(userProfile, (err, result) => {
        if (err) {
          console.log(err);
          return;
        }
        dispatch(setDynamicNotifyText("profileSaved"));
        setFirstName(result.user_metadata.firstName);
        void formik.setFieldValue("firstName", result.user_metadata.firstName);
        void formik.setFieldValue("country", result.user_metadata.country);
        void formik.setFieldValue("userType", result.user_metadata.userType);
        void formik.setFieldValue(
          "skillLevel",
          result.user_metadata.skillLevel
        );
      });
    },
    validationSchema: Yup.object().shape({
      firstName: Yup.string().max(255).required("required"),
      lastName: Yup.string().max(255).required("required"),
    }),
  });

  // formik for password pane
  const formik2 = useFormik({
    initialValues: {
      email: user?.email ?? "",
    },
    onSubmit: (values) => {
      sendChangePasswordEmail(values.email);
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email().required("required"),
    }),
  });

  const popupClick = (e: string) => {
    let node;
    if (e === "country") {
      if (bool1) {
        node = document.getElementById("country");
        const elem = (node as HTMLElement).getElementsByClassName(
          "select-search__select"
        )[0];
        if (elem && elem.classList.contains("hidden")) {
          elem.classList.remove("hidden");
        } else {
          elem.classList.add("hidden");
        }
        setBool(false);
      }
      setBool1(true);
    } else {
      if (bool) {
        node = document.getElementById("level");
        const elem = (node as HTMLElement).getElementsByClassName(
          "select-search__select"
        )[0];
        if (elem && elem.classList.contains("hidden")) {
          elem.classList.remove("hidden");
        } else {
          elem.classList.add("hidden");
        }
        setBool1(false);
      }
      setBool(true);
    }
  };

  return (
    <div className="profile-container">
      <div className="profile-category">
        <div className="profile-category-header"></div>
        <div className="profile-category-content">
          <div
            className={`profile-category-item${
              selectedItem === "profile" ? " selected" : ""
            }`}
            onClick={() => {
              setSelectedItem("profile");
            }}
          >
            <span className="profile-category-item-icon">
              <EditProfileSvg />
            </span>
            Edit Profile
          </div>
          <div
            className={`profile-category-item${
              selectedItem === "password" ? " selected" : ""
            }`}
            onClick={() => {
              setSelectedItem("password");
            }}
          >
            <span className="profile-category-item-icon">
              <ChangePasswordSvg />
            </span>
            Change Password
          </div>
          <div className="profile-category-item seperator"></div>
          <div className="profile-category-item data grandTotal">
            Total API Calls: {grandTotalFromReportsData}
          </div>
        </div>
      </div>

      <div className="profile-content">
        <div className="profile-content-header"></div>
        <div className="profile-content-body">
          <div className="profile-content-body-left">
            {selectedItem === "profile" ? (
              <form onSubmit={formik.handleSubmit}>
                <label>First name</label>
                <input
                  className={`profile-input firstname${
                    formik.errors.firstName ? " required" : ""
                  }`}
                  data-testid="change-first-name"
                  name="firstName"
                  placeholder="First name *"
                  type="text"
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                />

                <label data-testid="my-label">Last name</label>
                <input
                  className={`profile-input lastname${
                    formik.errors.lastName ? " required" : ""
                  }`}
                  data-testid="change-last-name"
                  name="lastName"
                  placeholder="Last name *"
                  type="text"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                />

                <label>Country</label>
                <div
                  className="profile-select"
                  data-testid="select-country-profile"
                  id="country"
                  onClick={() => popupClick("country")}
                  onBlur={() => setBool1(false)}
                >
                  <CountrySelector
                    value={formik.values.country}
                    onChange={(e) => {
                      void formik.setFieldValue("country", e);
                    }}
                  />
                </div>
                <label>SQL Level</label>
                <div
                  className="profile-select"
                  data-testid="select-sql-lvl-profile"
                  id="level"
                  onClick={() => popupClick("level")}
                  onBlur={() => setBool(false)}
                >
                  <SelectSearch
                    onChange={(e) => {
                      void formik.setFieldValue("skillLevel", e);
                    }}
                    options={SKILL_SQL_OPTIONS}
                    placeholder="Select your SQL Level *"
                    value={formik.values.skillLevel}
                  />
                </div>
                <button
                  className="update-button"
                  data-testid="save-profile-changes"
                  type="submit"
                >
                  Save Changes
                </button>

                {activeDynamicNotifyText === "profileSaved" && (
                  <DynamicNotify
                    text="Your changes have been successfully saved!"
                    query={undefined}
                  />
                )}
              </form>
            ) : (
              <form onSubmit={formik2.handleSubmit}>
                {resetPasswordState === "start" ||
                resetPasswordState === "fail" ? (
                  <>
                    <label>Your Registered Email *</label>
                    <div className="profile-password-description">
                      Changing password is a sensitive action, we need to make
                      sure that only you can make this change.
                    </div>
                    <input
                      className={`profile-input email${
                        formik2.errors.email ? " required" : ""
                      }`}
                      name="email"
                      placeholder="Email *"
                      type="email"
                      readOnly={true}
                      value={formik2.values.email}
                      onChange={(e) => {
                        dispatch(setResetPasswordState("start"));
                        formik2.handleChange(e);
                      }}
                    />

                    <button className="update-button" type="submit">
                      Continue
                    </button>
                  </>
                ) : (
                  <div className="password-success-wrapper">
                    <PasswordSuccessSvg className="success-icon" />
                    <div className="success-label">Success!</div>
                    <div className="profile-password-description">
                      We’ve just sent you an email to reset your password!
                    </div>
                    <button
                      className="resend-button"
                      onClick={() => {
                        dispatch(setResetPasswordState("start"));
                      }}
                    >
                      Resend again
                    </button>
                  </div>
                )}
              </form>
            )}
          </div>
          <div className="profile-content-body-right">
            <div
              className="user-picture-row"
              onDragEnter={handleDrag}
              onDragLeave={handleDrag}
              onDragOver={handleDrag}
              onDrop={handleDrop}
            >
              <input
                ref={inputRef}
                type="file"
                id="input-file-upload"
                accept="image/*"
                multiple={false}
                onChange={handleChange}
              />
              <div className="user-picture-wrapper">
                <h2 className="user-picture">{profileName}</h2>
                <img
                  className=" hidden"
                  src={""}
                  alt={""}
                  ref={pictureRef}
                ></img>
              </div>
            </div>
            <div className="profile-hello">Hello, {firstName}</div>
            <div className="profile-description">
              personal info for the user will be displayed.
            </div>
            <ProfileImageSvg className="profile-image" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
