import "./agreeCheck.css";

const AgreeCheck = (props) => {
  const { isChecked, handleClicked, newText } = props;

  return (
    <label className={`agree-check${isChecked ? "" : " required"}`}>
      <input name="agree" type="checkbox" onChange={handleClicked} />
      <div>
        {newText ? newText : `By checking this box I agree to the `}
        <a
          href="https://sonra.io/terms-conditions-flowhigh/"
          target="blank"
          className="terms"
        >
          terms and conditions
        </a>
        <span> and our </span>
        <a
          href="https://sonra.io/privacy-policy/"
          target="blank"
          className="terms"
        >
          privacy policy.
        </a>
        {!isChecked && "*"}
      </div>
    </label>
  );
};

export default AgreeCheck;
