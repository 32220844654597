import { Dispatch } from "../store";
import diagramActionTypes from "../actionTypes/layout";
import { LayoutStore } from "../reducer/layout";

export const zoomButtonClicked = () => (dispatch: Dispatch) => {
  dispatch({
    type: diagramActionTypes.ZOOM_BUTTON_CLICKED,
  });
};

export const applyZoomAction =
  (data: LayoutStore["zoomState"]) => (dispatch: Dispatch) => {
    dispatch({
      type: diagramActionTypes.ZOOM_ACTION,
      payload: data,
    });
  };

export const setZoomMode =
  (data: LayoutStore["zoomMode"]) => (dispatch: Dispatch) => {
    dispatch({
      type: diagramActionTypes.ZOOM_MODE,
      payload: data,
    });
  };

export const fullscreenButtonClicked =
  (data: boolean) => (dispatch: Dispatch) => {
    dispatch({
      type: diagramActionTypes.FULLSCREEN_BUTTON_CLICKED,
      payload: data,
    });
  };

export const setQueryButton =
  (data: LayoutStore["isQueryVisualized"]) => (dispatch: Dispatch) => {
    dispatch({
      type: diagramActionTypes.SET_IS_QUERY_VISUALIZED,
      payload: data,
    });
  };

export const setEditorSize =
  (data: LayoutStore["editorSize"]) => (dispatch: Dispatch) => {
    dispatch({
      type: diagramActionTypes.SET_EDITOR_SIZE,
      payload: data,
    });
  };

export const setSvgResized = (data: boolean) => (dispatch: Dispatch) => {
  dispatch({
    type: diagramActionTypes.SVG_RESIZED,
    payload: data,
  });
};

export const downloadSvgButtonClicked = () => (dispatch: Dispatch) => {
  dispatch({
    type: diagramActionTypes.DOWNLOAD_SVG_BUTTON_CLICKED,
  });
};

export const setDiagramBoxState =
  (data: LayoutStore["diagramBoxState"]) => (dispatch: Dispatch) => {
    dispatch({
      type: diagramActionTypes.DIAGRAM_BOX_STATE,
      payload: data,
    });
  };

export const setActiveToolbarItem = (data: string) => (dispatch: Dispatch) => {
  dispatch({
    type: diagramActionTypes.ACTIVE_TOOLBAR_ITEM,
    payload: data,
  });
};

export const setFirstTimeFlag = (data: boolean) => (dispatch: Dispatch) => {
  dispatch({
    type: diagramActionTypes.SET_FIRSTTIME_FLAG,
    payload: data,
  });
};
