import { components } from "../../generated-types/api";
import globalActionTypes from "../actionTypes/global";
import { AnyAction } from "redux";

export interface GlobalStore {
  analyzedSqlData: string[];
  animation: boolean;
  // The raw response from https://docs.sonra.io/flowhigh/master/docs/sql-optimiser/index.json, initialized to an empty object
  antipatternData:
    | {
        data: {
          content: string;
          correctness: string;
          name: string;
          performance: string;
          permalink: string;
          readability: string;
          severity: string;
          type: string;
        }[];
      }
    | {};
  clickSearch: boolean;
  isShowSDKModal: boolean;
  isShowSurveyModal: boolean;
  loginState: "start" | "success" | "fail";
  signupState: "start" | "success" | "fail";
  modalID:
    | "antipattern"
    | "data-lineage"
    | "feedback"
    | "post-registration"
    | "unauthorized-domain"
    | "reset-password"
    | "try"
    | "tuning"
    | "unsupported-browser"
    | "unsupported-resolution"
    | "login"
    | "signup"
    | "";
  produceDiagramFlag: boolean;
  reportsData: components["schemas"]["Report"];
  resetPasswordState: "start" | "success" | "fail";
  sampleSqlData: string;
  searchBoxOpen: boolean;
  searchTab: "left" | "right" | "both";
  searchText: string;
  showLoadingMask: boolean;
}

const initialState: GlobalStore = {
  antipatternData: {},
  sampleSqlData: "",
  showLoadingMask: false,
  produceDiagramFlag: false,
  loginState: "start",
  signupState: "start",
  resetPasswordState: "start",
  isShowSurveyModal: false,
  analyzedSqlData: [],
  animation: true,
  searchText: "",
  searchTab: "both",
  clickSearch: false,
  isShowSDKModal: false,
  reportsData: {
    allSDK: 0,
    allUI: 0,
    formatSDK: 0,
    formatUI: 0,
    grandTotal: 0,
    layoutSDK: 0,
    layoutUI: 0,
    parseSDK: 0,
    parseUI: 0,
    totalSDK: 0,
    totalUI: 0,
  },
  searchBoxOpen: false,
  modalID: "",
};

export default function globalReducer(
  state: GlobalStore = initialState,
  action: AnyAction
): GlobalStore {
  switch (action.type) {
    case globalActionTypes.SHOW_LOADING_MASK:
      return {
        ...state,
        showLoadingMask: action.payload,
      };
    case globalActionTypes.SET_PRODUCE_DIAGRAM_FLAG:
      return {
        ...state,
        produceDiagramFlag: !state.produceDiagramFlag,
      };
    case globalActionTypes.SET_SAMPLE_SQL_DATA:
      return {
        ...state,
        sampleSqlData: action.payload,
      };
    case globalActionTypes.SHOW_SDK_MODAL:
      return {
        ...state,
        isShowSDKModal: action.payload,
      };
    case globalActionTypes.SET_LOGIN_STATE:
      return {
        ...state,
        loginState: action.payload,
      };
    case globalActionTypes.SET_REPORTS_DATA:
      return {
        ...state,
        reportsData: action.payload,
      };
    case globalActionTypes.SET_RESET_PASSWORD_STATE:
      return {
        ...state,
        resetPasswordState: action.payload,
      };
    case globalActionTypes.SHOW_SURVEY_MODAL:
      return {
        ...state,
        isShowSurveyModal: action.payload,
      };
    case globalActionTypes.SET_ANALYZE_DATA:
      return {
        ...state,
        analyzedSqlData: action.payload,
      };
    case globalActionTypes.SET_ANIMATION:
      return {
        ...state,
        animation: action.payload,
      };
    case globalActionTypes.SET_SEARCH_TEXT:
      return {
        ...state,
        searchText: action.payload,
      };
    case globalActionTypes.SET_SEARCH_TAB:
      return {
        ...state,
        searchTab: action.payload,
      };
    case globalActionTypes.SET_CLICK_SEARCH:
      return {
        ...state,
        clickSearch: action.payload,
      };
    case globalActionTypes.SET_SEARCH_BOX_OPEN:
      return {
        ...state,
        searchBoxOpen: action.payload,
      };

    case globalActionTypes.SET_GLOBAL_MODAL_ID:
      return {
        ...state,
        modalID: action.modalID,
      };

    case globalActionTypes.SET_ANITPATTERN_DATA:
      return {
        ...state,
        antipatternData: action.antipatternData,
      };

    case globalActionTypes.SET_SIGNUP_STATE:
      return {
        ...state,
        signupState: action.payload,
      };
    default:
      return state;
  }
}
