import React, { forwardRef, useState } from "react";
import { useSelector } from "react-redux";
import Split from "react-split";
import SqlBox from "../sqlBox";
import Diagram from "../diagram";
import ParseDataBox from "../parseDataBox";
import TuningDataBox from "../tuningDataBox";
import ContainerBox from "../containerBox";
import DynamicNotify from "../commonComponent/dynamicNotify";
import OptimiseDataBox from "../optimiseDataBox";
import "./content.css";
import SDKBox from "../sdkBox";
import TimeOutAPI from "../commonComponent/timeoutApi";
import { ReactComponent as ScreenSvg } from "../.././assets/icons/verticallyresize.svg";
import Survey from "../commonComponent/Survey";
import { useAppDispatch } from "../../redux/hooks";
import { setEditorSize } from "../../redux/actions/layout";

const Content = forwardRef((props, parseEditorRef) => {
  const dispatch = useAppDispatch();
  const diagramBoxState = useSelector(
    (state) => state.layoutReducer.diagramBoxState
  );
  const user = useSelector((state) => state.propertyReducer.user);
  const currentDiagram = useSelector(
    (state) => state.propertyReducer.userLayout[user].currentDiagram
  );
  const activeDynamicNotifyText = useSelector(
    (state) =>
      state.propertyReducer.userLayout[user].dynamicNotifyText[currentDiagram]
  );
  const timeout = useSelector(
    (state) =>
      state.propertyReducer.userLayout[user].diagramList[currentDiagram]
        .APITimeOut
  );
  const [sizes, setSizes] = useState([500, 1220]);

  return (
    <div className="content-wrapper">
      <Split
        className="content-wrapper"
        sizes={sizes}
        minSize={[400, 625]}
        maxSize={[2000, 2000]}
        expandToMin={true}
        gutterSize={30}
        gutterAlign="center"
        snapOffset={5}
        dragInterval={1}
        direction="horizontal"
        cursor={ScreenSvg}
        onDrag={(size) => {
          dispatch(setEditorSize(size));
          setSizes(size);
        }}
      >
        <SqlBox
          setFindPreviousInEditor={props.setFindPreviousInEditor}
          setFindNextInEditor={props.setFindNextInEditor}
          width={sizes[0] + "px"}
        />
        <div className="diagram-wrapper">
          <div className="diagram-box">
            {timeout?.show ? (
              <TimeOutAPI data={timeout.data} />
            ) : (
              {
                visualise: <Diagram />,
                parse: (
                  <ParseDataBox
                    parseEditorRefToUse={parseEditorRef}
                    ref={parseEditorRef}
                  />
                ),
                analyse: <OptimiseDataBox />,
                "parser-sdk": <SDKBox />,
                tune: <TuningDataBox />,
                Survey: <Survey />,
              }[diagramBoxState]
            )}
            {
              {
                Format: (
                  <DynamicNotify text="Your code has already been formatted!" />
                ),
                Visualise: (
                  <DynamicNotify text="Your code has already been visualised!" />
                ),
                Parse: (
                  <DynamicNotify text="Your code has already been parsed!" />
                ),
                visualQuery: (
                  <DynamicNotify
                    text="Query changed, click 'Visualise' to update diagram."
                    query="visualQuery"
                  />
                ),
                parseQuery: (
                  <DynamicNotify
                    text="Query changed, click 'Parse' to update output."
                    query="parseQuery"
                  />
                ),
                optimiseQuery: (
                  <DynamicNotify
                    text="Query changed, click 'Optimise' to update output."
                    query="optimiseQuery"
                  />
                ),
                Visualized: (
                  <DynamicNotify
                    text="'Ctrl + Y' to redo query changes"
                    query="visualized"
                  />
                ),
                edit: (
                  <DynamicNotify
                    text="You can't edit sample queries."
                    query="edit"
                  />
                ),
                search: (
                  <DynamicNotify
                    text="Browser search is deactivated."
                    query="edit"
                  />
                ),
                "Code editor is blank. Submit SQL code": (
                  <DynamicNotify
                    text="Code editor is blank. Submit SQL code"
                    query="Code editor is blank"
                  />
                ),
                default: <></>,
              }[activeDynamicNotifyText]
            }
          </div>
        </div>
      </Split>
      <ContainerBox />
    </div>
  );
});

export default Content;
