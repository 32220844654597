/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from "react";
import { ReactComponent as MyPlanSvg } from "../../assets/icons/account-myplan.svg";
import { ReactComponent as BillingInfoSvg } from "../../assets/icons/account-billing-info.svg";
import { ReactComponent as PaymentMethodSvg } from "../../assets/icons/account-payment-method.svg";
import { ReactComponent as TeamSvg } from "../../assets/icons/account-team.svg";
import { ReactComponent as DeleteAccountSvg } from "../../assets/icons/account-delete.svg";
import "./accountSettings.css";
import { useAuth } from "../../auth/AuthContext";

const AccountSettings = () => {
  const { user } = useAuth();

  const [selectedItem, setSelectedItem] = useState("plan");
  const [creationDate, setCreationDate] = useState("");

  useEffect(() => {
    var userCreationDate = new Date(
      user?.["https://flowhigh.io/user_metadata.created_at"]
    );
    setCreationDate(
      userCreationDate.getFullYear() +
        "-" +
        (userCreationDate.getMonth() + 1).toString().padStart(2, "0") +
        "-" +
        userCreationDate.getDate().toString().padStart(2, "0")
    );
  }, [user]);

  return (
    <div className="account-setting-container">
      <div className="account-setting-category">
        <div className="account-setting-category-header"></div>
        <div className="account-setting-category-content">
          <div
            className={`account-setting-category-item ${
              selectedItem === "plan" ? " selected" : ""
            }`}
            onClick={() => {
              setSelectedItem("plan");
            }}
          >
            <span className="account-setting-category-item-icon">
              <MyPlanSvg />
            </span>
            My Plan
          </div>
          <div
            className={`account-setting-category-item hidden ${
              selectedItem === "billing" ? " selected" : ""
            }`}
            onClick={() => {
              setSelectedItem("billing");
            }}
          >
            <span className="account-setting-category-item-icon">
              <BillingInfoSvg />
            </span>
            Billing information
          </div>
          <div
            className={`account-setting-category-item hidden  ${
              selectedItem === "payment" ? " selected" : ""
            }`}
            onClick={() => {
              setSelectedItem("payment");
            }}
          >
            <span className="account-setting-category-item-icon">
              <PaymentMethodSvg />
            </span>
            Payment Method
          </div>
          <div
            className={`account-setting-category-item hidden ${
              selectedItem === "team" ? " selected" : ""
            }`}
            onClick={() => {
              setSelectedItem("team");
            }}
          >
            <span className="account-setting-category-item-icon">
              <TeamSvg />
            </span>
            Team
          </div>
          <div
            className={`account-setting-category-item ${
              selectedItem === "delete" ? " selected" : ""
            }`}
            onClick={() => {
              setSelectedItem("delete");
            }}
          >
            <span className="account-setting-category-item-icon">
              <DeleteAccountSvg />
            </span>
            Delete Account
          </div>
        </div>
      </div>

      <div className="account-setting-content">
        <div className="account-setting-content-header"></div>
        <div className="account-setting-content-body">
          {
            {
              plan: (
                <div className="plan-content">
                  <div className="plan-paragrah">
                    <h2>Term</h2>
                    <p>
                      <span className="details-bold">Trial Period:</span> 6
                      months from Effective Date. Can be cancelled by Sonra with
                      30 days notice period
                    </p>
                    <p>
                      <span className="details-bold">Effective Date:</span>{" "}
                      {creationDate}
                    </p>
                  </div>

                  <div className="plan-paragrah">
                    <h2>Service</h2>
                    <ul>
                      <li>FlowHighgh</li>
                      <li>Visulaise SQL</li>
                      <li>Format SQL</li>
                      <li>Parse SQL</li>
                      <li>Detect SQL Anti-Patterns</li>
                      <li>(the “Service(s)”)</li>
                      <li>Confidentiality during private preview</li>
                    </ul>
                  </div>

                  <div className="plan-paragrah">
                    <h2>FEES & PAYMENT TERMS</h2>
                    <ul>
                      <li>Services Fees: N/A. Private Preview - FREE TRIAL</li>
                      <li>
                        Service Limits: Private Preview fair usage policy. Hard
                        limits may be introduced at Sonra’s discretion
                      </li>
                      <li>Support: by email</li>
                      <li>Support SLA: N/A during private preview</li>
                      <li>Support policy: N/A during private preview</li>
                    </ul>
                  </div>
                </div>
              ),
              delete: (
                <div className="plan-paragrah">
                  <p>
                    <span className="details-bold">
                      Send an email to support@sonra.io to get your account
                      deleted.
                    </span>
                  </p>
                </div>
              ),
            }[selectedItem]
          }
        </div>
      </div>
    </div>
  );
};

export default AccountSettings;
