import React from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addSpecialTab } from "../../helper/util";
import {
  setDiagramBoxState,
  setActiveToolbarItem,
} from "../../redux/actions/layout";
import { setGlobalModalID } from "../../redux/actions/global";
import {
  activateDiagram,
  handlePropertyBox,
} from "../../redux/actions/property";
import { isSpecialTab } from "../../helper/util";
import { ReactComponent as DiagramSvg } from "../../assets/icons/diagram.svg";
import { ReactComponent as ParseSvg } from "../../assets/icons/parse.svg";
import { ReactComponent as LineageSvg } from "../../assets/icons/lineage.svg";
import { ReactComponent as AboutSvg } from "../../assets/icons/about.svg";
import { ReactComponent as FormatSvg } from "../../assets/icons/format.svg";
import { ReactComponent as TuningSvg } from "../../assets/icons/tuning.svg";
import { ReactComponent as OptimiseSvg } from "../../assets/icons/optimise.svg";
import { ReactComponent as FeedbackSvg } from "../../assets/icons/feedback.svg";
import { ReactComponent as HelpSvg } from "../../assets/icons/help.svg";
import { ReactComponent as ArrowSvg } from "../../assets/icons/arrow.svg";
import { ReactComponent as SDKSvg } from "../../assets/icons/SDK.svg";
import { ReactComponent as SurveySvg } from "../../assets/icons/survey.svg";

import "./toolbarNav.css";
import ModalWrapper from "../modalWrapper";
import { useAuth } from "../../auth/AuthContext";

const ToolbarNav = () => {
  const activeToolbarItem = useSelector(
    (state) => state.layoutReducer.activeToolbarItem
  );

  const user = useSelector((state) => state.propertyReducer.user);
  const currentDiagram = useSelector(
    (state) => state.propertyReducer.userLayout[user].currentDiagram
  );
  const recentStack = useSelector(
    (state) => state.propertyReducer.userLayout[user].recentStack
  );

  const { isAuthenticated } = useAuth();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formattestscreen = process.env.REACT_APP_FH_FEATURE_FORMAT_TEST;

  // change option of analyze
  const changeDiagramOption = (option) => {
    if (isSpecialTab(currentDiagram)) {
      batch(() => {
        dispatch(setActiveToolbarItem(option));
        dispatch(setDiagramBoxState(option));
      });

      // get last opened non-special tab
      for (let iStack = recentStack.length - 1; iStack >= 0; iStack--) {
        if (!isSpecialTab(recentStack[iStack])) {
          dispatch(activateDiagram(recentStack[iStack]));

          break;
        }
      }
    } else {
      navigate("/" + option + "-sql");
    }
  };

  const handleSqlDiagram = () => {
    changeDiagramOption("visualise");
  };

  const handleParseSql = () => {
    dispatch(handlePropertyBox(false));
    changeDiagramOption("parse");
  };

  const handleTuning = () => {
    changeDiagramOption("tune");
  };

  const handleSurvay = () => {
    batch(() => {
      dispatch(setActiveToolbarItem("Survey"));
      dispatch(setDiagramBoxState("Survey"));
    });
    addSpecialTab("Survey");
    changeDiagramOption("Survey");
  };
  const handleOptimise = () => {
    changeDiagramOption("analyse");
  };

  const handleSDK = () => {
    changeDiagramOption("parser-sdk");
  };

  const handleLineage = () => {
    dispatch(setGlobalModalID("data-lineage"));
  };

  const handleAbout = () => {
    dispatch(setActiveToolbarItem("about"));

    addSpecialTab("About");
    navigate("/about");
  };

  // show feedback modal
  const handleFeedback = () => {
    dispatch(setGlobalModalID("feedback"));
  };

  const handleHelp = (e) => {
    const bottomtoolbar = document.getElementsByClassName("bottom-toolbar")[0];
    const toolbarWidth = document.getElementsByClassName("main-toolbar")[0];
    if (e === "in") {
      bottomtoolbar.classList.remove("hide");
      bottomtoolbar.classList.add("showToolbar");
      toolbarWidth.classList.add("toolbar-width");
    } else if (e === "out") {
      bottomtoolbar.classList.remove("showToolbar");
      bottomtoolbar.classList.add("hide");
      toolbarWidth.classList.remove("toolbar-width");
    }
  };

  const handleFormatterTest = () => {
    if (isSpecialTab(currentDiagram)) {
      return;
    }

    dispatch(setActiveToolbarItem("formattest"));

    navigate("/formattest");
  };

  return (
    <>
      <div className="main-toolbar vertical-toolbar-wrapper">
        <div className="main-toolbar-item-wrapper" data-testid="main-toolbar">
          <div className="upr-wrapper">
            <span
              className={
                `main-toolbar-item top-item` +
                (activeToolbarItem === "visualise" ? ` activated` : ``)
              }
              onClick={() => handleSqlDiagram()}
            >
              <span className="menu-item">
                <DiagramSvg className="menu-icon" />
              </span>
              <span className="menu-text">Visualise & Format SQL</span>
            </span>

            <span
              className={
                `main-toolbar-item` +
                (activeToolbarItem === "parse" ? ` activated` : ``)
              }
              onClick={() => handleParseSql()}
            >
              <span className="menu-item">
                <ParseSvg className="menu-icon" />
              </span>
              <span className="menu-text">Parse SQL</span>
            </span>

            <span
              className={
                `main-toolbar-item` +
                (activeToolbarItem === "analyse" ? ` activated` : ``)
              }
              onClick={() => handleOptimise()}
            >
              <span className="menu-item">
                <OptimiseSvg className="menu-icon" />
              </span>
              <span className="menu-text">Analyse SQL</span>
            </span>

            <span
              className={
                `main-toolbar-item` +
                (activeToolbarItem === "parser-sdk" ? ` activated` : ``)
              }
              onClick={() => handleSDK()}
            >
              <span className="menu-item">
                <SDKSvg className="menu-icon" />
              </span>
              <span className="menu-text">SDK</span>
            </span>

            <span
              className={
                `main-toolbar-item` +
                (activeToolbarItem === "tune" ? ` activated` : ``)
              }
              onClick={() => handleTuning()}
            >
              <span className="menu-item">
                <TuningSvg className="menu-icon" />
              </span>
              <span className="menu-text">Tune SQL</span>
            </span>

            <span
              className={
                `main-toolbar-item` +
                (activeToolbarItem === "lineage" ? ` activated` : ``)
              }
              onClick={() => handleLineage()}
            >
              <span className="menu-item">
                <LineageSvg className="menu-icon" />
              </span>
              <span className="menu-text">Data Lineage</span>
            </span>

            {formattestscreen === "true" ? (
              <span
                className={
                  `main-toolbar-item` +
                  (activeToolbarItem === "formattest" ? ` activated` : ``)
                }
                onClick={() => handleFormatterTest()}
              >
                <span className="menu-item">
                  <FormatSvg className="menu-icon" />
                </span>
                <span className="menu-text">Test formatting text</span>
              </span>
            ) : (
              ""
            )}

            {isAuthenticated ? (
              <span
                className={
                  `main-toolbar-item` +
                  (activeToolbarItem === "Survey" ? ` activated` : ``)
                }
                onClick={() => handleSurvay()}
              >
                <span className="menu-item">
                  <SurveySvg className="menu-icon" />
                </span>
                <span className="menu-text">Private Preview Survey</span>
              </span>
            ) : (
              ""
            )}
          </div>

          <div className="bottom-wrapper ">
            <span
              className={
                `main-toolbar-item` +
                (activeToolbarItem === "about" ? ` activated` : ``)
              }
              id="help"
              onMouseEnter={() => handleHelp("in")}
              onMouseLeave={() => handleHelp("out")}
            >
              <span className="menu-item" data-testid="help-menu-item">
                <HelpSvg className="menu-icon" onClick={() => handleHelp()} />
              </span>
              <span className="menu-text">Help and support</span>
              <span className="menu-item">
                <ArrowSvg
                  className={
                    activeToolbarItem === "about"
                      ? ` color-white`
                      : `color-blue`
                  }
                />
              </span>
            </span>
          </div>
        </div>
        <div
          className="bottom-toolbar hide"
          onMouseEnter={() => handleHelp("in")}
          onMouseLeave={() => handleHelp("out")}
        >
          <span
            className={
              `main-toolbar-item` +
              (activeToolbarItem === "about" ? ` activated` : ``)
            }
            onClick={() => handleAbout()}
          >
            <span className="menu-item">
              <AboutSvg className="menu-icon" />
            </span>
            <span className="menu-text" data-testid="help-about">
              About
            </span>
          </span>

          <span className="main-toolbar-item" onClick={() => handleFeedback()}>
            <span className="menu-item">
              <FeedbackSvg className="menu-icon" />
            </span>
            <span className="menu-text" data-testid="feedback-btn">
              Send Feedback
            </span>
          </span>
          <a
            className="menu-text"
            href="https://docs.sonra.io/flowhigh/master/docs/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="main-toolbar-item" onClick={() => handleHelp()}>
              <span className="menu-item">
                <HelpSvg className="menu-icon" />
              </span>
              <span className="menu-text "> Help </span>
            </span>
          </a>
        </div>
      </div>

      <ModalWrapper />
    </>
  );
};

export default ToolbarNav;
