/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setHighlightIDs } from "../../redux/actions/property";
import { ReactComponent as PhysicalTableSvg } from "../../assets/icons/physical-table.svg";
import { ReactComponent as SelectTableSvg } from "../../assets/icons/select-table.svg";
import { ReactComponent as InlineTableSvg } from "../../assets/icons/inline-table.svg";
import { ReactComponent as RecursiveTableSvg } from "../../assets/icons/recursive-table.svg";
import { ReactComponent as FunctionTableSvg } from "../../assets/icons/function-table.svg";
import { ReactComponent as PseudoTableSvg } from "../../assets/icons/pseudo-table.svg";
import { ReactComponent as CommonTableSvg } from "../../assets/icons/common-table.svg";
import ListItem from "./ListItem";
import "./tableList.css";
import { useAuth } from "../../auth/AuthContext";

const TableList = () => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.propertyReducer.user);
  const currentDiagram = useSelector(
    (state) => state.propertyReducer.userLayout[user].currentDiagram
  );
  const diagramData = useSelector(
    (state) =>
      state.propertyReducer.userLayout[user].diagramList[currentDiagram]
        .diagramData
  );
  const highlightIDs = useSelector(
    (state) => state.propertyReducer.userLayout[user].highlightIDs
  );
  const highlightNodes = Object.keys(highlightIDs);

  const { isAuthenticated, login } = useAuth();

  const [physicalTables, setPhysicalTables] = useState([]);
  const [inlineTables, setInlineTables] = useState([]);
  const [selectTables, setSelectTables] = useState([]);
  const [pseudoTables, setPseudoTables] = useState([]);
  const [recursiveClause, setRecursiveClause] = useState([]);
  const [functionTables, setFunctionTables] = useState([]);
  const [commonTables, setCommonTables] = useState([]);

  // make table list items.

  const tableList = () => {
    if (!diagramData?.nodes) return;

    let physicalNodes = [],
      inlineNodes = [],
      selectNodes = [],
      recursiveNodes = [],
      pseudoNodes = [],
      functionNodes = [],
      commonNodes = [];

    diagramData.nodes
      .filter((node) => node.type === "dataset")
      .sort((a, b) => {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      })
      .forEach((node) => {
        switch (node.subtype) {
          case "physical":
            analysisData(physicalNodes, node);
            break;
          case "select":
            analysisData(selectNodes, node);
            break;
          case "logical":
            analysisData(inlineNodes, node);
            break;
          case "recursive":
            analysisData(recursiveNodes, node);
            break;
          case "sourceless":
            analysisData(pseudoNodes, node);
            break;
          case "functional":
          case "udtf":
            analysisData(functionNodes, node);
            break;
          case "cte":
            analysisData(commonNodes, node);
            break;
          default:
            analysisData(physicalNodes, node);
            break;
        }
      });

    // remove "WITH " prefix for common expression
    commonNodes.forEach((node) => {
      if (node.name.substr(0, 5) === "WITH ") {
        node.name = node.name.substr(5);
      } else if (node.name.substr(0, 4) === "CTE ") {
        node.name = node.name.substr(4);
      }
    });

    setPhysicalTables(physicalNodes);
    setSelectTables(selectNodes);
    setInlineTables(inlineNodes);
    setRecursiveClause(recursiveNodes);
    setPseudoTables(pseudoNodes);
    setFunctionTables(functionNodes);
    setCommonTables(commonNodes);

    function analysisData(nodeArray, item) {
      let i = 0;
      for (i = 0; i < nodeArray.length; i++) {
        if (nodeArray[i].name === item.label) {
          nodeArray[i].count++;
          nodeArray[i].id += "," + item.id;
          break;
        }
      }
      if (i === nodeArray.length) {
        let highlighted = false;
        if (highlightNodes.includes(item.id)) {
          highlighted = true;
        }
        nodeArray.push({
          id: item.id,
          name: item.label,
          count: 1,
          highlighted,
        });
      }
    }
  };
  useEffect(() => {
    tableList();
  }, [diagramData]);

  // set highlight
  useEffect(() => {
    setHighlightItems();
  }, [highlightIDs]);

  const setHighlightItems = () => {
    let physicalNodes = [...physicalTables];
    let inlineNodes = [...inlineTables];
    let selectNodes = [...selectTables];
    let recursiveNodes = [...recursiveClause];
    let pseudoNodes = [...pseudoTables];
    let functionNodes = [...functionTables];
    let commonNodes = [...commonTables];

    if (physicalNodes.length > 0) clearHighlightItems(physicalNodes);
    if (inlineNodes.length > 0) clearHighlightItems(inlineNodes);
    if (selectNodes.length > 0) clearHighlightItems(selectNodes);
    if (recursiveNodes.length > 0) clearHighlightItems(recursiveNodes);
    if (pseudoNodes.length > 0) clearHighlightItems(pseudoNodes);
    if (functionNodes.length > 0) clearHighlightItems(functionNodes);
    if (commonNodes.length > 0) clearHighlightItems(commonNodes);

    for (let iCount = 0; iCount < highlightNodes.length; iCount++) {
      if (
        physicalNodes.length > 0 &&
        setHighlightedItem(highlightNodes[iCount], physicalNodes)
      )
        continue;
      if (
        inlineNodes.length > 0 &&
        setHighlightedItem(highlightNodes[iCount], inlineNodes)
      )
        continue;
      if (
        selectNodes.length > 0 &&
        setHighlightedItem(highlightNodes[iCount], selectNodes)
      )
        continue;
      if (
        recursiveNodes.length > 0 &&
        setHighlightedItem(highlightNodes[iCount], recursiveNodes)
      )
        continue;
      if (
        pseudoNodes.length > 0 &&
        setHighlightedItem(highlightNodes[iCount], pseudoNodes)
      )
        continue;
      if (
        functionNodes.length > 0 &&
        setHighlightedItem(highlightNodes[iCount], functionNodes)
      )
        continue;
      if (
        commonNodes.length > 0 &&
        setHighlightedItem(highlightNodes[iCount], commonNodes)
      )
        continue;
    }

    if (physicalNodes.length > 0) setPhysicalTables(physicalNodes);
    if (selectNodes.length > 0) setSelectTables(selectNodes);
    if (inlineNodes.length > 0) setInlineTables(inlineNodes);
    if (recursiveNodes.length > 0) setRecursiveClause(recursiveNodes);
    if (pseudoNodes.length > 0) setPseudoTables(pseudoNodes);
    if (functionNodes.length > 0) setFunctionTables(functionNodes);
    if (commonNodes.length > 0) setCommonTables(commonNodes);

    function setHighlightedItem(highlight_id, nodeArray) {
      nodeArray.forEach((node) => {
        let ids = node.id.split(",");
        ids.forEach((id) => {
          if (id === highlight_id) {
            node.highlighted = true;
            return true;
          }
        });
      });

      return false;
    }

    function clearHighlightItems(nodeArray) {
      nodeArray.forEach((node) => {
        node.highlighted = false;
      });
    }
  };

  const handleClickedItem = async (e) => {
    if (!isAuthenticated) {
      await login();
    } else {
      let checked = e.target.checked;
      let ids = { ...highlightIDs };
      if (checked) {
        e.target
          .getAttribute("node_id")
          .split(",")
          .forEach((id) => {
            ids = { ...ids, [id]: [id] };
          });
      } else {
        e.target
          .getAttribute("node_id")
          .split(",")
          .forEach((id) => {
            delete ids?.[id];
          });
      }

      dispatch(setHighlightIDs(ids));
    }
  };

  const handleClickedBox = (e) => {
    if (e.target.tagName.toLowerCase() === "label") return;

    dispatch(setHighlightIDs({}));
  };

  return (
    <div className="tablelist-box" onClick={handleClickedBox}>
      {physicalTables.length > 0 && (
        <ul>
          <div className="table-type-wrapper">
            <PhysicalTableSvg />
            <span className="bullet-text physical-table"> Physical Tables</span>
          </div>
          <ListItem
            itemArray={physicalTables}
            handleClickedItem={handleClickedItem}
          />
        </ul>
      )}
      {inlineTables.length > 0 && (
        <ul>
          <div className="table-type-wrapper">
            <InlineTableSvg />
            <span className="bullet-text inline-table"> Inline Views</span>
          </div>
          <ListItem
            itemArray={inlineTables}
            handleClickedItem={handleClickedItem}
          />
        </ul>
      )}
      {selectTables.length > 0 && (
        <ul>
          <div className="table-type-wrapper">
            <SelectTableSvg />
            <span className="bullet-text inline-table">Select</span>
          </div>
          <ListItem
            itemArray={selectTables}
            handleClickedItem={handleClickedItem}
          />
        </ul>
      )}
      {recursiveClause.length > 0 && (
        <ul>
          <div className="table-type-wrapper">
            <RecursiveTableSvg />
            <span className="bullet-text recursive-table">
              {" "}
              Recursive Tables
            </span>
          </div>
          <ListItem
            itemArray={recursiveClause}
            handleClickedItem={handleClickedItem}
          />
        </ul>
      )}
      {pseudoTables.length > 0 && (
        <ul>
          <div className="table-type-wrapper">
            <PseudoTableSvg />
            <span className="bullet-text pseudo-table"> Pseudo Tables</span>
          </div>
          <ListItem
            itemArray={pseudoTables}
            handleClickedItem={handleClickedItem}
          />
        </ul>
      )}
      {functionTables.length > 0 && (
        <ul>
          <div className="table-type-wrapper">
            <FunctionTableSvg />
            <span className="bullet-text function-table"> Function Tables</span>
          </div>
          <ListItem
            itemArray={functionTables}
            handleClickedItem={handleClickedItem}
          />
        </ul>
      )}
      {commonTables.length > 0 && (
        <ul>
          <div className="table-type-wrapper">
            <CommonTableSvg />
            <span className="bullet-text common-table">
              {" "}
              Common Table Expressions
            </span>
          </div>
          <ListItem
            itemArray={commonTables}
            handleClickedItem={handleClickedItem}
          />
        </ul>
      )}
    </div>
  );
};

export default TableList;
