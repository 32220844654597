import { MigrationManifest, PersistState } from "redux-persist";
import _ from "underscore";
import { RootState } from "./store";

const migration: MigrationManifest = {
  1: (state) => {
    return {
      ...state,
      // See https://github.com/rt2zz/redux-persist/issues/675
      _persist: state?._persist as PersistState,
    };
  },
  2: (state) => {
    const newState = Object.assign({}, state, {
      _persist: state?._persist as PersistState,
      authReducer: {
        isShowVerifyEmailModal: undefined,
      },
      globalReducer: {
        isShowFeedbackModal: undefined,
        isShowLineageModal: undefined,
        isShowLoginModal: undefined,
        isShowResetPasswordModal: undefined,
        isShowSignupModal: undefined,
        isShowTryModal: undefined,
        isShowTuningModal: undefined,
        modalID: "",
      },
      propertyReducer: {
        userLayout: Object.fromEntries(
          // @ts-ignore delete previous value
          Object.entries(state.propertyReducer?.userLayout).map(
            (userLayout) => {
              // @ts-ignore delete previous value
              userLayout.isShowErrorModal = undefined;
              return userLayout;
            }
          )
        ),
      },
    });

    return newState;
  },
  /**
   * Added an isSampleTab flag to diagrams, populating it for preexisting tabs
   */
  3: (state) => {
    const newState = Object.assign({}, state as RootState);

    const newUserLayouts = Object.assign(
      {},
      newState.propertyReducer.userLayout
    );
    Object.keys(newUserLayouts).forEach((layoutName) => {
      const layout = newState.propertyReducer.userLayout[layoutName];
      const newDiagramList = Object.assign({}, layout.diagramList);
      Object.keys(newDiagramList).forEach((diagramName) => {
        if (diagramName === "Sample SQL") {
          newDiagramList[diagramName] = Object.assign(
            {},
            layout.diagramList[diagramName],
            {
              isSampleTab: true,
            }
          );
        } else {
          newDiagramList[diagramName] = Object.assign(
            {},
            layout.diagramList[diagramName],
            {
              isSampleTab: false,
            }
          );
        }
      });
      newUserLayouts[layoutName] = Object.assign({}, layout, {
        diagramList: newDiagramList,
      });
    });
    newState.propertyReducer.userLayout = newUserLayouts;
    return newState;
  },
  /**
   * NEW_DIAGRAM action was previously initializing highlightText to an empty object which is no longer valid
   */
  4: (state) => {
    const newState = Object.assign({}, state as RootState);

    Object.keys(newState.propertyReducer.userLayout).forEach((user) => {
      const layout = Object.assign(
        {},
        newState.propertyReducer.userLayout[user]
      );

      newState.propertyReducer.userLayout[user] = layout;
      Object.keys(layout.diagramList).forEach((diagramName) => {
        const diagram = Object.assign({}, layout.diagramList[diagramName]);

        newState.propertyReducer.userLayout[user].diagramList[diagramName] =
          diagram;
        // @ts-ignore // highlightText is now removed
        if (diagram.highlightText && _.isEmpty(diagram.highlightText)) {
          // @ts-ignore // highlightText is now removed
          diagram.highlightText = undefined;
        }
      });
    });

    return newState;
  },
  /**
   * moving antipatternData from userLayout in property store to global store
   */
  5: (state) => {
    const newState = Object.assign({}, state as RootState);

    // @ts-expect-error property no longer defined on userLayout
    newState.propertyReducer.userLayout.antipatternData = undefined;
    return newState;
  },
  /**
   * Remove highlightText and highlightLines
   */
  6: (state) => {
    const newState = Object.assign({}, state as RootState);
    Object.values(newState.propertyReducer.userLayout).forEach((layout) => {
      if (layout.hasOwnProperty("highlightLines")) {
        // @ts-expect-error highlightLines doesn't exist on this type any longer
        delete layout.highlightLines;
      }
      Object.values(layout.diagramList).forEach((diagram) => {
        if (diagram.hasOwnProperty("highlightText")) {
          // @ts-expect-error highlightText doesn't exist on this type any longer
          delete diagram.highlightText;
        }
      });
    });
    return newState;
  },
  /*
   * Remove auth store and remove signupState from global store
   */
  7: (state) => {
    const newState = Object.assign({}, state as RootState);
    if (newState.hasOwnProperty("authReducer")) {
      // @ts-ignore Removing a previously defined value
      delete newState.authReducer;
    }
    if (newState.globalReducer.hasOwnProperty("signupState")) {
      // @ts-ignore Removing a previously defined value
      delete newState.globalReducer.signupState;
    }
    return newState;
  },

  /**
   * Add showUnsupportedBrowserModal flag
   */
  8: (state) => {
    const newState = Object.assign({}, state as RootState);

    Object.values(newState.propertyReducer.userLayout).forEach((layout) => {
      if (_.includes(layout.disabledRemindModals, "unsupported-browser")) {
        layout.disabledRemindModals = ["unsupported-browser"];
      }
    });
    return newState;
  },
};

export default migration;
