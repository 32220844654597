import { useFormik } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import axios from "axios";
import "./suggestModal.css";
import { useAuth } from "../../auth/AuthContext";
import { useUpdateEffect } from "react-use";

const SuggestModal = ({ show, onClose }) => {
  const { user } = useAuth();

  const [suggestState, setSuggestState] = useState("start");

  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required("required"),
    suggestAntipattern: Yup.string().required("required"),
  });

  const formik = useFormik({
    initialValues: {
      email: user?.email ?? "",
      suggestAntipattern: "",
    },
    onSubmit: (values) => {
      let request_url =
        "https://f6d808b0.sibforms.com/serve/MUIEAJ5H1DgHIPdRN9wUt43CdmF2Q9PIizlQD1DSjERf16y9E79s1Y69R7LMgGpyynUkuCtYkdyJ5-7XpQ354F1VmJ--ijRGYe92OtUANX1J5bPrQ9IU34UJ2haVo0kOrbtKonz-IFU5Wxn1vFueKsfz3TqLvMZA4wOhNAy72mg_sD1r7_VBrcywsMYMV0Uqr9MiLbbZVl-W4dh6?isAjax=1";

      const suggestFormData = new FormData();
      suggestFormData.append("EMAIL", values.email);
      suggestFormData.append(
        "ANTIPATTERN_SUGGESTION",
        values.suggestAntipattern
      );
      suggestFormData.append("locale", "en");

      axios
        .post(request_url, suggestFormData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then(() => {
          setSuggestState("success");
          formik.resetForm();
        })
        .catch((err) => {
          console.log(err);
          setSuggestState("fail");
        });
    },
    validationSchema,
  });
  useUpdateEffect(() => {
    setSuggestState("start");
    formik.resetForm();
    void formik.setFieldValue("email", user?.email ?? "");
  }, [user?.email, show]);

  return (
    <div
      style={{
        visibility: show ? "visible" : "hidden",
        opacity: show ? "1" : "0",
      }}
      className="suggestModal overlay"
    >
      <form onSubmit={formik.handleSubmit} className="Modal">
        {suggestState === "fail" ? (
          <div id="error-message" class="sib-form-message-panel">
            <div className="sib-form-message-panel__text--center">
              <svg viewBox="0 0 512 512" className="sib-icon red">
                <path d="M256 40c118.621 0 216 96.075 216 216 0 119.291-96.61 216-216 216-119.244 0-216-96.562-216-216 0-119.203 96.602-216 216-216m0-32C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm-11.49 120h22.979c6.823 0 12.274 5.682 11.99 12.5l-7 168c-.268 6.428-5.556 11.5-11.99 11.5h-8.979c-6.433 0-11.722-5.073-11.99-11.5l-7-168c-.283-6.818 5.167-12.5 11.99-12.5zM256 340c-15.464 0-28 12.536-28 28s12.536 28 28 28 28-12.536 28-28-12.536-28-28-28z" />
              </svg>
              <span className="sib-form-message-panel__inner-text">
                Your submission could not be saved. Please try again.
              </span>
            </div>
          </div>
        ) : (
          ""
        )}

        {suggestState === "success" ? (
          <div id="success-message" className="sib-form-message-panel">
            <div className="sib-form-message-panel__text--center">
              <svg viewBox="0 0 512 512" className="sib-icon green">
                <path d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 464c-118.664 0-216-96.055-216-216 0-118.663 96.055-216 216-216 118.664 0 216 96.055 216 216 0 118.663-96.055 216-216 216zm141.63-274.961L217.15 376.071c-4.705 4.667-12.303 4.637-16.97-.068l-85.878-86.572c-4.667-4.705-4.637-12.303.068-16.97l8.52-8.451c4.705-4.667 12.303-4.637 16.97.068l68.976 69.533 163.441-162.13c4.705-4.667 12.303-4.637 16.97.068l8.451 8.52c4.668 4.705 4.637 12.303-.068 16.97z" />
              </svg>
              <span className="sib-form-message-panel__inner-text">
                Your submission has been successful.
              </span>
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="suggest-modal-body">
          <div style={{ display: "flex", justifyContent: "center" }}>
            <textarea
              name="suggestAntipattern"
              id="suggestAntipattern"
              placeholder="Are we missing an anti pattern? Write your suggestion here"
              data-required="true"
              onChange={formik.handleChange}
              value={formik.values.suggestAntipattern}
              required
              rows={11}
              cols={45}
            />
          </div>
          <div className="submit-btn">
            <button type="submit">Submit suggestion</button>
            <span onClick={onClose}>Cancel</span>
          </div>
        </div>
      </form>
    </div>
  );
};

export default SuggestModal;
