import { Dispatch } from "../store";
import authActionTypes from "../actionTypes/auth";
import { User } from "../reducer/auth";

export const setUser = (data: User) => (dispatch: Dispatch) => {
  dispatch({
    type: authActionTypes.SET_USER,
    payload: data,
  });
};

export const setErrorDescription =
  (errorDescription: string) => (dispatch: Dispatch) => {
    dispatch({
      type: authActionTypes.SET_ERROR_DESCRIPTION,
      errorDescription,
    });
  };
