import React from "react";
import { FallbackProps } from "react-error-boundary";
import "./error.css";
import { ReactComponent as TimeoutSVG } from "../../assets/icons/timeout.svg";
import { useAppDispatch } from "../../redux/hooks";
import { useAuth } from "../../auth/AuthContext";
import { setUser } from "../../redux/actions/property";

const ErrorPage: React.FC<FallbackProps> = (props) => {
  const { logout } = useAuth();
  const dispatch = useAppDispatch();

  const onClick = async () => {
    localStorage.removeItem("sample");
    localStorage.removeItem("response");
    localStorage.removeItem("UserInfo");
    localStorage.setItem("persist:root", "");
    props.resetErrorBoundary();
    await logout();
    dispatch(setUser("sample"));
  };
  return (
    <div className="error-page">
      <TimeoutSVG />
      <p className="message">
        There was an error while performing your requested operation. We'll look
        into it shortly, in the meantime please refresh the page. If this
        doesn't fix the problem,{" "}
        <em>
          please use the button below to logout and clear your saved data.
        </em>
      </p>
      <button onClick={onClick}>Click Here</button>
    </div>
  );
};

export default ErrorPage;
