import { batch } from "react-redux";
import {
  hideLoadingMask,
  setGlobalModalID,
  showLoadingMask,
} from "../redux/actions/global";
import {
  newTab,
  activateDiagram,
  setSDKState,
  setDynamicNotifyText,
} from "../redux/actions/property";
import { store } from "./../redux/store";
import Ace from "ace-builds/src-noconflict/ace";

const TokenIterator = Ace.require("ace/token_iterator").TokenIterator;
let url = process.env.REACT_APP_SDK_PACKAGE_URL ?? "flowhigh";

export const showResetPassword = () => {
  store.dispatch(setGlobalModalID("reset-password"));
};

// add special tab(such as about, my profile...)
export const addSpecialTab = (tabName) => {
  const {
    propertyReducer: { userLayout, user },
  } = store.getState();
  const { diagramList } = userLayout[user];

  // check if the tab is added already.
  if (tabName in diagramList) {
    store.dispatch(activateDiagram(tabName));
  } else {
    store.dispatch(newTab(tabName));
  }
};

// check tab is not diagram tab
export const isSpecialTab = (tabName) => {
  return (
    tabName === "My Profile" ||
    tabName === "Account Settings" ||
    tabName === "About" ||
    tabName === "SDK Downloads" ||
    tabName === "Survey"
  );
};

/**
 * Converts a position string to a Point actually
 * @param {string} sqlData - The string contents of the editor
 * @param {string | number} position - A position string representing a number like "10", the start of a range
 * @returns an object with attributes row and column
 */
export const convertPosToPoint = (sqlData, position) => {
  let lines = sqlData.split("\n");

  let pos = 0;
  for (let i = 0; i < lines.length; i++) {
    if (position <= pos + lines[i].length) {
      return { row: i, column: position - pos };
    }
    pos += lines[i].length + 1;
  }

  return { row: 0, column: 0 };
};

export const isEditorQuery = (editorR) => {
  if (editorR?.current?.editor?.getValue() === "") {
    store.dispatch(
      setDynamicNotifyText("Code editor is blank. Submit SQL code")
    );
    return false;
  }
  return true;
};

export const isDiagramDataValid = (diagramData) => {
  return diagramData && Object.keys(diagramData).length;
};

export const isParseDataValid = (parseData) => {
  return (
    parseData !== undefined &&
    Object.keys(parseData).length &&
    parseData.statement
  );
};

export const handlePressButton = (button) => {
  const {
    layoutReducer: { diagramBoxState },
  } = store.getState();
  let notifyText = null;
  if (button === "undo" || button === "redo") {
    if (diagramBoxState === "visualise") {
      notifyText = "visualQuery";
    } else if (diagramBoxState === "parse") {
      notifyText = "parseQuery";
    }
  }
  store.dispatch(setDynamicNotifyText(notifyText));
};

export const handleClickedClipboard = (editor) => {
  const cutLine = editor.selection.isEmpty();
  if (!cutLine) {
    copyToClipboard(editor, "selection");
    setTimeout(() => {
      editor.focus();
    }, 100);
  }
};

export const cutToClipboard = (editor) => {
  const {
    propertyReducer: { userLayout, user },
  } = store.getState();
  const { diagramList, currentDiagram } = userLayout[user];
  const cutLine = editor.selection.isEmpty();
  if (!cutLine) {
    if (currentDiagram !== Object.keys(diagramList)[0]) {
      handleClickedClipboard(editor);
      var range = editor.selection.getRange();
      editor._emit("cut", range);
      if (!range.isEmpty()) editor.session.remove(range);
      editor.clearSelection();
    } else {
      handleClickedClipboard(editor);
    }
  }
};

export const getAntipatternsOrErrorsByPos = (parseData, eltype, pos) => {
  if (eltype === "antipattern") {
    return parseData.statement?.flatMap(
      (value) =>
        value.antiPatterns?.filter((pattern) => pattern.pos.includes(pos)) || []
    );
  } else {
    return parseData.error?.filter(
      (value) => value.eltype === "error" && value.pos === pos
    );
  }
};

export const copyToClipboard = (editor, mode = "total") => {
  const {
    propertyReducer: { userLayout, user },
  } = store.getState();
  const { diagramList, currentDiagram } = userLayout[user];
  let selectedRange = editor.selection.cursor;
  let startRow = 0,
    startCol = 0,
    endRow = selectedRange.row,
    endCol = selectedRange.column;
  if (mode === "total") {
    endRow = editor.session.getLength() - 1;
    endCol = editor.session.getLine(endRow).length;
  } else {
    startRow = editor.getSelection().getRange().start.row;
    // add plus one to avoid index issues
    startCol = editor.getSelection().getRange().start.column + 1;
    endRow = editor.getSelection().getRange().end.row;
    endCol = editor.getSelection().getRange().end.column;

    if (startRow === endRow && startCol === endCol) {
      return;
    }
  }

  // get tokens in selected range
  var iterator = new TokenIterator(editor.session, startRow, startCol);
  let token = iterator.getCurrentToken();
  let formattedText = `<pre style='font-family:Roboto Mono, Consolas, monospace;font-size:14px;'><div>`;
  let curRow = startRow;

  do {
    // check if arrive end of selected range
    if (endRow !== null && curRow >= endRow) {
      if (iterator.getCurrentTokenColumn() + token.value.length > endCol) {
        break;
      }
    }
    // check new line
    if (iterator.getCurrentTokenRow() > curRow) {
      formattedText += `</div><div>`;
      curRow++;
    }
    if (iterator.getCurrentTokenRow() === endRow + 1) {
      break;
    }

    // make html element
    formattedText += makeFormattedToken(token);
    // get token
    token = iterator.stepForward();
  } while (token);
  formattedText += `</div></pre>`;

  setTimeout(() => {
    copyIntoClipboard(formattedText);
    if (Object.keys(diagramList)[0] === currentDiagram) {
      editor.setReadOnly(true);
    }
  }, 100);

  // copy formatted text in to clipboard
  function copyIntoClipboard(formattedText) {
    // Commented due to known issues in non-chrome browsers and also internal copy issue between diagrams in chrome
    /*if ("clipboard" in navigator && typeof navigator.clipboard.write === "function" && window.ClipboardItem) {
              const blobInput = new Blob([formattedText], {type: 'text/html'});
              const clipboardItemInputAsync = new window.ClipboardItem({'text/html': new Promise(async (resolve) => {resolve(blobInput)})});
              const clipboardItemInput = new window.ClipboardItem({'text/html': blobInput});
              try {
                navigator.clipboard.write([clipboardItemInputAsync]);
              } catch (error) {
                navigator.clipboard.write([clipboardItemInput]);
              }
            }*/

    document.designMode = "on";
    var tmpElem = document.createElement("div");
    tmpElem.innerHTML = "";
    tmpElem.style.opacity = 0;
    document.body.appendChild(tmpElem);
    tmpElem.innerHTML = formattedText;

    let selection = window.getSelection();
    selection.removeAllRanges();

    let range = document.createRange();
    range.selectNodeContents(tmpElem);
    selection.addRange(range);
    document.execCommand("copy");

    document.body.removeChild(tmpElem);
    document.designMode = "off";
  }

  // create element represent token's color
  function makeFormattedToken(token) {
    if (!token) return;
    // let text = token.value.replaceAll(" ", "&nbsp;");
    let text = token.value;
    let fontColor = null;
    switch (token.type) {
      case "keyword":
      case "statement":
        fontColor = "#5200FF";
        break;
      case "constant.numeric":
      case "numeric":
        fontColor = "#FF185E";
        break;
      case "storage":
      case "storage.type":
        fontColor = "#11B7BE";
        break;
      case "support.function":
        fontColor = "#006FBF";
        break;
      case "keyword.operator":
      case "punctuation":
      case "paren.lparen":
      case "paren.rparen":
        fontColor = "#808080";
        break;
      case "invisible":
        fontColor = "#62838f";
        break;
      case "constant.buildin":
      case "constant.library":
      case "support.constant":
        fontColor = "#5848f6";
        break;
      case "constant.language":
        fontColor = "#979797";
        break;
      case "comment":
        fontColor = "#19B0BB";
        break;
      case "string":
      case "string.regex":
        fontColor = "#FF0000";
        break;
      case "variable":
        fontColor = "#318495";
        break;
      case "support.storedprocedure":
        fontColor = "#800000";
        break;
      default:
        break;
    }

    if (fontColor) {
      return `<span style="color:` + fontColor + `;">` + text + `</span>`;
    } else {
      return text;
    }
  }
};

export const pyodideLoading = async () => {
  if (!window.Pyodide) {
    batch(() => {
      store.dispatch(setSDKState("in-progress"));
      store.dispatch(showLoadingMask());
    });
    let loadPyodide = await window.loadPyodide({ fullStdLib: false });
    await loadPyodide.loadPackage(["micropip"]);
    await loadPyodide.pyimport("micropip").install(["requests", url]); // flowhigh sdk dependency

    window.Pyodide = loadPyodide;
    batch(() => {
      store.dispatch(setSDKState("break"));
      store.dispatch(hideLoadingMask());
    });
  }
};

export const getPath = (tabName, diagramBoxState, isAuthenticated) => {
  let newPath = "";
  switch (tabName) {
    case "My Profile":
      newPath = "/userprofile";
      break;
    case "Account Settings":
      newPath = "/accountsettings";
      break;
    case "About":
      newPath = "/about";
      break;
    case "SDK Downloads":
      newPath = "/sdk-workflow";
      break;

    case "Survey":
      if (isAuthenticated) {
        newPath = "/Survey-sql";
      } else {
        newPath = "/visualise-sql";
      }
      break;

    default:
      newPath = "/" + diagramBoxState + "-sql";
      break;
  }
  return newPath;
};

export const profilePicture = (firstName, lastName) => {
  let i = 0;
  let j = 0;
  while (i < firstName.length) {
    if (firstName[i] !== " ") {
      break;
    } else {
      i++;
    }
  }

  while (j < lastName.length) {
    if (lastName[j] !== " ") {
      break;
    } else {
      j++;
    }
  }

  const fName = firstName?.[i]?.toUpperCase();
  const lName = lastName?.[j]?.toUpperCase();
  const cName = fName + lName;
  return cName ?? "";
};
