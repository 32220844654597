enum GlobalActionTypes {
  SHOW_LOADING_MASK = "SHOW_LOADING_MASK",
  SET_PRODUCE_DIAGRAM_FLAG = "SET_PRODUCE_DIAGRAM_FLAG",
  SET_SAMPLE_BUTTON_CLICKED = "SET_SAMPLE_BUTTON_CLICKED",
  SET_SAMPLE_SQL_DATA = "SET_SAMPLE_SQL_DATA",
  SET_LOGIN_STATE = "SET_LOGIN_STATE",
  SET_SIGNUP_STATE = "SET_SIGNUP_STATE",
  SET_RESET_PASSWORD_STATE = "SET_RESET_PASSWORD_STATE",
  SHOW_SURVEY_MODAL = "SHOW_SURVEY_MODAL",
  SET_ANALYZE_DATA = "SET_ANALYZE_DATA",
  SET_ANIMATION = "SET_ANIMATION",
  SET_SEARCH_TEXT = "SET_SEARCH_TEXT",
  SET_SEARCH_TAB = "SET_SEARCH_TAB",
  SET_CLICK_SEARCH = "SET_CLICK_SEARCH",
  SHOW_SDK_MODAL = "SHOW_SDK_MODAL",
  SET_REPORTS_DATA = "SET_REPORTS_DATA",
  SET_SEARCH_BOX_OPEN = "SET_SEARCH_BOX_OPEN",
  SET_GLOBAL_MODAL_ID = "SET_GLOBAL_MODAL_ID",
  SET_ANITPATTERN_DATA = "SET_ANTIPATTERN_DATA",
}

export default GlobalActionTypes;
