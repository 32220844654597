import propertyActionTypes from "../actionTypes/property";
import { AnyAction } from "redux";
import { LayoutStore } from "./layout";
import { components } from "../../generated-types/api";
import sampleJsonData from "../../assets/data/sample.json";
import { Merge, OverrideProperties } from "type-fest";

export type Node = NonNullable<Diagram["diagramData"]["nodes"]>[number];
export type Edge = NonNullable<Diagram["diagramData"]["edges"]>[number];
export interface HighlightIDs {
  [id: string]: string[];
}

export interface Diagram {
  isSampleTab: boolean;
  sqlData: string;
  diagramData: OverrideProperties<
    components["schemas"]["DiagramOutput"],
    {
      edges?: Merge<
        components["schemas"]["DiagramEdge"],
        {
          hidden?: boolean;
          stream_expanded?: boolean;
        }
      >[];
      nodes?: Merge<
        components["schemas"]["DiagramNode"],
        {
          columns?: string[];
          hidden?: boolean;
          stream_expanded?: boolean;
        }
      >[];
    }
  >;
  formatData: components["schemas"]["ParSeQLOutput"] | {};
  parseData: components["schemas"]["ParSeQLOutput"] | {};
  optimiseData: components["schemas"]["ParSeQLOutput"] | {};
  SDKData: components["schemas"]["ParSeQLOutput"] | {};
  visualiseState: "" | "start" | "in-progress" | "success" | "fail";
  parseState: string;
  optimiseState: string;
  SDKState: string;
  formatState: string;
  alignment: string;
  direction: string;
  expandNode: boolean;
  edgeSpan: number;
  nodeSpan: number;
  layerSpan: number;
  symbolWidth: number;
  titlePaddingWidth: number;
  defaultHeight: number;
  expandedRowHeight: number;
  operatorRadius: number;
  iconBufferSpace: number;
  maxColumnRender: number;
  maxTextSizeRender: number;
  expandIDs: string[];
  sampleTabExpandIDS?: {
    [tab: string]: string[];
  };
  transform: {
    k: number;
    x: number;
    y: number;
  };
  order: number;
  cursorPosition?: {
    column: number;
    row: number;
  };
  SDKActiveTab: string;
  SDKTabData: {
    Message: string;
    DDL: string;
    Antipattern: string;
    TableList: string;
    Format: string;
    Join?: string;
    SortGroup?: string;
    Filter?: string;
  };
  streamID: {
    [id: string]: Node;
  }[];
  isHighlighted?: string;
  APITimeOut: {
    show: boolean;
    data: string;
  };
  propertyBox: boolean;
  activeTab: string;
  currentEndPoint: LayoutStore["diagramBoxState"];
  optionMargin?: "compact" | "comfortable" | "balanced";
  searchNodes: {
    height: number;
    width: number;
    x: number;
    y: number;
  }[];
  displayedAntiPattern?: {
    pos: string;
    count: number;
    eltype: "antipattern" | "error";
    selectedPatternIndex: number;
    lineIndex: number;
    /**
     * The range to highlight when clicking on this antipattern. A plain object veresion of Ace.Range
     */
    range: {
      end: {
        column: number;
        row: number;
      };
      start: {
        column: number;
        row: number;
      };
    };
  };
  isShowAntiPattern: boolean;
  selectedRange: {
    end: {
      column: number;
      row: number;
    };
    start: {
      column: number;
      row: number;
    };
  };
}

export interface PropertyStore {
  user: string;
  userLayout: {
    [user: string]: {
      updateFlag: boolean;
      showColumnState: "showAll" | "hideAll";
      isShowColumnButtonClicked: boolean;
      sampleTab: "Simple" | "Regular" | "Complex";
      highlightIDs: HighlightIDs;
      recentStack: string[];
      currentDiagram: string;
      dynamicNotifyText: {
        // diagramName can also be 'global'
        [diagramName: string]: null | string;
      };
      queryModified: {
        [diagramName: string]: boolean;
      };
      lastActiveTab: string;
      recentSearch: string[];
      formatAndParse: {
        [diagramName: string]: number;
      };
      isQueryOptimized: {
        [diagramName: string]: boolean;
      };
      formatResponse: string[];
      preCheckData?: string;
      diagramList: {
        [diagramName: string]:
          | (Partial<Diagram> & { special: true })
          | (Diagram & { special: boolean });
      };
      disabledRemindModals: string[];
      tabsModal: {
        [diagramName: string]:
          | "antipattern"
          | "data-lineage"
          | "feedback"
          | "post-registration"
          | "reset-password"
          | "try"
          | "tuning"
          | "unsupported-browser"
          | "unsupported-resolution"
          | "";
      };
    };
  };
}
const initialState: PropertyStore = {
  user: "sample",
  userLayout: {
    sample: {
      updateFlag: false, // decide to redraw / update Diagram
      showColumnState: "hideAll", // command to show/hide column for all nodes (showAll | hideAll)
      isShowColumnButtonClicked: false,
      sampleTab: "Regular",
      highlightIDs: {}, // IDs to highlight
      recentStack: ["Sample SQL"], // recent stack for diagram list
      diagramList: {
        "Sample SQL": {
          isSampleTab: true,
          special: false, // flag if this is a special tab(none diagram)
          sqlData: sampleJsonData["Regular"].sql.join("\n"), // initialize sql data
          diagramData: {
            nodes: [],
            edges: [],
            height: 0,
            width: 0,
          }, // diagram data
          formatData: {}, // format data
          parseData: {}, // parsed data
          optimiseData: {}, // optimise data
          SDKData: {}, // optimise data
          visualiseState: "start", // flag for visualised
          parseState: "start", // flag for parsed
          optimiseState: "start", // flag for optimise
          SDKState: "start",
          formatState: "start", // flag for formatted
          alignment: "UL", // node's alignment
          direction: "TB", // node's direction
          expandNode: false, // node's expand
          edgeSpan: 5, // gap of edge
          nodeSpan: 40, // gap of nodes
          layerSpan: 60, // gap of layer
          symbolWidth: 10, // symbol width
          titlePaddingWidth: 60, // paddig width of title
          defaultHeight: 40, // height of node
          expandedRowHeight: 25, // row height when node is expanded
          operatorRadius: 15, // radius of operator
          iconBufferSpace: 6, // extra space for node width
          maxColumnRender: 10, // max number of columns to use for calculating of node height
          maxTextSizeRender: 30, // max number of characters to use for calculating of node width
          expandIDs: [], // IDs to show columns
          transform: { k: 1, x: 0, y: 0 }, // main diagram's transform
          order: 1,
          cursorPosition: { row: 0, column: 0 },
          SDKActiveTab: "Message",
          SDKTabData: {
            Message: "",
            DDL: "",
            Antipattern: "",
            TableList: "",
            Format: "",
          },
          streamID: [],
          isHighlighted: "",
          APITimeOut: { show: false, data: "API is not available" },
          propertyBox: false,
          activeTab: "property",
          currentEndPoint: "visualise",
          optionMargin: "compact",
          searchNodes: [],
          displayedAntiPattern: undefined, // one object in form of {pos : "", count : 0, eltype: 'antipattern', selectedPatternIndex: 0, lineIndex: 0} per diagram
          isShowAntiPattern: false, // one boolean flag per diagram
          selectedRange: {
            end: {
              column: 0,
              row: 0,
            },
            start: {
              column: 0,
              row: 0,
            },
          },
        },
      },
      currentDiagram: "Sample SQL",
      dynamicNotifyText: {}, // one text per diagram
      queryModified: {}, // one boolean flag per diagram
      lastActiveTab: "Sample SQL",
      recentSearch: [],
      formatAndParse: {},
      isQueryOptimized: {}, // check either query is optimized before page refreshing also it is tab based
      formatResponse: [], // set format response
      preCheckData: "",
      disabledRemindModals: [],
      tabsModal: {},
    },
  },
};

export default function propertyReducer(
  state: PropertyStore = initialState,
  action: AnyAction
): PropertyStore {
  let userLayout = state.userLayout[state.user];
  switch (action.type) {
    case propertyActionTypes.PROPERTY_RESET:
      return {
        ...state,
        userLayout: {
          ...state.userLayout,
          [state.user]: {
            ...userLayout,
            diagramList: {
              ...userLayout.diagramList,
              [userLayout.currentDiagram]: {
                ...userLayout.diagramList[userLayout.currentDiagram],
                edgeSpan: 5,
                nodeSpan: 40,
                layerSpan: 60,
                symbolWidth: 10,
                titlePaddingWidth: 60,
                defaultHeight: 40,
                expandedRowHeight: 25,
                operatorRadius: 15,
                iconBufferSpace: 6,
                maxColumnRender: 10,
                maxTextSizeRender: 30,
              },
            },
          },
        },
      };

    case propertyActionTypes.PROPERTY_UPDATE_FLAG:
      return {
        ...state,
        userLayout: {
          ...state.userLayout,
          [state.user]: {
            ...userLayout,
            updateFlag: action.payload,
          },
        },
      };

    case propertyActionTypes.PROPERTY_ALIGNMENT:
      return {
        ...state,
        userLayout: {
          ...state.userLayout,
          [state.user]: {
            ...userLayout,
            diagramList: {
              ...userLayout.diagramList,
              [userLayout.currentDiagram]: {
                ...userLayout.diagramList[userLayout.currentDiagram],
                alignment: action.payload,
              },
            },
          },
        },
      };

    case propertyActionTypes.PROPERTY_DIRECTION:
      return {
        ...state,
        userLayout: {
          ...state.userLayout,
          [state.user]: {
            ...userLayout,
            diagramList: {
              ...userLayout.diagramList,
              [userLayout.currentDiagram]: {
                ...userLayout.diagramList[userLayout.currentDiagram],
                direction: action.payload,
              },
            },
          },
        },
      };

    case propertyActionTypes.PROPERTY_EXPAND_NODE:
      return {
        ...state,
        userLayout: {
          ...state.userLayout,
          [state.user]: {
            ...userLayout,
            diagramList: {
              ...userLayout.diagramList,
              [userLayout.currentDiagram]: {
                ...userLayout.diagramList[userLayout.currentDiagram],
                expandNode: action.payload,
              },
            },
          },
        },
      };

    case propertyActionTypes.PROPERTY_EDGE_SPAN:
      return {
        ...state,
        userLayout: {
          ...state.userLayout,
          [state.user]: {
            ...userLayout,
            diagramList: {
              ...userLayout.diagramList,
              [userLayout.currentDiagram]: {
                ...userLayout.diagramList[userLayout.currentDiagram],
                edgeSpan: action.payload,
              },
            },
          },
        },
      };

    case propertyActionTypes.PROPERTY_NODE_SPAN:
      return {
        ...state,
        userLayout: {
          ...state.userLayout,
          [state.user]: {
            ...userLayout,
            diagramList: {
              ...userLayout.diagramList,
              [userLayout.currentDiagram]: {
                ...userLayout.diagramList[userLayout.currentDiagram],
                nodeSpan: action.payload,
              },
            },
          },
        },
      };

    case propertyActionTypes.PROPERTY_LAYER_SPAN:
      return {
        ...state,
        userLayout: {
          ...state.userLayout,
          [state.user]: {
            ...userLayout,
            diagramList: {
              ...userLayout.diagramList,
              [userLayout.currentDiagram]: {
                ...userLayout.diagramList[userLayout.currentDiagram],
                layerSpan: action.payload,
              },
            },
          },
        },
      };

    case propertyActionTypes.PROPERTY_SYMBOL_WIDTH:
      return {
        ...state,
        userLayout: {
          ...state.userLayout,
          [state.user]: {
            ...userLayout,
            diagramList: {
              ...userLayout.diagramList,
              [userLayout.currentDiagram]: {
                ...userLayout.diagramList[userLayout.currentDiagram],
                symbolWidth: action.payload,
              },
            },
          },
        },
      };

    case propertyActionTypes.PROPERTY_TITLE_PADDING_WIDTH:
      return {
        ...state,
        userLayout: {
          ...state.userLayout,
          [state.user]: {
            ...userLayout,
            diagramList: {
              ...userLayout.diagramList,
              [userLayout.currentDiagram]: {
                ...userLayout.diagramList[userLayout.currentDiagram],
                titlePaddingWidth: action.payload,
              },
            },
          },
        },
      };

    case propertyActionTypes.PROPERTY_DEFAULT_HEIGHT:
      return {
        ...state,
        userLayout: {
          ...state.userLayout,
          [state.user]: {
            ...userLayout,
            diagramList: {
              ...userLayout.diagramList,
              [userLayout.currentDiagram]: {
                ...userLayout.diagramList[userLayout.currentDiagram],
                defaultHeight: action.payload,
              },
            },
          },
        },
      };

    case propertyActionTypes.PROPERTY_EXPANDED_ROW_HEIGHT:
      return {
        ...state,
        userLayout: {
          ...state.userLayout,
          [state.user]: {
            ...userLayout,
            diagramList: {
              ...userLayout.diagramList,
              [userLayout.currentDiagram]: {
                ...userLayout.diagramList[userLayout.currentDiagram],
                expandedRowHeight: action.payload,
              },
            },
          },
        },
      };

    case propertyActionTypes.PROPERTY_OPERATOR_RADIUS:
      return {
        ...state,
        userLayout: {
          ...state.userLayout,
          [state.user]: {
            ...userLayout,
            diagramList: {
              ...userLayout.diagramList,
              [userLayout.currentDiagram]: {
                ...userLayout.diagramList[userLayout.currentDiagram],
                operatorRadius: action.payload,
              },
            },
          },
        },
      };

    case propertyActionTypes.PROPERTY_ICON_BUFFER_SPACE:
      return {
        ...state,
        userLayout: {
          ...state.userLayout,
          [state.user]: {
            ...userLayout,
            diagramList: {
              ...userLayout.diagramList,
              [userLayout.currentDiagram]: {
                ...userLayout.diagramList[userLayout.currentDiagram],
                iconBufferSpace: action.payload,
              },
            },
          },
        },
      };

    case propertyActionTypes.PROPERTY_MAX_COLUMN_RENDER:
      return {
        ...state,
        userLayout: {
          ...state.userLayout,
          [state.user]: {
            ...userLayout,
            diagramList: {
              ...userLayout.diagramList,
              [userLayout.currentDiagram]: {
                ...userLayout.diagramList[userLayout.currentDiagram],
                maxColumnRender: action.payload,
              },
            },
          },
        },
      };

    case propertyActionTypes.PROPERTY_MAX_TEXT_SIZE_RENDER:
      return {
        ...state,
        userLayout: {
          ...state.userLayout,
          [state.user]: {
            ...userLayout,
            diagramList: {
              ...userLayout.diagramList,
              [userLayout.currentDiagram]: {
                ...userLayout.diagramList[userLayout.currentDiagram],
                maxColumnRender: action.payload,
              },
            },
          },
        },
      };

    case propertyActionTypes.IS_HIGHLIGHTED:
      return {
        ...state,
        userLayout: {
          ...state.userLayout,
          [state.user]: {
            ...userLayout,
            diagramList: {
              ...userLayout.diagramList,
              [userLayout.currentDiagram]: {
                ...userLayout.diagramList[userLayout.currentDiagram],
                isHighlighted: action.payload,
              },
            },
          },
        },
      };

    case propertyActionTypes.SEARCH_NODES:
      return {
        ...state,
        userLayout: {
          ...state.userLayout,
          [state.user]: {
            ...userLayout,
            diagramList: {
              ...userLayout.diagramList,
              [userLayout.currentDiagram]: {
                ...userLayout.diagramList[userLayout.currentDiagram],
                searchNodes: action.payload,
              },
            },
          },
        },
      };

    case propertyActionTypes.PROPERTY_EXPAND_IDS:
      if (userLayout.diagramList[userLayout.currentDiagram]?.isSampleTab) {
        return {
          ...state,
          userLayout: {
            ...state.userLayout,
            [state.user]: {
              ...userLayout,
              diagramList: {
                ...userLayout.diagramList,
                [userLayout.currentDiagram]: {
                  ...userLayout.diagramList[userLayout.currentDiagram],
                  sampleTabExpandIDS: {
                    ...userLayout?.diagramList?.[userLayout?.currentDiagram]
                      ?.sampleTabExpandIDS,
                    [userLayout?.sampleTab]: action.payload,
                  },
                },
              },
            },
          },
        };
      }
      return {
        ...state,
        userLayout: {
          ...state.userLayout,
          [state.user]: {
            ...userLayout,
            diagramList: {
              ...userLayout.diagramList,
              [userLayout.currentDiagram]: {
                ...userLayout.diagramList[userLayout.currentDiagram],
                expandIDs: action.payload,
              },
            },
          },
        },
      };

    case propertyActionTypes.PROPERTY_SQL_DATA:
      return {
        ...state,
        userLayout: {
          ...state.userLayout,
          [state.user]: {
            ...userLayout,
            diagramList: {
              ...userLayout.diagramList,
              [userLayout.currentDiagram]: {
                ...userLayout.diagramList[userLayout.currentDiagram],
                sqlData: action.payload,
                expandIDs: [],
                visualiseState: "start",
                parseState: "start",
                optimiseState: "start",
                SDKState: "start",
                formatState: "start",
              },
            },
          },
        },
      };

    case propertyActionTypes.PROPERTY_SHOW_COLUMN_ACTION:
      return {
        ...state,
        userLayout: {
          ...state.userLayout,
          [state.user]: {
            ...userLayout,
            showColumnState: action.payload,
          },
        },
      };

    case propertyActionTypes.PROPERTY_SHOW_COLUMN_BUTTON_CLICKED:
      return {
        ...state,
        userLayout: {
          ...state.userLayout,
          [state.user]: {
            ...userLayout,
            isShowColumnButtonClicked: !userLayout.isShowColumnButtonClicked,
          },
        },
      };

    case propertyActionTypes.HIGHLIGHT_IDS:
      const highlightIdsNodes = Object.keys(action.payload);
      if (highlightIdsNodes.length) {
        highlightIdsNodes?.forEach((key) => {
          if (userLayout?.highlightIDs?.[key]) {
            delete userLayout?.highlightIDs?.[key];
          } else {
            userLayout = {
              ...userLayout,
              highlightIDs: {
                ...userLayout?.highlightIDs,
                [key]: action?.payload?.[key],
              },
            };
          }
        });
      } else {
        userLayout = {
          ...userLayout,
          highlightIDs: {},
        };
      }
      return {
        ...state,
        userLayout: {
          ...state.userLayout,
          [state.user]: {
            ...userLayout,
          },
        },
      };

    case propertyActionTypes.TRANSFORM:
      return {
        ...state,
        userLayout: {
          ...state.userLayout,
          [state.user]: {
            ...userLayout,
            diagramList: {
              ...userLayout.diagramList,
              [userLayout.currentDiagram]: {
                ...userLayout.diagramList[userLayout.currentDiagram],
                transform: action.payload,
              },
            },
          },
        },
      };

    case propertyActionTypes.ACTIVATE_DIAGRAM:
      for (let i = 0; i < userLayout.recentStack.length; i++) {
        if (userLayout.recentStack[i] === action.payload) {
          userLayout.recentStack.splice(i, 1);
          break;
        }
      }
      userLayout.recentStack.push(action.payload);
      return {
        ...state,
        userLayout: {
          ...state.userLayout,
          [state.user]: {
            ...userLayout,
            currentDiagram: action.payload,
            updateFlag: false,
          },
        },
      };

    case propertyActionTypes.RENAME_DIAGRAM:
      let tmpDiagramList = JSON.parse(JSON.stringify(userLayout.diagramList));
      let newDiagramList: PropertyStore["userLayout"][string]["diagramList"] =
        {};
      Object.keys(tmpDiagramList).forEach((diagramKey) => {
        if (diagramKey === action.payload.name) {
          newDiagramList[action.payload.newName] =
            tmpDiagramList[action.payload.name];
        } else {
          newDiagramList[diagramKey] = tmpDiagramList[diagramKey];
        }
      });

      let tmpRecentStack = userLayout.recentStack;
      tmpRecentStack = tmpRecentStack.map((el) => {
        if (el === action.payload.name) {
          return action.payload.newName;
        } else {
          return el;
        }
      });

      return {
        ...state,
        userLayout: {
          ...state.userLayout,
          [state.user]: {
            ...userLayout,
            recentStack: tmpRecentStack,
            diagramList: newDiagramList,
            currentDiagram: action.payload.newName,
          },
        },
      };

    case propertyActionTypes.NEW_DIAGRAM:
      const tmpDiagrams = JSON.parse(JSON.stringify(userLayout.diagramList));
      const diagramKeys = Object.keys(tmpDiagrams);
      const lastOrder = tmpDiagrams[diagramKeys[diagramKeys.length - 1]]?.order;

      return {
        ...state,
        userLayout: {
          ...state.userLayout,
          [state.user]: {
            ...userLayout,
            diagramList: {
              ...userLayout.diagramList,
              [action.payload.newDiagram]: {
                special: false,
                sqlData: "",
                diagramData: {},
                parseData: {},
                optimiseData: {},
                formatData: {}, // format data
                SDKData: {},
                visualiseState: "start",
                parseState: "start",
                optimiseState: "start",
                SDKState: "start",
                formatState: "start",
                alignment: "UL",
                direction: "TB",
                expandNode: false,
                edgeSpan: 5,
                nodeSpan: 40,
                layerSpan: 60,
                symbolWidth: 10,
                titlePaddingWidth: 60,
                defaultHeight: 40,
                expandedRowHeight: 25,
                operatorRadius: 15,
                iconBufferSpace: 6,
                maxColumnRender: 10,
                maxTextSizeRender: 30,
                expandIDs: [],
                transform: { k: 1, x: 0, y: 0 },
                order: lastOrder + 1,
                cursorPosition: { row: 0, column: 0 },
                SDKActiveTab: "Message",
                SDKTabData: {
                  Message: "",
                  DDL: "",
                  Antipattern: "",
                  TableList: "",
                  Format: "",
                },
                streamID: [],
                isHighlighted: "",
                searchNodes: [],
                APITimeOut: {
                  show: false,
                  data: "",
                },
                propertyBox: false,
                activeTab: "property",
                currentTab: "",
                currentEndPoint: action.payload.endPoint,
                optionMargin: "compact",
                displayedAntiPattern: {},
                isShowAntiPattern: false,
              },
            },
            recentStack: [...userLayout.recentStack, action.payload.newDiagram],
            currentDiagram: action.payload.newDiagram,
            recentSearch: userLayout.recentSearch,
          },
        },
      };

    case propertyActionTypes.NEW_TAB:
      userLayout.diagramList[action.payload] = {
        isSampleTab: false,
        special: true,
      };
      userLayout.recentStack.push(action.payload);

      return {
        ...state,
        userLayout: {
          ...state.userLayout,
          [state.user]: {
            ...userLayout,
            currentDiagram: action.payload,
          },
        },
      };

    case propertyActionTypes.SET_RECENT_SEARCH:
      const updatedRecentSearch = [
        action.payload,
        ...userLayout.recentSearch,
      ].slice(0, 6);
      const NewUserLayout = {
        ...state.userLayout,
        [state.user]: {
          ...userLayout,
          recentSearch: updatedRecentSearch,
        },
      };
      return {
        ...state,
        userLayout: NewUserLayout,
      };

    case propertyActionTypes.REMOVE_DIAGRAM:
      delete userLayout.diagramList[action.payload];
      const stack = userLayout.recentStack.filter(
        (item) => item !== action.payload
      );
      return {
        ...state,
        userLayout: {
          ...state.userLayout,
          [state.user]: {
            ...userLayout,
            recentStack: stack,
            currentDiagram: stack[stack.length - 1],
          },
        },
      };

    case propertyActionTypes.DIAGRM_ORDER_SET:
      let tmpDiagramsForOrder = JSON.parse(
        JSON.stringify(userLayout.diagramList)
      );

      Object.keys(tmpDiagramsForOrder).forEach((diagramKey, index) => {
        if (!tmpDiagramsForOrder[diagramKey].order) {
          if (index === 0) {
            tmpDiagramsForOrder[diagramKey].order = 1;
          } else {
            tmpDiagramsForOrder[diagramKey].order =
              tmpDiagramsForOrder[Object.keys(tmpDiagramsForOrder)[index - 1]]
                ?.order + 1;
          }
        }
      });
      return {
        ...state,
        userLayout: {
          ...state.userLayout,
          [state.user]: {
            ...userLayout,
            diagramList: tmpDiagramsForOrder,
          },
        },
      };

    case propertyActionTypes.PROPERTY_DIAGRAM_DATA:
      let currentDiagram = action.payload.currentDiagram;
      let response = action.payload.response;
      if (response?.nodes) {
        response.nodes
          .filter((n: Node) => n.type === "dataset" && !n.columns?.length)
          .forEach((node: Node) => {
            node.columns = ["*"];
          });
      }
      //  Object.keys(action.payload).length > 0 ? 'success' : 'fail';
      return {
        ...state,
        userLayout: {
          ...state.userLayout,
          [state.user]: {
            ...userLayout,
            diagramList: {
              ...userLayout.diagramList,
              [currentDiagram]: {
                ...userLayout.diagramList[currentDiagram],
                diagramData: response,
                visualiseState: "",
              },
            },
          },
        },
      };

    case propertyActionTypes.SET_VISUALISE_STATE:
      return {
        ...state,
        userLayout: {
          ...state.userLayout,
          [state.user]: {
            ...userLayout,
            diagramList: {
              ...userLayout.diagramList,
              [userLayout.currentDiagram]: {
                ...userLayout.diagramList[userLayout.currentDiagram],
                visualiseState: action.payload,
              },
            },
          },
        },
      };

    case propertyActionTypes.SET_CURSOR_POSITION:
      return {
        ...state,
        userLayout: {
          ...state.userLayout,
          [state.user]: {
            ...userLayout,
            diagramList: {
              ...userLayout.diagramList,
              [userLayout.currentDiagram]: {
                ...userLayout.diagramList[userLayout.currentDiagram],
                cursorPosition: action.payload,
              },
            },
          },
        },
      };

    case propertyActionTypes.PROPERTY_PARSE_DATA:
      return {
        ...state,
        userLayout: {
          ...state.userLayout,
          [state.user]: {
            ...userLayout,
            diagramList: {
              ...userLayout.diagramList,
              [userLayout.currentDiagram]: {
                ...userLayout.diagramList[userLayout.currentDiagram],
                parseState: "",
                parseData: action.payload,
              },
            },
          },
        },
      };

    case propertyActionTypes.SET_PARSE_STATE:
      return {
        ...state,
        userLayout: {
          ...state.userLayout,
          [state.user]: {
            ...userLayout,
            diagramList: {
              ...userLayout.diagramList,
              [userLayout.currentDiagram]: {
                ...userLayout.diagramList[userLayout.currentDiagram],
                parseState: action.payload,
              },
            },
          },
        },
      };

    case propertyActionTypes.PROPERTY_OPTIMISE_DATA:
      return {
        ...state,
        userLayout: {
          ...state.userLayout,
          [state.user]: {
            ...userLayout,
            diagramList: {
              ...userLayout.diagramList,
              [userLayout.currentDiagram]: {
                ...userLayout.diagramList[userLayout.currentDiagram],
                optimiseData: action.payload,
                isShowAntiPattern: true,
                optimiseState: "",
              },
            },
          },
        },
      };

    case propertyActionTypes.SDK_ACTIVE_TAB:
      return {
        ...state,
        userLayout: {
          ...state.userLayout,
          [state.user]: {
            ...userLayout,
            diagramList: {
              ...userLayout.diagramList,
              [userLayout.currentDiagram]: {
                ...userLayout.diagramList[userLayout.currentDiagram],
                SDKActiveTab: action.payload,
              },
            },
          },
        },
      };

    case propertyActionTypes.CURRENT_STATE:
      return {
        ...state,
        userLayout: {
          ...state.userLayout,
          [state.user]: {
            ...userLayout,
            diagramList: {
              ...userLayout.diagramList,
              [userLayout.currentDiagram]: {
                ...userLayout.diagramList[userLayout.currentDiagram],
                currentEndPoint: action.payload,
              },
            },
          },
        },
      };
    case propertyActionTypes.MARGIN_OPTION:
      return {
        ...state,
        userLayout: {
          ...state.userLayout,
          [state.user]: {
            ...userLayout,
            diagramList: {
              ...userLayout.diagramList,
              [userLayout.currentDiagram]: {
                ...userLayout.diagramList[userLayout.currentDiagram],
                optionMargin: action.payload,
              },
            },
          },
        },
      };

    case propertyActionTypes.PROPERTY_SDK_DATA:
      return {
        ...state,
        userLayout: {
          ...state.userLayout,
          [state.user]: {
            ...userLayout,
            diagramList: {
              ...userLayout.diagramList,
              [userLayout.currentDiagram]: {
                ...userLayout.diagramList[userLayout.currentDiagram],
                SDKData: action.payload,
                SDKState: "",
                // isShowAntiPattern: action.payload,
              },
            },
          },
        },
      };
    case propertyActionTypes.SET_OPTIMISE_STATE:
      return {
        ...state,
        userLayout: {
          ...state.userLayout,
          [state.user]: {
            ...userLayout,
            diagramList: {
              ...userLayout.diagramList,
              [userLayout.currentDiagram]: {
                ...userLayout.diagramList[userLayout.currentDiagram],
                optimiseState: action.payload,
              },
            },
          },
        },
      };

    case propertyActionTypes.FORMAT_DATA:
      return {
        ...state,
        userLayout: {
          ...state.userLayout,
          [state.user]: {
            ...userLayout,
            diagramList: {
              ...userLayout.diagramList,
              [userLayout.currentDiagram]: {
                ...userLayout.diagramList[userLayout.currentDiagram],
                formatData: action.payload,
              },
            },
          },
        },
      };

    case propertyActionTypes.SET_SDK_STATE:
      return {
        ...state,
        userLayout: {
          ...state.userLayout,
          [state.user]: {
            ...userLayout,
            diagramList: {
              ...userLayout.diagramList,
              [userLayout.currentDiagram]: {
                ...userLayout.diagramList[userLayout.currentDiagram],
                SDKState: action.payload,
              },
            },
          },
        },
      };

    case propertyActionTypes.SET_IS_FORMATTED:
      return {
        ...state,
        userLayout: {
          ...state.userLayout,
          [state.user]: {
            ...userLayout,
            diagramList: {
              ...userLayout.diagramList,
              [userLayout.currentDiagram]: {
                ...userLayout.diagramList[userLayout.currentDiagram],
                formatState: action.payload,
              },
            },
          },
        },
      };

    case propertyActionTypes.SET_SDK_MESSAGE:
      let newSDKTabDataForMessage: Diagram["SDKTabData"] = {
        Message: "",
        DDL: "",
        Antipattern: "",
        TableList: "",
        Format: "",
      };
      const currentSDKTabDataForMessage =
        userLayout.diagramList[userLayout.currentDiagram].SDKTabData;

      if (currentSDKTabDataForMessage) {
        newSDKTabDataForMessage = { ...currentSDKTabDataForMessage };
      }

      return {
        ...state,
        userLayout: {
          ...state.userLayout,
          [state.user]: {
            ...userLayout,
            diagramList: {
              ...userLayout.diagramList,
              [userLayout.currentDiagram]: {
                ...userLayout.diagramList[userLayout.currentDiagram],
                SDKTabData: {
                  ...newSDKTabDataForMessage,
                  Message: action.payload,
                },
              },
            },
          },
        },
      };

    case propertyActionTypes.SET_SDK_JOIN:
      let newSDKTabDataForJoin: Diagram["SDKTabData"] = {
        Message: "",
        DDL: "",
        Antipattern: "",
        TableList: "",
        Format: "",
      };
      const currentSDKTabDataForJoin =
        userLayout.diagramList[userLayout.currentDiagram].SDKTabData;

      if (currentSDKTabDataForJoin) {
        newSDKTabDataForJoin = { ...currentSDKTabDataForJoin };
      }

      return {
        ...state,
        userLayout: {
          ...state.userLayout,
          [state.user]: {
            ...userLayout,
            diagramList: {
              ...userLayout.diagramList,
              [userLayout.currentDiagram]: {
                ...userLayout.diagramList[userLayout.currentDiagram],
                SDKTabData: {
                  ...newSDKTabDataForJoin,
                  Join: action.payload,
                },
              },
            },
          },
        },
      };

    case propertyActionTypes.SET_SDK_SORT_AND_GROUP:
      let newSDKTabDataForSort: Diagram["SDKTabData"] = {
        Message: "",
        DDL: "",
        Antipattern: "",
        TableList: "",
        Format: "",
      };
      const currentSDKTabDataForSort =
        userLayout.diagramList[userLayout.currentDiagram].SDKTabData;

      if (currentSDKTabDataForSort) {
        newSDKTabDataForSort = { ...currentSDKTabDataForSort };
      }

      return {
        ...state,
        userLayout: {
          ...state.userLayout,
          [state.user]: {
            ...userLayout,
            diagramList: {
              ...userLayout.diagramList,
              [userLayout.currentDiagram]: {
                ...userLayout.diagramList[userLayout.currentDiagram],
                SDKTabData: {
                  ...newSDKTabDataForSort,
                  SortGroup: action.payload,
                },
              },
            },
          },
        },
      };

    case propertyActionTypes.SET_SDK_DDL:
      let newSDKTabDataForDDL: Diagram["SDKTabData"] = {
        Message: "",
        DDL: "",
        Antipattern: "",
        TableList: "",
        Format: "",
      };
      const currentSDKTabDataForDDL =
        userLayout.diagramList[userLayout.currentDiagram].SDKTabData;

      if (currentSDKTabDataForDDL) {
        newSDKTabDataForDDL = { ...currentSDKTabDataForDDL };
      }

      return {
        ...state,
        userLayout: {
          ...state.userLayout,
          [state.user]: {
            ...userLayout,
            diagramList: {
              ...userLayout.diagramList,
              [userLayout.currentDiagram]: {
                ...userLayout.diagramList[userLayout.currentDiagram],
                SDKTabData: {
                  ...newSDKTabDataForDDL,
                  DDL: action.payload,
                },
              },
            },
          },
        },
      };
    case propertyActionTypes.SET_SDK_ANTIPATTERN:
      let newSDKTabDataForAntipattern: Diagram["SDKTabData"] = {
        Message: "",
        DDL: "",
        Antipattern: "",
        TableList: "",
        Format: "",
      };
      const currentSDKTabDataForAntipattern =
        userLayout.diagramList[userLayout.currentDiagram].SDKTabData;

      if (currentSDKTabDataForAntipattern) {
        newSDKTabDataForAntipattern = { ...currentSDKTabDataForAntipattern };
      }

      return {
        ...state,
        userLayout: {
          ...state.userLayout,
          [state.user]: {
            ...userLayout,
            diagramList: {
              ...userLayout.diagramList,
              [userLayout.currentDiagram]: {
                ...userLayout.diagramList[userLayout.currentDiagram],
                SDKTabData: {
                  ...newSDKTabDataForAntipattern,
                  Antipattern: action.payload,
                },
              },
            },
          },
        },
      };
    case propertyActionTypes.SET_SDK_FILTER:
      let newSDKTabDataForFilter: Diagram["SDKTabData"] = {
        Message: "",
        DDL: "",
        Antipattern: "",
        TableList: "",
        Format: "",
      };
      const currentSDKTabDataForFilter =
        userLayout.diagramList[userLayout.currentDiagram].SDKTabData;

      if (currentSDKTabDataForFilter) {
        newSDKTabDataForFilter = { ...currentSDKTabDataForFilter };
      }

      return {
        ...state,
        userLayout: {
          ...state.userLayout,
          [state.user]: {
            ...userLayout,
            diagramList: {
              ...userLayout.diagramList,
              [userLayout.currentDiagram]: {
                ...userLayout.diagramList[userLayout.currentDiagram],
                SDKTabData: {
                  ...newSDKTabDataForFilter,
                  Filter: action.payload,
                },
              },
            },
          },
        },
      };
    case propertyActionTypes.SET_SDK_TABLE:
      let newSDKTabDataForTable: Diagram["SDKTabData"] = {
        Message: "",
        DDL: "",
        Antipattern: "",
        TableList: "",
        Format: "",
      };
      const currentSDKTabDataForTable =
        userLayout.diagramList[userLayout.currentDiagram].SDKTabData;

      if (currentSDKTabDataForTable) {
        newSDKTabDataForTable = { ...currentSDKTabDataForTable };
      }

      return {
        ...state,
        userLayout: {
          ...state.userLayout,
          [state.user]: {
            ...userLayout,
            diagramList: {
              ...userLayout.diagramList,
              [userLayout.currentDiagram]: {
                ...userLayout.diagramList[userLayout.currentDiagram],
                SDKTabData: {
                  ...newSDKTabDataForTable,
                  TableList: action.payload,
                },
              },
            },
          },
        },
      };
    case propertyActionTypes.SET_SDK_FORMAT:
      let newSDKTabDataForFormat: Diagram["SDKTabData"] = {
        Message: "",
        DDL: "",
        Antipattern: "",
        TableList: "",
        Format: "",
      };
      const currentSDKTabDataForFormat =
        userLayout.diagramList[userLayout.currentDiagram].SDKTabData;

      if (currentSDKTabDataForFormat) {
        newSDKTabDataForFormat = { ...currentSDKTabDataForFormat };
      }

      return {
        ...state,
        userLayout: {
          ...state.userLayout,
          [state.user]: {
            ...userLayout,
            diagramList: {
              ...userLayout.diagramList,
              [userLayout.currentDiagram]: {
                ...userLayout.diagramList[userLayout.currentDiagram],
                SDKTabData: {
                  ...newSDKTabDataForFormat,
                  Format: action.payload,
                },
              },
            },
          },
        },
      };

    case propertyActionTypes.SET_STREAM_ID:
      let streamIDs: Diagram["streamID"] = [];
      const currentStreamIDs =
        userLayout.diagramList[userLayout.currentDiagram].streamID;
      if (currentStreamIDs) {
        streamIDs = [...currentStreamIDs];
      }
      streamIDs.push(action.payload);

      return {
        ...state,
        userLayout: {
          ...state.userLayout,
          [state.user]: {
            ...userLayout,
            diagramList: {
              ...userLayout.diagramList,
              [userLayout.currentDiagram]: {
                ...userLayout.diagramList[userLayout.currentDiagram],
                streamID: streamIDs,
              },
            },
          },
        },
      };

    case propertyActionTypes.DEL_STREAM_ID:
      let streamID: Diagram["streamID"] = [];
      const currentStreamID =
        userLayout.diagramList[userLayout.currentDiagram].streamID;
      if (currentStreamID) {
        streamID = [...currentStreamID];
      } else {
        // If there are no stream IDs to delete just return
        return state;
      }

      streamID = streamID.filter(
        (item) => action.payload !== Object.keys(item)[0]
      );

      return {
        ...state,
        userLayout: {
          ...state.userLayout,
          [state.user]: {
            ...userLayout,
            diagramList: {
              ...userLayout.diagramList,
              [userLayout.currentDiagram]: {
                ...userLayout.diagramList[userLayout.currentDiagram],
                streamID,
              },
            },
          },
        },
      };

    case propertyActionTypes.SHOW_ANTIPATTERN:
      return {
        ...state,
        userLayout: {
          ...state.userLayout,
          [state.user]: {
            ...userLayout,
            diagramList: {
              ...userLayout.diagramList,
              [userLayout.currentDiagram]: {
                ...userLayout.diagramList[userLayout.currentDiagram],
                isShowAntiPattern: action.payload,
              },
            },
          },
        },
      };

    case propertyActionTypes.SET_DISPLAYED_ANTIPATTERN:
      return {
        ...state,
        userLayout: {
          ...state.userLayout,
          [state.user]: {
            ...userLayout,
            diagramList: {
              ...userLayout.diagramList,
              [userLayout.currentDiagram]: {
                ...userLayout.diagramList[userLayout.currentDiagram],
                displayedAntiPattern: action.payload,
              },
            },
          },
        },
      };
    case propertyActionTypes.SET_DYNAMIC_NOTIFY_TEXT:
      return {
        ...state,
        userLayout: {
          ...state.userLayout,
          [state.user]: {
            ...userLayout,
            dynamicNotifyText: {
              ...userLayout.dynamicNotifyText,
              [userLayout.currentDiagram || "global"]: action.payload,
            },
          },
        },
      };
    case propertyActionTypes.SET_QUERY_MODIFIED:
      return {
        ...state,
        userLayout: {
          ...state.userLayout,
          [state.user]: {
            ...userLayout,
            queryModified: {
              ...userLayout.queryModified,
              [userLayout.currentDiagram]: action.payload,
            },
          },
        },
      };
    case propertyActionTypes.CURRENT_DIAGRAM:
      return {
        ...state,
        userLayout: {
          ...state.userLayout,
          [state.user]: {
            ...userLayout,
            currentDiagram: action.payload,
          },
        },
      };

    case propertyActionTypes.SAMPLE_TAB:
      return {
        ...state,
        userLayout: {
          ...state.userLayout,
          [state.user]: {
            ...userLayout,
            sampleTab: action.payload,
          },
        },
      };

    case propertyActionTypes.LAST_ACTIVE_TAB:
      return {
        ...state,
        userLayout: {
          ...state.userLayout,
          [state.user]: {
            ...userLayout,
            lastActiveTab: action.payload,
          },
        },
      };

    case propertyActionTypes.SET_USER:
      const user = action.payload as string;
      // Initialize state if this is a new user
      let userState: PropertyStore["userLayout"][string] | undefined =
        undefined;
      if (!state.userLayout[user]) {
        userState = initialState.userLayout.sample;
      } else {
        userState = state.userLayout[user];
      }

      return {
        ...state,
        user,
        userLayout: {
          ...state.userLayout,
          [user]: {
            ...userState,
            sampleTab: state?.userLayout?.[state?.user]?.sampleTab,
          },
        },
      };

    case propertyActionTypes.SET_FORMAT_AND_PARSE:
      return {
        ...state,
        userLayout: {
          ...state.userLayout,
          [state.user]: {
            ...userLayout,
            formatAndParse: action.payload,
          },
        },
      };

    case propertyActionTypes.SET_IS_QUERY_OPTIMIZED:
      return {
        ...state,
        userLayout: {
          ...state.userLayout,
          [state.user]: {
            ...userLayout,
            isQueryOptimized: action.payload,
          },
        },
      };

    case propertyActionTypes.FORMAT_RESPONSE:
      return {
        ...state,
        userLayout: {
          ...state.userLayout,
          [state.user]: {
            ...userLayout,
            formatResponse: action.payload,
          },
        },
      };

    case propertyActionTypes.PRE_CHECK_DATA:
      return {
        ...state,
        userLayout: {
          ...state.userLayout,
          [state.user]: {
            ...userLayout,
            preCheckData: action.payload,
          },
        },
      };

    case propertyActionTypes.API_TIME_OUT:
      const show = action.payload.show;
      const data = action.payload.data;
      return {
        ...state,
        userLayout: {
          ...state.userLayout,
          [state.user]: {
            ...userLayout,
            diagramList: {
              ...userLayout.diagramList,
              [userLayout.currentDiagram]: {
                ...userLayout.diagramList[userLayout.currentDiagram],
                APITimeOut: { show: show, data: data },
              },
            },
          },
        },
      };

    case propertyActionTypes.PROPERTY_BOX:
      return {
        ...state,
        userLayout: {
          ...state.userLayout,
          [state.user]: {
            ...userLayout,
            diagramList: {
              ...userLayout.diagramList,
              [userLayout.currentDiagram]: {
                ...userLayout.diagramList[userLayout.currentDiagram],
                propertyBox: action.payload,
              },
            },
          },
        },
      };

    case propertyActionTypes.ACTIVE_TAB:
      return {
        ...state,
        userLayout: {
          ...state.userLayout,
          [state.user]: {
            ...userLayout,
            diagramList: {
              ...userLayout.diagramList,
              [userLayout.currentDiagram]: {
                ...userLayout.diagramList[userLayout.currentDiagram],
                activeTab: action.payload,
              },
            },
          },
        },
      };

    case propertyActionTypes.SET_DISABLE_REMIND_MODALS:
      return {
        ...state,
        userLayout: {
          ...state.userLayout,
          [state.user]: {
            ...userLayout,
            disabledRemindModals: userLayout?.disabledRemindModals?.includes(
              action?.payload
            )
              ? userLayout.disabledRemindModals?.filter(
                  (item) => item !== action?.payload
                )
              : [...userLayout?.disabledRemindModals, action.payload],
          },
        },
      };
    case propertyActionTypes.SET_SELECTED_RANGE:
      return {
        ...state,
        userLayout: {
          ...state.userLayout,
          [state.user]: {
            ...userLayout,
            diagramList: {
              ...userLayout.diagramList,
              [userLayout.currentDiagram]: {
                ...userLayout.diagramList[userLayout.currentDiagram],
                selectedRange: action.payload,
              },
            },
          },
        },
      };
    case propertyActionTypes.SET_TABS_MODAL:
      return {
        ...state,
        userLayout: {
          ...state.userLayout,
          [state.user]: {
            ...userLayout,
            tabsModal: {
              ...userLayout.tabsModal,
              [userLayout.currentDiagram]: action.tabsModal,
            },
          },
        },
      };
    default:
      return state;
  }
}
