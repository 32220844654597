/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { useFormik } from "formik";
import axios from "axios";
import * as Yup from "yup";
import Select from "react-select";
import "./feedbackModal.css";
import { setGlobalModalID } from "../../../redux/actions/global";
import { ReactComponent as LogoSvg } from "../../../assets/icons/sonra-logo.svg";
import { useAppDispatch } from "../../../redux/hooks";
import { useAuth } from "../../../auth/AuthContext";

const FeedbackModal = () => {
  const dispatch = useAppDispatch();

  const { isAuthenticated, user } = useAuth();

  const onClose = () => {
    dispatch(setGlobalModalID(""));
  };

  const roleOptions = [
    { value: "1", label: "Developer" },
    { value: "2", label: "Data Architect" },
    { value: "3", label: "Manager" },
    { value: "4", label: "Data Analyst" },
    { value: "5", label: "Other" },
  ];

  const [feedbackState, setFeedbackState] = useState("start");
  const [feedbackType, setFeedbackType] = useState("1");
  const [role, setrole] = useState("1");

  const handleClickRadio = (checked: string, type: string) => {
    if (checked) {
      setFeedbackType(type);
    }
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("required"),
    email: Yup.string().email().required("required"),
    feedback: Yup.string().required("required"),
  });

  const formik = useFormik({
    initialValues: {
      name: user?.nickname ?? "",
      email: user?.email ?? "",
      feedback: "",
    },
    onSubmit: (values) => {
      let request_url =
        "https://f6d808b0.sibforms.com/serve/MUIEAOWiE9kuUIIoik6KCGGNCq4HltKv-WsnSazHYTEchSGOMFOmQxFThp-bBzcECpSgZz0I-c8WHy1WK9Dk7kjXeFz6oJFvdY_ps1Mxla5zp2bbrTmPQQeW3lxNPYfkqCKQ0oGWBIR6UMcY8gyAXDXw13uQWahBzAw3z8IF6S7lFn6wcbmXzCXJEADcTBwcFBCC0m9q9r_7JMtB?isAjax=1";

      const feedbackFormData = new FormData();
      feedbackFormData.append("FIRSTNAME", values.name);
      feedbackFormData.append("EMAIL", values.email);
      feedbackFormData.append("UI_FEEDBACK_ROLE", role);
      feedbackFormData.append("FEEDBACK_TYPE", feedbackType);
      feedbackFormData.append("DESCRIBE_YOUR_FEEDBACK", values.feedback);
      feedbackFormData.append("email_address_check", "");
      feedbackFormData.append("locale", "en");

      axios
        .post(request_url, feedbackFormData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then(() => {
          setFeedbackState("success");
        })
        .catch((err) => {
          console.log(err);
          setFeedbackState("fail");
        });
    },
    validationSchema,
  });

  const preventClick: React.MouseEventHandler<HTMLDivElement> = (event) => {
    event.stopPropagation();
    return false;
  };

  const hideFeedbackPopup = () => {
    dispatch(setGlobalModalID(""));
  };

  return (
    <div
      style={{
        visibility: "visible",
        opacity: "1",
      }}
      className="overlay feedbackpopup"
      onClick={hideFeedbackPopup}
    >
      <div className="feedback-popup" onClick={preventClick}>
        <span className="close" onClick={onClose}>
          &times;
        </span>
        <div className="feedback-content" data-testid="global-modal-feedback">
          <div className="feedback-modal-body">
            <div className="right-panel">
              {
                {
                  success: (
                    <div
                      className="feedback-notify success"
                      data-testid="feedback-form-notify-success"
                    >
                      We will be in touch within the next 2 business days.
                    </div>
                  ),
                  fail: (
                    <div className="feedback-notify fail">
                      Your feedback could not be saved. Please try again.
                    </div>
                  ),
                  default: <></>,
                }[feedbackState]
              }
              <LogoSvg className="feedback-modal-avata" />
              <h1 className="feedback-modal-header1">FlowHigh</h1>

              <h2 className="feedback-modal-header">Feedback</h2>
              <form onSubmit={formik.handleSubmit}>
                <div className="feedback-entry-wrapper">
                  {/* <label className="text" htmlFor="name">
                                        Name:
                                    </label> */}

                  <input
                    className={`entry-text name${
                      formik.errors.name ? " required" : ""
                    }`}
                    name="name"
                    placeholder="Name *"
                    type="text"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    data-testid="feedback-form-name"
                  />
                </div>

                <div className="feedback-entry-wrapper">
                  {/* <label className="text" htmlFor="email">
                                        Email:
                                    </label> */}

                  <input
                    className={`entry-text email${
                      formik.errors.email ? " required" : ""
                    }`}
                    name="email"
                    placeholder="Email *"
                    type="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    data-testid="feedback-form-email"
                  />
                </div>

                <div className="feedback-entry-wrapper">
                  <label className="text" htmlFor="feedbackType">
                    Your Role:
                  </label>
                  <Select
                    className="dropdown"
                    styles={{
                      control: (base) => ({
                        ...base,
                        height: 48,
                        minHeight: 48,
                        fontSize: 14,
                        borderRadius: 4,
                        borderColor: "#3F74F655",
                        borderWidth: 2,
                        color: "#0C4284",
                      }),
                      valueContainer: (base) => ({
                        ...base,
                        height: 48,
                        minHeight: 48,
                        marginLeft: 10,
                      }),
                      indicatorsContainer: (base) => ({
                        ...base,
                        height: 48,
                        minHeight: 48,
                      }),
                      indicatorSeparator: () => ({
                        display: "none",
                      }),
                      menu: (base) => ({
                        ...base,
                        fontSize: 14,
                      }),
                      input: (base) => ({
                        ...base,
                        margin: "0px",
                      }),
                    }}
                    options={roleOptions}
                    value={roleOptions.filter(
                      (option) => option.value === role
                    )}
                    onChange={(e) => {
                      if (e?.value) {
                        setrole(e.value);
                      }
                    }}
                    data-testid="feedback-form-role"
                  />
                </div>

                <div className="feedback-entry-wrapper">
                  <label className="text" htmlFor="feedbackType">
                    Feedback Type:
                  </label>
                  <div>
                    <div className="case-item">
                      <label className={feedbackType === "1" ? "selected" : ""}>
                        <input
                          type="radio"
                          name="feedbackType"
                          checked={feedbackType === "1"}
                          onChange={(e) => {
                            handleClickRadio(e.target.value, "1");
                          }}
                          value="1"
                          required
                          data-testid="feedback-form-type1"
                        />
                        <span>Bug</span>
                      </label>
                    </div>
                    <div className="case-item">
                      <label className={feedbackType === "2" ? "selected" : ""}>
                        <input
                          type="radio"
                          name="feedbackType"
                          checked={feedbackType === "2"}
                          onChange={(e) => {
                            handleClickRadio(e.target.value, "2");
                          }}
                          value="2"
                          required
                          data-testid="feedback-form-type2"
                        />
                        <span>Feature request</span>
                      </label>
                    </div>
                    <div className="case-item">
                      <label className={feedbackType === "3" ? "selected" : ""}>
                        <input
                          type="radio"
                          name="feedbackType"
                          checked={feedbackType === "3"}
                          onChange={(e) => {
                            handleClickRadio(e.target.value, "3");
                          }}
                          value="3"
                          required
                          data-testid="feedback-form-type3"
                        />
                        <span>Other</span>
                      </label>
                    </div>
                  </div>
                </div>

                <div className="feedback-entry-wrapper">
                  <label className="feedback-comment" htmlFor="feedback">
                    Comment:
                  </label>

                  <textarea
                    className={`feedback-textarea${
                      formik.errors.feedback ? " required" : ""
                    }`}
                    style={{
                      minHeight: `${isAuthenticated ? "170px" : "120px"}`,
                    }}
                    rows={2}
                    name="feedback"
                    value={formik.values.feedback}
                    onChange={formik.handleChange}
                    maxLength={500}
                    autoComplete="off"
                    data-testid="feedback-form-body"
                  ></textarea>
                </div>

                <button
                  className="submit-button"
                  type="submit"
                  data-testid="feedback-form-submit"
                >
                  Submit Feedback
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default FeedbackModal;
