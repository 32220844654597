import React from "react";
import { ReactComponent as LoaderSvg } from "../../../assets/icons/loader.svg";
import "./loader.css";

const LoaderComponent = () => {
  return (
    <div className="loader-svg">
      <LoaderSvg />
    </div>
  );
};

export default LoaderComponent;
