import React from "react";
import { addSpecialTab, profilePicture } from "../../../helper/util";
import { useNavigate } from "react-router-dom";
import "./userProfileMenu.css";
import { useAuth } from "../../../auth/AuthContext";

interface UserProfileMenuProps {
  handleLogoutClicked: () => void;
}

const UserProfileMenu = (props: UserProfileMenuProps) => {
  const { user } = useAuth();

  const navigate = useNavigate();

  const [showDropdown, setShowDropdown] = React.useState(false);

  const handleProfileClicked = () => {
    addSpecialTab("My Profile");
    navigate("/userprofile");
  };

  const handleAccountSettingsClicked = () => {
    addSpecialTab("Account Settings");
    navigate("/accountsettings");
  };

  const picture = profilePicture(
    user?.["https://flowhigh.io/user_metadata.firstName"] ?? "",
    user?.["https://flowhigh.io/user_metadata.lastName"] ?? ""
  );

  return (
    <button
      className="user-info"
      data-testid="user-info"
      onBlur={() => setShowDropdown(false)}
      onClick={() => {
        setShowDropdown(!showDropdown);
      }}
    >
      <h5 className="user-picture">{picture}</h5>
      <div className="dropdown-wrapper">
        {showDropdown && (
          <div className="userinfo-dropdown-pane">
            <div className="userinfo-dropdown-pane-top">
              <div
                className="userinfo-dropdown-link"
                data-testid="profile"
                onClick={() => handleProfileClicked()}
              >
                Profile
              </div>
              <div
                className="userinfo-dropdown-link"
                data-testid="acc-settings"
                onClick={() => handleAccountSettingsClicked()}
              >
                Account Settings
              </div>
            </div>
            <div className="split-line"></div>
            <div className="userinfo-dropdown-pane-bottom">
              <div
                className="userinfo-dropdown-link"
                data-testid="logout"
                onClick={props.handleLogoutClicked}
              >
                Log Out
              </div>
            </div>
          </div>
        )}
      </div>
    </button>
  );
};

export default UserProfileMenu;
