import { Dispatch } from "../store";
import sampleDataActionTypes from "../actionTypes/sampleData";
import { Diagram } from "../reducer/property";

export const setSampleDiagramData =
  (data: Diagram["diagramData"], type: "Simple" | "Regular" | "Complex") =>
  (dispatch: Dispatch) => {
    let actionType = sampleDataActionTypes.SET_SIMPLE_SAMPLE;
    if (type === "Regular") {
      actionType = sampleDataActionTypes.SET_REGULAR_SAMPLE;
    }
    if (type === "Complex") {
      actionType = sampleDataActionTypes.SET_COMPLEX_SAMPLE;
    }

    dispatch({
      type: actionType,
      payload: data,
    });
  };
