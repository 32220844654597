/* eslint-disable no-useless-escape */
import React, { useState } from "react";
import { ReactComponent as About1Svg } from "../../assets/icons/about-pane1.svg";
import { ReactComponent as About2Svg } from "../../assets/icons/about-pane2.svg";
import { ReactComponent as About31Svg } from "../../assets/icons/about-pane3-1.svg";
import { ReactComponent as About32Svg } from "../../assets/icons/about-pane3-2.svg";
import "./about.css";

const About = () => {
  const [pane, setPane] = useState(1);

  const handleClickedNavigator = (e) => {
    setPane(parseInt(e.target.getAttribute("value")));
  };

  var navigators = [];
  for (var i = 1; i <= 3; i++) {
    navigators.push(
      <div
        className={`about-navigator` + (pane === i ? ` activated` : ``)}
        onClick={handleClickedNavigator}
        value={i}
        key={`help-navigator` + i}
      >
        <div className="about-navigator-circle"></div>
      </div>
    );
  }

  return (
    <div className="about-container">
      <div className="about-header">
        <div className="about-header-left"></div>
        <div className="about-header-right"></div>
      </div>
      <div className="about-content">
        <div className="about-content-body">
          {
            {
              1: (
                <div className="about-content-body-wrapper about-pane1">
                  <div className="about-caption">About Sonra</div>
                  <div className="about-box">
                    <About1Svg className="about-pane1-image" />
                    <div className="about-text-wrapper">
                      <div className="about-sub-title">
                        <span className="highlight-color">Sonra</span>
                        <span> is Data</span>
                      </div>
                      <div className="about-text-body">
                        Sonra (
                        <a href="https://sonra.io" target="blank">
                          sonra.io
                        </a>
                        ) stands for many things but it literally means data …
                        in Gaelic. Well, being based in Dublin (Ireland), it’s
                        only fair that we took our name from the mother tongue.
                      </div>
                    </div>
                  </div>
                </div>
              ),
              2: (
                <div className="about-content-body-wrapper about-pane2">
                  <div className="about-caption">About Sonra</div>
                  <div className="about-box">
                    <div className="about-text-wrapper">
                      <div className="about-sub-title">
                        <span className="highlight-color">Sonra</span>
                        <span> is Innovation</span>
                      </div>
                      <div className="about-text-body">
                        When you can’t get the answers from existing data
                        solutions, sometimes you have to create your own.
                        <br />
                        <br />
                        Our industry-leading data conversion tool{" "}
                        <a
                          href="https://sonra.io/products/flexter/"
                          target="blank"
                        >
                          Flexter
                        </a>{" "}
                        is a great example. The solution we needed for rapid
                        data conversion was simply not out there—so we built it.
                        <br />
                        <br />
                        Working on the bleeding edge of technology is considered
                        part of the job at Sonra.
                      </div>
                    </div>
                    <About2Svg className="about-pane2-image" />
                  </div>
                </div>
              ),
              3: (
                <div className="about-content-body-wrapper about-pane3">
                  <div className="about-caption">About Sonra</div>
                  <div className="about-box">
                    <div className="about-pane-top">
                      <div className="about-pane-left">
                        <About31Svg className="about-pane31-image" />
                        <ul style={{ marginLeft: "46px" }}>
                          <li>
                            FlowHigh helps data managers and data architects to
                            understand how SQL is used in the enterprise for
                            improved data governance and data lineage:
                          </li>
                          <div className="about-second-li">
                            <li>
                              <p>
                                Perform impact analysis in workflows and data
                                pipelines
                              </p>
                            </li>
                            <li>
                              <p>
                                Identify issues in data flows through root cause
                                analysis
                              </p>
                            </li>
                            <li>
                              <p>Identify critical data in the enterprise</p>
                            </li>
                            <li>
                              <p>
                                See how PII data travels through your workflows
                                and data pipelines
                              </p>
                            </li>
                            <li>
                              <p>
                                Audit the access to tables and columns in your
                                databases journey, data access audit logging
                                etc. (Parser for data governance)
                              </p>
                            </li>
                          </div>
                        </ul>
                      </div>

                      <div className="about-pane-right">
                        <div className="about-text-wrapper">
                          <div className="about-sub-title">
                            <span className="highlight-color">Sonra</span>
                            <span> is Products</span>
                          </div>

                          <div className="about-text-body">
                            <ul style={{ marginLeft: "-40px" }}>
                              <li className="diamond-indent">
                                <b>FlowHigh</b>
                              </li>
                              <p className="italic-highlighted">
                                Lift your data with SQL to the next level.
                              </p>
                              <li>
                                <p>
                                  FlowHigh parses, visualises, formats and
                                  analyses SQL.
                                </p>
                              </li>
                              <li>
                                <p>
                                  FlowHigh helps data engineers, data analysts
                                  and data scientists to write easy to read SQL
                                  that is fast.
                                </p>
                              </li>
                              <li>
                                <p>
                                  FlowHigh helps data managers and data
                                  architectes to reduce costs by identifying and
                                  fixing SQL performance issues & SQL errors:
                                </p>
                              </li>

                              <div className="about-second-li">
                                <li>
                                  <p>
                                    Automatically Identify poorly performing and
                                    badly written SQL.
                                  </p>
                                </li>
                                <li>
                                  <p>
                                    Automate formatting & documentation of SQL.
                                  </p>
                                </li>
                                <li>
                                  <p>
                                    Automatically identify areas for performance
                                    improvement.
                                  </p>
                                </li>
                              </div>
                            </ul>
                          </div>

                          <About32Svg className="about-pane32-image" />
                        </div>
                      </div>
                    </div>
                    <hr className="about-pane3-split-line"></hr>
                    <div className="about-pane-bottom">
                      <div className="about-text-body">
                        <ul style={{ marginLeft: "480px" }}>
                          <li className="diamond-indent">
                            <b>Flexter</b>
                          </li>
                          <p>
                            Converts XML & JSON to a readable format in seconds.
                            Any type, any volume, any size, any speed, any
                            format, any complexity
                          </p>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              ),
            }[pane]
          }
        </div>
        <div className="about-content-right"></div>
      </div>
      <div className="about-pane-navigator-wrapper">
        {navigators}
        <div className="about-navigate-pane-indicator">
          <div>0{pane}</div>
          <div className="highlight-color">/</div>
          <div className="highlight-color">03</div>
        </div>
      </div>
    </div>
  );
};

export default About;
