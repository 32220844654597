import React from "react";
import { ReactComponent as CloseIconSvg } from "../../../assets/icons/close-tab.svg";
import { ReactComponent as LogoSvg } from "../../../assets/icons/sonra-logo.svg";
import { ReactComponent as SignupSuccessSvg } from "../../../assets/icons/signup-success-image.svg";
import { useAppDispatch } from "../../../redux/hooks";
import { setGlobalModalID } from "../../../redux/actions/global";

const UnauthorizedDomain: React.FC = () => {
  const dispatch = useAppDispatch();

  const closeOverlay = async () => {
    dispatch(setGlobalModalID(""));
  };

  const preventClick: React.MouseEventHandler = (event) => {
    event.stopPropagation();
    return false;
  };

  return (
    <div
      className="post-registration-modal modal-overlay"
      onClick={closeOverlay}
    >
      <div
        className="modal-content"
        data-testid="global-modal-unauthorized-domain"
        onClick={preventClick}
      >
        <div className="left-content">
          <LogoSvg className="logo" />
          <div className="caption">Unauthorized Domain</div>
          <div className="verification-text">
            Verify you're not logging in from an unauthorized source. If the
            problem persists, reach out to our support team for help
          </div>{" "}
          <button className="return-button" onClick={closeOverlay}>
            Go to FlowHigh
          </button>
        </div>
        <SignupSuccessSvg className="success" />
        <CloseIconSvg className="close-button" onClick={closeOverlay} />
      </div>
    </div>
  );
};

export default UnauthorizedDomain;
