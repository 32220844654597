/* eslint-disable react-hooks/exhaustive-deps */
import React, { MouseEventHandler, useState } from "react";
import AgreeCheck from "../../commonComponent/agreeCheck";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ReactComponent as TryImage } from "../../../assets/icons/tryfree-image.svg";
import "./dataLineageModal.css";
import { setGlobalModalID } from "../../../redux/actions/global";
import { useAppDispatch } from "../../../redux/hooks";
import { useAuth } from "../../../auth/AuthContext";

const DataLineageModal = () => {
  const dispatch = useAppDispatch();

  const { isAuthenticated, user } = useAuth();

  // states for show
  const [tryState, setTryState] = useState("start");

  // varables for post
  const [selectedCase, setSelectedCase] = useState("business");

  const onClose = () => {
    dispatch(setGlobalModalID(""));
  };

  const handleClickRadio = (checked: string, useCase: string) => {
    if (checked) {
      setSelectedCase(useCase);
    }
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required("required"),
    feedback: Yup.string().required("required"),
    agree: Yup.boolean().oneOf([true], "*").required("*"),
  });

  const formik = useFormik({
    initialValues: {
      email: user?.email ?? "",
      feedback: "",
      agree: false,
    },
    onSubmit: (values) => {
      let request_url =
        "https://f6d808b0.sibforms.com/serve/MUIEALRAMS0kD_1nhnnYrrzEG0LlhZLWkR8N6i8GcrlvfNSu_tidMTzSDuMPvZH3XFJFkhYayUgZglXoYlHCNchE13CuzF9YnNF7IwuwE6qjZzVQd12j-UaRGMtsI7mA-phAW0cJ3LgSyCVlhZUb_byEgX7UMVpdHfGRt77lAYf1H2OubBkSyzpQZaiDSYu8i2u3SwJOleaY2n1I?isAjax=1";

      const tryFormData = new FormData();
      tryFormData.append("EMAIL", values.email);
      tryFormData.append("USE_CASE", selectedCase);
      tryFormData.append("DESCRIBE_YOUR_FEEDBACK", values.feedback);
      tryFormData.append("OPT_IN", "1");
      tryFormData.append("email_address_check", "");
      tryFormData.append("locale", "en");

      axios
        .post(request_url, tryFormData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then(() => {
          setTryState("success");
        })
        .catch((err) => {
          console.log(err);
          setTryState("fail");
        });
    },
    validationSchema,
  });

  const preventClick: MouseEventHandler<HTMLDivElement> = (event) => {
    event.stopPropagation();
    return false;
  };

  const hideTryPopup = () => {
    dispatch(setGlobalModalID(""));
  };
  return (
    <div
      style={{
        visibility: "visible",
        opacity: "1",
      }}
      className="overlay tryModal"
      onClick={hideTryPopup}
    >
      <div className="popup" onClick={preventClick}>
        <span
          className="close"
          onClick={onClose}
          data-testid="close-dataLineage-form"
        >
          &times;
        </span>
        <div className="content" data-testid="global-modal-data-lineage">
          <div className="try-modal-body">
            <div className="left-panel">
              <TryImage className="try-image" />
            </div>
            <div className="right-panel">
              <h2 className="try-modal-header">
                Request access to Data Lineage solution
              </h2>
              {tryState === "start" ? (
                <form onSubmit={formik.handleSubmit}>
                  <div
                    className="try-form-email-wrapper"
                    style={{
                      display: `${isAuthenticated ? "none" : "inline"}`,
                    }}
                  >
                    <label className="entry-label" htmlFor="email">
                      Email:
                    </label>

                    <input
                      className={`email${
                        formik.errors.email ? " required" : ""
                      }`}
                      name="email"
                      placeholder="Email *"
                      type="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      data-testid="dataLineage-email"
                    />
                  </div>

                  <div className="try-form-usecase-wrapper">
                    <label className="entry-label" htmlFor="use_case">
                      Use case:
                    </label>
                    <div>
                      <div className="case-item">
                        <label
                          className={
                            selectedCase === "business" ? "selected" : ""
                          }
                        >
                          <input
                            type="radio"
                            name="USE_CASE"
                            checked={selectedCase === "business"}
                            onChange={(e) => {
                              handleClickRadio(e.target.value, "business");
                            }}
                            value="1"
                            required
                          />
                          <span>
                            I have a <strong>business</strong> use case
                          </span>
                        </label>
                      </div>
                      <div className="case-item">
                        <label
                          className={
                            selectedCase === "personal" ? "selected" : ""
                          }
                        >
                          <input
                            type="radio"
                            name="USE_CASE"
                            checked={selectedCase === "personal"}
                            onChange={(e) => {
                              handleClickRadio(e.target.value, "personal");
                            }}
                            value="2"
                            required
                          />
                          <span>
                            I have a <strong>personal</strong> use case
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="try-form-feedback-wrapper">
                    <label className="entry-label" htmlFor="feedback">
                      Tell us about your use case:
                    </label>

                    <textarea
                      className={`feedback-textarea${
                        formik.errors.feedback ? " required" : ""
                      }`}
                      style={{
                        minHeight: `${isAuthenticated ? "170px" : "120px"}`,
                      }}
                      rows={2}
                      name="feedback"
                      value={formik.values.feedback}
                      onChange={formik.handleChange}
                      maxLength={500}
                      autoComplete="off"
                      data-testid="dataLineage-text-area"
                    ></textarea>
                  </div>

                  <div className="agree-wrapper">
                    <AgreeCheck
                      isChecked={!formik.errors.agree}
                      handleClicked={formik.handleChange}
                      newText={"By checking this box you agree to the "}
                    />
                  </div>

                  <button
                    className="try-button"
                    type="submit"
                    data-testid="dataLineage-form-access-button"
                  >
                    Request Access
                  </button>
                </form>
              ) : (
                <div className="thanks-message">
                  Thanks for your interest in a free trial of the FlowHigh SDK.
                  We will be in touch in the next business day.
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataLineageModal;
