import React, { useEffect, useState } from "react";
import { ReactComponent as PhysicalTableSvg } from "../../assets/icons/physical-table.svg";
import { ReactComponent as InlineTableSvg } from "../../assets/icons/inline-table.svg";
import { ReactComponent as RecursiveTableSvg } from "../../assets/icons/recursive-table.svg";
import { ReactComponent as FunctionTableSvg } from "../../assets/icons/function-table.svg";
import { ReactComponent as PseudoTableSvg } from "../../assets/icons/pseudo-table.svg";
import { ReactComponent as CommonTableSvg } from "../../assets/icons/common-table.svg";
import { ReactComponent as Drop } from "../../assets/icons/drop.svg";

const TableList = ({ type, data, styleData }) => {
  const [headData, setHeadData] = useState("");
  const [attrData, setAttrData] = useState("");
  const [active, setActive] = useState(false);
  const array = [
    "Physical Table",
    "Common Table Expression",
    "Inline View",
    "Recursive CTE",
    "Pseudo Table",
    "Table Function",
  ];

  let obj = {};
  if (type === "physical") {
    obj = {
      name: "Physical Table",
      bgColor: "#F4F7FE",
      color: "#3F74F6",
      logo: <PhysicalTableSvg style={{ borderRight: "1px solid #3F74F6" }} />,
    };
  } else if (type === "common") {
    obj = {
      name: "Common Table Expression",
      bgColor: "#FFF3EC",
      color: "#F6813F",
      logo: <CommonTableSvg style={{ borderRight: "1px solid #F6813F" }} />,
    };
  } else if (type === "inline") {
    obj = {
      name: "Inline View",
      bgColor: "#FCF8FF",
      color: "#A53FF6",
      logo: <InlineTableSvg style={{ borderRight: "1px solid #A53FF6" }} />,
    };
  } else if (type === "table") {
    obj = {
      name: "Table Function",
      bgColor: "#F5F5F5",
      color: "#414D77",
      logo: <FunctionTableSvg style={{ borderRight: "1px solid #414D77" }} />,
    };
  } else if (type === "recursive") {
    obj = {
      name: "Recursive CTE",
      bgColor: "#F2FEFF",
      color: "#009BA5",
      logo: <RecursiveTableSvg style={{ borderRight: "1px solid #009BA5" }} />,
    };
  } else if (type === "pseudo") {
    obj = {
      name: "Pseudo Table",
      bgColor: "#FFF2F4",
      color: "#F62F47",
      logo: <PseudoTableSvg style={{ borderRight: "1px solid #F62F47" }} />,
    };
  }

  useEffect(() => {
    setHeadData(obj.name);
  }, [obj.name]);

  useEffect(() => {
    setActive(false);
  }, [data]);

  const setItem = (item) => {
    setHeadData(item.name);
    setAttrData(item.attributes);
    setActive(true);
  };

  const headingItem = (item) => {
    setHeadData(item);
    setActive(false);
  };

  return (
    <div className="table_item">
      <div
        className="table_item_heading"
        style={{
          backgroundColor: obj.bgColor,
          border: `1px solid ${obj.color}`,
        }}
        onClick={() => headingItem(obj.name)}
      >
        {obj.logo}
        <div>
          <span style={{ color: obj.color }}>{headData}</span>
          {!array.includes(headData) ? (
            <Drop className={`drop_up  ${styleData.fill}`} />
          ) : (
            ""
          )}
        </div>
      </div>
      <div className={`table_item_body_wrapper ${styleData.border}`}>
        {!active
          ? data.map((item, i) => {
              if (item.name !== null) {
                return (
                  <div
                    key={i}
                    className={`table_item_body ${styleData.hover}`}
                    onClick={() => setItem(item)}
                  >
                    <p>{item.name}</p>
                    <Drop className={`table_item_drop ${styleData.fill}`} />
                  </div>
                );
              }
              return <></>;
            })
          : ""}
        <div>
          {active
            ? attrData.map((item, i) => {
                return (
                  <div key={i} className={`table_item_body ${styleData.hover}`}>
                    <p>{item}</p>
                  </div>
                );
              })
            : ""}
        </div>
      </div>
    </div>
  );
};

export default TableList;
