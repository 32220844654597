import log from "loglevel";
import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import Main from "./components/main";
import logLevelSessionStorage from "./logging/loglevelSessionStoragePlugin";
import { ErrorBoundary } from "react-error-boundary";
import { createLogExporter } from "./logging/sendLogsToAPI";
import ErrorPage from "./components/error";
import { useMount } from "react-use";
import { detect } from "detect-browser";
import { useAppDispatch, useAppSelector } from "./redux/hooks";
import { selectDisabledRemindModals } from "./redux/selectors/property";
import { setGlobalModalID } from "./redux/actions/global";
import UnsupportedBrowserModal from "./components/modals/unsupportedBrowser";

const App = () => {
  const dispatch = useAppDispatch();
  const [unsupportedDevice, setUnsupportedDevice] = useState(false);
  const disabledRemindModals = useAppSelector(selectDisabledRemindModals);

  /**
   * Initialize logger
   */
  useMount(() => {
    logLevelSessionStorage(log);
    log.setLevel(log.levels.INFO);
    log.getLogger("default").info("initializing App");
  });

  /**
   * Detect unsupport browser or window size and show relevant modal
   */
  useEffect(() => {
    const browser = detect();
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    const isUnsupportedBrowser =
      !browser || (browser.name !== "chrome" && browser.name !== "firefox");
    if (isMobile) {
      setUnsupportedDevice(true);
    } else if (
      isUnsupportedBrowser &&
      !disabledRemindModals?.includes("unsupported-browser")
    ) {
      dispatch(setGlobalModalID("unsupported-browser"));
    }
    function handleResize() {
      const screenWidth = window.innerWidth;
      const screenHeight = window.innerHeight;
      const isSmallResolution = screenWidth < 1300 || screenHeight < 650;
      if (
        isSmallResolution &&
        !isMobile &&
        !disabledRemindModals?.includes("unsupported-resolution")
      ) {
        dispatch(setGlobalModalID("unsupported-resolution"));
      }
    }

    window.addEventListener("resize", handleResize);
    handleResize(); // Check resolution on initial render

    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabledRemindModals?.length]);

  if (unsupportedDevice) {
    return <UnsupportedBrowserModal type="unsupported-device" />;
  }

  return (
    <div>
      <Routes>
        <Route path="/*" element={<Main />} />
      </Routes>
    </div>
  );
};

class ExtendedApp extends React.Component {
  render() {
    return (
      <ErrorBoundary
        FallbackComponent={ErrorPage}
        onError={createLogExporter()}
      >
        <App />
      </ErrorBoundary>
    );
  }
}

export default ExtendedApp;
