import authActionTypes from "../actionTypes/auth";

export interface User {
  name?: string;
  given_name?: string;
  family_name?: string;
  middle_name?: string;
  nickname?: string;
  preferred_username?: string;
  profile?: string;
  picture?: string;
  website?: string;
  email?: string;
  email_verified?: boolean;
  gender?: string;
  birthdate?: string;
  zoneinfo?: string;
  locale?: string;
  phone_number?: string;
  phone_number_verified?: boolean;
  address?: string;
  updated_at?: string;
  [key: string]: any;
}

export interface AuthStore {
  accessToken: string;
  expiresAt: number;
  error: string;
  refreshToken: string;
  user: User | undefined;
}

interface AuthAction {
  errorDescription: string;
  payload: Payload;
  type: string;
}

interface Payload {
  user: User | undefined;
  expiresAt: number;
  refreshToken: string;
  accessToken: string;
}

const initialState: AuthStore = {
  accessToken: "",
  refreshToken: "",
  error: "",
  expiresAt: 0,
  user: undefined,
};

export default function authReducer(
  state: AuthStore = initialState,
  action: AuthAction
): AuthStore {
  switch (action.type) {
    case authActionTypes.AUTH_SET_INFO:
      return {
        ...state,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
        expiresAt: action.payload.expiresAt,
        user: action.payload.user,
      };
    case authActionTypes.AUTH_CLEAR_INFO:
      return {
        ...state,
        accessToken: "",
        refreshToken: "",
        expiresAt: 0,
        user: undefined,
      };

    case authActionTypes.SET_USER:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        },
      };

    case authActionTypes.SET_ERROR_DESCRIPTION:
      return {
        ...state,
        error: action.errorDescription,
      };
    default:
      return state;
  }
}
