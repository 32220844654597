import React, { useState, useEffect, useCallback } from "react";
import { setDisplayedAntiPattern } from "../../redux/actions/property";
import * as tsutil from "../../helper/tsutil";
import "../sqlBox/ace/mode-parseql";
import "../sqlBox/ace/theme-parseql";
import "./antiPattern.css";
import { selectAntipatternData } from "../../redux/selectors/global";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import _ from "underscore";
import log from "loglevel";
import { EmptyObject, SetRequired } from "type-fest";
import { Diagram } from "../../redux/reducer/property";

interface AntiPatternDisplay {
  type: string;
  number: number;
  severity: string;
  description: "-";
  eltype?: string;
}

const AntiPatternContent = () => {
  const dispatch = useAppDispatch();

  const user = useAppSelector((state) => state.propertyReducer.user);
  const antipatternData = useAppSelector(selectAntipatternData);
  const currentDiagram = useAppSelector(
    (state) => state.propertyReducer.userLayout[user].currentDiagram
  );
  const activeDisplayedAntiPattern = useAppSelector(
    (state) =>
      state.propertyReducer.userLayout[user].diagramList[currentDiagram]
        .displayedAntiPattern
  );
  let parseData = useAppSelector(
    (state) =>
      state.propertyReducer.userLayout[user].diagramList[currentDiagram]
        .optimiseData
  );

  // states to display data
  const [count, setCount] = useState(0);
  const [eltype, setEltype] = useState("antipattern");
  const [patterns, setPatterns] = useState<AntiPatternDisplay[]>([]);
  const [selectedPatternIndex, setSelectedPatternIndex] = useState(0);
  // The data specifying the current antipattern to display, effectively metadata about the type of antipattern
  const [currentAntipatternData, setCurrentAntipatternData] = useState<
    Exclude<typeof antipatternData, EmptyObject>["data"][number] | undefined
  >(undefined);

  const [showDropdown, setShowDropdown] = useState(false);
  const handleToggleDropdown = () => {
    if (count > 1) {
      setShowDropdown(!showDropdown);
    }
  };

  /**
   * Format antipattern data content
   */
  const changeColor = useCallback(() => {
    const highlight = document.getElementsByClassName("highlight");
    if (highlight.length) {
      for (let i = 0; i < highlight.length; i++) {
        const table = highlight[i];
        const tableChildren = table.getElementsByTagName("span");
        for (let j = 0; j < tableChildren.length; j++) {
          const e = tableChildren[j];
          if (e.style.color === "rgb(32, 74, 135)") {
            e.style.color = "#5200FF";
          }
        }
      }
    }
  }, []);

  /**
   * Construct Antipatterns to display, setting relevant state along the way
   */
  useEffect(() => {
    if (activeDisplayedAntiPattern?.count) {
      setCount(activeDisplayedAntiPattern.count);
      setEltype(activeDisplayedAntiPattern.eltype);

      let tmpPatterns: AntiPatternDisplay[] = [];
      if (
        activeDisplayedAntiPattern.pos &&
        tsutil.isParseDataValid(parseData)
      ) {
        tmpPatterns = Array.from(
          new Set(activeDisplayedAntiPattern.pos.split(";"))
        )
          .flatMap<
            ReturnType<typeof tsutil.getAntipatternsOrErrorsByPos>[number]
          >((pos) =>
            tsutil.getAntipatternsOrErrorsByPos(
              parseData as SetRequired<
                Exclude<Diagram["optimiseData"], EmptyObject>,
                "statement"
              >,
              activeDisplayedAntiPattern.eltype,
              pos
            )
          )
          .map((item, i) => ({
            type: "message" in item ? item.message : item.type,
            number: i + 1,
            severity:
              "severity" in item && item.severity !== undefined
                ? item.severity
                : "-",
            description: "-",
            eltype: activeDisplayedAntiPattern.eltype,
          }));
      }
      setShowDropdown(false);
      setPatterns(tmpPatterns);

      if (activeDisplayedAntiPattern.selectedPatternIndex !== undefined) {
        setSelectedPatternIndex(
          activeDisplayedAntiPattern.selectedPatternIndex
        );
      } else {
        setSelectedPatternIndex(0);
      }
    }
  }, [activeDisplayedAntiPattern, dispatch, parseData]);

  /**
   * Set the current antipattern data so HTML can be dangerously set from its contents
   */
  useEffect(() => {
    if (_.isEmpty(antipatternData)) {
      log
        .getLogger("antipattern")
        .warn("Trying to show antipatterns but don't have pattern data");
      return;
    }

    const foundCurrentAntipatternData = (
      antipatternData as Exclude<typeof antipatternData, EmptyObject>
    ).data.find((antipattern) => {
      return antipattern.type === patterns[selectedPatternIndex]?.type;
    });

    if (foundCurrentAntipatternData) {
      setCurrentAntipatternData(foundCurrentAntipatternData);
    }

    changeColor();
  }, [antipatternData, changeColor, patterns, selectedPatternIndex]);

  return (
    <div className="content">
      <div className="anti-pattern-body p-lr">
        <div className={`content ${eltype === "error" ? "error" : ""}`}>
          <div className="sub-block first-sub-block">
            {count > 1 ? (
              <button
                type="button"
                className="dropdown"
                style={{
                  background: showDropdown
                    ? eltype === "error"
                      ? "#FEECEF"
                      : "#FEF6E9"
                    : "none",
                  justifyContent: count > 1 ? "space-between" : "center",
                }}
                onClick={() => {
                  handleToggleDropdown();
                }}
              >
                <span>
                  {(selectedPatternIndex + 1).toString().padStart(2, "0")}
                </span>

                {count > 1 ? (
                  <svg
                    width="9"
                    height="6"
                    viewBox="0 0 9 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 0.999999L4.5 4.5L8 1"
                      stroke={eltype === "error" ? "#F02849" : "#F9A825"}
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                ) : null}
              </button>
            ) : (
              ""
            )}
            <div className={`dropdown-content ${showDropdown ? "" : "hide"}`}>
              {patterns.map((pattern, index) => (
                <div
                  className={`item ${
                    index === selectedPatternIndex ? "selected" : ""
                  }`}
                  onClick={() => {
                    setShowDropdown(false);
                    if (activeDisplayedAntiPattern) {
                      dispatch(
                        setDisplayedAntiPattern({
                          ...activeDisplayedAntiPattern,
                          selectedPatternIndex: index,
                        })
                      );
                    }
                  }}
                  key={pattern.type + index}
                >
                  {(index + 1).toString().padStart(2, "0")}
                </div>
              ))}
            </div>
          </div>
          <div className="Anti-pattern">
            <h3 className="heading">{currentAntipatternData?.name}</h3>
            <div
              className="body"
              dangerouslySetInnerHTML={{
                __html: currentAntipatternData?.content ?? "",
              }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AntiPatternContent;
