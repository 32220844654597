import propertyReducer from "./property";
import layoutReducer from "./layout";
import globalReducer from "./global";
import sampleDataReducer from "./sampleData";
import authReducer from "./auth";
import { combineReducers } from "@reduxjs/toolkit";

const rootReducer = combineReducers({
  propertyReducer,
  layoutReducer,
  globalReducer,
  sampleDataReducer,
  authReducer,
});

export default rootReducer;
