import { batch } from "react-redux";
import globalActionTypes from "../actionTypes/global";
import {
  setAPITimeOut,
  setDynamicNotifyText,
  setOptimiseState,
  setParseState,
  setSDKState,
  setVisualizeState,
} from "../actions/property";
import { PropertyStore } from "../reducer/property";
import { Dispatch, store } from "../store";
import { GlobalStore } from "../reducer/global";
import * as client from "../../client/flowhighClient";
import { components } from "../../generated-types/api";

const prefixWithBearer = (accessToken: string): string => {
  if (!accessToken.startsWith("Bearer")) {
    return `Bearer ${accessToken}`;
  }
  return accessToken;
};

export const showLoadingMask = () => (dispatch: Dispatch) => {
  dispatch({
    type: globalActionTypes.SHOW_LOADING_MASK,
    payload: true,
  });
};

export const hideLoadingMask = () => (dispatch: Dispatch) => {
  dispatch({
    type: globalActionTypes.SHOW_LOADING_MASK,
    payload: false,
  });
};

export const fetchDiagramData =
  (data: client.FetchDiagramDataArg, accessToken: string) =>
  async (dispatch: Dispatch) => {
    const {
      globalReducer: { animation },
    } = store.getState();

    if (data.sqlData === "") {
      throw new Error("empty sql");
    } else {
      if (animation) {
        dispatch(setVisualizeState("in-progress"));
      }
      dispatch(showLoadingMask());

      accessToken = prefixWithBearer(accessToken);

      return client
        .fetchDiagramData(data, accessToken)
        .then((response) => {
          dispatch(hideLoadingMask());
          if (animation) {
            dispatch(setVisualizeState("success"));
          }
          if (response.status === 200) {
            dispatch(setAPITimeOut({ show: false, data: "" }));
            return response.data;
          } else {
            throw response.status;
          }
        })
        .catch((err) => {
          if (err.response) {
            const data = err.response.data;
            dispatch(setAPITimeOut({ show: true, data: data.statusMessage }));
          }

          dispatch(hideLoadingMask());
          if (animation) {
            dispatch(setVisualizeState("fail"));
          }
          throw err;
        });
    }
  };

export function fetchSampleData(
  dataType: {
    type: client.SampleDataType;
    sample: PropertyStore["userLayout"][string]["sampleTab"];
  },
  data?: PropertyStore["userLayout"][string]["diagramList"][string],
  rawInputEnabled?: boolean
): (dispatch: Dispatch) => Promise<components["schemas"]["ParSeQLOutput"]> {
  return async (dispatch: Dispatch) => {
    const {
      globalReducer: { animation },
    } = store.getState();

    if (animation) {
      dispatch(setVisualizeState("in-progress"));
    }
    dispatch(showLoadingMask());

    let sampleDataPromise: ReturnType<typeof client.fetchSampleData>;
    if (dataType.type === "sampleLayout") {
      if (!data || !client.isFetchSampleArg(data)) {
        return Promise.reject(
          "Could not fetchSampleData because diagram is missing required values"
        );
      } else {
        sampleDataPromise = client.fetchSampleData(dataType, data);
      }
    } else {
      sampleDataPromise = client.fetchSampleData(
        dataType,
        undefined,
        rawInputEnabled
      );
    }
    try {
      const response = await sampleDataPromise;
      dispatch(hideLoadingMask());
      if (animation) {
        dispatch(setVisualizeState("success"));
      }
      if (response.status === 200) {
        dispatch(setAPITimeOut({ show: false, data: "" }));
        return Promise.resolve(response.data);
      } else {
        return Promise.reject(response.status);
      }
    } catch (err: any) {
      if (err.response) {
        const data = err.response.data;
        dispatch(setAPITimeOut({ show: true, data: data.statusMessage }));
      }
      dispatch(hideLoadingMask());
      if (animation) {
        dispatch(setVisualizeState("fail"));
      }
      return Promise.reject(err);
    }
  };
}

export const fetchReportsData =
  (accessToken: string) => async (dispatch: Dispatch) => {
    accessToken = prefixWithBearer(accessToken);

    return client
      .fetchReportsData(accessToken)
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: globalActionTypes.SET_REPORTS_DATA,
            payload: response.data,
          });
          return response.data;
        } else {
          throw response.status;
        }
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  };

export const fetchParseData =
  (data: string, rawInputEnabled: boolean, accessToken: string) =>
  async (dispatch: Dispatch) => {
    batch(() => {
      dispatch(showLoadingMask());
      dispatch(setParseState("in-progress"));
      dispatch(setSDKState("in-progress"));
    });

    accessToken = prefixWithBearer(accessToken);

    return client
      .fetchParseData(data, rawInputEnabled, accessToken)
      .then((response) => {
        batch(() => {
          dispatch(hideLoadingMask());
          dispatch(setParseState("success"));
          dispatch(setSDKState("success"));
        });

        if (response.status === 200) {
          dispatch(setAPITimeOut({ show: false, data: "" }));
          return response.data;
        } else {
          console.error(
            "Error fetching parse data, received status",
            response.status
          );
          throw response.status;
        }
      })
      .catch((err) => {
        if (err.response) {
          const data = err.response.data;
          dispatch(setAPITimeOut({ show: true, data: data.statusMessage }));
          dispatch(
            setDynamicNotifyText("Code editor is blank. Submit SQL code")
          );
        }
        batch(() => {
          dispatch(hideLoadingMask());
          dispatch(setParseState("fail"));
          dispatch(setSDKState("fail"));
        });

        throw err;
      });
  };

export const fetchOptimizeData =
  (data: string, accessToken: string) => async (dispatch: Dispatch) => {
    batch(() => {
      dispatch(showLoadingMask());
      dispatch(setOptimiseState("in-progress"));
    });

    accessToken = prefixWithBearer(accessToken);

    return client
      .fetchOptimizeData(data, accessToken)
      .then((response) => {
        batch(() => {
          dispatch(hideLoadingMask());
          dispatch(setOptimiseState("success"));
        });

        if (response.status === 200) {
          dispatch(setAPITimeOut({ show: false, data: "" }));
          return response.data;
        } else {
          console.error(
            "Error fetching optimize data, received status",
            response.status
          );

          throw response.status;
        }
      })
      .catch((err) => {
        if (err.response) {
          const data = err.response.data;
          dispatch(setAPITimeOut({ show: true, data: data.statusMessage }));
        }

        batch(() => {
          dispatch(hideLoadingMask());
          dispatch(setOptimiseState("fail"));
        });

        throw err;
      });
  };

export const fetchAnalyzeData =
  (data: { sql: string }, accessToken: string) =>
  async (
    dispatch: Dispatch
  ): Promise<components["schemas"]["ParSeQLOutput"]> => {
    dispatch(showLoadingMask());

    accessToken = prefixWithBearer(accessToken);

    return client
      .fetchAnalyzeData(data.sql, accessToken)
      .then((response) => {
        dispatch(hideLoadingMask());

        if (response.status === 200) {
          dispatch(setAPITimeOut({ show: false, data: "" }));
          return response.data;
        } else {
          console.error(
            "Error while fetching analyze data, received status ",
            response.status
          );
          throw response.status;
        }
      })
      .catch((err) => {
        if (err.response) {
          const data = err.response.data;
          dispatch(setAPITimeOut({ show: true, data: data.statusMessage }));
        }
        dispatch(hideLoadingMask());
        throw err;
      });
  };

export const setAnalyzeData = (data: string[]) => (dispatch: Dispatch) => {
  dispatch({
    type: globalActionTypes.SET_ANALYZE_DATA,
    payload: data,
  });
};

export const setProduceDiagramFlag = () => (dispatch: Dispatch) => {
  dispatch({
    type: globalActionTypes.SET_PRODUCE_DIAGRAM_FLAG,
  });
};

export const setSampleSqlData = (data: string) => (dispatch: Dispatch) => {
  dispatch({
    type: globalActionTypes.SET_SAMPLE_SQL_DATA,
    payload: data,
  });
};

export const showSDKModal = (data: boolean) => (dispatch: Dispatch) => {
  dispatch({
    type: globalActionTypes.SHOW_SDK_MODAL,
    payload: data,
  });
};

export const setLoginState =
  (data: GlobalStore["loginState"]) => (dispatch: Dispatch) => {
    dispatch({
      type: globalActionTypes.SET_LOGIN_STATE,
      payload: data,
    });
  };

export const setResetPasswordState =
  (data: GlobalStore["resetPasswordState"]) => (dispatch: Dispatch) => {
    dispatch({
      type: globalActionTypes.SET_RESET_PASSWORD_STATE,
      payload: data,
    });
  };

export const showSurveyModal = (data: boolean) => (dispatch: Dispatch) => {
  dispatch({
    type: globalActionTypes.SHOW_SURVEY_MODAL,
    payload: data,
  });
};

export const setAnimation = (data: boolean) => (dispatch: Dispatch) => {
  dispatch({
    type: globalActionTypes.SET_ANIMATION,
    payload: data,
  });
};

export const setSearchText = (data: string) => (dispatch: Dispatch) => {
  dispatch({
    type: globalActionTypes.SET_SEARCH_TEXT,
    payload: data,
  });
};

export const setSearchTab =
  (data: GlobalStore["searchTab"]) => (dispatch: Dispatch) => {
    dispatch({
      type: globalActionTypes.SET_SEARCH_TAB,
      payload: data,
    });
  };

export const setClickSearch = (data: boolean) => (dispatch: Dispatch) => {
  dispatch({
    type: globalActionTypes.SET_CLICK_SEARCH,
    payload: data,
  });
};

export const setSearchBoxOpen =
  (isBoxOpen: boolean) => (dispatch: Dispatch) => {
    dispatch({
      type: globalActionTypes.SET_SEARCH_BOX_OPEN,
      payload: isBoxOpen,
    });
  };

export const setGlobalModalID =
  (modalID: GlobalStore["modalID"]) => (dispatch: Dispatch) => {
    dispatch({
      type: globalActionTypes.SET_GLOBAL_MODAL_ID,
      modalID,
    });
  };

export const setAntipatternData =
  (antipatternData: GlobalStore["antipatternData"]) => (dispatch: Dispatch) => {
    dispatch({
      type: globalActionTypes.SET_ANITPATTERN_DATA,
      antipatternData,
    });
  };

export const setSignupState =
  (data: GlobalStore["signupState"]) => (dispatch: Dispatch) => {
    dispatch({
      type: globalActionTypes.SET_SIGNUP_STATE,
      payload: data,
    });
  };
