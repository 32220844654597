/* eslint-disable react-hooks/exhaustive-deps */
import React, { forwardRef, useCallback, useEffect, useMemo } from "react";
import AceEditor, { IAceEditorProps } from "react-ace";
import Ace from "ace-builds/src-noconflict/ace";
import { batch } from "react-redux";
import { getEditorCommands } from "./editorCommands";
import { convertPosToPoint, isSpecialTab } from "../../helper/util";
import {
  AntipatternFlag,
  setAPITimeOut,
  setCursorPosition,
  setDisplayedAntiPattern,
  setQueryModified,
  setSelectedRange,
  setSqlData,
  setTabsModal,
} from "../../redux/actions/property";
import { useMount, useUpdateEffect } from "react-use";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { LayoutStore } from "../../redux/reducer/layout";
import { setGlobalModalID } from "../../redux/actions/global";
import {
  selectDisplayedAntipattern,
  selectIsSampleTab,
} from "../../redux/selectors/property";
import * as tsutil from "../../helper/tsutil";
import { selectGlobalModalID } from "../../redux/selectors/global";
import { ReactComponent as Copy } from "../../assets/icons/copy.svg";
import { debounce } from "underscore";

let firstLoadFlag = true; // flag to process reload state

export interface EditorComponentProps {
  editorRefToUse: React.MutableRefObject<AceEditor | null>;
  data: string | undefined;
  setData: (data: string | undefined) => void;
  scrollLines: () => void;
  showAntiPatternBar: boolean;
  errorCount: number;
  setErrorCount: (errorCount: number) => void;
  AEL: tsutil.AEL[] | undefined;
  setAEL: (AELs: tsutil.AEL[]) => void;
  setShowAntipatternBar: (showAntiPatternBar: boolean) => void;
  setAntipatternCount: (antipatternCount: number) => void;
  enterCmd: (diagramBoxState: LayoutStore["diagramBoxState"]) => void;
  handleSnackBar: () => void;
  filteredData: (data: string) => void;
  displayAntipattern: boolean;
  setDisplayAntipattern: (value: boolean) => void;
}

const EditorComponent = forwardRef<AceEditor, EditorComponentProps>(
  (editorProps, editorRef) => {
    const dispatch = useAppDispatch();
    const user = useAppSelector((state) => state.propertyReducer.user);
    const currentDiagram = useAppSelector(
      (state) => state.propertyReducer.userLayout[user].currentDiagram
    );

    let optimiseData = useAppSelector(
      (state) =>
        state.propertyReducer.userLayout[user].diagramList[currentDiagram]
          .optimiseData
    );
    const diagramBoxState = useAppSelector(
      (state) => state.layoutReducer.diagramBoxState
    );
    const showAntipattern = useAppSelector(
      (state) =>
        state.propertyReducer.userLayout[user].diagramList[currentDiagram]
          .isShowAntiPattern
    );

    const sampleTab = useAppSelector(
      (state) => state.propertyReducer.userLayout[user].sampleTab
    );
    let sqlData = useAppSelector(
      (state) =>
        state.propertyReducer.userLayout[user].diagramList[currentDiagram]
          .sqlData
    );

    const cursorPosition = useAppSelector(
      (state) =>
        state.propertyReducer.userLayout[user].diagramList[currentDiagram]
          .cursorPosition
    );
    const activeTabModal = useAppSelector(
      (state) =>
        state.propertyReducer.userLayout[user].tabsModal[currentDiagram]
    );
    const highlightIDs = useAppSelector(
      (state) => state.propertyReducer.userLayout[user].highlightIDs
    );
    const highlightNodes = Object.keys(highlightIDs);
    let isHighlighted = useAppSelector(
      (state) =>
        state.propertyReducer.userLayout[user].diagramList[currentDiagram]
          .isHighlighted
    );
    const selectedRange = useAppSelector(
      (state) =>
        state.propertyReducer.userLayout[user].diagramList[currentDiagram]
          .selectedRange
    );
    const sampleSqlData = useAppSelector(
      (state) => state.globalReducer.sampleSqlData
    );

    const searchText = useAppSelector(
      (state) => state.globalReducer.searchText
    );
    const searchTab = useAppSelector((state) => state.globalReducer.searchTab);
    const currentDiagramIsSampleTab = useAppSelector(selectIsSampleTab);
    const displayedAntipattern = useAppSelector(selectDisplayedAntipattern);
    const globalModalID = useAppSelector(selectGlobalModalID);

    // @ts-ignore author is doing something weird here
    const isFirefox = typeof InstallTrigger !== "undefined";

    const {
      editorRefToUse,
      data,
      setData,
      scrollLines,
      showAntiPatternBar,
      errorCount,
      setErrorCount,
      AEL,
      setAEL,
      setShowAntipatternBar,
      setAntipatternCount,
      enterCmd,
      handleSnackBar,
      filteredData,
      setDisplayAntipattern,
    } = editorProps;

    /**
     * Override a function on the ace editor prototype
     * All we're doing here is changing the settings passed to assembleRegExp
     */
    useMount(() => {
      let editor = editorRefToUse?.current?.editor;
      Ace.Editor.prototype.$getSelectionHighLightRegexp = function () {
        var session = this.session;
        var selection = this.getSelectionRange();
        if (selection.isEmpty() || selection.isMultiLine()) return;
        var startColumn = selection.start.column;
        var endColumn = selection.end.column;
        var line = session.getLine(selection.start.row);
        var needle = line.substring(startColumn, endColumn);
        if (needle.length > 5000 || !/[\w\d]/.test(needle)) return;
        var re = this.$search.$assembleRegExp({
          wholeWord: false,
          caseSensitive: false,
          needle: needle,
        });
        var wordWithBoundary = line.substring(startColumn - 1, endColumn + 1);
        if (!re.test(wordWithBoundary)) return;
        return re;
      };
      dispatch(setQueryModified(false));

      if (editor) {
        editor.container.style.lineHeight = "1.4";
        editor.renderer.updateFontSize();
      }
    });

    /**
     * When data changes, set AEL data
     */
    useEffect(() => {
      if (optimiseData !== undefined && sqlData !== undefined) {
        setAEL(tsutil.getAntipatternAndErrorLines(optimiseData, sqlData));
      } else {
        setAEL([]);
      }
    }, [optimiseData, setAEL, sqlData]);

    /**
     * when the AEL changes, set the displayed antipattern
     */
    useEffect(() => {
      if (AEL && AEL.length) {
        const targetAEL = AEL[0];
        if (targetAEL.antipatternsCount) {
          dispatch(
            setDisplayedAntiPattern({
              pos: Array.from(
                new Set(targetAEL.antipatterns.map((ap) => ap.pos))
              ).join(";"),
              eltype: "antipattern",
              count: targetAEL.antipatternsCount,
              selectedPatternIndex: 0,
              lineIndex: targetAEL.lineIndex,
              range: {
                end: {
                  column: targetAEL.antipatterns[0].range.end.column,
                  row: targetAEL.antipatterns[0].range.end.row,
                },
                start: {
                  column: targetAEL.antipatterns[0].range.start.column,
                  row: targetAEL.antipatterns[0].range.start.row,
                },
              },
            })
          );
        } else if (targetAEL.errorsCount) {
          dispatch(
            setDisplayedAntiPattern({
              pos: Array.from(
                new Set(targetAEL.errors.map((error) => error.pos))
              ).join(";"),
              eltype: "error",
              count: targetAEL.errorsCount,
              selectedPatternIndex: 0,
              lineIndex: targetAEL.lineIndex,
              range: {
                end: {
                  column: targetAEL.errors[0].range.end.column,
                  row: targetAEL.errors[0].range.end.row,
                },
                start: {
                  column: targetAEL.errors[0].range.start.column,
                  row: targetAEL.errors[0].range.start.row,
                },
              },
            })
          );
        }
      } else {
        batch(() => {
          dispatch(setDisplayedAntiPattern(undefined));
        });
      }
    }, [AEL, dispatch]);

    useEffect(() => {
      if (activeTabModal) {
        dispatch(setGlobalModalID(activeTabModal));
      } else if (!firstLoadFlag) {
        dispatch(setGlobalModalID(""));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTabModal]);

    // open Anti pattern modal
    const handleClickedAntiPatternMark = useCallback(
      (e: MouseEvent) => {
        e.stopPropagation();
        const target = e.target as HTMLDivElement;

        const AELIndex = Number(target.getAttribute("data-ael-index"));
        const targetAEL = AEL !== undefined ? AEL[AELIndex] : undefined;
        if (targetAEL === undefined) {
          return;
        }
        let eltype = target.getAttribute("data-eltype") as
          | "antipattern"
          | "error";
        let count: number;
        if (eltype === "antipattern") {
          count = targetAEL.antipatternsCount;
        } else {
          count = targetAEL.errorsCount;
        }

        const lineIndex = targetAEL.lineIndex;
        const targetRange =
          eltype === "antipattern"
            ? targetAEL.antipatterns[0].range
            : targetAEL.errors[0].range;

        const pos =
          eltype === "antipattern"
            ? Array.from(
                new Set(targetAEL.antipatterns.map((ap) => ap.pos))
              ).join(";")
            : Array.from(
                new Set(targetAEL.errors.map((error) => error.pos))
              ).join(";");

        dispatch(
          setDisplayedAntiPattern({
            pos,
            eltype,
            count,
            selectedPatternIndex: 0,
            lineIndex: lineIndex,
            range: {
              end: {
                column: targetRange.end.column,
                row: targetRange.end.row,
              },
              start: {
                column: targetRange.start.column,
                row: targetRange.start.row,
              },
            },
          })
        );
        if (eltype === "error") {
          batch(() => {
            dispatch(setGlobalModalID("antipattern"));
            dispatch(setTabsModal("antipattern"));
          });
        } else {
          batch(() => {
            dispatch(setGlobalModalID(""));
          });
        }
      },
      [AEL, dispatch]
    );

    // Highlight text based on active antipattern
    useEffect(() => {
      if (
        !displayedAntipattern ||
        !displayedAntipattern.range ||
        !editorProps?.displayAntipattern
      ) {
        return;
      }

      const highlightRange = new Ace.Range(
        displayedAntipattern.range.start.row,
        displayedAntipattern.range.start.column,
        displayedAntipattern.range.end.row,
        displayedAntipattern.range.end.column
      );

      editorRefToUse?.current?.editor.selection.setRange(highlightRange);
      // @ts-expect-error callback is not required, type is wrong
      editorRefToUse.current.editor.renderer.scrollToLine(
        highlightRange.start.row,
        true,
        true
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [displayedAntipattern, editorProps.displayAntipattern]);

    /**
     * Construct a string representing the HTML to be inserted for an antipattern mark in the editor gutter.
     * The onclick handler is attached later
     * @param top - The top position for the absolutely positioned HTML
     * @param left - The left position for the absolutely positioned HTML
     * @param zIndex
     * @param eltype - The eltype, one of "error" or "antipattern"
     * @param count - The number to display in the antipattern mark
     * @param AELIndex
     * @param lineIndex
     * @returns A string representation of the HTML to be inserted
     */
    const makeAntiPatternOrErrorMark = (
      top: string,
      left: string,
      zIndex: number,
      eltype: string,
      count: number,
      AELIndex: number
    ): string => {
      return (
        `<div class='anti-pattern-mark' style='top:${top}; left:${left}; background-color:"white"; z-index:${zIndex}' data-ael-index='${AELIndex}' data-eltype='${eltype}'>` +
        `<div class='anti-pattern'  data-ael-index='${AELIndex}' data-eltype='${eltype}'>` +
        `<svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">` +
        `<circle cx="8.5" cy="8.5" r="8.5" fill="${
          eltype === "antipattern" ? "#F9A825" : "#F02849"
        }"/>` +
        `</svg>` +
        `<div class='anti-pattern-count' style=color:${
          eltype === "antipattern" ? "#000000" : "#ffffff"
        } >` +
        count +
        `</div>` +
        `</div>` +
        `</div>`
      );
    };

    const showAntiPattern = useCallback(
      (which: "empty" | "both" | "error") => {
        let editor = editorRefToUse?.current?.editor;
        if (!editor) {
          throw new Error("tried to show antipattern with no editor");
        }
        if (!AEL) {
          return;
        }
        // Create anti pattern layer
        let gutterLayer =
          editor.container.getElementsByClassName("ace_gutter-layer")[0];
        let antiPatternLayer = gutterLayer.getElementsByClassName(
          "anti-pattern-wrapper"
        );
        Array.from(antiPatternLayer).forEach((el) => el.remove());
        let div = document.createElement("div");
        div.setAttribute("class", "anti-pattern-wrapper");
        const newAntiPatternLayer = gutterLayer.appendChild(div);

        if (AEL[0]?.antipatternsCount === 0) {
          if (AEL[0]?.errorsCount === 0) {
            // @ts-ignore this is clearly wrong, this shouldn't be passed an array
            dispatch(setDisplayedAntiPattern(antipatternAndErrorLines));
            setShowAntipatternBar(false);
            return;
          }
        } else if (!AEL.length || which === "empty") {
          newAntiPatternLayer.innerHTML = "";
          setShowAntipatternBar(false);
          if (globalModalID === "antipattern") {
            dispatch(setGlobalModalID(""));
          }
          return;
        }
        let runningAntipatternCount = 0;
        let runningErrorCount = 0;
        let antiPatternAndErrorMarks = "";
        AEL.forEach((item, i) => {
          let top = item.setmark * 21 + "px";
          if (which === "both") {
            if (item.antipatternsCount) {
              antiPatternAndErrorMarks += makeAntiPatternOrErrorMark(
                top,
                item.errorsCount ? "-10px" : "0",
                1,
                "antipattern",
                item.antipatternsCount,
                i
              );
              runningAntipatternCount += item.antipatternsCount;
            }
          }
          if (which === "error" || which === "both")
            if (item.errorsCount && item.errors) {
              antiPatternAndErrorMarks += makeAntiPatternOrErrorMark(
                top,
                "0",
                2,
                "error",
                item.errorsCount,
                i
              );
              runningErrorCount += item.errorsCount;
            }
        });
        setAntipatternCount(runningAntipatternCount);
        setErrorCount(runningErrorCount);

        newAntiPatternLayer.innerHTML = antiPatternAndErrorMarks;

        if (AEL.length && diagramBoxState === "analyse") {
          setShowAntipatternBar(true);
        }

        let elems = newAntiPatternLayer.getElementsByClassName(
          "anti-pattern-mark"
        ) as HTMLCollectionOf<HTMLDivElement>;
        for (let iElem = 0; iElem < elems.length; iElem++) {
          elems[iElem].addEventListener(
            "click",
            handleClickedAntiPatternMark,
            false
          );
        }
      },
      [
        editorRefToUse,
        AEL,
        setAntipatternCount,
        setErrorCount,
        diagramBoxState,
        dispatch,
        setShowAntipatternBar,
        globalModalID,
        handleClickedAntiPatternMark,
      ]
    );

    /**
     * onChange handler for the editor
     * Set data in the redux store, unhighlight highlighted text
     */
    const handleChange = useCallback(
      (data: string) => {
        data = data.replace(/&nbsp/g, " ");
        setData(data);
        setDisplayAntipattern(false);
        let nodes = document.querySelectorAll(".node.highlighted");
        if (nodes.length !== 0) {
          nodes.forEach((node) => {
            node.classList.remove("highlighted");
          });
        }
        batch(() => {
          dispatch(setSqlData(data));
          dispatch(AntipatternFlag(false));
          dispatch(setDisplayedAntiPattern(undefined));
          dispatch(setAPITimeOut({ show: false, data: "" }));
        });

        showAntiPattern("empty");
        filteredData(data);
        handleSnackBar();

        if (firstLoadFlag) {
          firstLoadFlag = false;
        }
      },

      // eslint-disable-next-line react-hooks/exhaustive-deps
      [dispatch, filteredData, handleSnackBar, setData, showAntiPattern]
    );

    const setCursor = useCallback(
      (val: typeof cursorPosition) => {
        if (!isSpecialTab(currentDiagram) && editorRefToUse) {
          if (editorRefToUse.current?.editor && val) {
            if (!currentDiagramIsSampleTab) {
              editorRefToUse.current.editor.moveCursorTo(val.row, val.column);
              editorRefToUse.current.editor.focus();
            } else {
              editorRefToUse.current.editor.focus();
            }
          }
        }
      },
      [currentDiagramIsSampleTab, currentDiagram, editorRefToUse]
    );

    // set highlight
    useUpdateEffect(() => {
      if (!editorRefToUse.current) {
        return;
      }
      if (isHighlighted === sqlData && !isSpecialTab(currentDiagram)) {
        let editor = editorRefToUse.current.editor;
        if (highlightNodes.length) {
          highlightNodes.forEach((key) => {
            highlightIDs?.[key]?.forEach((id) => {
              const positions = id.split("-");
              const range = new Ace.Range();
              range.start = convertPosToPoint(sqlData ?? "", positions[0]);
              const end = parseInt(positions[0]) + parseInt(positions[1]);
              range.end = convertPosToPoint(sqlData ?? "", end);

              editor.selection.addRange(range);
              editor.renderer.scrollCursorIntoView(
                { row: range.start.row, column: range.start.column },
                0.5
              );
            });
          });
          dispatch(
            setSelectedRange({
              start: { column: 0, row: 0 },
              end: { column: 0, row: 0 },
            })
          );
        } else if (
          selectedRange?.start?.column === selectedRange?.end?.column &&
          selectedRange?.end?.row === selectedRange?.start?.row
        ) {
          editorRefToUse.current.editor.selection.setRange(
            new Ace.Range(0, 0, 0, 0)
          );
        }
      }
    }, [highlightNodes.length]);

    useUpdateEffect(() => {
      if (editorRefToUse?.current) {
        editorRefToUse.current.editor.setValue(sampleSqlData, -1);
      }
    }, [sampleSqlData]);

    useEffect(() => {
      const val = editorRefToUse?.current?.editor?.getCursorPosition();
      if (val) {
        dispatch(setCursorPosition(val));
      }
    }, [dispatch, editorRefToUse]);

    useEffect(() => {
      const val = editorRefToUse?.current?.editor?.getCursorPosition();
      if (val) {
        setCursor(val);
      }
    }, [editorRefToUse, setCursor]);

    useEffect(() => {
      if (diagramBoxState === "analyse") {
        showAntiPattern("both");
      } else {
        showAntiPattern("error");
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [diagramBoxState, sampleTab, optimiseData, showAntiPattern]);

    /**
     * Set the "changeFold" handler of the editor
     */
    useEffect(() => {
      /**
       * Callback for the changeFold handler of the editor. Include in this effect so that removeEventListener works
       */
      const editorChangeFoldHandler = () => {
        if (editorRefToUse?.current?.editor.getValue() === "") {
          dispatch(AntipatternFlag(false));
          showAntiPattern("empty");
        } else {
          if (diagramBoxState === "analyse" && showAntipattern) {
            showAntiPattern("both");
          } else if (errorCount && showAntipattern) {
            showAntiPattern("error");
          }
        }
      };

      let editor = editorRefToUse?.current?.editor;
      if (editor) {
        editor.session.addEventListener("changeFold", editorChangeFoldHandler);
      }
      return () => {
        if (editor) {
          editor.session.removeEventListener(
            "changeFold",
            editorChangeFoldHandler
          );
        }
      };
    }, [
      optimiseData,
      editorRefToUse,
      dispatch,
      showAntiPattern,
      diagramBoxState,
      showAntipattern,
      errorCount,
    ]);

    useEffect(() => {
      if (!editorRefToUse?.current) {
        return;
      }
      if (searchTab === "left" || searchTab === "both") {
        if (searchText?.length > 1) {
          editorRefToUse.current.editor.findAll(searchText);
          editorRefToUse.current.editor.find(searchText);
        } else {
          editorRefToUse.current.editor.findAll("");
          editorRefToUse.current.editor.find("");
        }
      } else {
        editorRefToUse.current.editor.clearSelection();
      }
    }, [searchText, searchTab, editorRefToUse]);

    useEffect(() => {
      editorRefToUse?.current?.editor?.resize(true);
    }, [data, editorRefToUse, showAntiPatternBar]);

    const selectionChange: IAceEditorProps["onSelectionChange"] = (e) => {
      const aceLines = document.getElementsByClassName("ace_line");
      if (!e.isEmpty()) {
        for (let i = 0; i < aceLines.length; i++) {
          aceLines[i].classList.remove("hover");
        }
      } else {
        for (let i = 0; i < aceLines.length; i++) {
          aceLines[i].classList.add("hover");
        }
      }
    };

    const copyText = () => {
      if (!editorRefToUse?.current) {
        return;
      }
      const editor = editorRefToUse.current.editor;
      const sel = editor.selection.toJSON();
      editor.selectAll();
      editor.focus();
      document.execCommand("copy");
      editor.selection.fromJSON(sel);
    };
    useEffect(() => {
      editorRefToUse.current?.editor.selection.on("changeCursor", () => {
        const selectedRange =
          editorRefToUse.current?.editor.getSelectionRange();
        dispatch(setSelectedRange(selectedRange));
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const selectRange = useCallback(() => {
      if (!editorRefToUse.current?.editor || !selectedRange) {
        return;
      }
      const highlightRange = new Ace.Range(
        selectedRange.start.row,
        selectedRange.start.column,
        selectedRange.end.row,
        selectedRange.end.column
      );

      editorRefToUse.current.editor.selection.setRange(highlightRange);
      editorRefToUse.current.editor.renderer.scrollToLine(
        highlightRange.start.row,
        true,
        true,
        () => {}
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentDiagram]);

    const selectRangeOnTabChange = useMemo(() => {
      return debounce(selectRange, 5, false);
    }, [selectRange]);

    useUpdateEffect(() => {
      selectRangeOnTabChange();
    }, [currentDiagram]);

    return (
      <>
        <button className="copy_btn_sql_editor" onClick={() => copyText()}>
          <Copy className="copy_svg" />
        </button>
        <AceEditor
          className="sql-editor-container"
          mode="parseql"
          theme="parseql"
          name="sqlEditor"
          editorProps={{ $blockScrolling: true }}
          style={{
            height: `${
              !showAntiPatternBar ? "100%" : "78vh" ? "100%" : "78vh"
            }`,
            width: "100%",
            borderTop: "none",
          }}
          setOptions={{
            fontFamily: `${
              isFirefox
                ? "Consolas, monospace"
                : "Roboto Mono,Consolas, monospace"
            }`,
            fontSize: "12px",
            showLineNumbers: true,
            showPrintMargin: false,
            wrap: false,
            hScrollBarAlwaysVisible: false,
            vScrollBarAlwaysVisible: false,
            tabSize: 4,
            cursorStyle: "smooth",
          }}
          value={data}
          commands={getEditorCommands(enterCmd)}
          placeholder="Enter your query here"
          onChange={handleChange}
          onSelectionChange={selectionChange}
          onScroll={scrollLines}
          ref={editorRef}
        />
      </>
    );
  }
);

export default EditorComponent;
