import React from "react";
import "./optionSelector.css";

const OptionItem = ({ option, selectedOption, setSelectedOption }) => {
  return (
    <div
      className={`option-item${
        option.value === selectedOption ? " selected" : ""
      }`}
      onClick={() => {
        setSelectedOption(option.value);
      }}
    >
      <div className="option-item-circle"></div>
      <div className="option-item-text">{option.label}</div>
    </div>
  );
};

export default OptionItem;
