/* eslint-disable import/no-anonymous-default-export */

enum AuthActionTypes {
  AUTH_SET_INFO = "AUTH_SET_INFO",
  AUTH_CLEAR_INFO = "AUTH_CLEAR_INFO",
  AUTH_SET_INFO_LOADING = "AUTH_SET_INFO_LOADING",
  SET_USER = "SET_USER",
  SET_ERROR_DESCRIPTION = "SET_ERROR_DESCRIPTION",
}

export default AuthActionTypes;
