import ddl from "../../assets/data/snippets/.ddl.py";
import format from "../../assets/data/snippets/.format.py";
import sql from "../../assets/data/snippets/.message.py";
import ap from "../../assets/data/snippets/.ap.py";
import tables from "../../assets/data/snippets/.tables.py";
import filter from "../../assets/data/snippets/.filter.py";
import join from "../../assets/data/snippets/.join.py";
import sortAndGroup from "../../assets/data/snippets/.sort_and_group.py";
import { useCallback } from "react";
import { useSelector } from "react-redux";

const usePyodideHook = () => {
  const Pyodide = window.Pyodide;

  const user = useSelector((state) => state.propertyReducer.user);
  const currentDiagram = useSelector(
    (state) => state.propertyReducer.userLayout[user].currentDiagram
  );
  const activeDiagram = useSelector(
    (state) =>
      state.propertyReducer.userLayout[user].diagramList[currentDiagram]
  );
  let SDKData = useSelector(
    (state) =>
      state.propertyReducer.userLayout[user].diagramList[currentDiagram].SDKData
  );
  let sqlData = useSelector(
    (state) =>
      state.propertyReducer.userLayout[user].diagramList[currentDiagram].sqlData
  );
  let formatData = useSelector(
    (state) =>
      state.propertyReducer.userLayout[user].diagramList[currentDiagram]
        .formatData
  );
  SDKData = activeDiagram ? SDKData : {};
  sqlData = activeDiagram ? sqlData : {};
  formatData = activeDiagram ? formatData : {};

  const handlePyData = async (script) => {
    try {
      const output = Pyodide.runPython(await (await fetch(script)).text());
      return output;
    } catch (err) {
      console.error(err);
      return "";
    }
  };

  const handlePyScript = useCallback(async () => {
    if (Pyodide !== undefined) {
      Pyodide.globals.set("res", JSON.stringify(SDKData));
      Pyodide.globals.set("formatData", JSON.stringify(formatData));
      Pyodide.globals.set("sqlData", JSON.stringify(sqlData));
      try {
        const DDL = await handlePyData(ddl);
        const Message = await handlePyData(sql);
        const Format = await handlePyData(format);
        const Antipattern = await handlePyData(ap);
        const Tablelist = await handlePyData(tables);
        const Filter = await handlePyData(filter);
        const Join = await handlePyData(join);
        const SortGroup = await handlePyData(sortAndGroup);
        return {
          DDL: DDL,
          Message: Message,
          Format: Format,
          Antipattern: Antipattern,
          Tablelist: Tablelist,
          Filter: Filter,
          Join: Join,
          SortGroup: SortGroup,
        };
      } catch (err) {
        console.error(err);
        return {
          DDL: "",
          Message: "",
          Format: "",
          Antipattern: "",
          Tablelist: "",
          Filter: "",
          Join: "",
          SortGroup: "",
        };
      }
    }
    // eslint-disable-next-line
  }, [SDKData, Pyodide]);

  return {
    handlePyScript,
  };
};
export default usePyodideHook;
