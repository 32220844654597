import { RootState } from "../store";

export const selectIsSampleTab = (state: RootState) => {
  const user = state.propertyReducer.user;
  const currentDiagram = state.propertyReducer.userLayout[user].currentDiagram;
  return state.propertyReducer.userLayout[user].diagramList[currentDiagram]
    .isSampleTab;
};

export const selectSampleTab = (state: RootState) => {
  const user = state.propertyReducer.user;
  return state.propertyReducer.userLayout[user].sampleTab;
};

export const selectDisplayedAntipattern = (state: RootState) => {
  const user = state.propertyReducer.user;
  const currentDiagram = state.propertyReducer.userLayout[user].currentDiagram;
  return state.propertyReducer.userLayout[user].diagramList[currentDiagram]
    .displayedAntiPattern;
};

export const selectDisabledRemindModals = (state: RootState) => {
  const user = state.propertyReducer.user;
  return state.propertyReducer.userLayout[user].disabledRemindModals;
};
