import React, { useCallback } from "react";
import "./unsupportedBrowserModal.css";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { setGlobalModalID } from "../../../redux/actions/global";
import { ReactComponent as BrokenLightbulbSvg } from "../../../assets/icons/broken-light-bulb.svg";
import { ReactComponent as CloseIconSvg } from "../../../assets/icons/close-tab.svg";
import { ReactComponent as UnsupportedResolution } from "../../../assets/icons/unsupported-resolution.svg";
import { selectDisabledRemindModals } from "../../../redux/selectors/property";
import { setDisabledRemindModals } from "../../../redux/actions/property";

interface UnsupportedBrowserModalProps {
  type: string;
}

const UnsupportedBrowserModal = (props: UnsupportedBrowserModalProps) => {
  const dispatch = useAppDispatch();

  const disabledRemindModals = useAppSelector(selectDisabledRemindModals);

  const closeModal = useCallback(() => {
    dispatch(setGlobalModalID(""));
  }, [dispatch]);

  const checkboxOnChangeHandler = () => {
    dispatch(setDisabledRemindModals(props?.type));
  };

  const getTitle = () => {
    let title;
    switch (props?.type) {
      case "unsupported-browser":
        title = "Unsupported browser!";
        break;

      case "unsupported-resolution":
        title = "A low screen resolution!";
        break;

      case "unsupported-device":
        title = "Unsupported device!";
        break;

      default:
        break;
    }
    return title;
  };
  const getDescription = () => {
    let description;
    switch (props?.type) {
      case "unsupported-browser":
        description = (
          <div className="instructions">
            You are using an unsupported browser. Use{" "}
            <span className="browser">Firefox</span> or{" "}
            <span className="browser">Chrome</span>.
          </div>
        );
        break;

      case "unsupported-resolution":
        description = (
          <div className="instructions">
            Your screen resolution is too low.
            <br /> Use <span className="browser supported-size">
              1300x650
            </span>{" "}
            px screen resolution or higher
          </div>
        );
        break;

      case "unsupported-device":
        description = (
          <div className="instructions">
            You are using a mobile device . Use a{" "}
            <span className="browser supported-size">laptop</span> or{" "}
            <span className="browser supported-size">desktop device</span>
          </div>
        );
        break;

      default:
        break;
    }
    return description;
  };

  return (
    <div className="unsupported-browser-modal modal-overlay">
      <div
        className="modal-content-wrap"
        data-testid={`global-modal-${props?.type}`}
      >
        {props.type !== "unsupported-device" && (
          <CloseIconSvg className="modal-close-button" onClick={closeModal} />
        )}
        <div className="modal-content">
          {props?.type !== "unsupported-browser" ? (
            <UnsupportedResolution />
          ) : (
            <BrokenLightbulbSvg className="lightbulb-icon" />
          )}
          <div className="callout">
            {getTitle()}
            <br />
            <span className="dash">—</span>
          </div>
          {getDescription()}
          {props.type !== "unsupported-device" ? (
            <div className="opt-out">
              <input
                type="checkbox"
                checked={disabledRemindModals?.includes(props.type)}
                onChange={checkboxOnChangeHandler}
              />{" "}
              Don't remind me again
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default UnsupportedBrowserModal;
